<template>
    <div class="headAndContent">
      <div class="header">
        <div class="logo-and-tabs">
          <div class="logo" @click="goToHome"><img src="//file.dhunting.com/official_website/v1/logo_head.png" /></div>
          <div class="tabs">
<!--            <a-tabs :default-active-key="1" :activeKey="currentIndex" @change="changeIndex">-->
<!--              <a-tab-pane :tab="item" v-for="(item,index) in tabs" :key="index">-->
<!--              </a-tab-pane>-->
<!--            </a-tabs>-->
            <div class="tabs-item-wrap">
              <div class="tabs_item center" :class="currentIndex==index ? 'active_item':''" @click="changeIndex(index)" v-for="(item,index) in tabs" :key="index">{{item}}</div>
            </div>
<!--            <div class="tab-line"><div class="tab-active-line"></div></div>-->
          </div>
        </div>
        <div class="right_btn">
<!--          <div class="contact-btn" :class="currentIndex==-2?'active-contact-btn  wow bounce':''" @click="handleProductContact">产品咨询</div>-->
          <div class="contact-btn" @mouseleave="leaveProductContact" @mouseenter="enterProductContact">
            <div class="title-icon">
              <span class="title">产品咨询</span>
              <a-icon v-show="isShowChooseProductType" type="up" />
              <a-icon v-show="!isShowChooseProductType" type="down" />
            </div>
            <div v-show="isShowChooseProductType" class="product-type">
              <div @click="toBuyHunterSystem" :class="isHunter ? 'active-hunter-or-hr': ''" @mouseenter="enterHunter" @mouseleave="leaveHunter" class="hunter">
                <span>猎头/RPO/外包版</span>
                <a-icon v-if="isHunter" class="icon" type="right" />
              </div>
              <div @click="clickShowHrTable" :class="isHr ? 'active-hunter-or-hr': ''" @mouseenter="enterHr" @mouseleave="leaveHr" class="hr">
                <span>HR版</span>
                <a-icon v-if="isHr" class="icon" type="right" />
              </div>
            </div>
          </div>
          <a-button class="login_btn" @click="login">登录</a-button>
        </div>
      </div>
<!--      改版后hr系统的表单-->
      <a-modal
          dialogClass="new-pc-hr-modal"
          :visible="isShowHrTable"
          @cancel="cancelShowHrTable"
          :closable="true"
          :footer="null"
      >
        <div class="title">
          请完善以下信息
        </div>
        <div class="title marginB24">Dhunting专业顾问会在一个工作日内与您联系</div>
        <div class="form">
          <a-form-model
              :model="form"
              :rules="rules"
              :hideRequiredMark="true"
              ref="ruleForm"
          >
            <a-form-model-item label="公司名称" prop="companyName">
              <a-input v-model="form.companyName" placeholder='请输入公司名称'/>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="userName">
              <a-input v-model="form.userName" placeholder='请输入您的姓名'/>
            </a-form-model-item>
            <a-form-model-item label="电话" prop="phone">
              <a-input v-model="form.phone" placeholder='请输入电话号码'/>
            </a-form-model-item>
            <a-button type="primary" @click="handleSubmit" class="submit-btn">
              立即咨询
            </a-button>
          </a-form-model>
        </div>
      </a-modal>
      <successModal :is-visible="visible" type="order" @cancel="cancel"></successModal>
    </div>
</template>

<script>
import {consultationSave} from "@/api/request";
import successModal from "@/views/PCPage/productContact/component/successModal";
export default {
  components: { successModal },
  mounted() {
    this.$bus.$on("changeTabsIndex", (data) => {
      this.currentIndex = data;
      // console.log(data)
    })
    this.$bus.$on('openHrTable',()=>{
      this.isShowHrTable=true
    })
  },
  data() {
    return {
      isHunter:false,//是否选中猎头系统
      isHr:false,//是否选中hr系统
      visible:false,
      isShowChooseProductType:false,
      isShowHrTable:false,
      currentIndex:0,
      tabs:['产品概览','猎头/RPO/外包版','HR版','关于递航','文章资讯'],
      routes:['/home','/hunterSystem','/hrSystem','/aboutUs','/indexArticle'],
      form:{
        companyName: "",  //公司名称
        userName: '',
        phone:''
      },
      rules:{
        companyName: [{ required: true, message: '请输入公司名称', trigger: ['blur', 'change'] },],
        userName: [
          { required: true, message: '请输入姓名', trigger: ['blur', 'change'] },
        ],
        phone: [{
          required: true,
          len: 11,
          pattern: RegExp(/(^(\+?0?86-?)?1[3456789]\d{9}$)|(^(\+?852-?)?[569]\d{3}-?\d{4}$)|(^(\+?886-?|0)?9\d{8}$)|(^(0065)\d{8}$)/),
          message: "请输入正确的手机号",
          trigger: ['blur', 'change']
        }]
      },
    };
  },
  methods: {
    clickShowHrTable(){
      this.isShowHrTable=true
    },
    cancelShowHrTable(){
      this.isShowHrTable=false
      // this.$bus.$emit('closeHrSystem')
    },
    //提交表单
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          consultationSave(this.form).then(res => {
            if(res.data.code === 200) {
              // this.$message.success('提交成功');
              let form = {
                companyName: "",  //公司名称
                userName: '',
                phone:'',
              };
              this.form = JSON.parse(JSON.stringify(form));
              //关闭表单弹框，打开成功弹框
              this.isShowHrTable=false
              this.visible=true
            }
            else {
              this.$message.error(res.data.message);
            }
          })
        }
        else {
          return false;
        }
      });
    },
    // 预约成功弹窗关闭
    cancel() {
      this.visible = false;
    },
    enterHunter(){
      this.isHunter=true
    },
    leaveHunter(){
      this.isHunter=false
    },
    enterHr(){
      this.isHr=true
    },
    leaveHr(){
      this.isHr=false
    },
    //鼠标移入或移出产品咨询
    enterProductContact(){
      this.isShowChooseProductType=true
    },
    leaveProductContact(){
      this.isShowChooseProductType=false
    },
    toBuyHunterSystem(){
      this.$router.push('/productContact')
    },
    changeIndex(index) {
      // console.log(indexHome)
      this.currentIndex = index
      // const element=document.querySelector('.tab-active-line')
      // if(!element) return
      // element.style.transform= `translate3d(${this.currentIndex*110}px, 0px, 0px)`
      // element.style.transition='all 0.5s'

      this.$router.push(this.routes[index])
    },
    login() {
      window.open("https://h.dhunting.com/login")
    },
    handleProductContact() {
      this.currentIndex = -2
      this.$router.push('/productContact')
    },
    goToHome(){
      this.$router.push('/home')
    }
  },
  watch:{
    //这个是路由跳转+tab切换最终的解决方案
    $route:{
      handler(val){
        // console.log(val,'xin');//新路由信息
        // console.log(oldval,'old');//老路由信息
        if(val.path.indexOf('home')!==-1){
          this.currentIndex=0
        }
        if(val.path.indexOf('hunterSystem')!==-1){
          this.currentIndex=1
        }
        if(val.path.indexOf('hrSystem')!==-1){
          this.currentIndex=2
        }
        if(val.path.indexOf('aboutUs')!==-1){
          this.currentIndex=3
        }
        if(val.path.indexOf('indexArticle')!==-1){
          this.currentIndex=4
        }
        if(val.path.indexOf('productContact')!==-1){
          this.currentIndex=-2
        }
      },
      // 深度观察监听
      deep: true,
      //必须上来就监听一次
      immediate:true
    }
  },
  destroyed() {
    // 销毁时，删除监听滚动事件，否则其他.vue文件也会受影响
    window.removeEventListener('scroll',this.homeScroll)
  }
}
</script>

<style lang="less" scoped>
.headAndContent{
  /* 这个后面再改 */
  /* width: 1440Px; */
  /* height: 900px; */
  position: fixed;
  top: 0;
  left: 0;
  //padding-bottom: 15px;
  //仅低于蒙层的层级1000
  z-index: 999;
  background-color: #FFFFFF;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.logo-and-tabs{
  display: flex;
  justify-content: center;
  align-items: center;
}
.header{
  //max-width: 1440Px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo{
  width: 159px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  //position: relative;
  //top: -3px;
}
.logo img{
  width: 100%;
  height: 100%;
}
.tabs{
  margin-left: 60px;
}
.tabs-item-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs_item{
  box-sizing: border-box;
  cursor: pointer;
  padding-bottom: 8px;
  color: #8D8E99;
  margin-right: 48px;
  font-weight: 400;
  font-size: 16px;
  //消除切换tabs和产品咨询时发生的高度抖动
  //height: 33.5px;
}
.tabs_item:hover{
  color: #0055FF;
}

.active_item{
  border-bottom: 2px solid #0055FF;
  color: #0055FF;
  font-weight: 500;
  font-size: 16px;
}
.tab-line{
  width: 550px;
  height: 2px;
}
.tab-active-line{
  width: 80px;
  height: 2px;
  background-color: #0055FF;
  transform: translateX(0px);
}
.right_btn{
  /* margin-left: 342px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-btn {
  position: relative;
  margin-right: 48px;
  color: #8d8e99;
  font-weight: 400;
  font-size: 16px;
  cursor:pointer;
  height: 38px;
  line-height: 38px;
  box-sizing: border-box;
  //padding-bottom: 8px;
  //padding-top: 4px;
  .title-icon{
    .title{
      margin-right: 8px;
    }
  }
  .product-type{
    position: absolute;
    width: 200px;
    height: 116px;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-size: 16px;
    color: #1B1C33;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 22px;
    .hunter,.hr{
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon{
        font-size: 12px;
      }
    }
    .active-hunter-or-hr{
      background: #F2F6FF;
      border-radius: 4px;
      color: #0055FF;
    }
  }
}
.contact-btn:hover {
  color: #0055FF;
}
.active-contact-btn {
  border-bottom: 2px solid  #0055FF;
  color: #0055FF;
}
.login_btn {
  width: 144px;
  height: 38px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color:  #1B1C33!important;
  border: 1px solid #C8C8CC;
  border-radius: 8px;
}
.right_btn /deep/ .ant-btn{
  border-color:#C8C8CC!important;
}
.login_btn:hover {
  color: #0055FF!important;
  border-color: #0055FF!important;
}

.tabs{
  position: relative;
  top: 3px;
}
.tabs /deep/ .ant-tabs-bar{
  border-bottom: none!important;
}
.tabs /deep/ .ant-tabs-tab{
  font-weight: 400;
  font-size: 16px;
  color: #8d8e99;
}
.tabs /deep/ .ant-tabs-tab:active,
.tabs /deep/ .ant-tabs-tab:hover{
  color: #0055FF!important;
}
.tabs /deep/ .ant-tabs-tab-active{
  color: #0055FF
}
.tabs /deep/ .ant-tabs-ink-bar{
  background-color: #0055FF;
}
.tabs /deep/ .ant-tabs-bar{
  margin-bottom: 0;
}
.marginB24{
  margin-bottom: 24px;
}
.submit-btn{
  width: 416px;
  height: 40px;
  background: #0055FF;
  border-radius: 8px;
  padding: 10px 188px;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin-top: 20px;
}
</style>
<style lang="less">
.new-pc-hr-modal{
  width: 480px!important;
}
.new-pc-hr-modal .ant-modal-content{
  border-radius: 16px;
  width: 480px;
  //height: 520px;
  padding: 32px;
}
.new-pc-hr-modal .ant-modal-body{
  padding: 0;
}
.new-pc-hr-modal .ant-modal-body .title{
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1B1C33;
}
.new-pc-hr-modal .ant-form-item-label{
  height: 35px!important;
}
.new-pc-hr-modal .ant-form-item{
  margin-bottom: 10px;
}
.new-pc-hr-modal .submit-btn{
  height: 40px;
  width: 416px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0055FF;
  border-radius: 8px;
  color: #FFFFFF;
}

//以下修改所有新增弹框的样式
.new-pc-hr-modal,.new-pc-enterprise-free,.new-pc-enterprise-criterion,.new-pc-soho-pro,.new-pc-soho-pro{
  .top .title-wrap .title{
    margin-left: 16px!important;
  }
  .ant-form{
    .ant-form-item-control-wrapper,.ant-form-item-control,.ant-input{
      height: 32px!important;
    }
    .ant-form-explain{
      top: 35px;
    }
  }
  .invitation-code{
    .input-wrap,.ant-input{
      height: 32px!important;
    }
  }
  .ant-modal-content .title-wrap{
    height: 24px;
    .icon{
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.new-pc-hr-modal{
  .ant-form{
    .ant-form-item-control-wrapper,.ant-form-item-control,.ant-input{
      height: 40px!important;
    }
    .ant-form-explain{
      top: 40px;
    }
  }
}
.new-pc-soho-pro .modal-line-2{
  margin-top: 0px!important;
}
</style>
