<template>
  <div class="article_item_wrap">
    <div class="artitle_item" v-for="(item,index) in headArticle" :key="index" @click="goToArticleDetail(item.owArticleId)">
      <div class="artitle_item_img">
<!--        <img src="//file.dhunting.com/official_website/v1/article.png" alt="">-->
        <img :src="item.banner" alt="">
      </div>
      <div class="artitle_item_info">{{item.title}}</div>
      <div class="artitle_item_author">
        <slot name="advar"><div class="author_img"><img src="//file.dhunting.com/official_website/v1/article.png" alt=""></div></slot>
        <slot name="author"><div class="author_name">发到手机开发进度</div></slot>
        <div class="author_time">{{item.updateTime}}</div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      headArticle:[]
    }
  },
  methods:{
    goToArticleDetail(data){
      // this.$router.push({path:'/indexArticle/articleDetail',query:{
      //     owArticleId:data
      //   }})
      //编程式导航打开新页面
      let routeData = this.$router.resolve({
        query: {owArticleId:data},
        path:'/indexArticle/articleDetail'
      });
      window.open(routeData.href, '_blank');
    }
  },
  mounted() {
    this.headArticle= JSON.parse(localStorage.getItem('headArticle'))
    if(this.headArticle.length==0){
      this.$emit('articleIsNone')
    }
  }
}
</script>
<style scoped>
.article_item_wrap{
  display: flex;
  /*justify-content: space-between;*/
  justify-content: center;
}
.artitle_item{
  /*width: 300px;*/
  width: 360px;
  background: #FFFFFF;
  box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding-top: 80px;
  padding-bottom: 21px;
  cursor:pointer;
  margin-right: 60px;
}
.artitle_item:last-child{
  margin-right: 0;
}
.artitle_item_img{
  /*width: 300px;*/
  width: 360px;
  height: 150px;
}
.artitle_item_img > img{
  width: 360px;
  height: 100%;
  object-fit: cover!important;
}
.artitle_item_info{
  padding-left: 20px;
  padding-right: 20px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1B1C33;
  margin-top: 43px;
  margin-bottom: 63px;
}
.artitle_item_author{
  display: flex;
  padding-left: 23px;
}
.author_img{
  width: 20px;
  height: 20px;
}
.author_img > img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.author_name{
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
  line-height: 20px;
  color: #1B1C33;
}
.author_time{
  font-size: 14px;
  color: #A9A9AF;
  line-height: 20px;
}
</style>