import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "@/router/routes";

Vue.use(VueRouter)

const router =new VueRouter({
    mode:'history',
    routes,
    scrollBehavior() {
        // 始终滚动到顶部
        return { x: 0,y:0 }
    },
})

//导航守卫，如果是邀请链接则自动跳转
router.beforeEach((to, from, next) => {
    const { inviteUserId } = to.query
    if (to.path === '/home' && inviteUserId) {
        navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? next({
            path:'/productContact/soHoFree',
            query:{ inviteUserId }
        }) : next({path:'/productContact', query:{ inviteUserId }}
        );
    } else {
        next()
    }
})

//重写push方法和replace方法
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location, onResolve, onReject) {
    // 如果指定了成功或者失败的回调
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }
    //没有成功或者失败的回调就传空
    return originalPush.call(this, location,()=>{},()=>{})
}

VueRouter.prototype.replace = function (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalReplace.call(this, location, onResolve, onReject)
    }
    return originalReplace.call(this, location,()=>{},()=>{})
}


//百度自动推送
router.afterEach((to) => {
    // 每次路由变化后，将当前页面的 URL 地址通过 push 方法推送给百度
    if (typeof window !== 'undefined' && /baidu\.com/.test(window.location.hostname)) {
        const _hmt = window._hmt || [];
        _hmt.push(['_trackPageview', to.fullPath]);
    }
})

export default router
