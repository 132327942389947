<template>
  <div class="privacy-policy-page">
    <!--        <img :src="closeImg" alt="close" class="close" @click="showPrivacyWindow" @mouseenter="changeClose" @mouseleave="changeClose">-->
    <div class="privacy-policy-page-title">
      <h1 class="Privacy-title">递航隐私政策</h1>
      <h3>生效时间：2021年 11月 11 日</h3>
    </div>
    <br>
    <!--        <div class="line"></div>-->
    <div>
      <br/>
      本《个人信息保护政策》（以下简称“本政策”）适用于递航Dhunting旗下所有的产品及服务（以下简称“递航”或“平台”），您通过平台的产品及服务而接入的其他第三方产品或服务除外。<br/>
      <br/>
      请您务必在点击同意本政策之前，审慎阅读、充分理解各条款内容，特别是涉及个人信息收集及处理相关条款、免除或者限制责任的条款、法律适用和争议解决条款。<span
        class="strong">个人敏感信息、免除或者限制责任等重点条款将以粗体标识，您应重点阅读。</span>如您对政策有任何疑问，可按照本政策列明方式与递航取得联系。<br/>
      <br/>
      当您点击确认同意本政策，即表示您已充分阅读、理解且接受本政策的全部内容，并与递航达成一致，本政策对您及递航均具有法律约束力。<br/>
      <br/>
      <span class="strong">一、定义</span><br/>
      <br/>
      1、用户或您：需具有享受递航相关服务的民事行为及责任能力，按照《中华人民共和国民法典》、《中华人民共和国劳动法》、《中华人民共和国未成年人保护法》等相关法律规定，结合平台产品内容及服务特点，平台所称用户或您，不包含如下主体：自然人未满十六周岁的；自然人系无民事行为能力人的。<br/>
      如您未满十六周岁或属于无民事行为能力人的，请立即告知您的监护人或法定代理人，
      并由监护人或法定代理人陪同立即终止使用平台，并勿向平台提供任何个人信息。<br/>
      如您为十六周岁以上限制民事行为能力人的，请您务必在监护人或法定代理人陪同下阅读本政策并由监护人或法定代理人代理完成点击确认、同意行为。平台收集到点击确认、同意本政策操作记录即视为该同意行为系您的监护人或法定代理人作出且您及您的监护人或法定代理人已充分阅读、理解且接受本政策的全部内容，并与递航达成一致，本政策对您及您的监护人或法定代理人、递航均具有法律约束力。<br/>
      2、递航旗下产品及服务：指直猎（上海）网络科技有限公司通过下述途径向您提供的产品及服务：包括但不限于递航APP、web端、相关微信开放平台账号或小程序，
      以及供第三方网站和应用程序使用的递航软件开发工具包（SDK）和应用程序编程接口（API）等。<br/>
      3、关联公司：是指平台主体直接参股或间接控制的其他企业，以及同受某一企业控制的两个或多个企业。<br/>
      4、其他第三方产品或服务：包括但不限于递航以外的其他交易相对方、第三方网站以及第三方服务提供者为您提供的产品或服务。<br/>
      5、合作伙伴：指递航服务提供过程中涉及的第三方信息系统、支付机构、微信/钉钉等<br/>
      用于第三方登录的平台，以及递航为实现部分功能、提供相关客户服务而进行合作的其他第三方主体或平台。<br/>
      <span class="strong">6、个人信息：以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。如，姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。<br/>
                    7、个人敏感信息：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健 康受到损害或歧视性待遇等的个人信息。如，身份证件号码、个人生物识别信息、银行账户、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14 岁以下（含）儿童的个人信息等。<br/>
                    8、基本业务功能：又称基本功能，是指根据相关法律、行政法规、部门规章和规范性文件的规定，平台应为消费侧用户（不包括服务供给侧用户）提供的基本服务及功能。用户拒绝提供运行基本功能所需的必要个人信息以外的其他个人信息的，不影响平台为用户提供基本业务服务。<br/>
                    9、扩展业务功能，又称附加功能，是指平台除基本业务功能以外的其他功能。常见的扩展业务功能如：基本业务功能基础上的一些衍生服务或新型业务、提高产品或服务的使用体验的附加功能（如语音识别、图片识别等）、提升产品或服务的安全机制的扩展功能等（如收集密保邮箱、指纹等）。<br/>
                    10、必要个人信息：是指保障平台基本功能服务正常运行所必需的个人信息，缺少该信息平将无法实现或提供基本功能服务。<br/>
                    11、个人信息处理：是指包括个人信息的收集、储存、使用、加工、传输、提供、公开等活动。<br/></span>
      <br/>
      <span class="strong">二、个人信息保护</span><br/>
      <br/>
      本部分将帮助您了解以下内容：<br/>
      （一）平台如何收集和使用您的信息<br/>
      （二）平台如何使用 Cookie 等同类技术<br/>
      （三）平台如何委托处理、共享、转让、公开披露您的信息<br/>
      （四）平台如何保存您的信息<br/>
      （五）平台如何保护您的信息<br/>
      （六）您的权利<br/>
      （七）平台如何保护未成年人的信息<br/>
      （八）本政策如何更新<br/>
      <br/>
      <span class="strong">（一）平台如何收集和使用您的信息</span><br/>
      1、求职用户<br/>
      1.1基本业务功能<br/>
      为向您提供“求职招聘信息交换”基本功能服务，平台需通过以下方式收集、处理您的个人信息。如您不同意授权平台收集、处理的，平台将无法为您提供包括基本服务在内的所有服务。
      <br/>
      1.1.1注册用户<br/>
      您在使用递航提供的服务时，首先需要成为递航的注册用户并需要向递航提供您本人的<span
        class="underLine strong">手机号码</span>，递航将通过发送短信验证码的方式来验证您的身份是否有效。<br/>
      1.1.2创建简历<br/>
      如您以求职用户身份注册、使用递航服务时，您需要创建自己的简历。在您创建简历时，应提供姓名、性别、出生日期、身份（学生或职场人），如果您是职场人，还需提供公司名称、职位、当前所在地，如果您是学生，还需提供<span
        class="underLine strong">邮箱、教育信息</span>。您登陆后，还可以继续完善您的简历信息，您可以选择补充或修改您的学校名称、专业、时间、学历教育信息，行业、入职时间、职能、月薪、<span
        class="strong underLine">邮箱</span>。您还可以补充或修改您的求职意向，求职意向中包括期望职位、期望薪资、期望行业、期望地点、核心优势。您完善简历信息有助于企业更快了解您的信息，也有助于递航为您推荐合适的招聘信息，提供个性化的服务推荐和更优的服务体验，但如果您不填写这些补充信息，不会影响您使用递航的基本功能。<br/>
      1.1.3展示简历<br/>
      a.当您创建完毕简历后，您可以选择隐藏在或者特定的范围内公开简历。具体操作途径如下：<br/>
      <span class="strong">递航小程序或app端：我的 > 隐私设置 > 谁能查看我的简历</span><br/>
      当您选择的简历可见范围为“企业HR、猎头”、“企业HR”、“猎头”时，在您选择公开简历范围内的递航用户可以查看、分享您上传、填写除联系方式外的简历信息。递航对外展示您的信息包括头像、姓名或对外展示名称、性别、地点、年龄、学历、工作时间、薪资，最近工作的公司名称、职位，技能标签、期望行业。<br/>
      <span class="strong">b.真实姓名保护</span><br/>
      <span class="strong">为避免您的姓名信息被泄露、滥用，平台为您提供真实姓名保护功能并默认开启。您可在平台相应位置自行选择开启或关闭该功能，具体操作途径如下：<br/>
                    递航小程序或app端：我的 > 隐私设置 > 真实姓名保护 <br/>
                    如您选择开启“真实姓名保护”功能，招聘方在各场景下获取到的姓名信息或简历内均不显示您的真实姓名，实际显示为“X 先生/女士”或“姓氏+**”，如“刘先生”或“刘**”；<br/>
                    如您选择关闭“真实姓名保护”功能，为在必要场景保护您的个人信息，招聘方主动查看、转发您的简历时，仍然以“姓氏+**”方式显示您的姓名。除前述场景外，递航将尊重您的个人意愿，显示您的真实姓名。<br/>
                    无论您是否勾选“真实姓名保护”功能，招聘方在转发您的简历过程中（包括站内或站外），均不显示您的真实姓名。<br/>
                    <span class="underLine">c.您知悉并同意，在您选择公开简历范围内的递航用户可以付费查看您的简历以获取您填写或上传的简历信息。<br/></span></span>
      1.1.4投递简历<br/>
      当您进行简历投递时，递航会收集您的投递记录，方便您能查看投递职位的进展及面试状态等，您亦可在此处获取企业查看/获取您简历的记录。<br/>
      1.1.5为向您展示更契合您求职需求的职位，提供搜索、订阅功能，向您展现或推荐相关程度更高（而非普遍推送）的订阅信息、信息流、广告，以便为您提供更适合的定制化服务，我们会收集您在使用递航平台时的<span
        class="strong"><span
        class="underLine">上网记录、设备信息及浏览记录</span>，经匿名化或去标识化处理后使用</span>。如我们将这类非个人信息与其他信息结合用于识别特定用户身份，或与您的其他信息结合使用的，将另行获取您的授权。<br/>
      1.2扩展业务功能<br/>
      本部分内容将向您告知平台所涉及的扩展业务功能以及相关个人信息收集、处理事项。点击同意本隐私政策不视为您已向平台授权本部分内容所涉及的个人信息。在您使用平台扩展业务服务过程中，平台将在扩展业务场景再次告知并以明示方式获取您的授权同意。如您不同意向平台授权扩展业务功能相关个人信息的，平台不会收集和使用相关信息，同时不影响您正常使用平台的基本业务功能。与单独授权场景所涉及的个人信息处理或平台功能相关更详细的情况及处理规则，以本政策为补充。<br/>
      1.2.1视频面试<br/>
      当您主动发起或者被邀请进行视频面试时，在您同意并授权递航访问您设备的<span
        class="strong underLine">麦克风和相机</span>后，双方可以在递航平台上进行视频面试。如果您不授权递航访问麦克风和相机，您将无法使用视频面试服务，但不会影响您使用递航的其他功能、服务。<br/>
      1.2.2调研<br/>
      递航可能根据您向平台提供的相关联系方式，以电话、短信、邮件或者推送的方式邀请您参与与人力资源服务相关信息调研。<br/>
      1.2.3搜索、订阅功能<br/>
      当您使用递航提供的搜索、订阅功能时，为了给您带来更便捷的搜索服务并不断完善递航产品和/或服务，递航会收集您查询的关键字信息以及您在使用递航服务时所浏览信息。<br/>
      1.2.4推送<br/>
      为了向您展现或推荐相关程度更高（而非普遍推送）的订阅信息、信息流、广告，以便为您提供更适合的定制化服务，平台需收集您的<span
        class="strong underLine">日志信息、浏览记录、使用行为</span>，在您<span
        class="strong underLine">选择公开简历</span>后，收集您填写的简历信息；<br/>
      为了提高求职或招聘效率及成功率，经您同意后，递航可能会对收集的招聘信息和求职信息进行匹配，向您进行职位推送，职位推送可能采取人工、邮件、短信、电话等多种方式。<br/>
      但如果您不需上述服务，您可以关闭推送功能，<span class="strong">在递航 APP或小程序内可以通过 “我的”→ “设置”→“新消息通知”，开启或者关闭一项或多项消息通知服务；</span>如涉及邮件、短信的推送信息，您可以根据邮件或者短信内容提示退订服务。您选择退订后，不会影响您使用递航的其他功能。<br/>
      1.2.5支付功能<br/>
      在您选择购买递航的服务时，递航会根据法律规定记录、保存在递航上的服务信息、交易信息。您可以选择第三方支付机构所提供的支付服务，根据您的选择，您需要提供<span
        class="strong underLine">开户行、账户、银行账户</span>或者您选择的第三方支付时必要信息。如果需要发票，您还需要提交纳税人识别号。支付功能本身并不收集您的信息，但递航需要将您的<span
        class="strong underLine">订单号</span>与<span class="strong underLine">交易金额信息</span>与支付机构共享以实现其确认您的支付指令并完成支付。<br/>
      1.2.6在线沟通<br/>
      当您与求职用户或招聘用户在递航上进行线上沟通时，在一方主动发起，另一方同意的情况下，可能需要交换联系方式，联系方式包括<span
        class="strong underLine">微信、电话号码</span>；在沟通中可能需要发送简历；在沟通面试邀请中，可能需要交换联系方式、地址。<br/>
      如果招聘用户在沟通中存在违法违规或违反平台规则的行为，您可以通过个人主页上的“举报投诉”进行举报。<br/>
      1.2.7客户服务<br/>
      当您在递航发布信息、申诉、注销账户或者进行咨询时，为了方便与您联系或帮助您解决问题，递航可能需要您提供姓名、<span
        class="strong underLine">手机号码</span>、电子邮件、简历、<span class="strong underLine">证件号码</span>信息。同时，递航可能会利用您认证后的联系的信息与您直接沟通或互动，联系信息包括您的手机号码、电子邮件。为保证您的账号安全，客服在接受您的咨询反馈时，会使用您的账号信息核验您的身份。如您拒绝提供上述信息，可能部分功能无法使用，同时无法向您及时反馈申诉、注销或咨询结果。<br/>
      1.2.8平台安全<br/>
      为了保障您使用递航服务的人身、财产安全，更好地预防求职诈骗财产风险、人身安全风险，以及更准确地识别违反法律法规及递航相关协议、规则的行为，递航会记录、整合、使用您的<span
        class="strong underLine">常用设备信息</span>（包括 ANDROID_ID、OAID）<span class="strong underLine">、网络标识信息、使用记录信息</span>，
      以及递航关联公司、合作第三方取得您授权或依法共享的信息，用于判断您的账号风险、进行身份验证、检测递航认为存在风险的行为以及防范平台安全事件，并采取必要的记录、审计、分析、处置措施。<br/>
      1.2.9人才测评<br/>
      为向您提供人才测评相关服务，平台需收集您的用户 ID、性别、出生年月，以供向您提供客观、准确的测评结果。您可根据需要选择是否公开您的测评结果。<span
        class="strong underLine">当您选择公开时， 您知悉并同意招聘用户付费查看您的测评结果内容。</span>平台可能将前述个人信息匿名化或去标识化处理后，进行数据及样本分析、常模搭建。如平台将这类非个人信息与其他信息结合用于识别特定用户身份，或与您的其他信息结合使用的，将另行获取您的授权。<br/>
      <span class="strong">1.2.10电话号码保护<br/>
                    为了保护您的个人信息，避免该部分信息被泄露或滥用，在您使用平台为您提供招聘服务过程中，会默认开启手机号码隐私保护服务，即只有招聘方指定且绑定的电话号码才可通过递航系统呼叫并联系到您。<br/>
                    为保障平台服务质量、帮助平台处理纠纷及保障平台安全，在为您开启电话号码保护服务后，您与猎头或hr通过递航系统联系的<span
            class="underLine">通话内容</span>会被录音。有关上述录音信息的收集和使用规则如下：<br/>
                    a.当您在使用平台服务期间出现纠纷时，平台可调取并使用录音信息作为解决纠纷的参考依据；<br/>
                    b.录音只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。在您的个人信息超出保留期间后，平台会根据适用法律的要求删除您的个人信息，或使其匿名化处理。如遇投诉、尚未处理完毕的纠纷等，平台将适当延长录音保存期限。<br/></span>
      <span class="strong">2、招聘用户<br/>
                    2.1注册用户<br/></span>
      您在使用递航提供的服务时，首先需要成为递航的注册用户并需要向递航提供您本人的<span
        class="strong underLine">手机号码</span>，递航将通过发送短信验证码的方式来验证您的身份是否有效。<br/>
      <span class="strong">2.2招聘用户认证</span> <br/>
      您在递航平台上公开发布招聘信息前需要完成相关认证。您需要提供真实姓名、性别、国籍、<span class="strong underLine"> 身份证号码（或其他证件号码）或手持原件、面部特征信息</span>，所在企业名称、担任岗位。根据不同的认证场景，可能还需要提供<span
        class="strong underLine">企业邮箱、企业招聘授权书、手持营业执照原件、营业执照及  身份证原件照片、营业执照复印件（需加盖企业公章或合同章）</span>其中的一种或多种信息以证明您可以代表该企业进行招聘。如果您提交的企业是<span
        class="strong underLine">首次</span>在递航上公开发布招聘信息的，您还需要提供<span class="strong underLine">营业执照复印件（需加盖企业公章或合同章）、企业招聘授权书</span>和<span
        class="strong underLine">相关资质</span>以验证该企业的合法性，认证通过后该职位可正常对外显示。如您在钉钉上已经完成以上认证，您授权同意钉钉向递航同步您在钉钉实名认证、在职认证的姓名、职务、企业名称信息，您同时成为递航的用户，您授权同意已在钉钉发布的职位信息同步在递航上发布。<br/>
      <span class="strong">2.3发布职位信息<br/></span>
      <span class="strong underLine">您完成招聘用户认证后，才能使用对外发布职位信息服务</span>。您发布的职位信息会在递航Dhunting小程序或App上展示。基于相关法律法规的要求，在您使用本服务时，您需要手动填写您的职位所在地。递航不会将职位所在地用作实现上述功能之外的其他用途。<br/>
      <span class="strong">2.4视频面试</span><br/>
      当您主动发起或者被邀请进行视频面试时，在您同意并授权递航访问您设备的<span
        class="strong underLine">麦克风和相机后</span>，双方可以在递航平台上进行视频面试。如果您不授权递航访问麦克风和相机，您将无法使用视频面试服务，但不会影响您使用递航的其他功能、服务。<br/>
      <span class="strong">2.5调研</span><br/>
      您同意递航可能以电话、短信、邮件或者推送的方式邀请您参与与人力资源服务相关信息调研。<br/>
      <span class="strong">2.6搜索、订阅功能</span><br/>
      当您使用递航提供的搜索、订阅功能时，为了给您带来更便捷的搜索服务并不断完善递航产品和/或服务，递航会收集您查询的关键字信息以及您在使用递航服务时所浏览信息。<br/>
      <span class="strong">2.7推送</span><br/>
      为了向您展现或推荐相关程度更高（而非普遍推送）的订阅信息、信息流、广告，以便为您提供更适合的定制化服务，您同意递航收集您的发布的职位信息、<span
        class="strong underLine">搜索信息、浏览信息、下载信息、沟通信息</span>。<br/>
      为了提高求职或招聘效率及成功率，经您同意后，递航可能会对收集的招聘信息和求职信息进行匹配，向您进行选人推送、递航服务介绍，职位推送可能采取人工、邮件、短信、电话等多种方式。<br/>
      但如果您不需上述服务，您可以关闭推送功能，在递航 APP或小程序内可以通过<span class="strong underLine">“我的”→“隐私设置”→开启或者关闭“个性化内容推荐”</span>；如涉及邮件、短信的推送信息，您可以根据
      邮件或者短信内容提示退订服务。您选择退订后，不会影响您使用递航的其他功能。<br/>
      <span class="strong">2.8支付功能</span><br/>
      在您选择购买递航的服务时，递航会根据法律规定记录、保存在递航上的服务信息、交易信息。您可以选择第三方支付机构所提供的支付服务，根据您的选择，您需要提供<span
        class="strong underLine">开户行、账户、银行账户</span>或者您选择的第三方支付时必要信息。如果需要发票，您还需要提交纳税人识别号。支付功能本身并不收集您的信息，但递航需要将您的<span
        class="strong underLine">订单号</span>与<span class="strong underLine">交易金额信息</span>与支付机构共享以实现其确认您的支付指令并完成支付。<br/>
      <span class="strong">2.9在线沟通</span><br/>
      当您与求职用户在递航上进行线上沟通时，在一方主动发起，另一方同意的情况下， 可能需要交换联系方式，联系方式包括<span
        class="strong underLine">微信、电话号码</span>；在沟通中可能需要发送简历；在沟通面试邀请中，可能需要交换联系方式、地址。<br/>
      如果招聘用户在沟通中存在违法违规或违反平台规则的行为，您可以通过沟通页面上的“举报”进行举报。<br/>
      <span class="strong">2.10客户服务</span><br/>
      当您在递航发布信息、申诉、注销账户或者进行咨询时，为了方便与您联系或帮助您解决问题，递航可能需要您提供姓名、手机号码、电子邮件、简历、<span
        class="strong underLine">证件号码</span>信息。同时，递航可能会利用您认证后的联系的信息与您直接沟通或互动，联系信息包括您的手机号码、电子邮件。为保证您的账号安全，客服在接受您的咨询反馈时，会使用您的账号信息核验您的身份。如您拒绝提供上述信息，可能部分功能无法使用，同时无法向您及时反馈申诉、注销或咨询结果。<br/>
      3、通过第三方软件开发工具包（SDK）收集信息<br/>
      出于技术需要，递航在向求职用户及招聘用户提供部分功能和/或服务时均需要使用第三方 SDK 技术。平台或下述 SDK
      收集或使用您的以下信息，递航会以明示方式告知您，在您授权同意后再获取相关信息。如未取得您的授权，递航或第三方将不会收集和使用相关信息，也不会再反复弹窗向您获取授权。与授权场景所涉及的个人信息处理或平台功能相关更详细的情况及处理规则，以本政策为补充。目前这些
      SDK 包括：<br/>
      3.1小米推送、个推消息推送、魅族消息推送（魅族 Flyme 系统）、华为推送、OPPO 推送、VIVO 推送<br/>
      对应的功能或服务：实现向用户推送系统通知或重要消息<br/>
      提供 SDK 技术的第三方：小米、个推、魅族、华为、OPPO、VIVO<br/>
      需要获取您的信息或权限：操作系统版本、设备型号、本机设备信息、UUID、IP 地址、应用列表、MAC地址、AAID（应用的匿名设备标识）、网络信息状态<br/>
      3.2 360 加固、中国信息通信研究院<br/>
      对应的功能或服务：安全、防破解<br/>
      需要获取您的信息或权限：操作系统版本、设备型号、本机设备信息、MAC 地址、网络信息状态<br/>
      3.3高德地图<br/>
      对应的功能或服务：为了向用户提供地图查看、职位所在地选择功能提供SDK技术的第三方：高德需要获取您的信息或权限：外部储存状态、网络类型、网络服务运营商、IMEI、MAC地址、WiFi信息<br/>
      3.4声网<br/>
      对应的功能或服务：视频面试、招聘用户的视频认证<br/>
      需要获取您的信息或权限：相机权限、麦克风权限、操作系统版本、本机设备信息、设备型号、IP地址、设备制造商、网络信息状态、设备电量、通话状态<br/>
      3.5听云<br/>
      对应的功能或服务：为统计分析您在App内的使用效果，改进用户体验<br/>
      需要获取您的信息或权限：存储权限、日志信息读取、操作系统版本、设备型号、本机设备信息、IP 地址、<span
        class="strong underLine">APP 内的页面浏览</span>、点击记录、MAC
      地址、网络信息状态、广告标识符（IDFA）、性别、年龄<br/>
      需向您特别提示的是：递航<span class="strong">平台本身不会在您使用平台过程中收集您的位置信息</span>，但为实现各地域网络质量情况和性能耗时协助分析的目的，<span
        class="strong">听云 SDK </span>可能会在<span class="strong">经您明示授权同意后</span>收集使用您的<span
        class="strong underLine">位置信息</span>；为实现个城市访问平台用户占比统计。<br/>
      3.6微信支付、支付宝支付<br/>
      对应的功能或服务：向用户提供在线支付购买增值服务和/或产品的服务<br/>
      提供 SDK 技术的第三方：微信、蚂蚁金服<br/>
      需要获取您的信息或权限：设备信息、网络信息状态、MAC 地址、IP 地址、应用列表、读写存储<br/>
      需向您特别提示的是：<span class="strong">递航平台本身不会在您使用平台过程中收集您的位置信息</span>，但为实现登录账号、支付过程中安全风险控制的目的，<span
        class="strong">支付宝支付SDK</span>可能会在<span class="strong">经您明示授权同意后</span>收集、使用您的<span
        class="strong underLine">位置信息</span>。<br/>
      3.7钉钉分享、微信分享<br/>
      对应的功能或服务：职位分享、简历分享提供 SDK 技术的第三方：钉钉、微信<br/>
      需要获取您的信息或权限：操作系统版本、设备型号、本机设备信息、IP 地址、网络连接权限<br/>
      3.8阿里云<br/>
      对应的功能或服务：域名安全、域名防劫持提供 SDK 技术的第三方：阿里巴巴<br/>
      需要获取您的信息或权限：本机设备信息、网络信息状态<br/>
      3.9阿里云 WAF APP 防护<br/>
      对应的功能或服务：提升 APP 安全性，降低 APP 流量被中间人劫持、篡改、伪造，导致信息泄露的风险<br/>
      提供 SDK 技术的第三方：淘宝（中国）软件有限公司<br/>
      需要获取您的信息或权限：设备标识符（包括 MAC 地址、IMEI、IDFA、 OAID）、设备信息（包含设备品牌、设备型号、操作系统、设备加速度器）、网络环境信息（包括入网IP
      地址）<br/>
      3.10阿里云人脸识别<br/>
      对应的功能或服务：实现动作交互和照片采集功能，验证用户的身份、判断操作真实性，识别欺诈、作弊行为<br/>
      提供 SDK 技术的第三方：阿里云计算有限公司<br/>
      需要获取您的信息或权限：访问电话状态、读取外部存储空间的文件、写入外部存储权限以及访问摄像头权限<br/>
      3.11腾讯云音视频<br/>
      对应的功能或服务：在职认证二次核验<br/>
      提供 SDK 技术的第三方：腾讯云实时音视频<br/>
      需要获取您的信息或权限：设备生产商、设备型号、操作系统、屏幕分辨率、系统语言类型、网络类型、通话状态<br/>
      4、递航 APP 权限获取说明<br/>
      在您使用递航的部分功能和/或服务可能需要您自行开启以下权限。您有权自行决定开启或关闭这些权限，开启即代表您授权递航可以收集和使用这些信息来实现相关功能。如果您不使用这些功能和/或服务，您可以选择不开启或者关闭，递航不会收集该部分信息，
      且关闭不影响基本业务功能使用。与权限获取场景所涉及的个人信息处理或平台功能相关更详细的情况及处理规则，以本政策为补充。<br/>
      4.1使用相机：开启相机权限，为向您提供人脸识别、客服协助视频认证、拍照上传图像（如，头像、企业
      LOGO、意见反馈等）、在聊天界面发送图片、扫一扫登录 PC 端、视频面试相关功能。<br/>
      4.2读写存储：开启读写存储权限，方便存储图片、上传简历、视频、下载文件、更新应用。<br/>
      4.3使用麦克风：开启麦克风权限，为向您提供聊天界面发送语音、语音输入、视频/语音通话、客服协助视频认证相关功能。<br/>
      4.4使用日历：开启日历权限，方便在面试邀请中添加面试时间到日历，避免错过面试。<br/>
      <span class="strong">5、用户画像</span><br/>
      <span class="strong">在符合法律法规的前提下，递航可能会将您的信息在匿名化、汇总、脱敏和加密处理后，形成统计信息或用户画像（但画像无法识别任何用户个人），为您展示和推送相关服务、产品或功能。当递航要将信息用于本政策未载明的其他用途，将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span><br/>
      <span class="strong">6、征得授权同意的例外<br/>
                    以下情形中，平台收集、使用个人信息不必征得您的授权同意：<br/>
                    6.1与平台履行法律法规规定的义务相关的；<br/>
                    6.2与国家安全、国防安全直接相关的；<br/>
                    6.3与公共安全、公共卫生、重大公共利益直接相关的；<br/>
                    6.4与刑事侦查、起诉、审判和判决执行等直接相关的；<br/>
                    6.5出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；<br/>
                    6.6所涉及的个人信息是您自行向社会公众公开的；<br/>
                    6.7根据您要求签订和履行合同所必需的；<br/>
                    6.8从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br/>
                    6.9维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；<br/>
                    6.10法律规定的其他情形。<br/></span>
      <span class="strong">（二）平台如何使用 Cookie 等同类技术<br/></span>
      1、为确保网站正常运转，递航会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。平台需收集的 Cookie
      包含用户身份标识、版本号、设备型号、操作系统类型、标识符、以及一些字符。借助于
      Cookie，网站能够存储您的偏好等数据。<br/>
      递航不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见
      AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止 Cookie
      的功能。但如果您这么做，则需要在每一次访问递航的网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问以下浏览器：&lt;Internet
      Explorer&gt;、&lt;Google Chrome&gt;、&lt;Mozilla Firefox&gt;、&lt;Safari&gt;、&lt;Opera&gt;。<br/>
      2、递航网站可能包含一些电子图象，使用电子图像可以帮助网站计算浏览网站的用户或访问某些
      cookie，递航会通过电子图像收集您浏览网页活动的信息，例如您访问的页面地址、您先前访问的援引页面的位置、您停留在页面的时间、您的浏览环境以及显示设定等。<br/>
      3、您在浏览递航网站或使用递航服务时，递航通过使用 Cookie 收集并保存您的行为数据，根据您的兴趣偏好，可能向您展现与您兴趣相关的信息或内容。<br/>
      <span class="strong">（三）平台如何委托处理、共享、转让、公开披露您的信息<br/></span>
      1、委托处理<br/>
      递航的业务功能中一些具体的模块或功能由外部供应商提供。例如递航可能会聘请服务提供商来协助递航提供客户支持。对递航委托处理个人信息的公司、组织和个人，递航会与其签署严格的保密协定，要求他们按照递航的要求、本政策以及其他任何相关的保密和安全措施来处理个人信息。<br/>
      1.1基础服务商，递航为了平台的安全运行以及业务功能的实现、优化递航提供的产品和/或服务以及提升您的体验，接入了必要第三方基础服务商。如招聘用户的认证服务，当您选择注册成为递航的招聘用户时，您同意并授权向递航接入的认证服务商提供三维面部信息，完成所需认证；如图片或简历的解析，当您在递航上主动上传图片或简历时，您同意并授权递航向接入的第三方服务商提供图片或简历文件；如为您提供的手机号码保护服务，递航委托的第三方服务商将为您提供呼叫技术及录音功能；如递航的支付功能，当您在递航上购买需要增值服务时，您同意并授权向递航接入的第三方支付服务商提供支付所必要的信息，完成付款；如递航的验证、通知、推送功能、分享简历、分享职位、语音通话功能，经您授权后，递航将会向接入的服务商提供该功能所必要的电话号码、邮件设备信息、简历信息、职位信息、音视频数据。<br/>
      1.2增值服务商，为了向您提供多样的求职或招聘产品和/或服务，递航接入了大量、专业的第三方增值服务商。当您选择的增值产品和/或服务为第三方增值服务商提供时，经您另行授权后，递航会向第三方增值服务商提供履行您购买的增值产品和/或服务所必要的信息。如您购买简历顾问、面试教练、职业咨询、求职顾问服务时，经您另行授权后，递航会向您所购买服务的第三方增值服务商提供您的联系方式和简历，联系方式包括电话号码、<span
        class="strong underLine">邮箱</span>；如您购买人才测评服务，经另行您授权后，递航会向您所购买服务的第三方增值服务商提供您的姓名、出生年月、性别。<br/>
      2、共享<br/>
      平台不会与递航服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外： <br/>
      2.1在获取明确同意的情况下共享：获得您的明确同意后，平台会与其他方共享您的个人信息。<br/>
      2.2在法定情形下的共享：平台可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<br/>
      2.3与关联公司间共享：为便于平台基于关联账号共同向您提供服务，推荐您可能感兴趣的信息或保护递航关联公司或其他用户或公众的人身财产安全免遭侵害，经您授权同意后，您的个人信息可能会与平台的关联公司共享。平台只会共享必要的个人信息（如为便于您使用递航账号使用平台关联公司产品或服务，平台会向关联公司共享您必要的账号信息），如果平台共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。您同意关联公司与递航共享服务必要的相关信息。递航将准确记录和保存个人信息的共享、转让的情况，包括共享、转让的日期、规模、目的，以及数据接收方基本情况。<br/>
      2.4与授权合作伙伴共享：仅为实现本政策中声明的目的，平台的某些服务将由平台和授权合作伙伴共同提供。平台可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您购买平台上由第三方提供的产品或服务时，平台必须与第三方产品或服务提供商共享您的个人信息才能向您交付产品或提供服务。平台仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。<br/>
      2.5递航服务含有跳转到其他网站的链接。除法律另有规定外，递航对其他网站的隐私保护措施不负任何责任。平台可能在任何需要的时候增加商业伙伴或共用品牌的网站，但是提供给他们的将仅是<span
        class="strong">匿名化的信息</span>，平台将不会公开您的身份。<br/>
      <span class="strong">平台的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。 <br/></span>
      3、转让 <br/>
      递航不会将您的信息转让给除递航及其关联公司外的任何公司、组织和个人，但以下情形除外： <br/>
      3.1事先获得您的明确授权或同意； <br/>
      3.2根据适用的法律法规、法律程序的要求； <br/>
      3.3行政或司法机关强制要求； <br/>
      3.4符合与您签署的相关协议（包括在线同意的注册协议以及相应的产品和服务规则） 或其他的法律文件约定所提供；<br/>
      3.5如果递航或都会的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的信息有可能作为此类交易的一部分而被转移，递航将确保该等信息在转移时的保密性，并要求新的持有您信息的公司、组织继续受本政策的约束，否则递航将要求该公司、组织重新向您征求授权同意。<br/>
      4、公开披露<br/>
      4.1获得您明确同意或基于您的主动选择，平台可能会公开披露您的个人信息；<br/>
      4.2如果平台确定您出现违反法律法规或严重违反递航相关协议规则的情况，或为保护递航及其关联公司用户或公众的人身财产安全免遭侵害，平台可能依据法律法规或递航相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及递航已对您采取的措施。<br/>
      5、共享、转让、公开披露个人信息时事先征得授权同意的例外<br/>
      以下情形中，平台共享、转让、公开披露个人信息不必事先征得您的授权同意： <br/>
      5.1与平台履行法律法规规定的义务相关的；<br/>
      5.2与国家安全、国防安全直接相关的；<br/>
      5.3与公共安全、公共卫生、重大公共利益直接相关的；<br/>
      5.4与刑事侦查、起诉、审判和判决执行等直接相关的；<br/>
      5.5出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；<br/>
      5.6您自行向社会公众公开的个人信息；<br/>
      5.7从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br/>
      5.8法律法规规定的其他情形。<br/>
      <span class="strong">（四）平台如何保存您的信息<br/></span>
      递航高度重视您的信息安全，递航将严格遵守相关法律法规规定，采取业内认可的合理可行的措施，保存和保护您的信息。防止信息遭到未经授权的访问、披露、使用、修
      改，避免信息损坏或丢失。<br/>
      <span class="strong">1、保存期限<br/></span>
      平台仅为实现本政策的目的所需的期限和法律法规及监管规定的最短时限内，保留您的信息，但如出现下列情况下，递航将更改信息的存储时间：<br/>
      1.1法律法规等有关规定的要求；<br/>
      1.2法院判决、裁定或其他法律程序规定的要求；<br/>
      1.3相关行政机关的强制要求；<br/>
      1.4递航有理由确信需要遵守法律法规等有关规定；<br/>
      1.5为执行相关服务协议或本政策、维护社会公共利益，为保护们的客户、递航或递航的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。<br/>
      1.6当递航的产品或服务发生停止运营的情形时，递航将采取例如邮件、信函、电话、推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的信息。
      <br/>
      <span class="strong">2、保存地域<br/></span>
      您的信息均储存于中华人民共和国境内。<span class="strong">如部分产品或服务涉及跨境，递航需要向境外传输您的信息，递航会严格按照法律法规和相关监管部门的规定执行，并保证您的信息安全。<br/></span>
      <span class="strong">（五）平台如何保护您的信息<br/>
                    1、技术措施与数据安全措施<br/></span>
      1.1递航努力采取各种合理可行的措施来保护您的信息安全。递航积极建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范信息的存储和使用，确保未收集与递航提供的服务无关的信息。<br/>
      1.2递航通过与信息接触者签署保密协议、监控和审计机制来对数据进行全面安全控制。防止您的信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。<br/>
      1.3递航已使用符合业界标准的安全防护措施保护您提供的信息，防止数据遭到未经授权的访问、公开披露、使用、修改，防止数据发生损坏或丢失。递航会采取一切合理可行的措施，保护您的信息。例如，在您的浏览器与服务之间交换数据时受
      SSL 加密保护； 递航同时对递航网站提供 https
      安全浏览方式；递航会使用加密技术确保数据的保密性；递航会使用受信赖的保护机制防止数据遭到恶意攻击；递航会部署访问控制机制，确保只有授权人员才可以访问信息；以及递航会举办安全和隐私保护培训课程，加强员工对于保护信息重要性的认识。<br/>
      <span class="strong">2、安全认证<br/></span>
      递航已通过了公安机关的信息安全登记保护第三级备案，并于监管机构建立了良好的协调沟通机制，及时抵御并处置各类信息安全威胁，为您的信息安全提供全方位保障。<br/>
      <span class="strong">3、安全事件处置<br/></span>
      互联网环境并非百分之百安全，递航将尽力确保您发送给递航的任何信息的安全性。为防止安全事故的发生，递航制定了妥善的预警机制和应急预案。若不幸发生信息安全事件，递航将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、递航已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等，
      并立即启动应急预案，力求将损失最小化。递航将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户时，递航会采取合理、有效的方式发布公告。<br/>
      同时，递航还将按照监管部门要求，主动上报信息安全事件的处置情况，紧密配合政府机关的工作。<br/>
      <span class="strong">（六）您的权利<br/></span>
      递航非常重视您对信息的关注，在您使用递航服务期间，为了您可以更加方便访问和管理您的信息，同时保障您对于个人信息访问、更正、删除以及撤回同意的权利，递航在相关页面中为您提供了相应的操作设置，您可以参考下面的指引进行操作。<br/>
      <span class="strong">1、信息的查询、更正<br/></span>
      除法律法规规定外，您有权访问您的信息。如果您想行使数据访问权，可以通过以下方式自行访问：<br/>
      1.1求职端<br/>
      1.1.1 递航小程序<br/>
      您的个人资料信息：您登录递航小程序后，访问、更正您的账户信息。<br/>
      a. 您的账号安全信息：您登录后可以访问、更正您的认证手机、认证邮箱、登录密码等信息。<br/>
      b. 您的简历信息：您登录后，可以对您的简历进行编辑调整。<br/>
      1.2招聘端<br/>
      1.2.1 递航系统网站<br/>
      a. 您的个人资料信息：您登录后可以通过设置页面，访问、更正您的信息。<br/>
      b. 您的账号安全信息：您登录后，通过设置页面，修改您的邮箱、手机号、密码。<br/>
      <span class="strong">2. 信息的删除<br/></span>
      如发生以下情形，您可通过在线客服向递航提出删除信息的要求：<br/>
      2.1 如果递航收集、使用信息的行为违反法律法规的规定；<br/>
      2.2 如果递航收集、使用您的信息，未征得您的同意；<br/>
      2.3 如果递航收集、使用信息的行为违反了与您的约定。<br/>
      如平台停止运营或停止提供相关服务，平台将及时停止继续收集您个人信息的活动，
      将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。<br/>
      <span class="strong">3. 改变您授权同意的范围<br/></span>
      您可以通过登录递航网站或小程序，进入“设置”或“我的”页面，进行通过开启或关闭隐私或相关授权功能等来改变您授权递航收集信息的范围或撤回您的授权。<span
        class="strong">当您撤回同意或授权后，递航将不再处理相应的信息，但请您理解，当您撤回同意或授权后，递航无法继续为您提供撤回同意或授权所对应的全部或部分功能和服务。您撤回同意或授权的决定，不会影响递航此前基于您的授权或同意而进行的信息的使用。<br/>
                    4. 响应您的上述请求<br/></span>
      4.1 为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。递航可能会<br/>
      先要求您验证自己的身份，然后再处理您的请求。<br/>
      递航将在三十个自然日内作出答复。如您不满意，还可以通过本政策所列明的联系方式联系平台，帮您解决问题。<br/>
      4.2 对于您合理的请求，递航原则上不收取费用，但对多次重复、超出合理限度的请求，
      递航将视情况收取一定成本费用。对于无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，递航可能会予以拒绝。<br/>
      4.3 在以下情形中，递航将无法响应您的请求：<br/>
      4.3.1 与个人信息控制者履行法律法规规定的义务相关的；<br/>
      4.3.2 与国家安全、国防安全直接相关的；<br/>
      4.3.3 与公共安全、公共卫生、重大公共利益直接相关的；<br/>
      4.3.4 与刑事侦查、起诉、审判和执行判决等直接相关的；<br/>
      4.3.5 个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br/>
      4.3.6 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
      4.3.7 响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到<br/>
      严重损害的；<br/>
      4.3.8 涉及商业秘密的。<br/>
      <span class="strong">（七）平台如何保护未成年人的信息<br/></span>
      1、平台重视未成年人的信息保护，如您为未满十六周岁的未成年人，请您立即告知您的监护人或法定代理人，并由监护人或法定代理人陪同立即终止使用平台，并勿向平台提供任何个人信息。<br/>
      如您为十六周岁以上限制民事行为能力人的，请您务必在监护人或法定代理人陪同下阅读本政策并由监护人或法定代理人代理完成点击确认、同意行为。平台收集到点击确认、同意本政策操作记录即视为该同意行为系您的监护人或法定代理人作出且您及您的监护人或法定代理人已充分阅读、理解且接受本政策的全部内容，并与递航达成一致，本政策对您及您的监护人或法定代理人、递航均具有法律约束力。<br/>
      建议您请您的父母或监护人仔细阅读本政策，并在征得您的父母或监护人同意的前提下使用平台的服务或向平台提供信息。对于经父母或监护人同意使用平台的产品或服务而收集未成年人个人信息的情况，平台只会在法律法规允许，父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。<br/>
      2、为加强对未成年人个人信息的保护，除遵循本政策项下的其他规定外，平台将遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，根据国家相关法律法规及本政策的规定保护未成年人的个人信息，具体内容如下：<br/>
      2.1 平台就所收集的未成年人信息和未成年人以外的用户个人信息分别存储，平台将采取不低于行业同行的加密措施存储未成年人信息，确保未成年人信息安全。<br/>
      2.2 平台将指定专人负责未成年人信息保护，任何工作人员访问未成年人的人信息的，
      必须经未成年人信息保护人员书面审批（包括电子邮件、短信、微信此类电子信息交互方式），并形成访问情况的书面记录；同时，采取不低于行业同行的技术措施，避免违法复制、下载未成年人的个人信息。<br/>
      2.3 平台向第三方转移未成年人的个人信息的，除再次征得未成年人监护人的同意及满<br/>
      足上述原则外，将自行或者委托第三方机构进行安全评估，并形成书面评估报告。<br/>
      <span class="strong">（八）本政策如何更新<br/></span>
      递航有权在必要时修改本政策条款，并以弹窗或站内信等方式通知到您。您可以随时在本平台查阅修改后的最新版本政策。如您不同意修改后的政策，您有权终止对平台的授权。本政策更新后，如果您继续使用相应平台功能，即视为您已接受修改后的政策。<br/>
      对于重大变更，平台还会提供更为显著的通知（包括意见征集、公告通知、弹窗通知等方式）。<br/>
      本政策所指的重大变更包括但不限于：<br/>
      1. 平台的服务模式发生重大变化。如处理用户信息的目的、用户信息的使用方式等；<br/>
      2. 平台在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；<br/>
      3. 用户信息共享、转让或公开披露的主要对象发生变化；<br/>
      4. 平台负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；<br/>
      5. 用户信息安全影响评估报告表明存在高风险时。<br/>
      递航还会将本政策的旧版本存档，供您查阅，您可通过平台列明的联系方式获取《个人信息保护政策》旧版本。<br/>
      <span class="strong">三、知识产权<br/></span>
      1.
      递航及其关联公司是本平台的知识产权权利人。本平台的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本平台相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国大陆地区法律法规和相应的国际条约保护，递航或其关联公司享有上述知识产权，但相关权利人依照法律规定应享有的权利除外。<br/>
      2. 未经平台书面同意，上述资料均不得在任何媒体、平台直接或间接发布、播放、出于播放或发布目的而改写或再发行,
      或者被用于其他任何商业目的。所有以上资料或资料的任何部分仅可作为私人和非商业用途保存。<br/>
      <br/>
      <span class="strong">四、其他<br/></span>
      1. 本政策所有条款的标题仅为阅读方便，不作为本政策涵义解释或限制的依据。<br/>
      2. 本政策任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本政策其余条款的有效性及可执行性。<br/>
      3. 本政策可能存在包括中文、英文在内的多种语言的版本，如果存在中文版本与其他语言的版本相冲突的地方，以中文版本为准。<br/>
      4. 针对某些特定的产品及服务，平台还将制定特定的隐私政策，并在向您提供这些特定的产品及服务之前加以说明。如相关特定的隐私政策与本政策不一致的，适用该特定隐私政策。<br/>
      <br/>
      <span class="strong">五、法律适用与管辖  <br/></span>
      1. 本政策之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律，不包含冲突规范。如法律无相关规定的，参照商业惯例及/或行业惯例。<br/>
      2. 您因使用递航服务所产生及与递航服务有关的争议，由递航与您协商解决。协商不成时，任何一方均可向平台联系地有管辖权的人民法院提起诉讼。<br/>
      <br/>
      <span class="strong">六、与平台联系<br/></span>
      如您有个人信息相关问题或其他的投诉、建议等，可以通过如下方式与平台联系，平台将尽快审核所涉及内容，并于 15
      个工作日内对于您的问题、投诉、建议进行回复：<br/>
      平台运营主体名称：直猎（上海）网络科技有限公司<br/>
      注册地址：上海市徐汇区云锦路500号绿地汇中心1716<br/>
      联系地址：上海市徐汇区云锦路500号绿地汇中心1716<br/>
      个人信息保护专职部门联系邮箱：<span class="blueLine">service@dhunting.com</span><br/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="less">
.privacy-policy-page {
  margin-top: 65px;
}
.privacy-policy-page-title {
  width: 100%;
}
.privacy-policy-page-title > * {
  width: 100%;
  text-align: center;
}
</style>
