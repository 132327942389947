<template>
  <div class="mo-footer-wrap">
    <div class="mo-footer">
      <div class="mo-footer-logo"><img src="//file.dhunting.com/official_website/v1/logo_down.png" alt=""></div>
      <div class="mo-footer-address">地址：上海市徐汇云锦路500号绿地·汇中心A座</div>
      <div class="mo-footer-email">邮箱：hr@dhunting.com</div>
      <div class="mo-footer-contact">购买咨询微信：Dhuntingddd</div>
      <div class="mo-footer-contact-img">
        <div class="official-wechat-wrap">
          <div class="official-wechat"><img src="//file.dhunting.com/official_website/v1/contact-down-code.png" alt="" class=""></div>
          <span class="img-info">官方客服</span>
        </div>
        <div class="wechat-service-wrap">
          <div class="wechat-service"><img src="//file.dhunting.com/official_website/v1/wechat-code.png" alt=""></div>
          <span class="img-info">微信服务号</span>
        </div>
      </div>
      <div class="mo-footer-icp"><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20002773号-1</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'moFooter',
  components: {

  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods:{

  }
}
</script>
<style lang="less" scoped>
.mo-footer-wrap{
  background: #06173A;
  padding: 24px 36px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 400;
}
.mo-footer-logo{
  width: 120px;
  height: 23px;
}
.mo-footer-address{
  margin-top: 18px;
  margin-bottom: 12px;
}
.mo-footer-contact{
  margin-top: 12px;
  margin-bottom: 19px;
}
.official-wechat,
.wechat-service
{
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background-color: #FFFFFF;
}
.wechat-service-wrap{
  margin-left: 24px;
}
.mo-footer-contact-img{
  display: flex;
}
.wechat-service-wrap,
.official-wechat-wrap{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.img-info{
  margin-top: 6px;
}
.mo-footer-icp{
  margin-top: 18px;
}
.mo-footer-icp > a {
  color: #FFFFFF!important;
}
</style>
