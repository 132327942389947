<!--HR系统预约演示-->
<template>
  <div class="hr-wrap">
    <div class="hr-top">
      <div class="top-text">猎头系统咨询</div>
    </div>
    <div class="hr-content">
      <div class="hr-form">
        <a-form-model :model="form" :rules="rules" ref="form" :hideRequiredMark="true">
          <a-form-model-item  prop="companyName">
            <a-input placeholder="请输入公司名称" v-model="form.companyName"></a-input>
          </a-form-model-item>
          <a-form-model-item  prop="name">
            <a-input placeholder="请输入您的姓名" v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item  prop="phone">
            <a-input placeholder="请输入您的电话" v-model="form.phone"></a-input>
          </a-form-model-item>
<!--          <a-form-model-item  prop="baseShow">-->
<!--            <areaCascader-->
<!--                @cascaderChange="onCityChange"-->
<!--                :defaultList="form.baseShow"-->
<!--                type="city"-->
<!--                placeholder="请选择公司地址"-->
<!--            />-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item prop="baseShow">-->
<!--            <a-input @click="handleChooseBaseShow"  placeholder="请选择公司地址" v-model="baseName"></a-input>-->
<!--            <van-popup  v-model="isShowChooseBaseShow" position="bottom" >-->
<!--              <van-area  :area-list="areaList" @cancel="handleCancleAreaList"  @confirm="handleConfirmAreaList"/>-->
<!--            </van-popup>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item  prop="companySize">-->
<!--            <a-select placeholder="请选择公司规模" v-model="form.companySize">-->
<!--              <a-select-option-->
<!--                  v-for="(item, index) in employeesList"-->
<!--                  :key="index"-->
<!--                  :value="item.id"-->
<!--              >-->
<!--                {{ item.name }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item prop="companySize">-->
<!--            <a-input @click="handleChooseCompanySize"  placeholder="请选择公司规模" v-model="companySizeName"></a-input>-->
<!--            <van-popup :style="{ height: '30%' }" class="all-pop-height" v-model="isShowChooseCompanySize" position="bottom" >-->
<!--              <div style="text-align: center" v-for="(item, index) in employeesList" @click="clickChooseCompanySize(item.id,item.name)" :key="index">{{ item.name }}</div>-->
<!--            </van-popup>-->
<!--          </a-form-model-item>-->
          <!--          <a-form-model-item label="公司行业" :colon="false" prop="industryShow">-->
          <!--             <areaCascader-->
          <!--               @cascaderChange="onIndustryChange"-->
          <!--               :defaultList="form.industryShow"-->
          <!--               type="industry"-->
          <!--             />-->
          <!--          </a-form-model-item>-->
<!--          <a-form-model-item  prop="consultationChannelId">-->
<!--            <a-select-->
<!--                dropdownClassName="select-wrap"-->
<!--                v-model="form.consultationChannelId"-->
<!--                placeholder="请选择从哪个渠道了解到递航："-->
<!--                :getPopupContainer="(triggerNode)=>triggerNode.parentNode"-->
<!--            >-->
<!--              <a-select-option v-for="(item, index) in consultationChannelList" :key="index" :value="item.id">-->
<!--                {{ item.name }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--            <a-form-model-item v-if="form.consultationChannelId && form.consultationChannelId === '5'" label="" :colon="false" prop="consultationChannelName">-->
<!--              <a-input placeholder="请输入" v-model="form.consultationChannelName"></a-input>-->
<!--            </a-form-model-item>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item prop="consultationChannelId">-->
<!--            <a-input @click="handleChooseConsultationChannel" placeholder="请选择从哪个渠道了解到递航：" v-model="consultationChannelIdName"></a-input>-->
<!--            <van-popup :style="{ height: '30%' }" class="all-pop-height" v-model="isShowConsultationChannel" position="bottom" >-->
<!--              <div style="text-align: center" v-for="(item, index) in consultationChannelList" @click="clickChooseConsultationChannel(item.id,item.name)" :key="index">{{ item.name }}</div>-->
<!--            </van-popup>-->
<!--          </a-form-model-item>-->
<!--          <a-form-model-item v-if="form.consultationChannelId && form.consultationChannelId === '5'" label="" :colon="false" prop="consultationChannelName">-->
<!--            <a-input placeholder="请输入" v-model="form.consultationChannelName"></a-input>-->
<!--          </a-form-model-item>-->
        </a-form-model>
      </div>
                    <!--      <div>-->
      <!--        <img src="//file.dhunting.com/official_website/hrSystemWrite.svg" alt="">-->
      <!--      </div>-->
    </div>
    <a-button type="primary" class="consultation-btn" @click="onSubmit">立即咨询</a-button>
    <moSuccessModal :is-visible="visible" type="order" @cancel="cancel"></moSuccessModal>
  </div>
</template>
<script>
import {consultationSave, getEmployees, listChannel} from '@/api/request'
// import areaCascader from "@/components/areaCascader";
import moSuccessModal from "@/views/MoPage/components/moSuccessModal";
import { areaList } from '@vant/area-data';
export default {
  components: {
    moSuccessModal,
    // areaCascader
  },
  data() {
    return {
      isShowChooseCompanySize:false,
      companySizeName:undefined,//公司规模名称
      isShowConsultationChannel:false,
      consultationChannelIdName:undefined,
      areaList,//所有地区
      isShowChooseBaseShow:false,
      baseName:undefined,
      visible: false, // 是否展示预约成功弹窗
      employeesList: [], // 公司人员规模列表,
      base: '',
      baseShow:[],
      consultationChannelId: undefined,  //渠道
      consultationChannelName: "",  //其他渠道
      consultationChannelList: [],
      form: {
        companyName: undefined, // 公司名称
        name: undefined, // 姓名
        phone: undefined, // 电话
        companySize: undefined, // 公司规模
        consultationChannelId: undefined,  //渠道
        consultationChannelName: "",  //其他渠道
        base: '',
        baseShow:[],
        industry: undefined,
        industryShow: [],
        // intentProductType: undefined
        intentProductType:'1'
      },
      rules: {
        companyName: [{ required: true, message: '请输入公司名称', trigger: ['blur', 'change'] }],
        industryShow: [
          { required: true, message: '请选择行业', trigger: ['blur', 'change'] },
        ],
        name: [{ required: true, message: '请输入您的姓名', trigger:  ['blur', 'change']}],
        phone: [
          {
            required: true,
            len: 11,
            pattern: RegExp(/(^(\+?0?86-?)?1[3456789]\d{9}$)|(^(\+?852-?)?[569]\d{3}-?\d{4}$)|(^(\+?886-?|0)?9\d{8}$)|(^(0065)\d{8}$)/),
            message: "请输入正确的手机号",
            trigger:  ['blur', 'change'],
          },
        ],
        // 大陆手机号：/^(\+?0?86-?)?1[345789]\d{9}$/
        // 香港手机号：/^(\+?852-?)?[569]\d{3}-?\d{4}$/
        // 台湾手机号：/^(\+?886-?|0)?9\d{8}$/
        // 新加坡手机号：/^(0065)\d{8}$/
        companySize: [{ required: true, message: '请选择公司规模', trigger: [ 'change','blur'] }],
        baseShow: [
          { required: true, message: '请选择', trigger: [ 'change','blur'] },
        ],
        consultationChannelId: [{ required: true, message: '请选择渠道', trigger: ['change','blur'] },],
        consultationChannelName: [
          {
            required: true,
            trigger: ['change','blur'],
            validator: (rule, value, callback) => {
              if (this.form.consultationChannelId && this.form.consultationChannelId === '5' && (!this.form.consultationChannelName || this.form.consultationChannelName === "") ) {
                callback(new Error("请输入渠道"))
              }else {
                callback()
              }
            }
          }
        ]
      },
    }
  },
  async mounted() {
    //获取公司规模
    await this.getEmployeesByCodeFun();
    // 获取渠道
    await this.getChannelFun()
  },
  methods: {
    // 行业选择
    onIndustryChange(value) {
      if (value.length > 0) {
        // this.form.industry = value[value.length - 1];
        this.$set(this.form,'industry',value[value.length - 1])
        // this.form.industryShow = value;
        this.$set(this.form,'industryShow',value)
      }
      else {
        // this.form.industry = '';
        this.$set(this.form,'industry','')
        this.$set(this.form,'industryShow',[])
        // this.form.industryShow = [];
      }
    },
    // 城市选择
    onCityChange(value) {
      if (value.length > 0) {
        this.$set(this.form,'base',value[value.length - 1])
        // this.form.base = value[value.length - 1];
        // this.form.baseShow = value;
        this.$set(this.form,'baseShow',value)
      }
      else {
        // this.form.base = '';
        this.$set(this.form,'base','')
        // this.form.baseShow = [];
        this.$set(this.form,'baseShow',[])
      }
    },
    // 获取人员规模
    async getEmployeesByCodeFun() {
      let res = await getEmployees();
      if (res.data.code === 200) {
        this.employeesList = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    //从哪里了解到递航
    async getChannelFun() {
      let { data } = await listChannel()
      if (data.code === 200) {
        this.consultationChannelList = data.data
      }else {
        this.$message.error(data.message)
      }
    },
    // 返回上一个页面
    returnFun() {
      // 调用父组件的重置systemType方法
      this.$emit('systemTypeReset');
    },
    // 立即体验
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const tempObj={
            base:this.form.base,
            companyName:this.form.companyName,
            companySize:this.form.companySize,
            consultationChannelId:this.form.consultationChannelId,
            consultationChannelName:this.form.consultationChannelName,
            phone:this.form.phone,
            userName:this.form.name,
            industry: this.form.industry,
            intentProductType: this.form.intentProductType
          }
          consultationSave(tempObj).then(res=>{
            if(res.data.code === 200) {
              // this.$message.success('提交成功');
              this.visible = true;
              let form = {
                companyName: "",  //公司名称
                companySize: undefined, // 公司规模
                consultationChannelId: undefined,  //渠道
                consultationChannelName: "",  //其他渠道
                name: '',
                phone:'',
                base: '',
                baseShow:[],
                industry: undefined,
                industryShow: [],
                // intentProductType: undefined

              };
              this.form = JSON.parse(JSON.stringify(form));
              //通知areaCasader组件清空数据
            }
            else {
              this.$message.error(res.data.message);
            }
          })
        } else {
          return false;
        }
      });
    },
    // 预约成功弹窗关闭
    cancel() {
      this.visible = false;
    },
    handleChooseCompanySize(){
      document.activeElement.blur();
      this.isShowChooseCompanySize=true
    },
    clickChooseCompanySize(id,name){
      this.companySizeName=name
      this.form.companySize=id
      this.isShowChooseCompanySize=false
      this.handleValidateCompanySize()
    },
    handleValidateCompanySize(){
      this.$refs.form.validateField('companySize',()=>{
      })
    },
    handleChooseConsultationChannel(){
      document.activeElement.blur();
      this.isShowConsultationChannel=true
    },
    clickChooseConsultationChannel(id,name){
      this.consultationChannelIdName=name
      this.form.consultationChannelId=id
      this.isShowConsultationChannel=false
      this.handleValidateConsultationChannelId()
    },
    handleValidateConsultationChannelId(){
      this.$refs.form.validateField('consultationChannelId',()=>{
      })
    },
    //地址选择
    handleConfirmAreaList(e){
      this.form.base=e[2].code
      let temp=[]
      // console.log(,'dd')
      const str1=e[0].code.substring(0, 2)
      const str2=e[1].code.substring(0, 4)
      temp.push(str1)
      temp.push(str2)
      temp.push(e[2].code)
      this.form.baseShow=temp
      this.baseName=e[0].name +'/'+ e[1].name +'/'+ e[2].name
      this.isShowChooseBaseShow=false
      //手动触发校验
      this.handleValidateBaseShow()
    },
    handleValidateBaseShow(){
      this.$refs.form.validateField('baseShow',()=>{
      })
    },
    handleCancleAreaList(){
      this.isShowChooseBaseShow=false
    },
    handleChooseBaseShow(){
      document.activeElement.blur();
      this.isShowChooseBaseShow=true
    }
  }
}
</script>
<style lang="less" scoped>
.clearMargin24{
  margin-bottom: 0!important;
}
.hr-wrap{
  padding: 24px 32px 32px;
  /deep/ .ant-form-item{
    margin-bottom: 24px;
  }
  /deep/ .ant-form-explain{
    font-size: 12px!important;
  }
  .top-text{
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #1B1C33;
    margin-bottom: 30px;
  }
}
.consultation-btn{
  padding: 8px 24px;
  width: 311px;
  height: 40px;
  background: #0055FF;
  border-radius: 51.0404px;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 14px;
  margin-top: 32px;
}
</style>