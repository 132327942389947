<template>
  <div>
    <a-modal
        :visible="outerVisible"
        :footer="null"
        dialogClass="new-pc-soho-pro"
        @cancel="handleCancel"
    >
      <div class="top">
        <div class="title-wrap">
          <div class="icon"><iconpark-icon name="new-modal-icon"></iconpark-icon></div>
          <div class="title">已选择产品类型：SOHO-Max版</div>
        </div>
        <div class="modal-line"></div>
        <div class="info">填写信息</div>
        <div class="form-and-register">
          <a-form-model :model="form" :rules="rules" ref="form" layout="horizontal" :hideRequiredMark="true">
            <a-form-model-item label="姓名" prop="name">
              <a-input placeholder="请输入您的姓名" v-model="form.name"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电话" prop="phone">
              <a-input placeholder="请输入您的手机号码" v-model="form.phone"></a-input>
            </a-form-model-item>
            <a-form-model-item label="验证码" prop="validCode" class="get-code-wrap">
              <a-input placeholder="请输入验证码" v-model="form.validCode"></a-input>
              <div class="hit-code-wrap">
                <a v-show="!showTimer" class="items-code-2" @click="getCode">获取验证码</a>
                <span v-show="showTimer" class="items-code-time-2">{{time}}s重新发送</span>
                <span class="code-hit-2">{{hitCode}}</span>
              </div>
            </a-form-model-item>
          </a-form-model>
          <div class="register-wrap">
            <div class="register-customer-service">
              <img src="//file.dhunting.com/official_website/weixin.svg" alt="" class="register-customer-service-img">
              <span class="register-customer-service-text">扫描添加客服，回复关键词“注册码”获取注册码</span>
            </div>
            <div class="register-code-wrap">
              <img src="//file.dhunting.com/official_website/customerServiceQrCode.svg" alt="" class="register-qr-code">
              <a-form-model :model="form" :rules="rules" ref="formRegister" :hideRequiredMark="true">
                <a-form-model-item  prop="registerCode" class="register-item">
                  <a-input v-model="form.registerCode" placeholder="请输入注册码" class="register-qr-input"></a-input>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="title">
          <div class="buy">购买套餐</div>
        </div>
        <div class="price-card-wrap">
          <div class="price-card small-hand"
               :class="{'active-price-card' : idx === index}"
               v-for="(item, idx) in goodsList"
               :key="idx"
               @click="setHover(idx)">
            <div class="price-choose-month">{{ item.name }}</div>
            <div class="sell-price-and-original">
              <div class="price-choose-discounts">￥<span class="data">{{ item.sellPrice }}</span></div>
              <div v-if="type != 'Pro' && type != 'Max'" class="price-choose-original">
                <div>
                  ￥{{ item.markPrice }}
                </div>
                <div class="delete-line"></div>
              </div>
            </div>
            <div class="price-choose-day">
              （低至<span class="price-choose-day-monney">{{ parseInt(goodsList[idx].name) == 12 ? (item.sellPrice / 365).toFixed(2) : (item.sellPrice / 30 / parseInt(goodsList[idx].name)).toFixed(2) }}</span>元/天）
            </div>
          </div>
        </div>
        <div class="modal-line-2"></div>
        <div class="price-btn">
          <div class="calculate-wrap">
            <div class="calculate-price">
              <span>账号费用</span>
              <span>￥{{ price }}</span>
            </div>
            <div class="calculate-last">
              <span>应付金额</span>
              <span><span>￥</span><span>{{ lastPrice }}</span></span>
            </div>
          </div>
          <div class="btn-wrap">
            <div class="submit-check">
              <a-checkbox v-model="isAgree"></a-checkbox>
              <span class="agree">同意<span @click="showDhuntingWindow" class="small-hand agreement">《递航服务协议》</span>
                <span class="small-hand agreement" @click="showPrivacyWindow">《递航隐私政策》</span>
              </span>
            </div>
            <a-button type="danger" class="enterprise-criterion-btn" @click="onSubmit(form,isAgree)" :loading="isLoading">提交结算</a-button>
          </div>
        </div>
      </div>
    </a-modal>
    <!--    递航服务协议弹窗-->
    <serviceAgreementModal :showDhunting="showDhunting" @visibleCancel="showDhunting = false"></serviceAgreementModal>
    <!--    递航隐私政策弹窗-->
    <privacyPolicyModal :showPrivacy="showPrivacy" @visibleCancel="showPrivacy = false"></privacyPolicyModal>
    <!--    微信支付二维码-->
    <a-modal
        v-model="ifCodeShow"
        :footer="null"
        @cancel = "closeCodeVisible"
        destroyOnClose
        centered
    >
      <div class="pay-window-small">
        <div id="qrcode" ref="qrcode" class="pay-success">
          <div v-if="visibleCode" class="pay-success-inner"></div>
          <img v-if="visibleCode" src="//file.dhunting.com/official_website/v1/success.png" alt="success" class="pay-success-image">
        </div>
        <p v-if="!visibleCode" class="qr-text">请使用微信扫码支付</p>
        <p v-if="visibleCode" class="qr-success">支付成功</p>
      </div>
    </a-modal>
    <!--    成功提交弹窗-->
    <successModal :isVisible="showSuccessWindow" type="register" :phone="form.phone" @cancel="cancel" @toHome="toHome"></successModal>
  </div>
</template>
<script>
import successModal from "@/views/PCPage/productContact/component/successModal";
import serviceAgreementModal from "@/components/serviceAgreementModal";
import privacyPolicyModal from "@/components/privacyPolicyModal";
import {code} from "@/api/request";
import {check, getSohoMaxPriceMenu, payCode, submitOrder} from "@/api/Interface/order";
import QRCode from "qrcodejs2";

export default {
  name: "newSohoMax",
  components: {successModal,serviceAgreementModal,privacyPolicyModal},
  props:{
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading:false,
      hitCode: '',
      time: 0,//倒计时
      outerVisible:this.visible,
      // outerVisible:true,
      showTimer: false,//显示倒计时
      showSuccessWindow: false, // 是否展示预约成功弹窗
      showDhunting: false,//显示用户协议
      showPrivacy: false,//显示隐私政策
      isAgree: false, // 是否同意服务协议
      form: {
        name: undefined, // 姓名
        phone: undefined, // 电话
        validCode: undefined, // 验证码
        registerCode: undefined, // 注册码
        buyNum: 1, // 账号数量
        skuId: -1,
        type: 6,  // 6是sohoMax的类型
      },
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: ['blur','change'] }],
        phone: [
          {
            required: true,
            len: 11,
            pattern: RegExp(/(^(\+?0?86-?)?1[3456789]\d{9}$)|(^(\+?852-?)?[569]\d{3}-?\d{4}$)|(^(\+?886-?|0)?9\d{8}$)|(^(0065)\d{8}$)/),
            message: "请输入正确的手机号",
            trigger: ['blur','change'],
          },
        ],
        // 大陆手机号：/^(\+?0?86-?)?1[345789]\d{9}$/
        // 香港手机号：/^(\+?852-?)?[569]\d{3}-?\d{4}$/
        // 台湾手机号：/^(\+?886-?|0)?9\d{8}$/
        // 新加坡手机号：/^(0065)\d{8}$/
        validCode: [{ required: true, message: '请输入验证码', trigger: ['blur','change'] }],
        registerCode: [{ required: true, message: '请输入注册码', trigger: ['blur','change'] }],
      },
      orderSn: '',//支付二维码序号
      ifCodeShow: false,//控制二维码界面
      qrCodeAddress: '',//支付二维码地址
      ifShow : false,//控制页面是否展示
      qrCode: '',//二维码
      timer: null,//轮询计时器
      timer2: null,//轮询计时器
      visibleCode: false,//是否支付成功
      payTime: 1000 * 60 * 10,//支付时间
      goodsList: [],  // 商品菜单
      accountDiscountList: '',
      index: 0,
      Adiscount: 1,
      Cdiscount: 1,
      price: 0,
      lastPrice: 0,
      type:'Max',
      inviteUserId: undefined
    }
  },
  async mounted() {
    if(this.$route.query && this.$route.query.inviteUserId){
      this.inviteUserId = this.$route.query.inviteUserId
    }
    await this.getPriceMenuFun();
    await this.initForm()
  },
  methods:{
    //购买量折扣
    checkDiscount(){
      for(let i=0;i<this.accountDiscountList.length;i++){
        if(this.accountDiscountList[i].start<=this.form.buyNum&&this.accountDiscountList[i].end>=this.form.buyNum){
          this.Adiscount = this.accountDiscountList[i].discount;
          break;
        }else if(this.accountDiscountList[i].start>this.form.buyNum){
          break;
        }
      }
    },
    // 获取价格
    async getPriceMenuFun() {
      await this.getSohoMaxPriceMenuFun()
      this.priceDefaultInit()
    },
    checkPrice() {
      //计算价格
      this.checkDiscount()
      if(this.index >= 0){
        this.price = Math.floor((this.goodsList[this.index].markPrice*this.form.buyNum)*10)/10;
        this.discountPrice = this.type === 'Pro' ? 0 : Math.floor((this.price - this.goodsList[this.index].sellPrice*this.form.buyNum*this.Adiscount)*10)/10;
        this.couponPrice = this.type === 'Pro' ? 0 : Math.ceil(((this.price - this.discountPrice) * (1-this.Cdiscount)*10).toFixed(2))/10;
        this.lastPrice = this.type === 'Pro' ? this.goodsList[this.index].sellPrice : Math.floor((this.price - this.discountPrice - this.couponPrice)*10)/10;
      }else{
        this.price = 0;
        this.discountPrice = 0;
        this.couponPrice = 0;
        this.lastPrice = 0;
      }
    },
    setHover(index){
      this.index = index;
      this.form.skuId = this.goodsList[this.index].skuId;
      this.checkPrice();
    },
    // Max版价格清单
    async getSohoMaxPriceMenuFun() {
      let {data} = await getSohoMaxPriceMenu()
      if (data.code === 200) {
        this.goodsList = data.data.skuList
      } else {
        this.$message.error(data.message)
      }
    },
    toHome(){
      this.$router.push('/home')
    },
    // 初始化表单
    initForm() {
      this.setHover(this.index)
    },
    // 重置价格选择和协议状态
    async reInit() {
      this.index = 0;
      this.isAgree = false; // 是否同意服务协议
    },
    // 价格选择初始化
    priceDefaultInit() {
      this.form.skuId = this.goodsList[0].skuId ; // 默认选中12个月(数组最后一个)
      this.checkPrice();
    },
    handleCancel(){
      this.outerVisible = false
      this.$emit('sohoTypeReset');
    },
    // 是否显示递航服务协议
    showDhuntingWindow() {
      this.showDhunting = true;
    },
    // 是否显示递航隐私政策
    showPrivacyWindow() {
      this.showPrivacy = true;
    },
    // 获取验证码
    getCode(){
      // 先进行手机号验证，成功后发获取验证码请求
      this.$refs.form.validateField(['phone'], valid => {
        if (!valid) {
          code({ username: this.form.phone}).then((res)=>{
            if(res.data.code === 200){
              //转变成倒计时
              this.time = 60;
              this.codeTimer = setInterval(()=>{
                if(this.time === 0){
                  clearInterval(this.codeTimer);
                  this.codeTimer = null;
                  this.showTimer = false;
                }
                this.time--;
              },1000)
              this.showTimer = true;
            }
            else{
              this.$message.error(res.data.message);
            }
          })
        }
        else {
          return false
        }
      })
    },
    // 提交结算
    onSubmit(form, isAgree) {
      // 表单验证公司、姓名、电话、验证码
      this.$refs.form.validate(valid => {
        if(valid) {
          // 表单验证注册码
          this.$refs.formRegister.validate(valid1 => {
            if(valid1) {
              // 是否同意递航服务协议和递航隐私政策
              if(isAgree) {
                this.isLoading=true
                this.form = form;
                // MAX购买
                let obj = this.form;
                obj.mobile = this.form.phone;
                obj.inviteUserId = this.inviteUserId;
                this.purchaseSohoMax(obj)
              }
              else {
                this.$message.warning('请先阅读并同意递航服务协议和递航隐私政策');
              }
            }
            else {
              return false;
            }
          })
        }
        else {
          return false;
        }
      });
    },
    // 注册成功弹窗关闭
    cancel() {
      this.showSuccessWindow = false;
      this.outerVisible = false;
      this.visibleCode = false; // 关闭支付成功的窗口
      this.$emit('sohoTypeReset');
      this.reInit()
      this.form = {
        name: undefined, // 姓名
        phone: undefined, // 电话
        validCode: undefined, // 验证码
        buyNum: 1, // 账号数量
        skuId: -1,
        registerCode: undefined, // 注册码
        type: 6,  // 6是sohoPro的类型
        inviteUserId: undefined, // 邀请人用户id
      };
    },
    // 购买
    async purchaseSohoMax(obj) {
      // 发送订单
      await this.sendOrder(obj)
    },
    // 发送订单
    async sendOrder(obj) {
      let { data } =  await submitOrder(obj)
      if (data.code === 200) {
        this.orderSn = data.data.orderSn;
        // 生成二维码
        let obj = {
          orderSn: this.orderSn,
          cityId: this.form.cityId,
          industry: this.form.industry,
        }
        await this.createQRCode(obj)
      }else {
        this.isLoading=false
        this.$message.error(data.message)
      }
    },
    // 支付二维码
    async createQRCode(obj) {
      let { data } = await payCode(obj)
      if (data.code === 200) {
        this.ifCodeShow = true;
        this.qrCodeAddress = data.data.codeUrl;
        if(!this.qrCode) {
          this.qrcode(this.qrCodeAddress);
        }
        const that = this;
        that.timer = window.setInterval(() => {
          if(that.ifCodeShow == false || that.ifShow == false){
            window.clearInterval(that.timer);
          }
          that.timer2 = window.setInterval(() => {
            that.pollQuery(obj)
          },2000)
        }, 1000);
      }else {
        this.$message.error(data.message);
      }
    },
    // 生成二维码
    qrcode(url){
      this.$nextTick(()=>{
        this.qrCode = new QRCode("qrcode",{
          width:180,
          height:180,
          text:url,
        }),
        this.isLoading=false
      })
    },
    // 轮询查询二维码状态
    async pollQuery(obj) {
      let { data } = await check(obj)
      if (data.code === 200) {
        if (data.data) {
          window.clearInterval(this.timer);
          window.clearInterval(this.timer2);
          this.timer = null;
          this.timer2 = null;
          this.visibleCode = true;
          this.showTimer=false
          setTimeout(()=>{
            this.ifCodeShow = false;
            this.payTime = 1000 * 60 * 10;
            this.clearQrCode()
            this.showSuccessWindow = true;
          },3000)
        }
      }else {
        this.payTime -= 1000;
        if(this.payTime === 0){
          window.clearInterval(this.timer);
          window.clearInterval(this.timer2);
          this.timer = null;
          this.timer2 = null;
          alert('支付失败');
          this.visibleCode = false;
          this.ifCodeShow = false;
          this.payTime = 1000 * 60 * 10;
        }
      }
    },
    // 关闭二维码窗口
    closeCodeVisible(){
      this.ifCodeShow = false;
      this.visibleCode = false;
      this.qrCode = '';
      window.clearInterval(this.timer);
      window.clearInterval(this.timer2);
    },
    // 清除二维码
    clearQrCode(){
      // this.$refs.qrcode.innerHTML = "";
      this.qrCode = '';
    }
  }
}
</script>

<style lang="less">
.new-pc-soho-free{
  width: 848px!important;
}
.new-pc-soho-free .ant-modal-content{
  width: 848px!important;
  padding: 32px!important;
  background: #F7F8FA;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}
.new-pc-soho-free .ant-modal-body{
  padding: 0;
}
.new-pc-soho-free .top,
.new-pc-soho-free .bottom
{
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;
}
.new-pc-soho-free .top {
  .title-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #1B1C33;

    .icon {
      font-size: 24px;
    }

    .title {
      margin-left: 23px;
    }
  }

  .modal-line {
    height: 1px;
    background: #EDEDF0;
    margin: 16px 0;
  }

  .info {
    font-size: 14px;
    font-weight: 400;
    color: #1B1C33;
    margin-bottom: 16px;
  }
  .form-and-register{
    display: flex;
    .ant-form{
      flex: 1;
      margin-right: 30px;
      flex-shrink: 0;
      .ant-form-item{
        display: flex;
        flex-shrink: 0;
        margin-bottom: 20px;
        .ant-form-item-label{
          width: 80px!important;
          text-align: right;
          flex-shrink: 0;
        }
        .ant-form-item-control-wrapper{
          flex-shrink: 0;
          //todo
          width: 220px;
        }
      }
      .get-code-wrap{
        position: relative;
        .hit-code-wrap{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          .items-code-2{
            color: #0055FF;
          }
          .items-code-time-2{
            color: #8D8E99;
          }
        }
      }
    }
    .register-wrap{
      flex: 1;
      margin-left: 30px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      .register-customer-service{
        display: flex;
        align-items: center;
        margin-bottom: 16px!important;
        img{
          width: 24px;
          height: 24px;
        }
        span{
          margin-left: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1B1C33;
        }
      }
      .register-code-wrap{
        display: flex;
        align-items: center;
        justify-content: end;
        img{
          width: 80px;
          height: 80px;
          margin-right: 20px;
        }
        .ant-form{
          margin-right: 0;
        }
      }
    }
  }
}
.new-pc-soho-pro .bottom{
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  .title{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .buy{
      font-weight: 400;
      font-size: 14px;
      color: #1B1C33;
    }
  }
  .price-card-wrap{
    display: flex;
    margin: 18px 0;
    .price-card{
      width: 172px;
      background: #F7F8FA;
      border-radius: 8px;
      padding: 12px 16px;
      margin-right: 16px;
      box-sizing: border-box;
      flex-shrink: 0;
      .price-choose-month{
        font-size: 16px;
        color: #1B1C33;
        line-height: 22px;
      }
      .sell-price-and-original{
        display: flex;
        .price-choose-discounts{
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #FE6C30;
        }
        .price-choose-original{
          margin-left: 16px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8D8E99;
          display: flex;
          align-items: end;
          position: relative;
          .delete-line{
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #8D8E99;
            top: 75%;
            //transform: translateY(-50%);
          }
        }

      }
      .price-choose-day{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8D8E99;
      }
    }
    .active-price-card{
      background: rgba(0, 85, 255, 0.04);
      border: 1px solid #0055FF;
    }
    .active-price-card:last-of-type{
      margin-right: 0;
    }
  }
  .modal-line-2{
    width: 100%;
    height: 1px;
    background: #EDEDF0;
    margin: 16px 0;
  }
  .price-btn{
    display: flex;
    justify-content: space-between;
    .calculate-wrap{
      .calculate-last,.calculate-price,.calculate-discount,.price-show-2{
        width: 155px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;
        color: #8D8E99;
        margin-bottom: 8px;
      }
      .calculate-last{
        color: #FE6C30;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .btn-wrap{
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      .submit-check{
        .agree{
          margin-left: 8px;
          font-size: 14px;
          line-height: 20px;
          color: #8D8E99;
          .agreement{
            color: #0055FF;
          }
        }
      }
      .enterprise-criterion-btn{
        margin-top: 8px;
        // padding: 10px 80px;
        background: #FE6C30;
        border-radius: 8px;
        width: 276px;
        height: 40px;
      }
    }
  }
}

//支付弹框样式
.pay-window-small{
  // 二维码样式
  .pay-success{
    position: relative;
    margin:56px auto;
    margin-bottom: 0;
    width: 180px;
    height: 180px;
    background-color: #7389b6;
    .pay-success-inner{
      position: absolute;
      width: 180px;
      height: 180px;
      left: 0;
      top: 0;
      background-color: #F2F3F5;
      opacity: 0.8;
    }
    .pay-success-image{
      position: absolute;
      top: 58.5px;
      left:58.5px;
      width: 63px;
      height: 63px;
    }
  }
  .qr-success{
    position: relative;
    width: 100%;
    margin-top:12px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #65CB65;
    margin-bottom:24px;
  }
  .qr-text{
    position: relative;
    width: 100%;
    margin-top:12px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #8D8E99;
    margin-bottom:24px;
  }
}
</style>
