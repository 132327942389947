<!--猎头系统选择产品套餐-->
<template>
  <div>
    <div v-if="enterpriseType && version === 1">
<!--      <enterpriseFree  @enterpriseTypeReset="enterpriseTypeReset" v-if="enterpriseType === 1" />-->
      <newEnterpriseFree  @enterpriseTypeReset="enterpriseTypeReset" v-if="enterpriseType === 1" :visible="enterpriseType === 1"/>
<!--      <enterpriseCriterion @enterpriseTypeReset="enterpriseTypeReset" v-else-if="enterpriseType === 2" />-->
      <newEnterpriseCriterion @enterpriseTypeReset="enterpriseTypeReset" v-else-if="enterpriseType === 2" :visible="enterpriseType === 2"></newEnterpriseCriterion>
    </div>
<!--    v-else-if="sohoType && version === 2"-->
    <div>
<!--      <sohoFree @sohoTypeReset="sohoTypeReset" v-if="sohoType === 1"></sohoFree>-->
      <newSohoFree @sohoTypeReset="sohoTypeReset" v-if="sohoType === 1" :visible="sohoType === 1"></newSohoFree>
      <newSohoPro @sohoTypeReset="sohoTypeReset" v-else-if="sohoType === 2" :visible="sohoType === 2"></newSohoPro>
      <newSohoMax @sohoTypeReset="sohoTypeReset" v-else-if="sohoType === 3" :visible="sohoType === 3"></newSohoMax>
    </div>
    <div class="headhunting-wrap">
      <div v-if="false" class="headhunting-top">
<!--        <div class="return-wrap" @click="returnFun">-->
<!--          <a-icon type="left" />-->
<!--          <span>返回</span>-->
<!--        </div>-->
        <div class="top-text" v-if="false">选择您的产品套餐</div>
      </div>
      <div class="headhunting-content">
        <div class="tabs-item-wrap">
          <div
            class="tabs_item center"
            :class="version == index + 1 ? 'active_item':''"
            @click="changeIndex(index + 1)"
            v-for="(item,index) in tabs"
            :key="index"
          >
            {{item}}
          </div>
        </div>
        <enterprise @enterpriseTypeChange="enterpriseTypeChange" v-if="version === 1" />
        <soho @sohoTypeChange="sohoTypeChange" v-else-if="version === 2" />
      </div>
    </div>
  </div>
</template>

<script>
import enterprise from "@/views/PCPage/productContact/component/enterpriseIndex"; // 企业版页面
// import enterpriseFree from "@/views/PCPage/productContact/component/enterpriseFree"; // 企业版-免费版页面
import soho from "@/views/PCPage/productContact/component/sohoIndex"; // SOHO版页面
// import sohoFree from "@/views/PCPage/productContact/component/sohoFree"; // SOHO版-Free版页面
// import sohoPro from "@/views/PCPage/productContact/component/sohoPro"; // SOHO版-Pro版页面
// import sohoMax from "@/views/PCPage/productContact/component/sohoMax";
import newEnterpriseCriterion from "@/views/PCPage/productContact/component/newEnterpriseCriterion"; // SOHO版-Max版页面
import newSohoFree from "@/views/PCPage/productContact/component/newSohoFree";
import newSohoPro from "@/views/PCPage/productContact/component/newSohoPro";
import newSohoMax from "@/views/PCPage/productContact/component/newSohoMax";
import newEnterpriseFree from "@/views/PCPage/productContact/component/newEnterpriseFree";

export default {
  name: "comHunterSystem",
  components: {enterprise, newEnterpriseFree, newEnterpriseCriterion, soho, newSohoFree, newSohoPro, newSohoMax},
  data() {
    return {
      version: 1, // 1为企业版，2为SOHO版
      enterpriseType: 0, // 1为免费版，2为标准版
      sohoType: 0, // 1为Free版，2为Pro版，3为Max版
      tabs: ['企业版','SOHO版'],
    }
  },
  mounted() {
    if(this.$route.query && this.$route.query.version) {
      this.version = Number(this.$route.query.version);
    }
    if(this.$route.query && this.$route.query.enterpriseType) {
      this.enterpriseType = Number(this.$route.query.enterpriseType);
    }
    if(this.$route.query && this.$route.query.sohoType) {
      this.sohoType = Number(this.$route.query.sohoType);
    }
    if(this.$route.query && this.$route.query.inviteUserId){
      this.version=2
      this.sohoType=0
    }
  },
  methods: {
    changeIndex(index) {
      this.version = index;
      this.versionChange();
    },
    // 返回上一个页面
    returnFun() {
      // 调用父组件的重置systemType方法
      this.$emit('systemTypeReset');
    },
    // 选择企业版 SOHO版
    versionChange(value) {
      if(value === 1) {
        this.enterpriseType = 0;
      }
      else if(value === 2) {
        this.sohoType = 0;
      }
    },
    // 将enterpriseType重置为0
    enterpriseTypeReset() {
      this.enterpriseType = 0;
    },
    // 点击立即体验切换到企业版购买详情页面
    enterpriseTypeChange(type) {
      this.enterpriseType = type;
    },
    // 将sohoType重置为0
    sohoTypeReset() {
      this.sohoType = 0;
    },
    // 点击立即体验切换到SOHO版购买详情页面
    sohoTypeChange(type) {
      this.sohoType = type;
    },
  }
}
</script>

<style lang="less" scoped>
.headhunting-wrap {

}
.headhunting-top {
  position: relative;
  padding: 64px 0 90px;
}
.headhunting-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tabs-item-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
}
.tabs_item {
  box-sizing: border-box;
  cursor: pointer;
  padding-bottom: 8px;
  color: #1B1C33;
  margin-right: 48px;
  font-weight: 400;
  font-size: 24px;
  //消除切换tabs和产品咨询时发生的高度抖动
  //height: 33.5px;
}
.tabs-item-wrap > .tabs_item:nth-last-child(1) {
  margin-right: 0;
}
.tabs_item:hover {
  color: #0055FF;
}
.active_item {
  border-bottom: 2px solid #0055FF;
  color: #0055FF;
  font-weight: 500;
  font-size: 24px;
}
.tab-line {
  width: 550px;
  height: 2px;
}
.tab-active-line {
  width: 80px;
  height: 2px;
  background-color: #0055FF;
  transform: translateX(0px);
}
</style>
<style lang="less">
.return-wrap {
  color: #1B1C33;
  position: absolute;
  top: 72px;
  left: 66px;
  cursor: pointer;
}
.return-wrap:hover {
  color: #0055FF;
}
.return-wrap > i {
  font-size: 23px;
}
.return-wrap > span {
  font-size: 24px;
  font-weight: 500;
  margin-left: 12px;
}
.top-text {
  color: #1B1C33;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
}
</style>
