<template>
  <div class="article-item" @click="goToArticleDetail">
    <div class="title-btn-wrap">
      <div class="article-item-btn" v-if="articleItem.topFlag">置顶</div>
      <div class="article-item-title">{{articleItem.title}}</div>
    </div>
    <div class="article-item-time">{{articleItem.updateTime}}</div>
    <div class="article-item-info" v-html="articleItem.content">'<p>fdjskfjkddddddddddksjfkdjfkljakejflkdjalkfjlkdjlkfjlkajfoijdiogjfiojqfkljalkjfkljdklsjfkldsjklfjklasjfkljakljfkldjafkjkfljlakjlf</p>'</div>
  </div>
</template>
<script>
export default {
  props:{
    articleItem:{
      type:Object,
      required:true
    }
  },
  data() {
    return {
      count: 0
    }
  },
  methods:{
    goToArticleDetail(){
      // this.$router.push({path:'/indexArticle/articleDetail',query:{
      //     owArticleId:this.articleItem.owArticleId
      //   }})
      //编程式导航打开新页面
      let routeData = this.$router.resolve({
        query: {owArticleId:this.articleItem.owArticleId},
        path:'/indexArticle/articleDetail'
      });
      window.open(routeData.href, '_blank');
    }
  }
}
</script>
<style scoped>
.article-item{
  width: 431px;
  margin-bottom: 43px;
  cursor:pointer;
}
.title-btn-wrap{
  display: flex;
}
.article-item-btn{
  background: #0055FF;
  border-radius: 4px;
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 13px;
  color: #FFFFFF;
  font-size: 18px;
}
.article-item-title{
  width: 360px;
  font-weight: 600;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  color: #1B1C33;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.article-item-time{
  margin-top: 15px;
  margin-bottom: 21px;
  font-size: 14px;
  color:  #8D8E99;
}
.article-item-info{
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
  line-height: 25px;
  height: 50px;
}
.article-item-info /deep/ h1,
.article-item-info /deep/ h2,
.article-item-info /deep/ h3,
.article-item-info /deep/ strong,
.article-item-info
{
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #5E5E66;
}

/*消除富文本编辑器里得元素得一些影响*/
.article-item-info /deep/ p,
.article-item-info /deep/ h1,
.article-item-info /deep/ h2,
.article-item-info /deep/ h3,
.article-item-info /deep/ strong
{
  margin: 0;
}
.article-item-info /deep/ img{
  margin: 100px;
}

</style>