<template>
  <div class="article-card-wrap">
    <div class="article-card">
      <div class="article-title">{{top.title}}</div>
      <div class="article-time">{{top.updateTime}}</div>
      <div class="btn">
        <a-button class="cancle-btn" @click="handleCancleHead" type="primary">
          取消头部
        </a-button>
        <div class="delete-and-edit">
          <a-popconfirm
              title="确定删除?删除后不可恢复"
              ok-text="确定"
              cancel-text="取消"
              @confirm="confirm"
              @cancel="cancel"
          >
            <a-button class="delete-btn">删除</a-button>
          </a-popconfirm>
          <a-button class="edit-btn" @click="handleEditHead">编辑</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {cancleHead,deleteArticle} from '@/api/request'
import Bus from "@/utils/bus";
export default {
  components: {

  },
  props: {
    top: {
      type: Object
    }
  },
  mounted() {

  },
  methods:{
    handleCancleHead(){
      cancleHead(
          {
            owArticleId:this.top.owArticleId
          }
      ).then(res=>{
        if(res.data.code===200){
          this.$message.success('取消成功！');
        }
        else {
          this.$message.error(res.data.message);
        }
        //重新更新一下头部文章数据，子传父
        this.$emit("update")
      })

    },
    handleDeleteHead(){
      deleteArticle(
          {
            owArticleId:this.top.owArticleId
          }
      ).then(res=>{
        if(res.data.code===200){
          this.$message.success('删除成功！');
        }
        else {
          this.$message.error(res.data.message);
        }
        //重新更新一下头部文章数据，子传父
        this.$emit("update")
      })
    },
    handleEditHead(){
      Bus.$emit('updateKey','3')
      const that=this
      this.$router.push({path:'/manage/editArticle', query: {owArticleId: that.top.owArticleId}})
    },
    confirm() {
      this.handleDeleteHead()
    },
    cancel() {
      this.$message.success('取消成功！');
    },
  }
}
</script>

<style scoped>
  .article-card-wrap{
    padding: 18px 22px;
    width: 300px;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
  }
  .article-card{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .article-title{
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #1B1C33;
    height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .article-time{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #5E5E66;
    margin: 10px 0 20px 0;
  }
  .btn{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .cancle-btn{
    padding: 5px 16px;
    width: 88px;
    height: 32px;
    background: #0055FF;
    border-radius: 2px;
  }
  .delete-and-edit{
    display: flex;
    justify-content: right;
  }
  .delete-btn,
  .edit-btn
  {
    padding: 5px 16px;
    width: 60px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
  }
  .edit-btn{
    margin-left: 8px;
  }


</style>
