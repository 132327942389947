<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  components:{

  },
  data() {
    return {

    }
  },
  methods:{

  }
}
</script>
<style></style>