//pc
import indexHome from '../views/PCPage/indexHome/indexHome'
import hunterSystem from "@/views/PCPage/hunterSystem/hunterSystem";
import hrSystem from "@/views/PCPage/hrSystem/hrSystem";
import indexArticle from "@/views/PCPage/indexArticle/component/indexArticle";
import aboutUs from "@/views/PCPage/aboutUs/aboutUs";
import defaultArticle from "@/views/PCPage/indexArticle/component/defaultArticle/defaultArticle";
import articleDetail from "@/views/PCPage/indexArticle/component/articleDetail/articleDetail";
import productContact from "@/views/PCPage/productContact/productContact";
import privacyPolicyPage from "@/views/PCPage/privacyPolicyPage";

//从下面开始是文章管理的
import login from "@/views/PCPage/loginIndex";
import manageIndex from "@/views/PCPage/manageIndex";
import manageArticle from "@/views/PCPage/manageArticle";
import addArticle from "@/views/PCPage/addArticle";
import editArticle from "@/views/PCPage/editArticle";
//mobile
import moHome from "@/views/MoPage/views/moHome";
import moHunterSystem from "@/views/MoPage/views/moHunterSystem";
import moHrSystem from "@/views/MoPage/views/moHrSystem";
import moAboutUs from "@/views/MoPage/views/moAboutUs";
import moDefaultArticle from "@/views/MoPage/views/moDefaultArticle";
import moArticleIndex from "@/views/MoPage/views/moArticleIndex";
import moArticleDetail from "@/views/MoPage/views/moArticleDetail";
import buyHunterSystem from "@/views/MoPage/views/buyHunterSystem";
import buyHunterSystemIndex from "@/views/MoPage/views/buyHunterSystemIndex";
import moSoHoFree from "@/views/MoPage/components/moSoHoFree";
// import moEnterpriseCriterion from "@/views/MoPage/components/moEnterpriseCriterion";
import newMoEnterpriseCriterion from '@/views/MoPage/components/newMoEnterpriseCriterion'
import moEnterpriseFree from "@/views/MoPage/components/moEnterpriseFree";
import buyHrSystem from "@/views/MoPage/views/buyHrSystem";
import moFreeUseTable from "@/views/MoPage/views/moFreeUseTable";
import bindWeixinSuccess from "@/views/MoPage/views/bindWeixinSuccess"


// PC端的路由
const PCRoutes=[
    {
        path:'',
        redirect:'/home',
        meta: { hasBtn: true }
    },
    {
        path:'/home',
        component: indexHome,
        meta: { hasBtn: true }
    },
    {
        path: '/hunterSystem',
        component:hunterSystem,
        meta: { hasBtn: true }
    },
    {
        path: '/hrSystem',
        component:hrSystem,
        meta: { hasBtn: true }
    },
    {
        path: '/indexArticle',
        component:indexArticle,
        meta: { hasBtn: true },
        children:[
            {
                path: 'articleDetail',
                component: articleDetail,
                meta: { hasBtn: true },
            },
            {
                path: 'defaultArticle',
                component: defaultArticle,
                meta: { hasBtn: true },
            },
            {
                path: '',
                redirect:'defaultArticle',
                meta: { hasBtn: true },
            }
        ]
    },
    {
        path: '/defaultArticle',
        component:defaultArticle,
        meta: { hasBtn: true }
    },
    {
        path: '/aboutUs',
        component:aboutUs,
        meta: { hasBtn: true }
    },
    {
        path: '/productContact',
        component: productContact,
        meta: { hasBtn: true }
    },
    {
        path: '/privacyPolicyPage',
        component: privacyPolicyPage,
        meta: { hasBtn: true }
    },
    //从下面开始是文章的
    {
        path:"/login",
        component:login,
        meta: {
            isArticle:true
        }
    },
    {
        path: '/manage',
        component: manageIndex,
        meta: {
            isArticle:true
        },
        children: [
            {
                path:'manageArticle',
                component:manageArticle,
                meta: {
                    isArticle:true
                }
            },
            {
                path: '',
                redirect:'manageArticle',
                meta: {
                    isArticle:true
                }
            },
            {
                path: 'addArticle',
                component: addArticle,
                meta: {
                    isArticle:true
                }
            },
            {
                path: 'editArticle',
                component: editArticle,
                meta: {
                    isArticle:true
                }
            }
        ]
    }

];
// 移动端路由
const MoRoutes=[
    {
        path:'',
        redirect:'/home',
    },
    {
        path:'/home',
        component: moHome,
    },
    {
        path: '/Success',
        component: bindWeixinSuccess
    },
    {
        path: '/hunterSystem',
        component:moHunterSystem,
    },
    {
        path: '/hrSystem',
        component:moHrSystem,
    },
    {
        path: '/indexArticle',
        component:moArticleIndex,
        children:[
            {
                path: 'articleDetail',
                component: moArticleDetail,
            },
            {
                path: 'defaultArticle',
                component: moDefaultArticle,
            },
            {
                path: '',
                redirect:'defaultArticle',
            }
        ]
    },
    {
        path: '/defaultArticle',
        component:moDefaultArticle,
    },
    {
        path: '/aboutUs',
        component:moAboutUs,
    },
    {
        path: '/productContact',
        component: buyHunterSystemIndex,
        children: [
            {
                path: '',
                component: buyHunterSystem
            },
            {
                path: 'soHoFree',
                component: moSoHoFree,
            },
            {
                path: 'enterpriseCriterion',
                component: newMoEnterpriseCriterion,
            },
            {
                path: 'enterpriseFree',
                component: moEnterpriseFree
            }
        ]
    },
    {
        path: '/buyHrSystem',
        component: buyHrSystem,

    },
    {
        path: '/freeUse',
        component: moFreeUseTable
    },
];


export default navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? MoRoutes : PCRoutes;
