<template>
  <div>
    <div v-if="articleItem.title" class="article-card" @click="goToArticleDetail">
      <div class="left-img">
<!--        <img src="//file.dhunting.com/official_website/v1/article.png" alt="">-->
        <img :src="articleItem.banner" alt="">
      </div>
      <div class="right-info flex-col-enter">
        <div class="title">{{articleItem.title}}</div>
        <div class="time">{{articleItem.updateTime}}</div>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: 'moArticleCard',
  props:{
    articleItem:{
      type:Object
    }
  },
  components: {

  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods:{
    goToArticleDetail(){
      // this.$router.push({path:'/indexArticle/articleDetail',query:{
      //     owArticleId:this.articleItem.owArticleId
      //   }})
      //编程式导航打开新页面
      let routeData = this.$router.resolve({
        query: {owArticleId:this.articleItem.owArticleId},
        path:'/indexArticle/articleDetail'
      });
      window.open(routeData.href, '_blank');
    }
  }
}
</script>
<style lang="less" scoped>
.line{
  width: 311px;
  height: 1px;
  background: #EDEDF0;
  margin: 16px 0;
  border: none;
}
.article-card{
  width: 100%;
  display: flex;
  .left-img{
    height: 80px;
    width: 120px!important;
    flex-shrink: 0;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain!important;
    }
  }
  .right-info{
    padding-left: 16px;
    flex-shrink: 0;
    width: 175px;
    .title{
      font-weight: 500;
      font-size: 12px;
      color: #1B1C33;
      margin-bottom: 16px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
      width: 100%;
      text-align: left!important;
    }
    .time{
      font-weight: 400;
      font-size: 10px;
      color: #8D8E99;
      width: 100%;
      text-align: left;
    }
  }
}
</style>