<template>
  <div class="btn-wrap-wrap">
    <div class="btn-wrap">
      <div class="btn-item">
<!--        老版表单-->
<!--        <contactTable class="hover-contact" @closeTable="handleCloseTable" v-if="isHoverContact"></contactTable>-->
<!--        <div class="small-hand contact-wrap" @click="clickContact" v-show="!isHoverContact">-->
<!--          <iconpark-icon class="contact-icon" name="demo"></iconpark-icon>-->
<!--          <span class="nowrap">预约演示</span>-->
<!--        </div>-->
<!--        <div class="small-hand cancle-contact" @click="clickContact" v-show="isHoverContact"><a-icon type="close" /></div>-->
<!--        新版表单-->
<!--        <contactTable class="hover-contact" v-if="isHoverContact"></contactTable>-->
        <div class="small-hand contact-wrap" @click="clickContact">
          <iconpark-icon class="contact-icon" name="demo"></iconpark-icon>
          <span class="nowrap">预约演示</span>
        </div>
        <a-modal
            dialogClass="pc-free-use-modal"
            :visible="isHoverContact"
            @cancel="cancelFreeUse"
            :closable="true"
            :footer="null"
        >
          <div class="title">
            请完善以下信息
          </div>
          <div class="title marginB24">Dhunting专业顾问会在一个工作日内与您联系</div>
          <div class="form">
            <a-form-model
                :model="form"
                :rules="rules"
                :hideRequiredMark="true"
                ref="ruleForm"
            >
              <a-form-model-item label="公司名称" prop="companyName">
                <a-input v-model="form.companyName" placeholder='请输入公司名称'/>
              </a-form-model-item>
              <a-form-model-item label="姓名" prop="userName">
                <a-input v-model="form.userName" placeholder='请输入您的姓名'/>
              </a-form-model-item>
              <a-form-model-item label="电话" prop="phone">
                <a-input v-model="form.phone" placeholder='请输入电话号码'/>
              </a-form-model-item>
              <a-form-model-item label="意向产品类型" prop="intentProductType">
                <a-select
                    dropdownClassName="select-wrap"
                    v-model="form.intentProductType"
                    placeholder="请选择"
                    :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
                >
                  <a-select-option v-for="(item, index) in versionsList" :key="index" :value="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-button type="primary" @click="handleSubmit" class="submit-btn">
                立即咨询
              </a-button>
            </a-form-model>
          </div>
        </a-modal>
      </div>
    </div>
    <successModal :is-visible="visible" type="order" @cancel="cancel"></successModal>
  </div>

</template>

<script>
// import weChat from "@/components/weChat/weChat";
import successModal from "@/views/PCPage/productContact/component/successModal";
import {consultationSave, consultationVersions} from "@/api/request";
// import contactTable from "@/components/contactTable/contactTable";
export default {
  components: {
    // weChat,
    // contactTable,
    successModal
  },
  mounted() {
    //获取产品类型
    this.getConsultationVersions()
  },
  data() {
    return {
      versionsList: [], // 产品类型
      form:{
        companyName: "",  //公司名称
        userName: '',
        phone:'',
        intentProductType: undefined
      },
      rules:{
        companyName: [{ required: true, message: '请输入公司名称', trigger: ['blur', 'change'] },],
        userName: [
          { required: true, message: '请输入姓名', trigger: ['blur', 'change'] },
        ],
        phone: [{
          required: true,
          len: 11,
          pattern: RegExp(/(^(\+?0?86-?)?1[3456789]\d{9}$)|(^(\+?852-?)?[569]\d{3}-?\d{4}$)|(^(\+?886-?|0)?9\d{8}$)|(^(0065)\d{8}$)/),
          message: "请输入正确的手机号",
          trigger: ['blur', 'change']
        }],
        intentProductType: [
          { required: true, message: '请选择意向类型', trigger: ['blur', 'change'] },
        ],
      },
      isHoverContact:false,
      isHoverWeChat:false,
      visible:false
    };
  },
  methods:{
    cancelFreeUse(){
      this.isHoverContact=false
    },
    //提交表单
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          consultationSave(this.form).then(res => {
            if(res.data.code === 200) {
              // this.$message.success('提交成功');
              let form = {
                companyName: "",  //公司名称
                userName: '',
                phone:'',
                intentProductType: undefined
              };
              this.form = JSON.parse(JSON.stringify(form));
              //关闭表单弹框，打开成功弹框
              this.isHoverContact=false
              this.visible=true
            }
            else {
              this.$message.error(res.data.message);
            }
          })
        }
        else {
          return false;
        }
      });
    },
    //获取产品类型
    async getConsultationVersions() {
      let res = await consultationVersions();
      if(res.data.code === 200) {
        this.versionsList = res.data.data;
      }
      else {
        this.$message.error(res.data.message);
      }
    },
    // 预约成功弹窗关闭
    cancel() {
      this.visible = false;
    },
    handleCloseTable(){
      this.isHoverContact=false
      this.visible=true
    },
    clickContact(){
      // this.isHoverContact=!this.isHoverContact
      this.isHoverContact=true
    },
    handleHoverContact(){
      this.isHoverContact=true
    },
    handleHoverWeChat(){
      this.isHoverWeChat=true
    },
    handleLeaveContact(){
      this.isHoverContact=false
    },
    handleLeaveWeChat(){
      this.isHoverWeChat=false
    }
  }
}
</script>

<style lang="less" scoped>
.small-hand{
  cursor: pointer;
}
.btn-wrap-wrap{
  position: fixed;
  right: 24px;
  bottom: 100px;
  z-index: 9999;
}
.hover-contact{
  height: 600px;
  overflow-y: scroll;
  width: 300px;
  position: absolute;
  right: 80px;
  bottom: 0px;
  padding: 20px;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  background-color: #FFFFFF;
  z-index: 9999;
}
.hover-weChat{
  width: 300px;
  position: absolute;
  right: 80px;
  top: -100px;
  padding: 24px;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  background-color: #FFFFFF;
  z-index: 9999;
}
.btn-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FE6C30;
  //border-radius: 20px 0px 0px 20px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  z-index: 9999;
  //height: 216px;
  height: 80px;
  width: 80px;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.2);
}
.btn-wrap  iconpark-icon{
  font-size: 50px;
}
.btn-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  width: 80px;
  box-sizing: border-box;
}
/deep/ .ant-form-item{
  margin-bottom: 10px !important;
}
.contact-icon{
  //width: 30px;
  //height: 34px;
  font-size: 48px!important;
}
.nowrap {
  white-space: nowrap;
  font-size: 12px!important;
}
.cancle-contact{
  font-size: 28px;
}
.contact-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.marginB24{
  margin-bottom: 24px;
}
.submit-btn{
  width: 416px;
  height: 40px;
  background: #0055FF;
  border-radius: 8px;
  //padding: 10px 188px;
  font-size: 14px;
  //line-height: 20px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
<style lang="less">
.pc-free-use-modal{
  width: 480px!important;
}
.pc-free-use-modal .ant-modal-content{
  border-radius: 16px;
  width: 480px;
  //height: 520px;
  padding: 32px;
}
.pc-free-use-modal .ant-modal-body{
  padding: 0;
}
.pc-free-use-modal .ant-modal-body .title{
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1B1C33;
}
.pc-free-use-modal .ant-form-item-label{
  height: 35px!important;
}
.pc-free-use-modal {
  .ant-form {
    .ant-form-item-control-wrapper, .ant-form-item-control, .ant-input ,.ant-select-selection{
      height: 40px !important;
    }
    .ant-select{
      position: relative;
      top:-5px;
    }
    .ant-form-explain {
      top: 40px;
    }
  }
}
</style>

