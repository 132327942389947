<template>
  <div class="mobile-wrap">
    <mo-header></mo-header>
    <div class="height60"></div>
    <router-view></router-view>
    <mo-footer></mo-footer>
  </div>
</template>

<script>
import moHeader from "@/views/MoPage/components/moHeader";
import moFooter from "@/views/MoPage/components/moFooter";
export default {
  name: 'MIndex',
  components: {
    moHeader,
    moFooter,
  },
  data() {
    return {

    };
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
  .height60{
    height: 60px;
    width: 100%;
  }
  .mobile-wrap{

  }
  //.content{
  //  margin-top: 60px;
  //}
</style>

