<template>
  <a-modal
      :width="600"
      :footer="false"
      :visible="isVisible"
      @cancel="cancel"
  >
    <div class="success-content">
      <template v-if="type === 'order'">
        <div class="success-text">预约成功</div>
        <div class="success-submit">您已成功提交信息！</div>
        <div class="success-tip">将由专人致电与您沟通，请留意来电。</div>
        <a-button type="primary" class="success-btn" @click="toHome">返回首页</a-button>
      </template>
      <template v-else-if="type === 'register'">
        <div class="success-text marginB24">注册成功</div>
        <div>
          <div class="success-info-text">【账号】：{{phone}}</div>
          <div class="success-info-text">【密码】：123123</div>
          <div class="success-info-text">【登录网址】：https://h.dhunting.com</div>
        </div>
        <div class="success-chrome">建议使用Chrome浏览器登录</div>
        <div class="success-btn-wrap">
          <a-button class="success-btn-left" @click="copyInfo">复制信息</a-button>
          <a-button type="primary" class="success-btn-right" @click="toSystem">立即登录</a-button>
        </div>
      </template>
    </div>
  </a-modal>
</template>

<script>
export default {
  props:{
    isVisible: {
      type: Boolean,
      default: false,
    },
    // type: order预约成功 register注册成功
    type: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: ""
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // 关闭弹窗
    cancel() {
      this.$emit('cancel')
    },
    copyInfo(){
      let text=`账号：${this.phone}，密码：123123，登录网址：https://h.dhunting.com`
      navigator.clipboard.writeText(text)
          .then(() => this.$message.success('复制成功！'))
          .catch(() => this.$message.error('复制失败！'))
    },
    // 跳转系统
    toSystem() {
      window.open('https://h.dhunting.com')
    },
    toHome(){
      if(this.$route.path!='/home'){
        this.$router.push('/home')
      }
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-modal-header {
  display: none;
}
/deep/.ant-modal-content {
  border-radius: 8px;
}
/deep/.ant-modal-body {
  padding: 0;
}
.success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 38px 0 42px;
  width: 100%;
  height: 100%;
  background: url("//file.dhunting.com/official_website/successModelBg.png") no-repeat;
  background-size: 100% 100%;
}
.success-text {
  color: #0055FF;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 48px;
}
.success-submit {
  color: #1B1C33;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
}
.success-tip {
  color: #5E5E66;
  font-size: 16px;
  margin-bottom: 70px;
}
.success-btn {
  width: 180px;
  height: 48px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
}
.success-info-text {
  color: #1B1C33;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}
.success-chrome {
  color: #8D8E99;
  font-size: 16px;
  margin-bottom: 26px;
}
.success-btn-wrap {

}
.success-btn-left,
.success-btn-right {
  width: 180px;
  height: 48px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
}
.success-btn-right {
  margin-left: 78px;
}
.marginB24 {
  margin-bottom: 24px;
}
</style>
