<!--递航服务协议弹窗-->
<template>
  <a-modal
      v-model="visible"
      title="递航服务协议"
      centered
      :footer="null"
      :bodyStyle="bodyStyle"
      width="600px"
      wrapClassName="service-agreement-modal"
      @cancel="handleCancel"
  >
    <div>
      直猎（上海）网络科技有限公司（以下简称“Dhunting递航”或“递航”）
      依据《递航服务协议》(以下简称"本协议")通过Dhunting递航平台(简称“平台”)提供服务，本协议具有合同效力。 <br/>
      为使用本协议的服务，您应当保证具备中华人民共和国法律规定的与行为相适应的民事行为能力，您应当仔细阅读并遵守本协议。请您务必认真阅读、充分理解各条款内容，特别是免除或限制责任的相应条款、递航发布的业务规则，并选择接受或不接受。如您不同意本协议的内容，您将无法使用本服务。如您继续使用本协议的服务，即视为您已阅读并同意本协议的约束。<br/>
      递航有权修改协议内容并在平台上予以公布，修改后的协议经递航公布后，立即自动生效;一旦您继续使用或购买本协议的服务，则表示您已接受并自愿遵守经修订后的条款。<br/>
      <br/>
      <span class="strong">第一条 服务说明 <br/></span>
      <br/>
      1.钱包余额是由用户使用邀请码帮助其他公司购买账号及在递航小程序完成奖励所得，只限于在递航平台购买及续费账号，充值平台通话时长。<br/>
      2.递航可能会根据用户需求、产品及服务类型的变化等因素，调整现有服务种类及内容或不断推出新的服务。平台最新服务种类、服务内容、具体规则及费用标准以相关协议或服务界面公布的规则、
      实际提供的内容为准。用户可以自行根据需要选择相应服务。 <br/>
      3.基于⻛控策略/安全⻛险/产品政策等的需要，递航可能要求部分⽤户补充提供材料（包括但不限于企业资质证明、承诺书、业务协议等），具体要求会在相关⻚⾯上做明确展示。如⽤户拒绝提供前述材料，Dhunting递航有权视情况暂停或终⽌向该⽤户提供部分或全部服务。<br/>
      4.递航有权通过拨打电话、发送短信或电⼦邮件等⽅式，告知⽤户平台服务相关的⼴告信息、促销优惠等营销信息，以及邀请⽤户参与版本测试、⽤户体验反馈、回访等活动。<br/>
      <br/>
      <span class="strong">第二条 服务开通及使用 <br/></span>
      <br/>
      1. 用户无法将钱包余额提现，如有疑问，用户可通过递航客服专线进行处理。<br/>
      2. 用户不得以如下任何情形开通或使用服务:<br/>
      (1) 以营利为目的为自己或他人开通或使用服务;<br/>
      (2) 以非法目的为自己或他人开通或使用服务; <br/>
      (3) 以窃取、滥用技术等不正当方式为自己或他人开通或使用服务;<br/>
      (4) 向第三方公开、披露或提供已经开通或使用的服务; <br/>
      (5) 通过黑客技术、爬虫软件、机器人软件、第三方插件、逆向工程或病毒程序等任何方式为自己或他人开通或使用服务; <br/>
      (6) 以未经递航授权的方式或渠道为自己或他人开通或使用本服务; <br/>
      (7) 侵犯递航或第三人的专利、商标、著作权、财产权等合法权利为自己或他人开通或使用服务; <br/>
      (8) 违反本协议、递航发布或修订的相关协议、规则，为自己或他人开通或使用服务; <br/>
      (9) 其他违反法律、法规、规章或相关规定，为自己或他人开通或使用服务。 <br/>
      3. 用户使用的服务可能存在相关期限限制，如有期限限制以递航平台界面公布的相关服务的具体规则为准。 <br/>
      4.申请开票<br/>
      (1) 经Dhunting递航平台购买续费账号或充值平台通话时长后，用户可以到【运营管理】-【发票管理】中申请开具发票。<br/>
      (2) 用户每一次申请开票的金额不得超过页面显示的最高可开金额。开票申请提交后，递航会对用户提交的开票主体进行身份验证（具体验证时间以页面提示为准）验证通过即视为提交成功。<br/>
      (3)用户应保证提交给递航平台的开票资料真实、合法、有效；开票主体应与用户当前所认证企业保持一致，如用户提交的开票资料有误或开票主体不一致等原因，递航有权直接拒绝用户的开票请求。用户如遇开票问题，例如更换发票、重开发票等，可咨询人工客服。<br/>
      <br/>
      <span class="strong">第三条 用户权利及义务<br/> </span>
      <br/>
      1.用户应遵守中华人民共和国相关法律法规、所居住或者开展经营活动或者业务国家或者地区的法律法规，不得将平台的网络存储平台和/或服务用于非法目的，不也得以非法方式使用平台的网络存储平台和/或服务。用户承诺在注册或者认证过程中，提供的资料合法、真实、有效。如提交的资料有任何变动，应该及时、准确更新。如因提交的资料不真实或者更新不及时而引发的问题，用户应承担相应责任。<br/>
      2.用户应按照递航相关规则及用户与Dhunting递航的相关约定合理、合法使用在递航获取、下载的信息及文件。未经递航及文件相关权利人授权，用户不得将递航服务过程中获取的任何信息或文件用于服务目的以外的其他目的，亦不得改编相关文件，不得将在递航服务过程中获取的简历信息/文件/肖像上传、提供或分享至与服务无关的任何第三方/平台/应用或商业使用。<br/>
      3.递航将会尽其商业上的合理努力保障用户在递航及服务中的数据存储安全，并在法律要求的期限内保留用户的个人信息。递航不对用户在递航服务中相关数据的删除或储存失败、超出法律规定及平台承诺的储存期限以外的数据负责，用户应当根据自身需求自行备份递航及服务中的相关数据。<br/>
      4.
      用户不得通过未经递航授权许可的任何第三方平台或渠道(包括但不限于淘宝、天猫、京东、拼多多等平台上的商家、个人或其他组织)购买递航平台上的服务，不得将自身账号提供给第三方进行代充代付不得为他人进行代充代付。因违反前述约定导致用户隐私泄露或财产损失的，用户应当自行承担相应后果;如给递航造成损失的，递航可以要求用户承担违约责任并赔偿损失。<br/>
      5. 用户应妥善保管自己的账号和密码，不应将其账号、密码转让、出售或出借他人使用。如用户发现其账号被盗取，或遭他人非法使用，应立即通知递航。因用户的保管疏忽等导致账号、密码泄露，或遭他人非法使用，递航不承担任何责任。
      <br/>
      6.
      在递航平台进行充值时，用户应仔细核对并确认自己的账号、仔细选择相关操作选项，充分确认充值金额。如因为用户自身输入账号错误、操作不当、泄露信息或不了解充值计费方式等因素造成充错账号、错选充值种类、被他人使用等情形而损害自身权益，递航将不会作任何补偿或赔偿。
      <br/>
      7.
      若用户以非法的方式，或使用非递航所指定的充值方式进行充值，递航不保证该充值顺利或者正确完成。若因此造成用户权益或第三方利益受损时，递航有权不作任何补偿或赔偿，同时保留随时冻结或终止该用户账号资格及使用各项充值服务的权利，相关资源费用不予退还。
      <br/>
      8.
      用户承诺在递航上传、发表、转载或者传输的内容合法、真实、有效，不得夸张描述或编造虚假信息，并对内容承担全部责任。用户不得在递航的任何页面发布、转载、传送含有下列任何内容的信息，否则递航有权不通知用户自行采取措施，包括但不限于删除用户发布的内容，限制、暂停使用，或终止合作永久封禁账户等措施:<br/>
      (1) 违反宪法确定的基本原则的;<br/>
      (2) 危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的; <br/>
      (3) 损害国家荣誉和利益的; <br/>
      (4) 煽动民族仇恨、民族歧视，破坏民族团结的;<br/>
      (5) 破坏国家宗教政策，宣扬邪教和封建迷信的;<br/>
      (6) 散布谣言，扰乱社会秩序，破坏社会稳定的;<br/>
      (7) 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的; <br/>
      (8) 侮辱或者诽谤他人，侵害他人合法权益的; <br/>
      (9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的; <br/>
      (10) 以非法民间组织名义活动的; <br/>
      (11) 性别歧视或指向性歧视，如民族歧视、地域歧视等内容;<br/>
      (12) 虚假、胁迫、猥亵、骚扰、中伤、粗俗、侵害他人隐私或其他有悖道德、令人反感的内容或信息; <br/>
      (13) 侵犯任何人的隐私权、名誉权、肖像权、专利权、商标权、著作权、商业秘密或其他专属权利的内容; <br/>
      (14) 传销、直销、广告(寻求合作)、促销、宣传、非法集资等内容，不限于文字、图片、二维码、 站外链接等形式; <br/>
      (15) 一个岗位发布多个职位信息，职位名称、职位描述、职位类型不明确或不符，职位名称包含职位名称以外的信息，不真实的企业介绍、招聘人身份、职位名称及描述等，以及其他存在较大风险的职位信息包
      括但不限于无经验交易员、无经验操盘手、无经验分析师、夜场等娱乐会所、网约车司机、微商代理、淘宝刷单、游戏代练、手机兼职、手工兼职、船员、影视娱乐圈等相关职位信息;
      <br/>
      (16) 含有其他违法违规及平台协议、违背善良风俗内容的。 <br/>
      9.
      用户充分知晓并同意，用户应为自己账号下的一切行为承担责任，包括但不限于用户所发布的任何内容以及由此产生的任何后果。用户应对本服务中的内容自行加以判断，并承担因使用服务而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险。如用户违反递航产品服务协议、规则或本协议等，递航有权冻结账号、终止账号使用资格或永久取消使用权限，同时有权要求用户赔偿递航遭受的实际损失。
      <br/>
      <br/>
      <span class="strong">第四条 信息保护<br/></span>
      <br/>
      1.平台重视对用户的信息保护，递航依法保护用户个人信息和隐私信息。特别地，简历储备作为用户及公司私有财产，平台将采用深入底层框架的多租户技术，多层数据隔离，保证数据的安全独立性。平台不会读取用户储存的简历信息。<br/>
      2.但由于外部人员对用户信息所采取的违法方式，包括但不限于破坏计算机系统、爬虫、数据窃取等，以及由于用户公司工作人员利用职权自行下载、盗取信息等行为所导致的一切后果，递航将不负任何法律责任。<br/>
      <br/>
      <span class="strong">第五条 免责条款 <br/></span>
      <br/>
      1. 用户理解递航无法保证网络服务任何条件下均不会中断，故递航对网络服务的及时性、安全性、准确性不作担保。同时，递航对服务器受病毒侵袭及其他故障侵扰免责。用户应做好数据备份，若发生使用递航时的数据丢失的情况，递航免责。
      <br/>
      2. 因遇不可抗力造成的递航无法为用户提供服务的，递航不承担任何法律责任，不可抗力包括但不限于:政府行为、自然灾害、战争、罢工、电脑病毒、黑客攻击、电信管理部门因技术调整导致网络中断等。
      不可抗力可能导致递航无法访问、访问速度缓慢、存储数据丢失、用户个人信息泄漏等不利后果。<br/>
      <br/>
      <span class="strong">第六条 违约责任 <br/></span>
      <br/>
      1. 如用户严重违反本服务条款、规则或相关协议，递航有权在不通知用户的情况下立即终止用户已购买的部分或所有服务，以及暂时冻结或永久取消用户的递航账户和使用权限，同时有权要求用户对递航遭受的损失进行赔偿。
      <br/>
      2. 如用户通过未经递航授权许可的任何第三方平台或渠道(包括但不限于淘宝、天猫、京东、拼多多等
      平台上商家、个人或其他组织等)购买递航平台上的增值服务，或将自身账号提供给第三方进行代充代付，或为他人进行代充代付等行为，用户前述行为视为严重违约，递航有权终止用户购买的此等产品或服务的使用权限，已购买的产品或已兑换服务、已赠送权益不予退还，同时有权要求用户对递航遭受的损失进行赔偿。<br/>
      3. 递航未行使或延迟行使其在本协议权利并不构成对这些权利的放弃，单独或部分行使其在本协议项下的任何权利不妨碍任何其它权利的行使。递航随时有权要求用户继续履行义务并承担相应的违约责任。
      <br/>
      4. 如因递航违反有关法律法规或本协议约定而给用户造成损失的，递航同意赔偿给用户造成的实际损失。但递航的赔偿限额不超过用户就使用服务所付给递航的服务费用。<br/>
      5. 用户如违反法律法规或本协议约定，导致递航或其关联公司遭受损失的(包括但不限于直接经济损失、递航向其他第三方支付的赔偿或补偿、行政处罚、维权成本)，用户应承担损失赔偿责任。<br/>
      6. 用户如与第三方产生纠纷、争议，或违反法律法规或本协议约定，导致任何第三方遭受，用户应当自行承担全部责任;递航如因此遭受损失的，递航有权要求用户进行赔偿。
      <br/>
      7.
      如因用户违反本协议、被递航系统检测出异常操作行为的（包括但不限于短时间内多次购买/取消订单等）或被递航发现利用规则漏洞获取任何服务的，递航有权在不通知用户的情况下，按照相关规定对其采取处罚措施，包括但不限于，暂时/永久禁封用户账号、将用户加入黑名单、限制用户使用部分或全部服务、拒绝为其退货/退款。<br/>
      8.如果平台发现了因系统故障或其他原因导致的处理错误，无论有利于平台还是有利于用户，平台都有权在根据本协议规定通知用户后纠正该错误、回转/回档相关交易或数据。用户理解并同意，用户因前述处理错误而多付或少付的款项均不计利息，平台不承担因前述处理错误而导致的任何损失或责任（包括用户可能因前述错误导致的利息、汇率等损失），但因平台恶意而导致的处理错误除外。<br/>
      <br/>
      <span class="strong">第七条 版权声明<br/></span>
      <br/>
      平台特有的标识、版面设计、编排方式、软件设计、特有功能等知识产权均属Dhunting递航享有，未经许可，不得任意复制、转载或使用。<br/>
      <br/>
      <span class="strong">第八条 法律适用、管辖<br/></span>
      <br/>
      1.本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。本协议因与中华人民共和国现行法律相抵触而导致部分条款无效的，不影响其他条款的效力。<br/>
      2.用户因使用平台服务所产生及与平台服务有关的争议，由递航与用户协商解决。协商不成时，任何一方均可向递航所在地有管辖权的人民法院提起诉讼。<br/>
      <br/>
      <span class="strong">第九条 其他条款 <br/></span>
      <br/>
      1.本协议所有条款的标题仅为阅读方便，不作为本协议涵义解释或限制的依据。<br/>
      2.本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。<br/>
      3.本协议内容包括协议正文及所有递航可能不断发布的关于本服务的相关协议、业务规则等内容。所有相关协议、业务规则为本协议不可分割的一部分，与本协议正文具有同等法律效力，发生冲突时，以递航最新公布的规则为准。
      <br/>
      4.本协议项下递航对于用户所有的通知均可通过递航平台公告、电子邮件、手机短信或常规的信件传送等方式进行;该等通知于发送之日视为已送达用户。
      <br/>
      5.本协议未涉及的其他服务条款及个人信息保护相关内容以递航平台公布的《递航隐私政策》及《递航发票管理规范》等其他线上协议为补充，平台与用户将一并遵守。
      <br/>
      6.本平台仅提供电话呼叫系统的便捷嵌入服务，以方便猎头公司或个人的业务需要。递航不承担一切与电话呼叫系统提供商相关的法律义务与后果，用户在使用递航提供的电话呼叫系统过程中有如下情况的：使用线路中的话术与猎头业务不符合，用户在与客户沟通过程中辱骂客户的，罚款3000元/次。<br/>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: ["showDhunting"],
  data() {
    return {
      visible: false,
      bodyStyle: {
        'width': '600px',
        'height': '460px',
        'overflow': 'scroll'
      },
    }
  },
  watch: {
    showDhunting(val) {
      this.visible = val;
    }
  },
  mounted() {

  },
  methods: {
    handleCancel() {
      this.$emit('visibleCancel')
    }
  }
}
</script>

<style lang="less" scoped>

</style>
