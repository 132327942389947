<template>
  <div>
    <div class="mo-about-us">
      <div class="title-wrap">
        <div class="title">Dhunting递航</div>
      </div>
      <div class="info-wrap">
        <div class="info">
          Dhunting递航是一家人力资源科技公司，我们为尊重人才、善用人才的企业提供智能化的一站式招聘管理系统。2019年5月，Dhunting递航正式成立。目前在上海、北京、香港、北美均设有专业的服务团队，平均从业经验超过10年，服务精英人才超过200000名。分别涵盖科技、金融、零售、医疗、地产等领域；2019年10月，开始搭建产品、研发、运营团队。核心成员分别来自于猎聘、阿里巴巴、领英等知名互联网公司；
        </div>
      </div>
      <div class="about-us-video">
        <div class="phone small-hand" v-if="!isPlay" @click="handlePlay">
          <img src="//file.dhunting.com/official_website/v1/aboutUsCover.png" alt="">
        </div>
        <a-modal
            v-model="isPlay"
            centered
            :footer="null"
            width="300px"
            height="200px"
            :closable="false"
            dialogClass="modal-about-us-video"
        >
          <div class="video-wrap">
            <!--//file.dhunting.com/official_website/v1/Dhunting.mp4              <a-icon type="close-circle" @click="handleStop"/>-->
            <video src="//file.dhunting.com/official_website/v1/aboutUs.mp4" id="aboutUsVideo" controls autoplay></video>
          </div>
          <div class="close-video small-hand" @click="handleStop"><a-icon type="close" /></div>
        </a-modal>
      </div>
      <div class="hope-wrap">
        <div class="hope-item flex-col-enter">
          <div class="title item-title">使命</div>
          <div class="tip">让金子早点发光</div>
          <div class="info banner-info flex-enter">
            让金子早点发光我们为尊重人才、善用人才的企业，提供智能化招聘管理系统及一站式招聘解决方案。致力于通过卓越的服务、一流的产品，为企业整合人才数据、打通招聘渠道，优化招聘流程，提高招聘效率。让金子早点发光！
          </div>
          <div class="line"></div>
        </div>
        <div class="hope-item hope-item2 flex-col-enter">
          <div class="title item-title">愿景</div>
          <div class="tip">多做一步，成为用户最信任的合作伙伴！</div>
          <div class="info banner-info flex-enter">
            成为用户最信任的合作伙伴，就是通过递航人为客户多做一步，帮助用户成为更好的自己，获得更好的结果。多做一步是优先替用户想到业务解决方案，并在系统上实现。多做一步是高效为用户解决使用疑问，让用户学习系统时间成本最低，价值产出最大化。多做一步是陪伴用户快速成长，递航会持续邀请猎头大咖、知名企业HR分享，让猎企的每一个角色都能快速成长，学习先进经验。
          </div>
          <div class="line"></div>
        </div>
      </div>
      <div class="core-member-wrap">
        <div class="title flex-enter">核心团队</div>
      </div>
      <div class="core-member-swiper">
<!--        <core-member></core-member>-->
        <newCoreMember></newCoreMember>
      </div>
    </div>
  </div>
</template>
<script>
// import coreMember from "@/views/MoPage/components/coreMember";
import newCoreMember from "@/views/MoPage/components/newCoreMember";
export default {
  name: 'moAboutUs',
  components: {
    // coreMember
    newCoreMember
  },
  data() {
    return {
      isPlay:false
    };
  },
  mounted() {

  },
  methods:{
    handlePlay(){
      this.isPlay=true
      const video=document.getElementById('aboutUsVideo')
      video.play()
    },
    handleStop(){
      this.isPlay=false
      const video=document.getElementById('aboutUsVideo')
      video.pause()
    },
  }
}
</script>
<style scoped lang="less">
.mo-about-us{
  .title-wrap{
    position: relative;
    width: 100%;
    height: 200px;
    background-image: url("//file.dhunting.com/official_website/v1/dhunting-bg.png");
    background-size:100% 100%;
    .title{
      width: 100%;
      position: absolute;
      top: 10%;
      text-align: center;
    }
  }
  .info-wrap{
    position: relative;
    height: 150px;
    width: 100%;
    .info{
      padding: 16px;
      background: #FFFFFF;
      box-shadow: 0px 9px 28px 8px rgba(153, 153, 153, 0.05), 0px 6px 16px rgba(153, 153, 153, 0.08), 0px 3px 6px -4px rgba(153, 153, 153, 0.12);
      border-radius: 16px;
      width: 311px;
      height: 212px;
      font-weight: 400;
      font-size: 12px;
      color: #5E5E66;
      position: absolute;
      left: 50%;
      transform: translate(-50%,-50%);
      line-height: 20px;
    }
  }
  .about-us-video{
    padding: 0 42px;
  }
  .hope-wrap{
    padding: 32px 48px;
    .hope-item{
      .tip{
        font-weight: 500;
        font-size: 14px!important;
        color: #1B1C33;
        margin: 16px 0 8px 0;
      }
      .info{
        margin-bottom: 16px;
        font-size: 12px!important;
        text-align: center;
      }
      .line{
        background-color: #0055FF;
        width: 100px;
        height: 2px;
        border: none;
        margin-bottom: 0;
      }
    }
    .hope-item2{
      margin-top: 32px;
    }
  }
  .core-member-wrap{
    padding: 32px;
    padding-bottom: 0;
    .title{
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 18px;
      color: #1B1C33;
    }
  }
  .core-member-swiper{
    padding-left: 32px;
    padding-bottom: 32px;
  }
}
/deep/ .modal-about-us-video{
   .ant-modal-content{
    width: 300px;
    height: 200px;
     .ant-modal-body{
       width: 300px;
       height: 200px;
       padding: 0;
       position: relative;
       .video-wrap{
         width: 100%;
         height: 100%;
         video{
           width: 100%;
           height: 100%;
         }
       }
       .close-video{
         width: 15px;
         height: 15px;
         text-align: center;
         line-height: 15px;
         background: #0055FF;
         color: #FFFFFF;
         font-size: 10px;
         position: absolute;
         right: -15px;
         top: 0;
         cursor: pointer;
       }
     }
  }
}
</style>