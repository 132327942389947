import { render, staticRenderFns } from "./slideCode.vue?vue&type=template&id=0b90187b&scoped=true"
import script from "./slideCode.vue?vue&type=script&lang=js"
export * from "./slideCode.vue?vue&type=script&lang=js"
import style0 from "./slideCode.vue?vue&type=style&index=0&id=0b90187b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b90187b",
  null
  
)

export default component.exports