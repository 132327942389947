import Vue from 'vue'
import Antd from 'ant-design-vue';
import VueRouter from 'vue-router'
import router from "@/router/index";
import 'amfe-flexible'
import {changeRem} from '@/assets/js/flexible.js'
import {WOW} from 'wowjs'
import '../node_modules/wowjs/css/libs/animate.css'
import '@/utils/flexible'
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min';
import { Cell,Popup,Area,Swipe, SwipeItem } from 'vant';
Vue.use(Cell)
Vue.use(Popup);
Vue.use(Area);
Vue.use(Swipe).use(SwipeItem);
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 0,
  mobile: true, // default
  live: true, // default
})
import { message} from 'ant-design-vue'
Vue.prototype.$message = message;
message.config({
    duration: 2,// 持续时间
    top: `100px`, // 到页面顶部距离
    maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
})

import App from './App.vue'
import 'ant-design-vue/dist/antd.css';

import Bus from './utils/bus'
Vue.prototype.$bus = Bus
Vue.config.productionTip = false

Vue.use(Antd);
Vue.use(VueRouter);

function getRem() {
  var html = document.getElementsByTagName("html")[0];
  html.style.fontSize = window.fontSizeSave
}
function _isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  if(flag){
    return 'MIndex'
  }else{
    return 'CIndex'
  }
}
window.addEventListener("resize", changeRem);
changeRem();
if (_isMobile() == 'MIndex') {
  var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
  window.fontSizeSave = (oWidth / 375) * 144 + 'px'
  window.onload = function () {
    // getRem(375, 144);
    getRem()
  };
  window.onresize = function () {
    // getRem(375, 144);
    getRem()
  };
}
setInterval(()=>{
  getRem()
},1000)

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')


