<template>
  <div class="mo-head-wrap">
    <div class="mo-head-left">
      <img src="//file.dhunting.com/official_website/v1/logo_head.png" alt="">
    </div>
    <div class="mo-head-right" @click="changeOpenMenuVisible">
      <iconpark-icon v-show="!isOpenMenuVisible" name="mo-menu-open"></iconpark-icon>
      <iconpark-icon v-show="isOpenMenuVisible" name="mo-menu-close"></iconpark-icon>
<!--      <a-icon v-show="!isOpenMenuVisible" type="menu" />-->
<!--      <a-icon v-show="isOpenMenuVisible" type="close" />-->
    </div>
    <div class="open-menu" v-show="isOpenMenuVisible">
      <div class="open-menu-item" @click="gotoHome">产品概览</div>
      <a-divider />
      <div class="open-menu-item" @click="gotoHunterSystem">猎头/RPO/外包版</div>
      <a-divider />
      <div class="open-menu-item" @click="gotoHrSystem">HR版</div>
      <a-divider />
      <div class="open-menu-item" @click="gotoAboutUs">关于递航</div>
      <a-divider />
      <div class="open-menu-item" @click="gotoArticle">文章资讯</div>
      <a-divider />
      <div class="open-menu-item open-menu-item-product-contact">
        <div class="open-menu-item-title" @click="clickProduct">
          <span>产品咨询</span>
          <span class="product-icon"><a-icon v-show="!visible" type="down" /><a-icon v-show="visible" type="up" /></span>
        </div>
        <div v-show="visible" class="open-menu-sub-item-wrap">
          <div class="open-menu-sub-item" @click="onClickProduct(item.id)" v-for="(item, index) in versionsList" :key="index">
            {{item.name}}
          </div>
        </div>
      </div>
<!--      <a-dropdown class="product-drop-down" :trigger="['click']" v-model="visible" overlayClassName="product-child">-->
<!--        <div class="open-menu-item ant-dropdown-link" @click="clickProduct">-->
<!--           <span>产品咨询</span><span class="product-icon"><a-icon v-show="visible" type="down" /><a-icon v-show="!visible" type="up" /></span>-->
<!--        </div>-->
<!--        <a-menu slot="overlay">-->
<!--          <a-menu-item @click="onClickProduct(item.id)" v-for="(item, index) in versionsList" :key="index">-->
<!--            {{item.name}}-->
<!--          </a-menu-item>-->
<!--        </a-menu>-->
<!--      </a-dropdown>-->

<!--      <div class="product-contact-new">-->
<!--        <span>产品咨询</span>-->
<!--        <a-select-->
<!--            dropdownClassName="mo-header-select-wrap"-->
<!--            v-model="intentProductType"-->
<!--            :getPopupContainer="(triggerNode)=>triggerNode.parentNode"-->
<!--        >-->
<!--          <a-select-option v-for="(item, index) in versionsList" :key="index" :value="item.id">-->
<!--            {{item.name}}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import {consultationVersions} from "@/api/request";

export default {
  name: 'moHeader',
  components: {
    
  },
  data() {
    return {
      visible:false,
      isOpenMenuVisible:false,
      versionsList:[],
      intentProductType:undefined
    };
  },
  mounted() {
    this.getConsultationVersions()
  },
  methods:{
    //实现点击导航条，就会重新跳到猎头选择卡片页面
    clearProductCard(){
      bus.$emit('clearproductcard');
      console.log('dfd')
    },
    // 获取hr或者猎头
    async getConsultationVersions() {
      let res = await consultationVersions();
      if(res.data.code === 200) {
        this.versionsList = res.data.data;
      }
      else {
        this.$message.error(res.data.message);
      }
    },
    gotoHome(){
      this.$router.push('/home')
      this.visible=false
      this.isOpenMenuVisible=false
    },
    gotoHunterSystem(){
      this.$router.push('/hunterSystem')
      this.visible=false
      this.isOpenMenuVisible=false
    },
    gotoHrSystem(){
      this.$router.push('/hrSystem')
      this.visible=false
      this.isOpenMenuVisible=false
    },
    gotoAboutUs(){
      this.$router.push('/aboutUs')
      this.visible=false
      this.isOpenMenuVisible=false
    },
    gotoArticle(){
      this.$router.push('/defaultArticle')
      this.visible=false
      this.isOpenMenuVisible=false
    },
    // clickProduct(event){
    //   event.preventDefault()
    //   this.visible=!this.visible
    // },
    clickProduct(){
      this.visible=!this.visible
    },
    onClickProduct(id){
      //这里还是得特判，传值过去也是死的
      //如果不想这样，最好在产品咨询的每一项带上该跳转的路径
      if(id==='1'){
        this.$router.push('/productContact')
        this.visible=false
        this.isOpenMenuVisible=false
      }
      if(id==='2'){
        this.$router.push('/buyHrSystem')
        this.visible=false
        this.isOpenMenuVisible=false
      }
      this.clearProductCard()
    },
    changeOpenMenuVisible(){
      this.isOpenMenuVisible=!this.isOpenMenuVisible
    }
  }
}
</script>
<style lang="less" scoped>
  .product-icon{
    color: #5E5E66;
  }
  .mo-head-wrap{
    padding: 17px 32px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
  }
  .mo-head-left{
    width: 117px;
    height: 22px;
    font-size: initial;
  }
  .mo-head-right{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px!important;
  }
  .open-menu{
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    padding: 0px 32px;
    background-color: #FFFFFF;
    z-index: 99;
    height: calc(100vh - 56px);
    overflow-y: auto;
  }
  .open-menu-item,
  .product-contact-new >span
  {
    font-weight: 400;
    font-size: 16px;
    color: #1B1C33;
    padding: 17px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .open-menu-item{
    font-weight: 500;
  }
  .open-menu /deep/ .ant-divider{
    margin: 0;
  }
  //.product-contact-new{
  //  position: relative;
  //}
  //.product-contact-new /deep/ .ant-select{
  //  position: absolute;
  //}
  .open-menu-item-title{
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
  }
  .open-menu-item-product-contact{
    display: block;
  }
  .open-menu-sub-item-wrap{
    padding-top: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #5E5E66;
  }
  .open-menu-sub-item{
    margin-top: 18px;
  }
</style>
<style>
.product-child{
  width: 311px;
  z-index: 9999999;
}
.product-child .ant-dropdown-menu{
  border-radius: 0;
  box-shadow: none;
  background-color: #FFFFFF;
  position: relative!important;
  z-index: 9999999;
}
</style>