function changeRem(){
    //设置最大字体
    let docE = document.documentElement;
    let fontsize = parseInt(docE.style.fontSize);
    if(fontsize>144){
        docE.style.fontSize = 144+'px';
    }
    if(fontsize<37.5){
        docE.style.fontSize = 37.5+'px';
    }
    console.log(fontsize)
}

export {changeRem};