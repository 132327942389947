<template>
  <div>
    <div class="mo-article-index-banner flex-enter">
      文章资讯
    </div>
    <div class="article-tabs flex-enter">
      <div class="article-tabs-item" :class="index==currentIndex ? 'active_item' : ''"  @click="handleTab(index)" v-for="(item,index) in tabs" :key="index">{{item.typeName}}</div>
    </div>
    <div class="article-item" v-if="allArticle.length>0">
      <mo-article-card v-for="(item,index) in allArticle" :key="index" :articleItem="item"></mo-article-card>
    </div>
    <div class="pagination-wrap-wrap flex-enter" v-if="total>0">
      <div class="pagination-wrap">
        <a-pagination v-model="currentPage" :total="total" @change="onChange" show-less-items />
      </div>
    </div>
    <div class="article-empty" v-if="total==0">
      <!--      <a-empty />-->
      <div class="img-wrap">
        <img src="//file.dhunting.com/official_website/v1/article-empty.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>

import {getArticle, getArticleType} from "@/api/request";
import moArticleCard from "@/views/MoPage/components/moArticleCard";

export default {
  name: 'moDefaultArticle',
  components: {
    moArticleCard
  },
  data() {
    return {
      tabs:[
        {
          "owArticleTypeId": "",
          "typeName": "全部",
          "typeSort": 0
        }
      ],
      currentIndex:0,
      currentPage: 1,
      allArticle:[],
      total:0
    };
  },
  mounted() {
    this.handleGetAllArticle()
    this.handelGetArticleType()
  },
  methods:{
    handelGetArticleType(){
      getArticleType().then(res=>{
        if(res.data.code===200){
          this.tabs.push.apply(this.tabs,res.data.data)
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    // changeIndex(index){
    //   // console.log(indexHome)
    //   this.currentIndex=index
    // },
    onChange(pageNumber){
      this.currentPage=pageNumber
      this.handleGetAllArticle()
    },
    handleGetAllArticle(id){
      getArticle({
        current:this.currentPage,
        size:8,
        title:undefined,
        owArticleTypeId:id
      }).then(res=>{
        if(res.data.code===200){
          this.allArticle=res.data.data.records
          this.total=res.data.data.total
          // console.log(res.data.data.total)
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    handleTab(index){
      this.currentIndex=index
      // const allElement=document.querySelectorAll('.article-tabs-item')
      // let total_length=0
      // for(let i=0;i<index;i++){
      //   total_length=total_length+allElement[i].clientWidth+48
      // }
      // // total_length
      // const element=document.querySelector('.article-active-line')
      // if(!element) return
      // element.style.transform= `translate3d(${total_length}px, 0px, 0px)`
      // element.style.transition='all 0.5s'
      if(index!=0){
        this.handleGetAllArticle(this.tabs[index].owArticleTypeId)
      }
      else {
        this.handleGetAllArticle()
      }
    }
  }
}
</script>
<style scoped lang="less">
.article-empty{
  margin: 80px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .img-wrap{
    width: 311px;
    height: 125px;
  }
}
.mo-article-index-banner{
  width: 375px;
  height: 95px;
  font-weight: 600;
  font-size: 28px;
  color: #1B1C33;
  background-image: url("//file.dhunting.com/official_website/v1/login-bg.png");
  background-size: 100% 100%;
}
.article-tabs{
  display: flex;
  /*position: absolute;*/
  /*left: 50%;*/
  /*transform: translateX(-50%);*/
  margin-bottom: 24px;
}
.article-tabs-item:last-of-type {
  margin-right: 0;
}
.article-tabs-item{
  box-sizing: border-box;
  cursor: pointer;
  padding-bottom: 8px;
  color: #8D8E99;
  margin-right: 24px;
  font-weight: 400;
  font-size: 14px;
}
.article-tabs-item:hover{
  color: #0055FF;
}
.active_item{
  border-bottom: #0055FF 2px solid;
  color: #0055FF;
  font-weight: 500;
  font-size: 14px;
}
.article-item{
  padding: 32px;
  padding-top: 0;
  //margin-top: 8px;
}
// .article-item /deep/ .line:last-child {
//  height: 0;
//  margin: 0;
//}
.pagination-wrap-wrap{
  margin-bottom: 32px;
}
.pagination-wrap /deep/ .ant-pagination-item{
  background: #FFFFFF;
}
.pagination-wrap /deep/ .ant-pagination-item a{
  color:#1B1C33!important;
}
.pagination-wrap /deep/ .ant-pagination-item-active{
  background-color:#0055FF;
}
.pagination-wrap /deep/ .ant-pagination-item-active a{
  color: #FFFFFF!important;
}
.pagination-wrap /deep/ .ant-pagination-prev a{
  color: #1B1C33!important;
}
.pagination-wrap /deep/ .ant-pagination-next a{
  color: #1B1C33!important;
}
</style>