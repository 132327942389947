// 基准大小
const baseSize = 144
// 设置 rem 函数
function setRem () {
    // 当前页面宽度相对于 1440 宽的缩放比例，可根据自己需要修改。
    const scale = document.documentElement.clientWidth / 1440
    // 设置页面根节点字体大小
    //如果页面宽度大于1440，则scale设为1
    if(scale>=1){
        document.documentElement.style.fontSize = '144px'
    }
    //375/1440=0.26
    else if(scale<0.26){
        document.documentElement.style.fontSize = '37.5px'
    }
    else {
        document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
    }

}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
}
 
