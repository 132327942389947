<!--猎头端-企业版-标准版-->
<template>
  <div>
    <a-modal
        :visible="outerVisible"
        :footer="null"
        dialogClass="new-pc-enterprise-criterion"
        @cancel="handleCancel"
    >
      <div class="top">
        <div class="title-wrap">
          <div class="icon"><iconpark-icon name="new-modal-icon"></iconpark-icon></div>
          <div class="title">已选择产品类型：猎企-标准版</div>
        </div>
        <div class="modal-line"></div>
        <div class="info">填写信息</div>
        <a-form-model :model="form" :rules="rules" ref="form" layout="horizontal" :hideRequiredMark="true">
          <a-form-model-item label="公司" prop="companyName">
            <a-input placeholder="请输入公司名称" v-model="form.companyName"></a-input>
          </a-form-model-item>
          <a-form-model-item label="姓名" prop="name">
            <a-input placeholder="请输入您的姓名" v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item class="marginB0" label="电话" prop="phone">
            <a-input placeholder="请输入您的手机号码" v-model="form.phone"></a-input>
          </a-form-model-item>
          <a-form-model-item label="验证码" prop="validCode" class="marginB0 get-code-wrap">
            <a-input placeholder="请输入验证码" v-model="form.validCode"></a-input>
            <div class="hit-code-wrap">
              <a v-show="!showTimer" class="items-code-2" @click="getCode">获取验证码</a>
              <span v-show="showTimer" class="items-code-time-2">{{time}}s重新发送</span>
              <span class="code-hit-2">{{hitCode}}</span>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="bottom">
        <div class="title">
          <div class="buy">购买套餐</div>
          <div class="invitation-code">
            <div v-if="!isInvitationValid&&!isUseInvitationCode" @click="useInvitationCode" class="word">
              <span class="left-word">填写邀请码</span>
              <a-icon type="form" class="small-hand"/>
            </div>
            <div v-if="!isInvitationValid && isUseInvitationCode" class="input">
              <div class="input-wrap">
                <a-input placeholder="请输入邀请码" v-model="invitationCode" />
                <div class="validate small-hand" @click="confirmUseInvitationCode">验证</div>
              </div>
              <div class="tip" v-if="invitationTip&&!invitationCode">{{invitationTip}}</div>
            </div>
            <div v-if="isInvitationValid && isUseInvitationCode" class="is-use-invitation-code">
              <span>邀请码：{{invitationCode}}</span><a-icon class="close" @click="cancleInvitationCode" type="close" />
            </div>
          </div>
        </div>
        <div class="price-card-wrap">
          <div class="price-card small-hand"
               :class="{'active-price-card' : idx === index}"
               v-for="(item, idx) in goodsList"
               :key="idx"
               @click="setHover(idx)">
            <div class="price-choose-month">{{ item.name }}</div>
            <div class="sell-price-and-original">
              <div class="price-choose-discounts">￥<span class="data">{{ item.sellPrice }}</span></div>
              <div v-if="type != 'Pro' && type != 'Max'" class="price-choose-original">
                <div>
                  ￥{{ item.markPrice }}
                </div>
                <div class="delete-line"></div>
              </div>
            </div>
            <div class="price-choose-day">
              (低至<span class="price-choose-day-monney">{{ parseInt(goodsList[idx].name) == 12 ? (item.sellPrice / 365).toFixed(2) : (item.sellPrice / 30 / parseInt(goodsList[idx].name)).toFixed(2) }}</span>元/天)
            </div>
          </div>
        </div>
        <div class="num-wrap">
          <div class="num">账号数量：</div>
          <a-input v-model="form.buyNum" class="num-input">
            <span slot="addonBefore" class="small-hand" @click="numReduce"><a-icon type="minus" /></span>
            <span slot="addonAfter" class="small-hand" @click="numAdd"><a-icon type="plus" /></span>
          </a-input>
          <div class="tip">优惠说明：购买账号10～49个可打9折；购买账号50个及50个以上可打8折。</div>
        </div>
        <div class="modal-line-2"></div>
        <div class="price-btn">
          <div class="calculate-wrap">
            <template v-if="type === 'Pro' || type === 'Max'">
              <div class="calculate-last">
                <span>应付金额</span>
                <span><span>￥</span><span>{{ lastPrice }}</span></span>
              </div>
            </template>
            <template v-else>
              <div class="calculate-price">
                <span>账号费用</span>
                <span>￥{{ price }}</span>
              </div>
              <div class="calculate-discount">
                <span class="calculate-discount-text">优惠金额</span>
                <span>￥{{ discountPrice }}</span>
              </div>
              <div class="price-show-2" v-show="Cdiscount != 1">
                <p class="price-title">邀请码</p>
                <p class="price-shows">-￥{{couponPrice}}</p>
              </div>
              <div class="calculate-last">
                <span>应付金额</span>
                <span><span>￥</span><span class="last-price">{{ lastPrice }}</span></span>
              </div>
            </template>
          </div>
          <div class="btn-wrap">
            <div class="submit-check">
              <a-checkbox v-model="isAgree"></a-checkbox>
              <span class="agree">同意<span @click="showDhuntingWindow" class="small-hand agreement">《递航服务协议》</span>
                <span class="small-hand agreement" @click="showPrivacyWindow">《递航隐私政策》</span>
              </span>
            </div>
            <a-button type="danger" class="enterprise-criterion-btn " @click="settlement" :loading="isLoading">提交结算</a-button>
          </div>
        </div>
      </div>

    </a-modal>
    <!--    递航服务协议弹窗-->
    <serviceAgreementModal :showDhunting="showDhunting" @visibleCancel="showDhunting = false"></serviceAgreementModal>
    <!--    递航隐私政策弹窗-->
    <privacyPolicyModal :showPrivacy="showPrivacy" @visibleCancel="showPrivacy = false"></privacyPolicyModal>
    <!--    支付弹框-->
    <a-modal
        v-model="ifCodeShow"
        :footer="null"
        dialogClass="new-pc-pay-window"
        @cancel="closeCodeVisible"
        destroyOnClose
    >
      <div class="pay-window-small">
        <div id="qrcode" ref="qrcode" class="pay-success">
          <div v-if="visibleCode" class="pay-success-inner"></div>
          <img v-if="visibleCode" src="//file.dhunting.com/official_website/v1/success.png" alt="success" class="pay-success-image">
        </div>
        <p v-if="!visibleCode" class="qr-text">请使用微信扫码支付</p>
        <p v-if="visibleCode" class="qr-success">支付成功</p>
      </div>
    </a-modal>
    <!--    成功提交弹窗-->
    <successModal :isVisible="showSuccessWindow" type="register" :phone="form.phone" @cancel="cancel" @toHome="toHome"></successModal>
  </div>
</template>
<script>
// import vueQr from "vue-qr";
import successModal from "@/views/PCPage/productContact/component/successModal";

import {check, checkCode, checkPhone, checkValid, code, getDiscount, payCode, submitOrder} from "@/api/request";
import imgUrl1 from "@/assets/close.png";
import serviceAgreementModal from "@/components/serviceAgreementModal"; // 递航服务协议
import privacyPolicyModal from "@/components/privacyPolicyModal";
import imgUrl2 from "@/assets/close-hover.png";
import {getSohoMaxPriceMenu, getSohoProPriceMenu} from "@/api/Interface/order";
import QRCode from "qrcodejs2"; // 递航隐私政策

export default {
  name: "newEnterpriseCriterion",
  components: {
    // vueQr,
    successModal,serviceAgreementModal,privacyPolicyModal},
  props:{
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      isLoading:false,
      type:'enterprise',
      hitCode: '',
      time: 0,//倒计时
      showTimer: false,//显示倒计时
      outerVisible:this.visible,//显示弹框//放在props，由父组件传过来
      form: {
        type:1,
        companyName: undefined, // 公司名称
        name: undefined, // 姓名
        phone: undefined, // 电话
        validCode: undefined, // 验证码
        buyNum: 3, // 账号数量
        skuId: -1,//商品的id
        couponCode:''//邀请码
      },
      rules: {
        companyName: [{ required: true, message: '请输入公司名称', trigger: ['blur','change'] }],
        name: [{ required: true, message: '请输入您的姓名', trigger: ['blur','change'] }],
        phone: [
          {
            required: true,
            len: 11,
            pattern: RegExp(/(^(\+?0?86-?)?1[3456789]\d{9}$)|(^(\+?852-?)?[569]\d{3}-?\d{4}$)|(^(\+?886-?|0)?9\d{8}$)|(^(0065)\d{8}$)/),
            message: "请输入正确的手机号",
            trigger: ['blur','change'],
          },
          {
            validator:this.validatePhone
          }
        ],
        // 大陆手机号：/^(\+?0?86-?)?1[345789]\d{9}$/
        // 香港手机号：/^(\+?852-?)?[569]\d{3}-?\d{4}$/
        // 台湾手机号：/^(\+?886-?|0)?9\d{8}$/
        // 新加坡手机号：/^(0065)\d{8}$/
        validCode: [
          { required: true, message: '请输入验证码', trigger: ['blur','change'],len: 4 },
          {
            validator:this.validateCode
          }
        ]
      },

      // showPayWindow:false,
      showSuccessWindow:false,
      // closeImg : imgUrl1,
      // visible2:true,//二维码是否显示(支付或者成功)
      // orderSn:'',//支付二维码序号
      // qrCodeAddress:'',//支付二维码地址
      // payTime:1000*60*10,//支付时间
      orderSn: '',//支付二维码序号
      ifCodeShow: false,//控制二维码界面
      qrCodeAddress: '',//支付二维码地址
      ifShow : false,//控制页面是否展示
      qrCode: '',//二维码
      timer: null,//轮询计时器
      timer2: null,//轮询计时器
      visibleCode: false,//是否支付成功
      payTime: 1000 * 60 * 10,//支付时间

      isUseInvitationCode:false,//是否试用邀请码
      invitationCode:'',//邀请码
      isInvitationValid:false,//邀请码是否有效
      invitationTip:'',//邀请码错误的提示信息
      Cdiscount:1,//优惠券的折扣
      //price选择的数据
      Adiscount: 1,//数量的折扣
      isAgree: false, // 是否同意服务协议
      showDhunting: false,//显示用户协议
      showPrivacy: false,//显示隐私政策
      index: 0,//选中的类型的索引值
      accountDiscountList: undefined,
      goodsList: [],  // 商品菜单
      price: 0,
      discountPrice: 0,
      couponPrice: 0,
      lastPrice: 0,
    }
  },
  async mounted() {
    await this.getPriceMenuFun();
    await this.initForm()
  },
  methods:{
    toHome(){
      this.$router.push('/home')
    },
    //购买量折扣
    checkDiscount(){
      for(let i=0;i<this.accountDiscountList.length;i++){
        if(this.accountDiscountList[i].start<=this.form.buyNum&&this.accountDiscountList[i].end>=this.form.buyNum){
          this.Adiscount = this.accountDiscountList[i].discount;
          break;
        }else if(this.accountDiscountList[i].start>this.form.buyNum){
          break;
        }
      }
    },
    // 获取价格
    async getPriceMenuFun() {
      // 企业版-标准版
      if (this.type === 'enterprise') {
        await this.getEnterprisePriceMenu()

        // soho-pro
      } else if (this.type === 'Pro') {
        await this.getSohoProPriceMenuFun()

        // soho-max
      } else if (this.type === 'Max') {
        await this.getSohoMaxPriceMenuFun()
      }

      this.priceDefaultInit()

    },
    checkPrice() {
      //计算价格
      this.checkDiscount()
      if(this.index >= 0){
        this.price = Math.floor((this.goodsList[this.index].markPrice*this.form.buyNum)*10)/10;
        this.discountPrice = this.type === 'Pro' ? 0 : Math.floor((this.price - this.goodsList[this.index].sellPrice*this.form.buyNum*this.Adiscount)*10)/10;
        this.couponPrice = this.type === 'Pro' ? 0 : Math.ceil(((this.price - this.discountPrice) * (1-this.Cdiscount)*10).toFixed(2))/10;
        this.lastPrice = this.type === 'Pro' ? this.goodsList[this.index].sellPrice : Math.floor((this.price - this.discountPrice - this.couponPrice)*10)/10;
      }else{
        this.price = 0;
        this.discountPrice = 0;
        this.couponPrice = 0;
        this.lastPrice = 0;
      }
    },
    setHover(index){
      this.index = index;
      this.form.skuId = this.goodsList[this.index].skuId;
      this.checkPrice();
    },
    changeClose() {
      if (this.closeImg == imgUrl1) {
        this.closeImg = imgUrl2;
      } else {
        this.closeImg = imgUrl1;
      }
    },
    // 是否显示递航服务协议
    showDhuntingWindow() {
      this.showDhunting = true;
    },
    // 是否显示递航隐私政策
    showPrivacyWindow() {
      this.showPrivacy = true;
    },
    // 提交结算
    settlement() {
      this.onSubmit(this.form, this.isAgree)
    },
    // 标准版
    async getEnterprisePriceMenu(){
      let { data } = await getDiscount();
      if (data.code === 200) {
        this.goodsList = data.data.skuList.reverse();
        this.accountDiscountList = data.data.accountDiscountList;
      }
    },
    // soho pro版价格清单
    async getSohoProPriceMenuFun() {
      let {data} = await getSohoProPriceMenu()
      if (data.code === 200) {
        this.goodsList = data.data.skuList.reverse()
      } else {
        this.$message.error(data.message)
      }
    },
    // soho max版价格清单
    async getSohoMaxPriceMenuFun() {
      let {data} = await getSohoMaxPriceMenu()
      if (data.code === 200) {
        this.goodsList = data.data.skuList.reverse()
      } else {
        this.$message.error(data.message)
      }
    },
    // 价格选择初始化
    priceDefaultInit() {
      this.form.skuId = this.goodsList[0].skuId ; // 默认选中12个月(数组第一个)
      this.checkPrice();
    },
    // 初始化表单
    initForm() {
      this.setHover(this.index)
    },
    // 重置价格选择和协议状态
    async reInit() {
      this.index = 0;
      this.isAgree = false; // 是否同意服务协议
    },
    useInvitationCode(){
      this.isUseInvitationCode = true;
    },
    cancleInvitationCode(){
      this.invitationCode='';
      this.form.couponCode='';
      this.isUseInvitationCode = false;
      this.isInvitationValid=false
      this.Cdiscount=1;
    },
    confirmUseInvitationCode(){
      checkCode({couponCode:this.invitationCode}).then((res)=>{
        if(res.data.code == 200){
          //这个页面得到值，全部需要传到priceChoose组件内
          this.Cdiscount = res.data.data.discount;
          this.form.couponCode = this.invitationCode;
          this.checkPrice()
          this.isInvitationValid = true;
          this.invitationTip = ''
        }else{
          this.invitationTip = '邀请码无效';
          this.isInvitationValid=false;
          this.invitationCode=''
          this.form.couponCode=''
          this.Cdiscount=1
        }
      });
    },
    showPay(){
      this.showPayWindow = !this.showPayWindow;
    },
    handleCancel(){
      this.outerVisible=false
      //需要通知父组件 弹框已关闭，调整父组件传过来的值
      //也就是让父组件初为未选中的状态
      this.$emit('enterpriseTypeReset')
    },
    validatePhone(rule, value, callback){
      if (!value) {
        callback(new Error('请输入手机号!'))
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error('手机号格式不正确!'))
        } else {
          checkPhone({mobile: value, huntingVersion: 1}).then((res) => {
            if (res.data.code != 200) {
              //已经注册
              callback(new Error('手机号已注册过!'))
              // callback()
            }else {
              callback()
            }
          })
        }
      }
    },
    validateCode(rule, value, callback){
      checkValid({username:this.form.phone,validCode:this.form.validCode}).then((res)=>{
        // console.log(res,'验证码')
        if(res.data.code == 200){
          callback()
        }else{
          callback(new Error('验证码错误！'))
        }
      })
    },
    // 账号数量减1
    numReduce() {
      this.form.buyNum = this.form.buyNum > 1 ? this.form.buyNum - 1 : 1;
      this.checkPrice();
    },
    // 账号数量加1
    numAdd() {
      this.form.buyNum += 1;
      this.checkPrice();
    },
    // 获取验证码
    getCode(){
      // setTimeout(()=>{
      // 先进行手机号验证，成功后发获取验证码请求
      this.$refs.form.validateField(['phone'], valid => {
        //上面这个校验，如果校验成功就为空
        if (!valid) {
          code({ username: this.form.phone}).then((res)=>{
            if(res.data.code === 200){
              //转变成倒计时
              this.time = 60;
              this.codeTimer = setInterval(()=>{
                if(this.time === 0){
                  clearInterval(this.codeTimer);
                  this.codeTimer = null;
                  this.showTimer = false;
                }
                this.time--;
              },1000)
              this.showTimer = true;
            }else{
              this.$message.error(res.data.message);
            }
          })
        }
        else {
          return false
        }
      })
      // },1000)
    },
    // 提交结算
    onSubmit(form, isAgree) {
      // const that = this;
      // 表单验证公司、姓名、电话、验证码
      this.$refs.form.validate(valid => {
        if(valid) {
          // 是否同意递航服务协议和递航隐私政策
          if(isAgree) {
            this.isLoading = true;
            let obj = {
              name:form.name,
              mobile:form.phone,
              validCode:form.validCode,//验证码
              buyNum:form.buyNum,
              skuId:form.skuId,
              type:form.type,
              companyName:form.companyName,
              couponCode:form.couponCode
            }
            this.purchaseEnterpriseCriterion(obj)
            // submitOrder(obj).then(res=>{
            //   if(res.data.code == 200){
            //     this.orderSn = res.data.data.orderSn;
            //     payCode({ orderSn: this.orderSn }).then((res) => {
            //       if (res.data.code === 200) {
            //         this.qrCodeAddress = res.data.data.codeUrl;
            //         this.showPayWindow = true;
            //         that.timer = window.setInterval(() => {
            //           check({ orderSn: this.orderSn }).then((res) => {
            //             if (res.data.code === 200) {
            //               if (res.data.data) {
            //                 window.clearInterval(that.timer);
            //                 that.timer = null;
            //                 //暂时定为二维码
            //                 that.visible2 = false;
            //                 //跳转到成功界面
            //                 setTimeout(()=>{
            //                   that.showPayWindow = false;
            //                   that.showSuccessWindow = true;
            //                   // console.log(that.showSuccessWindow,'ddd')
            //                   // that.$options.methods.init.call(that);
            //                 },1000)
            //               }
            //             } else {
            //               that.payTime -= 1000;
            //               if(that.payTime == 0){
            //                 window.clearInterval(that.timer);
            //                 that.timer = null;
            //                 alert('支付失败');
            //                 that.visible2 = true;
            //                 that.showPayWindow = false;
            //                 that.payTime = 1000*60*10;
            //               }
            //
            //               // //测试用代码 5s后自动支付成功
            //               // window.clearInterval(that.timer);
            //               // that.timer = null;
            //               // that.visible = false;
            //
            //               // //跳转到成功界面
            //               // setTimeout(()=>{
            //               //     that.visible = true;
            //               //     that.showPayWindow = false;
            //               //     that.showSuccessWindow = true;
            //               //     that.$options.methods.init.call(that);
            //               // },1000)
            //             }
            //           });
            //           //时间为1s
            //         }, 1000);
            //       } else {
            //         this.$message.error(res.data.message);
            //       }
            //     });
            //   }else{
            //     alert(res.data.message);
            //     // console.log(res.data);
            //   }
            // })
          }
          else {
            this.$message.warning('请先阅读并同意递航服务协议和递航隐私政策');
          }
        }
        else {
          return false;
        }
      });
    },
    async purchaseEnterpriseCriterion(obj){
      await this.sendOrder(obj)
    },
    // 发送订单
    async sendOrder(obj) {
      let { data } =  await submitOrder(obj)
      if (data.code === 200) {
        this.orderSn = data.data.orderSn;
        // 生成二维码
        let obj = {
          orderSn: this.orderSn,
          // cityId: this.form.cityId,
          // industry: this.form.industry,
        }
        await this.createQRCode(obj)
      }else {
        this.$message.error(data.message)
      }
    },
    // 支付二维码
    async createQRCode(obj) {
      let { data } = await payCode(obj)
      if (data.code === 200) {
        this.ifCodeShow = true;
        this.qrCodeAddress = data.data.codeUrl;
        if(!this.qrCode) {
          this.qrcode(this.qrCodeAddress);
        }
        const that = this;
        that.timer = window.setInterval(() => {
          if(that.ifCodeShow == false || that.ifShow == false){
            window.clearInterval(that.timer);
          }
          that.timer2 = window.setInterval(() => {
            that.pollQuery(obj)
          },2000)
        }, 1000);
      }else {
        this.$message.error(data.message);
      }
    },
    // 生成二维码
    qrcode(url){
      this.$nextTick(()=>{
        this.qrCode = new QRCode("qrcode",{
          width:180,
          height:180,
          text:url,
        })
        this.isLoading = false;
      })
    },
    // 轮询查询二维码状态
    async pollQuery(obj) {
      let { data } = await check(obj)
      if (data.code === 200) {
        if (data.data) {
          window.clearInterval(this.timer);
          window.clearInterval(this.timer2);
          this.timer = null;
          this.timer2 = null;
          this.visibleCode = true;
          this.showTimer=false
          setTimeout(()=>{
            this.ifCodeShow = false;
            this.payTime = 1000 * 60 * 10;
            this.clearQrCode()
            this.showSuccessWindow = true;
          },3000)
        }
      }else {
        this.payTime -= 1000;
        if(this.payTime === 0){
          window.clearInterval(this.timer);
          window.clearInterval(this.timer2);
          this.timer = null;
          this.timer2 = null;
          alert('支付失败');
          this.visibleCode = false;
          this.ifCodeShow = false;
          this.payTime = 1000 * 60 * 10;
        }
      }
    },
    // 关闭二维码窗口
    closeCodeVisible(){
      this.ifCodeShow = false;
      this.visibleCode = false;
      this.qrCode = '';
      window.clearInterval(this.timer);
      window.clearInterval(this.timer2);

    },
    // 清除二维码
    clearQrCode(){
      // this.$refs.qrcode.innerHTML = "";
      this.qrCode = '';
    },
    // 注册成功弹窗关闭
    cancel() {
      this.visible2 = true
      this.showSuccessWindow=false
      this.outerVisible=false
      this.$emit('enterpriseTypeReset')
      this.visibleCode = false; // 关闭支付成功的窗口
      this.form = {
        type:1,
        companyName: undefined, // 公司名称
        name: undefined, // 姓名
        phone: undefined, // 电话
        validCode: undefined, // 验证码
        buyNum: 3, // 账号数量
        skuId: -1,
        couponCode:''//邀请码
      };
      this.invitationCode='';
      this.invitationTip='';
      this.Cdiscount=1
      this.Adiscount=1
      this.showTimer=false
    },
  }
}
</script>

<style lang="less" scoped>
.marginB0{
  margin-bottom: 0!important;
}
</style>
<style lang="less">
.new-pc-enterprise-criterion{
  width: 848px!important;
}
.new-pc-enterprise-criterion .ant-modal-content{
  width: 848px!important;
  padding: 32px!important;
  background: #F7F8FA;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}
.new-pc-enterprise-criterion .ant-modal-body{
  padding: 0;
}
.new-pc-enterprise-criterion .top,
.new-pc-enterprise-criterion .bottom
{
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;
}

.new-pc-enterprise-criterion .top{
  .title-wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #1B1C33;
    .icon{
      font-size: 24px;
    }
    .title{
      margin-left: 23px;
    }
  }
  .modal-line{
    height: 1px;
    background: #EDEDF0;
    margin: 16px 0;
  }
  .info{
    font-size: 14px;
    font-weight: 400;
    color: #1B1C33;
    margin-bottom: 16px;
  }
  .ant-form{
    display: flex;
    flex-wrap: wrap;
    .ant-form-item{
      display: flex;
      width: 50%;
      flex-shrink: 0;
      margin-bottom: 20px;
      .ant-form-item-label{
        width: 80px;
        flex-shrink: 0;
      }
      .ant-form-item-control-wrapper{
        width: 100%;
        box-sizing: border-box;
      }
    }
    .get-code-wrap{
      position: relative;
      .hit-code-wrap{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        .items-code-2{
          color: #0055FF;
        }
        .items-code-time-2{
          color: #8D8E99;
        }
      }
    }
  }
}
.new-pc-enterprise-criterion .bottom{
  margin-top: 8px;
  .title{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .buy{
      font-weight: 400;
      font-size: 14px;
      color: #1B1C33;
    }
    .invitation-code{
      .word{
        font-weight: 400;
        font-size: 14px;
        color: #8D8E99;
        .left-word{
          margin-right: 6px;
        }
      }
      .input-wrap{
        position: relative;
        .validate{
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
          color: #0055FF;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .tip{
        color: #FF4D4F;
        font-size: 14px;
      }
      .close{
        margin-left: 10px;
      }
    }
  }
  .price-card-wrap{
    display: flex;
    margin: 18px 0;
    .price-card{
      width: 172px;
      background: #F7F8FA;
      border-radius: 8px;
      padding: 12px 16px;
      margin-right: 16px;
      box-sizing: border-box;
      flex-shrink: 0;
      .price-choose-month{
        font-size: 16px;
        color: #1B1C33;
        line-height: 22px;
      }
      .sell-price-and-original{
        display: flex;
        .price-choose-discounts{
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #FE6C30;
        }
        .price-choose-original{
          margin-left: 16px;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8D8E99;
          display: flex;
          align-items: end;
          position: relative;
          .delete-line{
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #8D8E99;
            top: 75%;
            //transform: translateY(-50%);
          }
        }

      }
      .price-choose-day{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8D8E99;
      }
    }
    .active-price-card{
      background: rgba(0, 85, 255, 0.04);
      border: 1px solid #0055FF;
    }
    .active-price-card:last-of-type{
      margin-right: 0;
    }
  }
  .num-wrap{
    display: flex;
    .num{
      //margin-left: 18px;
      //width: 80px!important;
      justify-content:right;
      display: flex;
      align-items: center;
      color: #1B1C33;
      flex-shrink: 0;
    }
    .num-input{
      width: 205px;
      flex-shrink: 0;
      .ant-input{
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: #1B1C33;
        border-radius: 0!important;
      }
    }
    .tip{
      width: 100%;
      display: flex;
      font-size: 12px;
      color: #8D8E99;
      justify-content: center;
      align-items: center;
    }
  }
  .modal-line-2{
    width: 100%;
    height: 1px;
    background: #EDEDF0;
    margin: 16px 0;
  }
  .price-btn{
    display: flex;
    justify-content: space-between;
    .calculate-wrap{
      .calculate-last,.calculate-price,.calculate-discount,.price-show-2{
        width: 155px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;
        color: #8D8E99;
        margin-bottom: 8px;
      }
      .calculate-last{
        color: #FE6C30;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
      }
      .price-show-2{
        height: auto;
        .price-title,.price-shows{
          margin-bottom: 0;
        }
      }
    }
    .btn-wrap{
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      .submit-check{
        .agree{
          margin-left: 8px;
          font-size: 14px;
          line-height: 20px;
          color: #8D8E99;
          .agreement{
            color: #0055FF;
          }
        }
      }
      .enterprise-criterion-btn{
        margin-top: 8px;
        // padding: 10px 80px;
        background: #FE6C30;
        border-radius: 8px;
        width: 276px;
        height: 40px;
      }
    }
  }
}
.new-pc-pay-window{
  .qr-text,.qr-success{
    text-align: center;
  }
}
.is-use-invitation-code{
  color: #8D8E99;
}
//支付弹框样式
.pay-window-small{
  // 二维码样式
  .pay-success{
    position: relative;
    margin:56px auto;
    margin-bottom: 0;
    width: 180px;
    height: 180px;
    background-color: #7389b6;
    .pay-success-inner{
      position: absolute;
      width: 180px;
      height: 180px;
      left: 0;
      top: 0;
      background-color: #F2F3F5;
      opacity: 0.8;
    }
    .pay-success-image{
      position: absolute;
      top: 58.5px;
      left:58.5px;
      width: 63px;
      height: 63px;
    }
  }
  .qr-success{
    position: relative;
    width: 100%;
    margin-top:12px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #65CB65;
    margin-bottom:24px;
  }
  .qr-text{
    position: relative;
    width: 100%;
    margin-top:12px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #8D8E99;
    margin-bottom:24px;
  }
}
</style>
