<template>
  <div>
    <div class="article-news-wrap">
      <div class="article-news">
        <div class="article-news-title">文章资讯</div>
        <articleCard>
          <template v-slot:advar>
            <span></span>
          </template>
          <template v-slot:author>
            <span></span>
          </template>
        </articleCard>
      </div>
    </div>
    <div class="article-tabs-wrap-wrap">
      <div class="article-tabs-wrap">
        <div class="article-tabs">
          <div class="article-tabs-item" :class="index==currentIndex ? 'active_item' : ''"  @click="handleTab(index)" v-for="(item,index) in tabs" :key="index">{{item.typeName}}</div>
<!--          <a-tabs default-active-key="1" @change="handleTab">-->
<!--            <a-tab-pane :tab="item.typeName"  v-for="(item,index) in tabs" :key="index">-->
<!--            </a-tab-pane>-->
<!--          </a-tabs>-->
        </div>
<!--        <div class="tab-line"><div class="article-active-line"></div></div>-->
      </div>
    </div>
    <div class="article-item-wrap-wrap" v-if="allArticle.length>0">
      <div class="article-item-wrap wow bounceInLeft">
        <articleItem v-for="(item,index) in allArticle" :key="index" :articleItem="item"></articleItem>
      </div>
    </div>
    <div class="pagination-wrap-wrap" v-if="total>0">
      <div class="pagination-wrap wow bounceInRight">
        <a-pagination v-model="currentPage" :total="total" @change="onChange" show-less-items />
      </div>
    </div>
    <div class="article-empty" style="margin-bottom: 80px" v-if="total==0">
<!--      <a-empty />-->
      <div class="img-wrap">
        <img src="//file.dhunting.com/official_website/v1/article-empty.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {getArticle,getArticleType} from '@/api/request'
import articleCard from "@/components/articleCard/articleCard";
import articleItem from "@/components/articleItem/articleItem";
export default {
  mounted() {
    this.$bus.$emit("changeTabsIndex", 4);

    this.handleGetAllArticle()
    this.handelGetArticleType()
  },
  components:{
    articleCard,
    articleItem
  },
  data() {
    return {
      tabs:[
        {
          "owArticleTypeId": "",
          "typeName": "全部",
          "typeSort": 0
        }
        ],
      currentIndex:0,
      currentPage: 1,
      allArticle:[],
      total:0
    }
  },
  methods:{
    handelGetArticleType(){
      getArticleType().then(res=>{
        if(res.data.code===200){
          this.tabs.push.apply(this.tabs,res.data.data)
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    // changeIndex(index){
    //   // console.log(indexHome)
    //   this.currentIndex=index
    // },
    onChange(pageNumber){
      this.currentPage=pageNumber
      this.handleGetAllArticle()
    },
    handleGetAllArticle(id){
      getArticle({
        current:this.currentPage,
        size:8,
        title:undefined,
        owArticleTypeId:id
      }).then(res=>{
        if(res.data.code===200){
          this.allArticle=res.data.data.records
          this.total=res.data.data.total
          // console.log(res.data.data.total)
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    handleTab(index){
      this.currentIndex=index
      // const allElement=document.querySelectorAll('.article-tabs-item')
      // let total_length=0
      // for(let i=0;i<index;i++){
      //   total_length=total_length+allElement[i].clientWidth+48
      // }
      // // total_length
      // const element=document.querySelector('.article-active-line')
      // if(!element) return
      // element.style.transform= `translate3d(${total_length}px, 0px, 0px)`
      // element.style.transition='all 0.5s'
      if(index!=0){
        this.handleGetAllArticle(this.tabs[index].owArticleTypeId)
      }
      else {
        this.handleGetAllArticle()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.article-empty{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  .img-wrap{
    width: 506px;
    height: 170px;
  }
}
  .article-news-wrap,
  .article-tabs-wrap-wrap,
  .article-item-wrap-wrap,
  .pagination-wrap-wrap
  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .article-news-wrap{
    background-image: url("//file.dhunting.com/official_website/v1/login-bg.png");
    background-size: cover;
  }
  .article-news,
  .article-tabs-wrap,
  .article-item-wrap,
  .pagination-wrap
  {
    width: 100%;
    max-width: 1440Px;
  }
.article-news{
  padding: 128px 117px 60px 117px;
}
.article-news-title{
  font-weight: 600;
  font-size: 48px;
  color: #1B1C33;
  margin-bottom: 40px;
}
.article-tabs-wrap{
  margin-top: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;
  flex-direction: column;
}
.article-tabs{
  display: flex;
  /*position: absolute;*/
  /*left: 50%;*/
  /*transform: translateX(-50%);*/
}
.article-tabs-item{
  box-sizing: border-box;
  cursor: pointer;
  padding-bottom: 8px;
  color: #8D8E99;
  margin-right: 48px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
  .article-tabs-item:nth-last-child(1) {
    margin-right: 0;
  }
.article-tabs-item:hover{
  color: #0055FF;
}
.active_item{
  border-bottom: #0055FF 2px solid;
  color: #0055FF;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
  .tab-line{
    width: 320px;
    height: 2px;
  }
  .article-active-line{
    width: 70px;
    height: 2px;
    background-color: #0055FF;
    transform: translateX(0px);
  }
.article-item-wrap{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 160px 0 203px;
}
.pagination-wrap{
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

  .article-tabs /deep/ .ant-tabs-bar{
    border-bottom: none!important;
  }
  .article-tabs /deep/ .ant-tabs-tab{
    font-weight: 400;
    font-size: 16px;
    color: #A9A9AF;
  }
  .article-tabs /deep/ .ant-tabs-tab:active,
  .article-tabs /deep/ .ant-tabs-tab:hover{
    color: #0055FF;
  }
  .article-tabs /deep/ .ant-tabs-ink-bar{
    background-color: #0055FF;
  }
  .pagination-wrap /deep/ .ant-pagination-item{
    background: #FFFFFF;
  }
  .pagination-wrap /deep/ .ant-pagination-item a{
    color:#1B1C33!important;
  }
  .pagination-wrap /deep/ .ant-pagination-item-active{
    background-color:#0055FF;
  }
  .pagination-wrap /deep/ .ant-pagination-item-active a{
    color: #FFFFFF!important;
  }
  .pagination-wrap /deep/ .ant-pagination-prev a{
    color: #1B1C33!important;
  }
  .pagination-wrap /deep/ .ant-pagination-next a{
    color: #1B1C33!important;
  }
</style>