<template>
  <div class="home-wrap">
    <div class="banner-wrap">
      <div class="banner">
        <div class="banner_left">
          <div class="banner_title_wrap">
            <div class="banner_title">递航智能化招聘工具</div>
<!--            <div class="font_blue"><span class="fontBlue">更多</span>推荐，<span class="fontBlue">更多</span>Offer!</div>-->
            <div class="font_blue"></div>
          </div>
          <div class="banner_info">一个既能对内提效，又能对外开源的智能化招聘管理工具</div>
          <div class="free-use-wrap" @mouseenter="enterFreeUse" @mouseleave="leaveFreeUse">
            <a-button class="use_btn" type="primary">
              <span class="title">免费试用</span>
              <div class="icon-wrap">
                 <a-icon v-show="isShowFreeUse" class="icon" type="right" />
              </div>
            </a-button>
            <div class="product-type" v-show="isShowFreeUse">
              <div @click="toBuyHunterSystem" class="pointer title" :class="isEnterHunter ? 'active' : ''" @mouseenter="enterHunter" @mouseleave="leaveHunter">
                <span>猎头/RPO/外包版</span>
                <a-icon v-show="isEnterHunter" class="icon" type="right" />
              </div>
              <div @click="clickShowHrTable" class="pointer title" :class="isEnterHr ? 'active' : ''" @mouseenter="enterHr" @mouseleave="leaveHr">
                <span>HR版</span>
                <a-icon v-show="isEnterHr" class="icon" type="right" />
              </div>
            </div>
          </div>
        </div>
        <div class="banner_video">
          <!--        <img src="//file.dhunting.com/official_website/v1/banner1.png" alt=""/>-->
          <img src="//file.dhunting.com/official_website/v1/banner-video.png" alt="" v-if="!isPlay" @click="handlePlay">
          <div class="video-modal-wrap">
            <a-modal
                v-model="isPlay"
                centered
                :footer="null"
                @cancel="handleStop"
                wrapClassName="pc-home-video-wrap"
                dialogClass="pc-home-video"
                :closable="false"
            >
              <div class="video-wrap">
                <!--              <a-icon type="close-circle" @click="handleStop"/>-->
                <video src="//file.dhunting.com/official_website/v1/Dhunting.mp4" id="dhuntingVideo" controls autoplay></video>
              </div>
              <div class="close-video" @click="handleStop"><a-icon type="close" /></div>
            </a-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-wrap">
      <div  class="swpier">

        <a-carousel arrows dots autoplay>
          <div
              slot="prevArrow"
              class="custom-slick-arrow"
              style="left: 100px;top: 261px;zIndex: 1"
          >
            <a-icon type="left" />
          </div>
          <div slot="nextArrow"  class="custom-slick-arrow" style="right: 100px;top: 261px">
            <a-icon type="right" />
          </div>
          <!--        <div v-for="(item,index) in swiper_list" :key="index" class="img-wrap"><img :src="item"/></div>-->
          <div class="swiper-list-item">
            <div class="swiper-list-item-leftImg"><img src="//file.dhunting.com/official_website/v1/swiper1.png" alt=""></div>
            <div class="swiper-list-item-rightInfo">
              <div class="item-rightInfo-title">数据资产的留存包含了所有的沟通记录、评价反馈</div>
              <div class="rightInfo-advar-name">
                <img src="//file.dhunting.com/official_website/v1/swiper-advar.png" alt="">
                <span>某公司HRD</span>
              </div>
              <div class="rightInfo-dialog">“和团队之间的信息对齐效率大大提高！不会出现任何沟通信息不准确的问题。”</div>
            </div>
          </div>
          <div class="swiper-list-item">
            <div class="swiper-list-item-leftImg"><img src="//file.dhunting.com/official_website/v1/swiper2.png" alt=""></div>
            <div class="swiper-list-item-rightInfo">
              <div class="item-rightInfo-title">打通猎企私域合作渠道，发挥自身优势，让交付效率更高，实现双赢！</div>
              <div class="rightInfo-advar-name">
                <img src="//file.dhunting.com/official_website/v1/swiper-advar.png" alt="">
                <span>某SOHO猎头</span>
              </div>
              <div class="rightInfo-dialog">通过使用猎头联盟，季度增收了30万+业绩额！</div>
            </div>
          </div>
          <div class="swiper-list-item">
            <div class="swiper-list-item-leftImg"><img src="//file.dhunting.com/official_website/v1/swiper3.png" alt=""></div>
            <div class="swiper-list-item-rightInfo">
              <div class="item-rightInfo-title">免费使用SOHO猎头系统，限量注册</div>
              <div class="rightInfo-advar-name">
                <img src="//file.dhunting.com/official_website/v1/swiper-advar.png" alt="">
                <span>某SOHO猎头</span>
              </div>
              <div class="rightInfo-dialog">功能完全不输付费的系统，可以建立自己的私域人才库，积累自己的候选人和客户。</div>
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <div class="solute-wrap">
      <div class="solute">
        <div class="wow bounceInUp solute_title">高效协同、深度赋能、精准开源！</div>
        <div class="wow bounceInUp solute_info">递航人才推荐系统核心价值主张</div>
        <div class="system">
          <div  class="wow bounceInLeft hunter_sys">
            <div class="sys_advar"><img src="//file.dhunting.com/official_website/v1/user.png" alt=""></div>
            <div class="sys_title">猎头/RPO/外包版</div>
            <div class="sys_content">
              <div class="sys_content_item">
                <div class="sys-item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
                <div class="item_info">资产管理：记录并留存业务流程中所有录入及行为数据</div>
              </div>
              <div class="sys_content_item">
                <div class="sys-item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
                <div class="item_info">业务提效：通过内外协同，精准赋能助力业务提效</div>
              </div>
              <div class="sys_content_item">
                <div class="sys-item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
                <div class="item_info">业绩管理：独家的订单系统，精准预测业绩走向，赋能决策</div>
              </div>
              <div class="sys_content_item">
                <div class="sys-item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
                <div class="item_info">数据洞察：多维度数据洞察，帮助企业做科学管理</div>
              </div>
            </div>
            <div class="sys_btn">
              <a-button type="primary" @click="toHunterSystem">立即体验</a-button>
<!--              <a-button type="primary" class="know-more" ghost @click="toHunterSystemTab">了解更多</a-button>-->
              <div @click="toHunterSystemTab" class="know-more-btn">了解更多</div>
            </div>
          </div>
          <div class="wow bounceInRight hunter_sys">
            <div class="sys_advar"><img src="//file.dhunting.com/official_website/v1/user.png" alt=""></div>
            <div class="sys_title">HR版</div>
            <div class="sys_content">
              <div class="sys_content_item">
                <div class="sys-item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
                <div class="item_info">资产管理：自建企业人才库，沉淀人才资产及招聘过程数据</div>
              </div>
              <div class="sys_content_item">
                <div class="sys-item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
                <div class="item_info">Al小助理：24小时智能寻访、人才评估、面试管理等降本增效功能</div>
              </div>
              <div class="sys_content_item">
                <div class="sys-item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
                <div class="item_info">对外开源：递航速聘、猎头联盟助力企业获取更多简历</div>
              </div>
              <div class="sys_content_item">
                <div class="sys-item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
                <div class="item_info">数据洞察：招聘全流程数据分析，赋能企业做出决策</div>
              </div>
            </div>
            <div class="sys_btn">
              <a-button type="primary" @click="toHrSystem">立即体验</a-button>
<!--              <a-button type="primary" class="know-more" ghost @click="toHrSystemTab">了解更多</a-button>-->
              <div @click="toHrSystemTab" class="know-more-btn">了解更多</div>
            </div>
          </div>
        </div>
        <div class="solute-bottom">
          <img src="//file.dhunting.com/official_website/v1/solute_bottom.png">
        </div>

      </div>
    </div>
    <div class="effient-collaboration-wrap">
      <div class="efficent-collaboration">
        <div class="efficent-collaboration-left">
          <div class="efficent-collaboration-title wow bounceInUp">高效协同</div>
          <div class="efficent-collaboration-intro wow bounceInUp">0时差的内外协同<br>全面提高沟通及反馈效率</div>
          <div class="efficent-collaboration-info-wrap">
            <div class="efficent-collaboration-info-title wow bounceInUp">猎头/RPO/外包版</div>
            <div class="efficent-collaboration-info-info wow bounceInUp">内协同：数据信息留存对齐、及通、接派单、任务看板、日程待办等。
              外协同：通过猎头联盟，与信任的猎企建立联盟，发挥各自的优势，一起高效交付</div>
            <div class="efficent-collaboration-info-line"></div>
            <div class="efficent-collaboration-info-title wow bounceInUp">HR版</div>
            <div class="efficent-collaboration-info-info wow bounceInUp">三方协同：沉浸式流程管理，与用人经理/面试官、供应商0时差协同。
              外部协同：通过递航速聘社交裂变、猎头联盟助力交付，帮助企业获取更多优质简历。</div>
          </div>
        </div>
        <div class="efficent-collaboration-right">
          <img src="//file.dhunting.com/official_website/v1/efficent-collabor-banner.png" alt="">
        </div>
      </div>
    </div>
    <div class="deep-power-wrap">
      <div class="deep-power">
        <div class="deep-power-left">
          <img src="//file.dhunting.com/official_website/v1/deep-power-banner.png" alt="">
        </div>
        <div class="deep-power-right">
          <div class="deep-power-title wow bounceInUp">深度赋能</div>
          <div class="deep-power-intro wow bounceInUp">让没有经验的小白也能瞬间变身招聘专家</div>
          <div class="deep-power-info-wrap wow bounce">
            管理赋能：先进的赋能型管理理念让没有任何经验的用户也能做好招聘绩效管理。
            <div class="divided"></div>
            数据赋能：实时查看招聘过程中的每一项关键数据，及时察觉工作能力的优劣势。
            <div class="divided"></div>
            AI赋能：企业简历自动化解析入库、智能画像分析、自动设置待办、发送面试提醒，将用户从反复的事务性工作中解脱出来。
            <!--          <div class="deep-power-info-item">-->
            <!--            -->
            <!--          </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="accurate-open-source-wrap">
      <div class="accurate-open-source">
        <div class="accurate-open-source-title wow bounce">精准开源</div>
        <div class="accurate-open-source-intro wow bounceInLeft">递航速聘、猎头联盟助力企业<br>获取更多优质简历</div>
        <div class="accurate-open-source-info wow bounceInRight">递航速聘：可快速扩散企业招聘信息，高效整合员工的二度、三度人脉，扩大简历来源。
          <div class="divided"></div>
          猎头联盟：可获取更多专业猎头的优质简历推荐，在现有招聘渠道外，有效补充简历来源。
          <div class="divided"></div>
          智能推荐：智能化推荐算法及人才获取路径追踪技术，降低职位筛选及扩散成本。</div>
      </div>
    </div>
    <div class="friend-wrap">
      <div class="friend">
        <div class="friend-title wow backInUp">多做一步，成为用户最信任的合作伙伴</div>
        <div class="data" ref="scroll">
          <div class="data-item">
            <div class="data-item-top wow">
              <span class="data-item-top-data1 wow">{{num1}}</span>
              <span class="percent">%</span>
              <iconpark-icon name="up" class="percent-icon wow"></iconpark-icon>
            </div>
            <div class="data-item-bottom">
              招聘成本 降低
            </div>
          </div>
          <div class="data-item-line"></div>
          <div class="data-item">
            <div class="data-item-top">
              <span class="data-item-top-data2 wow">{{num2}}</span>
              <span class="percent">%</span>
              <iconpark-icon name="down" class="percent-icon wow"></iconpark-icon>
            </div>
            <div class="data-item-bottom">
              推荐效率 提升
            </div>
          </div>
          <div class="data-item-line"></div>
          <div class="data-item">
            <div class="data-item-top">
              <span class="data-item-top-data3 wow">{{num3}}</span>
              <span class="percent">%</span>
              <iconpark-icon name="down" class="percent-icon wow"></iconpark-icon>
            </div>
            <div class="data-item-bottom">
              反馈效率 提升
            </div>
          </div>
          <div class="data-item-line"></div>
          <div class="data-item">
            <div class="data-item-top">
              <span class="data-item-top-data4 wow">{{num4}}</span>
              <span class="percent">%</span>
              <iconpark-icon name="down" class="percent-icon wow"></iconpark-icon>
            </div>
            <div class="data-item-bottom">
              候选人满意度 提升
            </div>
          </div>
        </div>
        <div class="friend-intro">
          <swiperIndex></swiperIndex>
        </div>
      </div>
    </div>
    <div class="artitle-wrap" v-if="isShowArticle">
      <div class="artitle">
        <div class="article-title">文章资讯</div>
        <articleCard @articleIsNone="handleArticleIsNone">
          <template v-slot:advar>
            <span></span>
          </template>
          <template v-slot:author>
            <span></span>
          </template>
        </articleCard>
        <div class="more_article_wrap">
          <div class="more_artitle" @click="toArticleTab">更多文章</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import articleCard from "@/components/articleCard/articleCard";
import swiperIndex from "@/components/swiper/swiperIndex";
export default {
  components:{
    articleCard,
    swiperIndex
  },
  mounted() {
    this.$wow.init()
    this.$bus.$emit("changeTabsIndex", 0);
    this.elementScroll=this.$refs.scroll
    window.addEventListener('scroll', this.handleScroll)
    // 创建一个可以观察元素交叉状态的观察器，并指定需要观察的根元素
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        // 如果目标元素的交叉比率大于 0，则表示该元素当前在视口范围内
        if (entry.intersectionRatio > 0) {
          this.isInSight=true
        } else {
          this.isInSight=false
        }
      });
    }, { root: null });
  },
  data() {
    return {
      visible:false,
      //鼠标是否悬浮
      isEnterHr:false,
      isEnterHunter:false,
      isShowFreeUse:false,
      isShowArticle:true,//是否显示文章模块
      isPlay:false,
      num1:50,//初始值不要为0，速度快点
      num2:50,
      num3:50,
      num4:50,
      timer1: null,
      timer2: null,
      timer3: null,
      timer4: null,
      elementScroll:undefined,
      observer:undefined,//用于观测元素是否在视口范围内
      isInSight:false//是否在视口内
    }
  },
  methods:{
    //关闭成功弹窗
    cancel() {
      this.visible = false;
    },
    clickShowHrTable(){
      //通知indexHeader组件打开hrtable
      this.$bus.$emit('openHrTable')
    },
    toBuyHunterSystem(){
      this.$router.push('/productContact')
    },
    enterHr(){
      this.isEnterHr=true
    },
    leaveHr(){
      this.isEnterHr=false
    },
    enterHunter(){
      this.isEnterHunter=true
    },
    leaveHunter(){
      this.isEnterHunter=false
    },
    enterFreeUse(){
      this.isShowFreeUse=true
    },
    leaveFreeUse(){
      this.isShowFreeUse=false
    },
    handleArticleIsNone(){
      this.isShowArticle=false
    },
    // 跳转产品咨询
    toProductContact() {
      let routerUrl = this.$router.resolve({path: '/productContact'});
      window.open(routerUrl.href, '_blank');
    },
    // 跳转猎企标准版
    toHunterSystem() {
      let routerUrl = this.$router.resolve({path: '/productContact', query: { systemType: 1 }});
      window.open(routerUrl.href, '_blank');
    },
    // 跳转猎头系统tab
    toHunterSystemTab() {
      let routerUrl = this.$router.resolve({path: '/hunterSystem'});
      window.open(routerUrl.href, '_blank');
    },
    // 跳转HR预约演示
    toHrSystem() {
      let routerUrl = this.$router.resolve({path: '/productContact', query: { systemType: 2 }});
      window.open(routerUrl.href, '_blank');
    },
    // 跳转HR系统tab
    toHrSystemTab() {
      let routerUrl = this.$router.resolve({path: '/hrSystem'});
      window.open(routerUrl.href, '_blank');
    },
    // 跳转文章资讯tab
    toArticleTab() {
      let routerUrl = this.$router.resolve({path: '/indexArticle/defaultArticle'});
      window.open(routerUrl.href, '_blank');
    },
    handlePlay(){
      this.isPlay=true
      const video=document.getElementById('dhuntingVideo')
      video.play()
    },
    handleStop(){
      const video=document.getElementById('dhuntingVideo')
      video.pause()
      this.isPlay=false
    },
    handleScroll() {
      if(this.elementScroll){
        // 将目标元素添加到观察器中进行监测
        this.observer.observe(this.elementScroll);
        if(this.isInSight) {
          this.timer1 = setInterval(() => {
            if(this.num1 === 50) {
              this.elementScroll.querySelector('.data-item-top-data1').classList.add('bounceIn');
              return;
            }
            this.num1 += 1;
          }, 20);
          this.timer2 = setInterval(() => {
            if(this.num2 === 65) {
              this.elementScroll.querySelector('.data-item-top-data2').classList.add('bounceIn');
              return;
            }
            this.num2 += 1;
          }, 10);
          this.timer3 = setInterval(() => {
            if(this.num3 === 66) {
              this.elementScroll.querySelector('.data-item-top-data3').classList.add('bounceIn');
              return;
            }
            this.num3 += 1;
          }, 10);
          this.timer4 = setInterval(() => {
            if(this.num4 === 80) {
              this.elementScroll.querySelector('.data-item-top-data4').classList.add('bounceIn');
              return;
            }
            this.num4 += 1;
          }, 10);
        }
      }
    }
  },
  beforeDestory() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
    clearInterval(this.timer4);
  }
}
</script>
<style lang="less" scoped>
.video-wrap{
  width: 100%;
  height: 100%;
}
/*使页面不管怎么拉伸，内容始终居中*/
.banner-wrap,
.swiper-wrap,
.solute-wrap,
.effient-collaboration-wrap,
.deep-power-wrap,
.accurate-open-source-wrap,
.friend-wrap,
.artitle-wrap
{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-wrap{
  background-image: url("//file.dhunting.com/official_website/v1/bg.png");
  background-size:100% 100%;
}
.solute-wrap{
  background-image: url("//file.dhunting.com/official_website/v1/solute_bg.png");
  background-size: 100% 100%;
}
.effient-collaboration-wrap{
  background-image: url("//file.dhunting.com/official_website/v1/efficent-collabor-bg.png");
  background-size: 100% 100%;
}
.deep-power-wrap{
  background-image: url("//file.dhunting.com/official_website/v1/deep-power-bg.png");
  background-size: 100% 100%;
}
.accurate-open-source-wrap{
  background-image: url("//file.dhunting.com/official_website/v1/open-source.png");
  background-size: cover;
}
.friend-wrap{
  background: #F9FAFF;
}
.banner,
.swpier,
.solute,
.efficent-collaboration,
.deep-power,
.accurate-open-source,
.friend,
.artitle
{
  max-width: 1440Px;
  width: 100%;
}
.solute{
  overflow: hidden;
}
.banner{
  display: flex;
  width: 100%;
  height: 600px;
  /*justify-content: space-between;*/
}
.banner_left{
  padding:142px 0px 86px 120px ;
  flex: 1;
  box-sizing: border-box;
}
.banner_title,.font_blue,.font_blue{
  font-weight: 600;
  font-size: 48px;
  width: 500px;
  line-height: 80px;
  color: #1B1C33;
}
.fontBlue{
  color: #0055FF;
}
.banner_info{
  margin-top: 40px;
  width: 600px;
  height: 60px;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #5E5E66;
}
.pointer{
  cursor: pointer;
}
.free-use-wrap{
  margin-top: 60px;
  position: relative;
  .product-type{
    left: 308px;
    top: 0;
    position: absolute;
    width: 200px;
    height: 116px;
    background: #FFFFFF;
    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px;
    line-height: 38px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 16px;
    color: #1B1C33;
    .title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      span{
        line-height: 22px;
      }
      .icon{
        font-size: 12px;
      }
    }
    .active{
      background: #F2F6FF;
      color: #0055FF;
      border-radius: 4px;
    }
  }
}
.use_btn{
  width: 300px;
  height: 56px !important;
  border-radius: 8px;
  font-weight: 400;
  font-size: 20px !important;
  .icon-wrap{
    position: relative;
    display: inline-block;
  }
  .icon{
    position: absolute;
    font-size: 16px;
    left: 16px;
    top: -14px;
  }
}
/*.banner_img img{*/
/*  width: 100%;*/
/*  padding-top: 116px;*/
/*}*/
.banner_video{
  /*padding: 40px 40px 80px 20px;*/
  box-sizing: border-box !important;
  flex: 1;
  cursor: pointer;
}
.banner_video img{
  width: 100%;
  height: 100%;
  /*min-width: 100%;*/
  object-fit: contain;
}

/*.banner_video .video-wrap{*/
/*  position: absolute;*/
/*  width: 100%;*/
/*  height: 100vh;*/
/*  top: 0;*/
/*  left: 0;*/
/*}*/
.close-video{
  width: 32px;
  height: 32px;
  background: #0055FF;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  font-size: 20px;
  position: absolute;
  right: -32px;
  top: 0;
  cursor: pointer;
}
.video-wrap .anticon-close-circle{
  position: relative;
  right: 50px;
  top: 100px;
  font-size: 30px;
  z-index: 999;
}
.video-wrap video{
  height: 100%;
  width: 100%;
  object-fit: fill;
}
.swpier{
  width: 100%;
}
.swiper-list-item{
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 641px;
}
.swiper-list-item-leftImg{
  flex: 1.5;
}
.swiper-list-item-leftImg img{
  width: 100%;
  height: 100%;
}
.swiper-list-item-rightInfo{
  flex: 1.2;
  padding-right: 180px;
  padding-left: 80px;
}
.item-rightInfo-title{
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #1B1C33;
}
.rightInfo-advar-name{
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
}
.rightInfo-advar-name > span{
  font-weight: 500;
  font-size: 20px;
  line-height: 50px;
  color: #1B1C33;
}
.rightInfo-advar-name > img{
  height: 50px;
  margin-right: 10px;
  width: 50px;
}
.rightInfo-dialog{
  padding: 16px 24px;
  background: #FFFFFF;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 0px 30px 30px 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #5E5E66;
}

.swiper-wrap /deep/ .ant-carousel .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 641px;
  overflow: hidden;
}

.swiper-wrap /deep/ .ant-carousel .custom-slick-arrow {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  font-size: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.25);
  color: #0055FF;
  line-height: 64px;
  text-align: center;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.swiper-wrap /deep/ .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.swiper-wrap /deep/ .slick-prev:hover,
.swiper-wrap /deep/ .slick-next:hover,
.swiper-wrap /deep/ .slick-prev:focus,
.swiper-wrap /deep/ .slick-next:focus{
  color: #0055FF!important;
  background-color: #FFFFFF !important;
}
/*/deep/ .ant-carousel svg{*/
/*  width: 35px;*/
/*  height: 35px;*/
/*  line-height: 35px;*/
/*}*/
.swiper-wrap /deep/ .slick-dots button{
  width: 60px !important;
  height: 6px !important;
  background: #D9D9D9 !important;
}
.swiper-wrap /deep/  .slick-active button{
  background: #0055FF !important;
}

.solute{
  box-sizing: border-box;
  height: 720px;
  padding: 60px 40px 0px 40px;
  position: relative;
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-direction: column;
}
.solute_title{
  height: 50px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  color: #1B1C33;
}
.solute_info{
  margin-top: 16px;
  height: 30px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #5E5E66;
}
.system{
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
}
.hunter_sys{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sys_title{
  //height: 60px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  //line-height: 60px;
  color: #1B1C33;
}
.sys_advar{
  width: 44px;
  height: 46px;
}
.sys_advar > img{
  width: 100%;
  height: 100%;
}
.sys_title{
  margin-top: 16px;
  margin-bottom: 24px;
}
.sys_content_item{
  display: flex;
  /*height: 30px;*/
  /*justify-content: center;*/
  align-items: center;
  padding: 0 40px;
  margin-bottom: 20px;
}
.sys-item-icon{
  height: 22px;
  width: 22px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.item_info{
  font-size: 14px;
  height: 30px;
  line-height: 30px;
}
/*这个还是用图片*/
/*/deep/ .anticon-check-circle{*/
/*  margin-right: 10px;*/
/*  background-color: #0055FF;*/
/*  border-radius: 50%;*/
/*}*/
.sys_btn{
  width: 100%;
  margin-top: 4px;
  display: flex;
  justify-content: center;
}
.know-more{
  color: #0055FF !important;
  margin-left: 48px;
}
.know-more-btn{
  margin-left: 48px;
  width: 140px!important;
  height: 40px!important;
  line-height: 40px;
  text-align: center;
  color: #0055FF;
  border-radius: 6px;
  border: 1px solid #0055FF;
  font-size: 16px;
  cursor: pointer;
}
.know-more:hover, .know-more:focus{
  background-color: transparent !important;
}
.sys_btn button{
  width: 140px!important;
  height: 40px!important;
  font-size: 16px!important;
}
.solute-bottom{
  width: 100%;
  position: absolute;
  bottom: -100px;
  left: 0;
  z-index: 1;
}
/*.miniprogram{*/
/*  height: 800px;*/
/*  background-image: url("//file.dhunting.com/official_website/v1/miniprogram.png");*/
/*  padding-left: 121px;*/
/*  padding-top: 135px;*/
/*}*/
/*.minnipro_title{*/
/*  height: 60px;*/
/*  font-family: 'PingFang SC';*/
/*  font-style: normal;*/
/*  font-weight: 600;*/
/*  font-size: 36px;*/
/*  line-height: 60px;*/
/*  color: #0055FF;*/
/*  margin-bottom: 20px;*/
/*}*/
/*.minnipro_info{*/
/*  height: 60px;*/
/*  font-family: 'PingFang SC';*/
/*  font-style: normal;*/
/*  font-weight: 600;*/
/*  font-size: 36px;*/
/*  line-height: 60px;*/
/*  color: #1B1C33;*/
/*  margin-bottom: 57px;*/
/*}*/
/*.mini-triangle{*/
/*  width: 0;*/
/*  height: 0;*/
/*  position: relative;*/
/*  left: 30px;*/
/*  border-bottom: 12px solid #FFFFFF;*/
/*  border-right: 12px solid transparent;*/
/*  border-left: 12px solid transparent;*/
/*}*/
/*.minipro_content{*/
/*  background: #FFFFFF;*/
/*  border-radius: 30px;*/
/*  padding: 32px 24px;*/
/*  width: 496px;*/
/*}*/
.efficent-collaboration{
  display: flex;
}
.efficent-collaboration-left{
  width: 678px;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 60px 25px 60px 120px;
  margin-right: 100px;
}
.efficent-collaboration-right{
  padding: 80px 0 0 0;
}
.efficent-collaboration-right img{
  width: 100%;
  height: 100%;
}
.efficent-collaboration-title{
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  color: #0055FF;
}
.efficent-collaboration-intro{
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  color: #1B1C33;
  margin-top: 20px;
  margin-bottom: 40px;
}
.efficent-collaboration-info-wrap{
  background: #FFFFFF;
  border-radius: 30px;
  padding: 32px 24px;
}
.efficent-collaboration-info-title{
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1B1C33;
  margin-bottom: 16px;
}
.efficent-collaboration-info-info{
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #5E5E66;
}
.efficent-collaboration-info-line{
  height: 1px;
  background: #EDEDF0;
  margin:24px 0;
}
/*.union{*/
/*  height: 800px;*/
/*  background-image: url("//file.dhunting.com/official_website/v1/union.png");*/
/*  padding-right: 55px;*/
/*  padding-top: 204px;*/
/*}*/
/*.flex_end{*/
/*  display:flex;*/
/*  justify-content: flex-end;*/
/*}*/
/*.union_title{*/
/*  height: 60px;*/
/*  font-family: 'PingFang SC';*/
/*  font-style: normal;*/
/*  font-weight: 600;*/
/*  font-size: 36px;*/
/*  line-height: 60px;*/
/*  color: #0055FF;*/
/*  margin-bottom: 20px;*/
/*}*/
/*.union_info{*/
/*  height: 60px;*/
/*  font-family: 'PingFang SC';*/
/*  font-style: normal;*/
/*  font-weight: 600;*/
/*  font-size: 36px;*/
/*  line-height: 60px;*/
/*  color: #1B1C33;*/
/*  margin-bottom: 60px;*/
/*}*/
/*.union_content_wrap{*/
/*  position: relative;*/
/*}*/
/*.union_content{*/
/*  background: #FAFAFA;*/
/*  border-radius: 30px;*/
/*  width: 507px;*/
/*  padding: 32px 24px;*/
/*  font-family: 'PingFang SC';*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  font-size: 16px;*/
/*  line-height: 30px;*/
/*  color: #575757;*/
/*  position: absolute;*/
/*  right: 0;*/
/*}*/
.deep-power{
  display: flex;
}
.deep-power-left{
  flex: 2;
  padding: 60px 0 0 0;
}
.deep-power-left > img{
  width: 100%;
  height: 100%;
}
.deep-power-right{
  flex: 1;
  padding: 60px 120px 80px 0;
}
.deep-power-title{
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  color: #0055FF;
}
.deep-power-intro{
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  color: #1B1C33;
  margin: 20px 0 40px 0;
}
.deep-power-info-wrap{
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #575757;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 32px 24px;
}
.divided{
  height: 1em;
}
.accurate-open-source-wrap{
  justify-content: left;
}
.accurate-open-source{
  width: 100%;
  padding: 60px 0px 70px 120px;
  height: 675px;
  width:616px;
  box-sizing: border-box;
}
.accurate-open-source-title{
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  color: #0055FF;
}
.accurate-open-source-intro {
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  color: #1B1C33;
  margin: 20px 0 60px 0;
}
.accurate-open-source-info{
  padding: 32px 24px;
  background: #FFFFFF;
  border-radius: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #5E5E66;
}
.friend{
  padding: 60px 0 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.friend-title{
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  color: #000000;
}
.data{
  margin: 60px 0;
  display: flex;
}
.data-item{
  padding:0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.data-item-top {
  /*svg得大小参照父元素font-size*/
  color: #1B1C33;
  font-size: 50px;
}
.data-item-top-data {
  font-weight: 500;
  font-size: 56px;
  line-height: 60px;
  letter-spacing: 3px;
  color: #1B1C33;
}
.percent{
  margin-right: 15px;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: 3px;
  color: #1B1C33;
}
.data-item-bottom{
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #464E69;
}
.data-item-line{
  width: 2px;
  height: 70px;
  background: #D9D9D9;
}
.friend-intro{
  //margin-top: 60px;
  /*因为没有设置width导致宽度飙升*/
  width: 100%;
  /*height: 360px;*/
}
.friend-intro-item{
  background: #FFFFFF;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  width: 350px;
  padding: 24px 32px;
}
.friend-intro-title{
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #1B1C33;
}
.friend-intro-line{
  height: 1px;
  background: #EDEDF0;
  margin-top: 24px;
  margin-bottom: 24px;
}
.friend-intro-info{
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #1B1C33;
}
.friend-intro /deep/ .ant-carousel .slick-slide {
  display: flex;
  justify-content: space-around;
}
.hoop-item{
  display: flex!important;
  flex-direction: row;
  justify-content: space-around;
  width: 100% !important;
  margin-right: 150px;
}

.artitle{
  height: 800px;
  background: #FFFFFF;
  padding: 60px 100px 116px 100px;
}
.article-title{
  height: 80px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 80px;
  color: #1B1C33;
  margin-bottom: 30px;
}

.more_article_wrap{
  width: 100%;
  display: flex;
  justify-content: center;
}
.more_artitle{
  margin-top: 80px;
  width: 320px;
  height: 60px;
  background: #0055FF;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}
.freeUse_wrap{
  padding: 60px 102px 60px 120px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
}
.freeUse_title{
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  color: #000000;
}
.freeUse_item_wrap{
  margin-top: 60px;
  margin-bottom: 60px;
}
.freeUse_item{
  font-weight: 400;
  font-size: 20px;
  line-height: 60px;
  color: #5E5E66;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.line{
  display: inline-block;
  width: 10px;
  border: 1px solid  #5E5E66;
  margin-right: 5px;
}
.freeUse_btn{
  margin-top: 60px;
  padding: 9px 56px;
  width: 240px;
  height: 56px;
  line-height: 30px;
  background: #0055FF;
  border-radius: 8px;
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
}
.data_detail{
  width: 100%;
  height: 228px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 243px;
}
.detail_item_num{
  font-weight: 500;
  font-size: 56px;
  line-height: 60px;
  color: #000000;
  text-align: center;
}
.detail_item_info{
  font-weight: 400;
  font-size: 20px;
  color: #464E69;
}
.detail_item_line{
  width: 0;
  height: 70px;
  border: 1px solid #D9D9D9;
}
</style>
<style lang="less">
.pc-home-video-wrap{
  top: 100px!important;
  .pc-home-video{
    width: 1200px!important;
    height: 648px!important;
    padding-bottom: 0;
    .ant-modal-content{
      width: 100%;
      height: 100%;
    .ant-modal-body{
      width: 100%;
      height: 100%;
      .video-wrap{
        width: 100%;
        height: 100%;
      }
    }
    }
  }
}
</style>
