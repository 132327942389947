<template>
  <div>
<!--    <div v-if="enterpriseType && version === 1">-->
<!--&lt;!&ndash;      <moEnterpriseCriterion @enterpriseTypeReset="enterpriseTypeReset" v-if="enterpriseType === 2" />&ndash;&gt;-->
<!--      <moEnterpriseFree @enterpriseTypeReset="enterpriseTypeReset" v-if="enterpriseType === 1" />-->
<!--      <newMoEnterpriseCriterion @enterpriseTypeReset="enterpriseTypeReset" v-if="enterpriseType === 2" ></newMoEnterpriseCriterion>-->
<!--    </div>-->
<!--    <div v-else-if="sohoType && version === 2">-->
<!--      <moSoHoFree @sohoTypeReset="sohoTypeReset" v-if="sohoType === 1"></moSoHoFree>-->
<!--      <moSoHoPro @sohoTypeReset="sohoTypeReset" v-else-if="sohoType === 2"></moSoHoPro>-->
<!--      <moSoHoMax @sohoTypeReset="sohoTypeReset" v-else-if="sohoType === 3"></moSoHoMax>-->
<!--    </div>-->
    <div class="mo-product-contact">
      <div class="select-a-plan">
        <div class="title flex-enter">选择您的套餐</div>
        <div class="tabs-wrap">
          <div class="tabs-item-wrap">
            <div
                class="tabs_item"
                :class="[{'active_item': version == index + 1},{ 'marginR64':index===0}]"
                @click="changeIndex(index + 1)"
                v-for="(item,index) in tabs"
                :key="index"
            >
              {{item}}
            </div>
          </div>
        </div>
      </div>
      <div class="plan-detail">
        <mo-enterprise @enterpriseTypeChange="enterpriseTypeChange" v-show="version===1"></mo-enterprise>
        <mo-so-ho @sohoTypeChange="sohoTypeChange" v-show="version===2"></mo-so-ho>
      </div>
      <div class="power-compare">
        <img v-show="version===1" src="//file.dhunting.com/official_website/v1/enterprise-compare.png" alt="">
        <img v-show="version===2" src="//file.dhunting.com/official_website/v1/soho-compare.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import bus from '@/utils/bus'
import '@/assets/css/common.css'
import moEnterprise from "@/views/MoPage/components/moEnterprise";
import moSoHo from "@/views/MoPage/components/moSoHo";
// import moEnterpriseCriterion from "@/views/MoPage/components/moEnterpriseCriterion";
// import moSoHoFree from "@/views/MoPage/components/moSoHoFree";
// import moSoHoMax from "@/views/MoPage/components/moSoHoMax";
// import moSoHoPro from "@/views/MoPage/components/moSoHoPro";
// import moEnterpriseFree from "@/views/MoPage/components/moEnterpriseFree";
// import newMoEnterpriseCriterion from "@/views/MoPage/components/newMoEnterpriseCriterion";
export default {
  name: 'buyHunterSystem',
  components: {
    moEnterprise,
    moSoHo,
    // moEnterpriseCriterion,
    // moSoHoFree,
    // moSoHoMax,
    // moSoHoPro,
    // moEnterpriseFree,
    // newMoEnterpriseCriterion
  },
  data() {
    return {
      tabs: ['企业版','SOHO版'],
      version: 1, // 1为企业版，2为SOHO版
      enterpriseType: 0, // 1为免费版，2为标准版
      sohoType: 0, // 1为Free版，2为Pro版，3为Max版
    };
  },
  mounted() {
    bus.$on('clearproductcard', () => {
      this.enterpriseType=0
      this.sohoType=0
    })
  },
  methods:{
    changeIndex(index) {
      this.version = index;
      this.versionChange(index)
    },
    // 选择企业版 SOHO版
    versionChange(value) {
      if(value === 1) {
        this.enterpriseType = 0;
      }
      else if(value === 2) {
        this.sohoType = 0;
      }
    },
    // / 将enterpriseType重置为0
    enterpriseTypeReset() {
      this.enterpriseType = 0;
    },
    // / 点击立即体验切换到企业版购买详情页面
    enterpriseTypeChange(type) {
      this.enterpriseType = type;
      console.log('切换值',this.enterpriseType)
    },
    // 将sohoType重置为0
    sohoTypeReset() {
      this.sohoType = 0;
    },
    // 点击立即体验切换到SOHO版购买详情页面
    sohoTypeChange(type) {
      this.sohoType = type;
      console.log('type',type)
    },
  }
}
</script>
<style scoped lang="less">
.marginR64{
  margin-right: 64px;
}
.select-a-plan{
  .title{
    margin: 24px 0 32px 0;
    font-weight: 500;
    font-size: 18px;
    color: #1B1C33;
  }
  .tabs-wrap{
    padding: 0 105px;
  }
  .tabs-item-wrap{
    display: flex;
    //justify-content: center;
    .tabs_item{
      font-weight: 500;
      font-size: 14px!important;
      color: #1B1C33;
      padding-bottom: 8px;
    }
    .active_item {
      border-bottom: 1px solid #0055FF;
      color: #0055FF;
    }
    margin-bottom: 32px;
  }
}

.power-compare{
  padding: 0px 37.5px 32px;
}
.plan-detail{
  overflow-x: auto;
}

</style>