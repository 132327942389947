<!--猎头端-企业版-免费版-->
<template>
  <div class="enterprise-free-wrap">
    <div class="enterprise-free-top">
      <div class="top-text">猎头企业——免费版</div>
    </div>
    <div class="enterprise-free-content">
      <div class="enterprise-free-form">
        <a-form-model :model="form" :rules="rules" ref="form" :hideRequiredMark="true">
          <a-form-model-item  prop="companyName">
            <a-input placeholder="请输入公司名称" v-model="form.companyName"></a-input>
          </a-form-model-item>
          <a-form-model-item  prop="name">
            <a-input placeholder="请输入您的姓名" v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item  prop="phone">
            <a-input placeholder="请输入您的手机号码" v-model="form.phone"></a-input>
          </a-form-model-item>
          <a-form-model-item  prop="validCode" class="get-code-wrap">
            <a-input placeholder="请输入验证码" v-model="form.validCode"></a-input>
            <div class="hit-code-wrap">
              <a v-show="!showTimer" class="items-code-2" @click="getCode">获取验证码</a>
              <span v-show="showTimer" class="items-code-time-2">{{time}}s重新发送</span>
              <span class="code-hit-2">{{hitCode}}</span>
            </div>
          </a-form-model-item>
        </a-form-model>
        <a-form-model :model="form" :rules="rules" ref="formRegister" :hideRequiredMark="true">
          <a-form-model-item prop="registerCode" class="register-item">
            <a-input v-model="form.registerCode" placeholder="请输入注册码" class="register-qr-input"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="register-wrap">
        <div class="register-customer-service">
          <img src="//file.dhunting.com/official_website/weixin.svg" alt="" class="register-customer-service-img">
          <span class="register-customer-service-text">扫描添加客服，回复关键词“注册码”获取注册码</span>
        </div>
        <div class="register-code-wrap">
          <img src="//file.dhunting.com/official_website/customerServiceQrCode.svg" alt="" class="register-qr-code">
        </div>
      </div>
    </div>
    <div class="submit-check">
      <a-checkbox v-model="isAgree"></a-checkbox>
      <span class="agree">同意<span @click="showDhuntingWindow">《递航服务协议》</span><span
          @click="showPrivacyWindow">《递航隐私政策》</span></span>
    </div>
    <a-button type="danger" class="enterprise-free-btn" @click="onSubmit">立即体验</a-button>
    <!--    递航服务协议弹窗-->
    <moServiceAgreementModal :showDhunting="showDhunting" @visibleCancel="showDhunting = false"></moServiceAgreementModal>
    <!--    递航隐私政策弹窗-->
    <moPrivacyPolicyModal :showPrivacy="showPrivacy" @visibleCancel="showPrivacy = false"></moPrivacyPolicyModal>
    <moSuccessModal :isVisible="visible" type="register" :phone="form.phone" @cancel="cancel"></moSuccessModal>
  </div>
</template>

<script>
import {checkValid, code} from "@/api/request";
import moSuccessModal from "@/views/MoPage/components/moSuccessModal";
import moPrivacyPolicyModal from "@/views/MoPage/components/moPrivacyPolicyModal";
import moServiceAgreementModal from "@/views/MoPage/components/moServiceAgreementModal";
import {registerEnterpriseFree} from "@/api/Interface/order";

export default {
  components: { moSuccessModal,moPrivacyPolicyModal,moServiceAgreementModal },
  data() {
    return {
      hitCode: '',
      time: 0,//倒计时
      showTimer: false,//显示倒计时
      visible: false, // 是否展示预约成功弹窗
      form: {
        companyName: undefined, // 公司名称
        name: undefined, // 姓名
        phone: undefined, // 电话
        validCode: undefined, // 验证码
        registerCode: undefined, // 注册码
      },
      rules: {
        companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        phone: [
          {
            required: true,
            len: 11,
            pattern: RegExp(/(^(\+?0?86-?)?1[3456789]\d{9}$)|(^(\+?852-?)?[569]\d{3}-?\d{4}$)|(^(\+?886-?|0)?9\d{8}$)|(^(0065)\d{8}$)/),
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        // 大陆手机号：/^(\+?0?86-?)?1[345789]\d{9}$/
        // 香港手机号：/^(\+?852-?)?[569]\d{3}-?\d{4}$/
        // 台湾手机号：/^(\+?886-?|0)?9\d{8}$/
        // 新加坡手机号：/^(0065)\d{8}$/
        validCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        registerCode: [
            { required: true, message: '请输入注册码', trigger: 'blur' },
          {
            validator:this.validateRegisterCode
          }
        ],
      },
      showDhunting: false,//显示用户协议
      showPrivacy: false,//显示隐私政策
      isAgree: false, // 是否同意服务协议
    }
  },
  methods: {
    //验证注册🐎
    validateRegisterCode(rule, value, callback){
      if(this.form.registerCode=='0513'){
        callback()
      }
      else{
        callback(new Error('注册码错误！'))
      }
    },
    // 是否显示递航服务协议
    showDhuntingWindow() {
      this.showDhunting = true;
    },
    // 是否显示递航隐私政策
    showPrivacyWindow() {
      this.showPrivacy = true;
    },
    // 返回上一个页面
    returnFun() {
      // 调用父组件的重置enterpriseType方法
      this.$emit('enterpriseTypeReset');
    },
    // 获取验证码
    getCode(){
      // setTimeout(()=>{
      // 先进行手机号验证，成功后发获取验证码请求
      this.$refs.form.validateField(['phone'], valid => {
        if (!valid) {
          code({ username: this.form.phone}).then((res)=>{
            if(res.data.code === 200){
              //转变成倒计时
              this.time = 60;
              this.codeTimer = setInterval(()=>{
                if(this.time === 0){
                  clearInterval(this.codeTimer);
                  this.codeTimer = null;
                  this.showTimer = false;
                }
                this.time--;
              },1000)
              this.showTimer = true;
            }
            else{
              this.$message.error(res.data.message);
            }
          })
        }
        else {
          return false
        }
      })
      // },1000)
    },
    //验证验证码
    validateCode(rule, value, callback){
      checkValid({username:this.form.phone,validCode:this.form.validCode}).then((res)=>{
        // console.log(res,'验证码')
        if(res.data.code == 200){
          callback()
        }else{
          callback(new Error('验证码错误！'))
        }
      })
    },
    // 立即体验
    onSubmit() {
      // 表单验证公司、姓名、电话、验证码
      this.$refs.form.validate(valid => {
        if(valid) {
          //表单所有项都去验证一遍
          if(this.isAgree){
            this.registerEnterpriseFreeFun()
          }
          else{
            this.$message.warning('请先阅读并同意递航服务协议和递航隐私政策');
          }
        }
        else {
          return false;
        }
      });
    },
    // 注册成功弹窗关闭
    cancel() {
      this.visible = false;
      this.form = {
        companyName: undefined, // 公司名称
        name: undefined, // 姓名
        phone: undefined, // 电话
        validCode: undefined, // 验证码
        registerCode: undefined, // 注册码
      };
    },
    // 提交注册
    async registerEnterpriseFreeFun() {
      let obj = {
        companyName: this.form.companyName,
        name: this.form.name,
        registerCode: this.form.registerCode,
        validCode: this.form.validCode,
        mobile: this.form.phone
      }
      let { data } = await registerEnterpriseFree(obj)
      if (data.code === 200) {
        this.visible = true
      } else {
        // this.$message.config({content:data.message,duration: 2, top: `100px`, maxCount: 3});
        this.$message.error(data.message)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.enterprise-free-wrap{
  /deep/ .ant-form-item{
    margin-bottom: 24px;
  }
  /deep/ .ant-form-explain{
    font-size: 12px!important;
  }
  padding: 32px;
  .top-text{
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #1B1C33;
    margin-bottom: 30px;
  }
  .get-code-wrap{
    position: relative;
    margin-bottom: 32px!important;
    .hit-code-wrap{
      position: absolute;
      right: 10px;
      top: -10px;
    }
  }
  .register-wrap{
    margin-top: 32px;
    .register-customer-service{
      margin-bottom: 16px!important;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
      span{
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        color: #1B1C33;
      }
    }
    .register-code-wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      img{
        width: 120px;
        height: 120px;
        margin: 0px 0 32px;
      }
    }
  }
  .submit-check{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .agree {
    font-size: 14px;
    margin-left: 9px;
  }
  .agree > span {
    color: #0055FF;
    cursor: pointer;
  }
  .enterprise-free-btn{
    margin-top: 16px;
    padding: 8px 24px;
    width: 311px;
    height: 40px;
    background: #FF812D;
    border-radius: 51.0404px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
  }
}
.register-qr-input{
  width: 311px!important;
}
</style>
