<template>
  <a-modal
      :closable="false"
      :footer="false"
      :visible="isVisible"
      @cancel="cancel"
      dialogClass="mo-success-modal"
  >
    <div class="success-content">
      <template v-if="type === 'order'">
        <div class="success-text">预约成功</div>
        <div class="success-submit">您已成功提交信息！</div>
        <div class="success-tip">将由专人致电与您沟通，请留意来电。</div>
        <a-button type="primary" class="success-btn" @click="toHome">返回首页</a-button>
      </template>
      <template v-else-if="type === 'register'">
        <div class="success-text marginB24">注册成功</div>
        <div>
          <div class="success-info-text">【账号】：{{phone}}</div>
          <div class="success-info-text">【密码】：123123</div>
          <div class="success-info-text">【登录网址】：https://h.dhunting.com</div>
        </div>
        <div class="success-chrome">建议使用Chrome浏览器登录</div>
<!--        <div class="success-btn-wrap">-->
<!--          <a-button class="success-btn-left" @click="cancel">稍后登录</a-button>-->
<!--          <a-button type="primary" class="success-btn-right" @click="toSystem">立即登录</a-button>-->
<!--        </div>-->
        <a-button type="primary" class="success-btn" @click="toHome">返回首页</a-button>
      </template>
    </div>
  </a-modal>
</template>

<script>
export default {
  props:{
    isVisible: {
      type: Boolean,
      default: false,
    },
    // type: order预约成功 register注册成功
    type: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: ""
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // 关闭弹窗
    cancel() {
      this.$emit('cancel')
    },
    // 跳转系统
    toSystem() {
      window.open('https://h.dhunting.com')
    },
    toHome(){
      if(this.$route.path!='/home'){
        this.$router.push('/home')
      }
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less" scoped>

.success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 300px;
  //height: 222px;
  background: url("//file.dhunting.com/official_website/successModelBg.png") no-repeat;
  background-size: 100% 100%;
  //padding-bottom: 0;
}
.success-text {
  color: #0055FF;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px!important;
}
.success-submit {
  color: #1B1C33;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16x;
}
.success-tip {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 16px;
}
/deep/ .ant-btn-primary,
.success-btn {
  width: 220px;
  height: 36px;
  background: #0055FF;
  border-radius: 18px !important;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
}
.success-info-text {
  color: #1B1C33;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}
.success-chrome {
  color: #8D8E99;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: left!important;
  width: 100%;
  padding-left: 27px;
}

//.success-btn-left,
//.success-btn-right {
//  width: 180px;
//  height: 48px;
//  border-radius: 8px;
//  font-size: 18px;
//  font-weight: 500;
//}
//.success-btn-right {
//  margin-left: 78px;
//}
.marginB24 {
  margin-bottom: 24px;
}
/deep/ .mo-success-modal{
  width: 300px!important;
  //height: 222px!important;
  border-radius: 12px;
  padding: 0;
  .ant-modal-content{
    width: 300px!important;
    //height: 222px!important;
    border-radius: 12px;
    padding: 0;
    .ant-modal-body{
      width: 300px!important;
      //height: 222px!important;
      border-radius: 12px;
      padding: 0;
    }
  }
}
</style>
