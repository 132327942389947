<template>
  <div>
    <div class="mo-article-detail">
      <div class="title">{{articleDetail.title}}</div>
      <div class="time"><iconpark-icon name="time" style="margin-right: 10px"></iconpark-icon>{{articleDetail.updateTime}}</div>
      <div class="banner">
        <img :src="articleDetail.banner" alt="">
      </div>
      <div class="line"></div>
      <div style="font-size: 14px;" v-html="articleDetail.content"></div>
    </div>
  </div>
</template>
<script>
import {getArticleDetail} from "@/api/request";
export default {
  name: 'moArticleDetail',
  components: {

  },
  data() {
    return {
      articleDetail:{},
      owArticleId:''
    };
  },
  mounted() {
    this.owArticleId=this.$route.query.owArticleId
    this.getArticleDetail()
  },
  methods:{
    getArticleDetail(){
      getArticleDetail(
          {
            owArticleId:this.owArticleId
          }
      ).then(res=>{
        if(res.data.code===200){
          this.articleDetail=res.data.data
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
  }
}
</script>
<style scoped lang="less">
.mo-article-detail{
  padding: 32px;
  .title{
    font-weight: 500;
    font-size: 24px;
    color: #1B1C33;
    margin: 0px 0 12px 0;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
    overflow: hidden;
  }
  .time{
    font-weight: 400;
    font-size: 12px;
    color: #8D8E99;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }
  .banner{
    height: 167px;
    img{
      width: auto;
      border-radius: 8px;
    }
  }
  .line{
    width: 311px;
    height: 1px;
    background: #EDEDF0;
    margin: 24px 0;
    border: none;
  }
}
</style>
<style>
/*手机版文章详情所有图片圆角设为8*/
.mo-article-detail img{
  border-radius: 8px;
}
</style>