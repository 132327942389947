<template>
  <div>
    <div class="mo-hr-system-banner">
      <div class="title banner-title">让<span class="banner-title-blue">HR</span>获取</div>
      <div class="title banner-title">更多优质简历的</div>
      <div class="title banner-title">Al招聘助手+智能化招聘管理工具</div>
      <div class="info banner-info">不止是招聘管理系统，更是打开了新的招聘渠道</div>
      <div class="img">
        <img src="//file.dhunting.com/official_website/v1/hr-banner.png" alt="">
      </div>
      <div class="btn flex-enter">
        <a-button @click="toHrSystem" class="banner-btn" type="primary">立即体验</a-button>
      </div>
    </div>
    <div class="mo-hr-system-whychoose">
      <div class="title1 item-title">为什么选择</div>
      <div class="title2 item-title item-title-blue">递航智能化招聘管理工具</div>
      <div class="whychoose-item flex-col-enter">
        <div class="title-wrap">
          <div class="icon flex-enter">
            <iconpark-icon name="why-choose-1"></iconpark-icon>
          </div>
          <div class="title flex-enter">资产管理</div>
        </div>
        <div class="info">
          企业自建人才库，多渠道简历快速导入自有人才库，既能提前对比避免重复下载，浪费成本，又能免费更新人才库。帮助企业快速沉淀人才资产及招聘过程数据，让招聘数据成为企业重要数字资产。
        </div>
        <div class="line"></div>
      </div>
      <div class="whychoose-item flex-col-enter">
        <div class="title-wrap">
          <div class="icon flex-enter">
            <iconpark-icon name="why-choose-2"></iconpark-icon>
          </div>
          <div class="title flex-enter">协同提效</div>
        </div>
        <div class="info">
          沉浸式流程管理，与用人经理/面试官、供应商0时差协同，打通PC和移动间的各端口，真正实现招聘者、人选、面试官多触点的高效协同。通过递航速聘社交裂变、猎头联盟助力交付，帮助企业获取更多优质简历。
        </div>
        <div class="line"></div>
      </div>
      <div class="whychoose-item flex-col-enter">
        <div class="title-wrap">
          <div class="icon flex-enter">
            <iconpark-icon name="why-choose-5"></iconpark-icon>
          </div>
          <div class="title flex-enter">对外开源</div>
        </div>
        <div class="info">
          递航速聘快速扩散企业招聘信息，高效整合员工的二度、三度人脉，内推外扩，扩大简历来源，降低简历成本。通过猎头联盟获取更多专业猎头的优质简历推荐，在现有招聘渠道外，有效补充简历来源。
        </div>
        <div class="line"></div>
      </div>
      <div class="whychoose-item flex-col-enter">
        <div class="title-wrap">
          <div class="icon icon-img flex-enter">
            <img src="//file.dhunting.com/official_website/v1/why-choose-4.png"/>
          </div>
          <div class="title flex-enter">数据洞察</div>
        </div>
        <div class="info">
          富的招聘数据分析维度：招聘失败分析、招聘效率分析、反馈效率分析、渠道分析等，实时查看招聘过程中的每一项关键数据，及时察觉工作能力的优劣势。
        </div>
        <div class="line"></div>
      </div>
    </div>
    <div class="mo-hr-system-talent">
      <div class="talent-title item-title item-title-blue">人才库建设</div>
      <div class="item-title">建立企业私有人才库</div>
      <div class="talent-tip item-title">大幅度节约简历获取成本</div>
      <div class="talent-info banner-info">标准化、智能化简历库建立，多维度条件设置，高效搜索符合条件的简历，简洁的可视化搜索界面，易于HR上手，符合用户操作习惯。</div>
      <div class="talent-img">
        <img src="//file.dhunting.com/official_website/v1/talent.png" alt="">
      </div>
    </div>
    <div class="mo-hr-system-open-source">
      <div class="open-title flex-enter item-title item-title-blue">
        开源
      </div>
      <div class="open-tip item-title flex-enter">对外开源的人才推荐系统</div>
      <div class="open-info banner-info">
        独家的社交招聘小程序-递航速聘，可快速扩散企业招聘信息高效整合员工的二度、三度人脉，扩大简历来源。
        <div class="space"></div>
        独家的猎头联盟链，可获取更多专业猎头的优质简历推荐，在现有招聘渠道外，有效补充简历来源。
        <div class="space"></div>
        智能化推荐算法及人才获取路径追踪技术，降低职位筛选及扩散成本。
      </div>
      <div class="open-img">
        <img src="//file.dhunting.com/official_website/v1/mo-open-source.png" alt="">
      </div>
    </div>
    <div class="mo-hr-system-up">
      <div class="up-title item-title-blue item-title flex-enter">提效</div>
      <div class="up-tip item-title flex-enter">对内提效的人才管理系统</div>
      <div class="up-info banner-info">
        高效协同：超出所有其他招聘系统的流程细分及用户体验，打通PC和移动间的各端口，真正实现招聘者、人选、面试官多触点的高效协同。
        <div class="space"></div>
        深度赋能: 独创的CC话术、职位访谈模板、任务目标及待办管理体系，融入了先进的赋能型管理理念让没有任何经验的用户也能做好招聘绩效管理。
        <div class="space"></div>
        AI自动化: 企业简历自动化解析入库、智能画像分析、自动设置待办、发送面试提醒，将用户从反复的事务性工作中解脱出来大大提升了招聘效率。
      </div>
      <div class="up-img">
        <img src="//file.dhunting.com/official_website/v1/up-left.png" alt="">
      </div>
    </div>
    <div class="mo-hr-system-data-look">
      <div class="data-look-title item-title-blue item-title">数据洞察</div>
      <div class="data-look-tip item-title">为提高招聘效率提供科学的数字依据</div>
      <div class="data-look-info banner-info">
        实时查看招聘过程中的每一项关键数据，及时察觉工作能力的优劣势，更好地扬长避短。
        <div class="space"></div>
        推荐量、面试量、推荐/面试反馈效率、沟通记录、电话录音等招聘数据全部留痕，便于针对每个HR、用人经理实现全时段复盘，具体问题具体分析。
        <div class="space"></div>
        针对职位、人选、渠道等全方位的数据洞察，便于分析出最优的招聘及用人策略，比如职位要求、薪酬待遇合理性、各渠道ROI等。
      </div>
      <div class="data-look-img">
        <img src="//file.dhunting.com/official_website/v1/data-look.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'moHrSystem',
  components: {

  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods:{
    toHrSystem(){
      this.$router.push('/buyHrSystem')
    }
  }
}
</script>
<style scoped lang="less">
.mo-hr-system-banner{
  padding: 32px;
  .banner-info{
    margin: 12px 0 32px 0;
  }
  .btn{
    margin-top: 32px;
  }
}
.space{
  height: 20px;
}
.mo-hr-system-whychoose{
  padding: 32px;
  .title2{
    margin-bottom: 34px;
  }
  .whychoose-item{
    .title-wrap{
      display: flex;
      .icon{
        width: 24px;
        flex-shrink: 0;
        font-size: 24px;
      }
      .icon-img{
        width: 24px!important;
        height: 24px!important;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title{
        margin-left: 9px;
        font-weight: 500;
        font-size: 14px;
        color: #1B1C33;
      }
    }
    .info{
      margin-top: 18px;
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 12px;
      color: #5E5E66;
      text-align: center;
    }
    .line{
      //margin-top: 18px;
      width: 100px;
      height: 2px;
      background: #0055FF;
      border-radius: 2px;
      border: none;
      margin-bottom: 32px!important;
    }
  }
}
.mo-hr-system-talent{
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F9FAFF;
  .talent-tip{
    margin: 0 0 24px 0;
  }
  .talent-info{
    margin-bottom: 24px;
  }
  .talent-img{
    width: 100%;
    height: 182px;
  }
}
.mo-hr-system-open-source{
  padding: 32px;
  .open-tip{
    margin: 8px 0 24px 0;
  }
  .open-info{
    margin-bottom: 24px;
  }
  .open-img{
    width: 100%;
    height: 182px;
  }
}
.mo-hr-system-up{
  padding: 32px;
  background: #F9FAFF;
  .up-tip{
    margin: 8px 0 24px 0;
  }
  .up-info{
    margin-bottom: 24px;
  }
  .up-img{
    width: 100%;
    height: 223px;
  }
}
.mo-hr-system-data-look{
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .data-look-tip{
    margin: 0 0 24px 0;
  }
  .data-look-info{
    margin-bottom: 24px;
  }
  .data-look-img{
    width: 100%;
    height: 223px;
  }
}

</style>