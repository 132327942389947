<template>
  <div class="login-wrap center">
    <div class="login">
      <div class="login-logo"><img src="//file.dhunting.com/official_website/v1/head-logo.png" alt=""></div>
      <div class="logo-content center">
        <div class="login-title">官网管理系统</div>
        <div class="login-table">
          <a-form-model
              :model="form"
              :rules="rules"
              ref="ruleForm"
          >
          <div class="login-table-title">密码登录</div>
          <a-form-model-item>
            <a-input v-model="form.user" placeholder="请输入登录账号">
            </a-input>
          </a-form-model-item>
          <a-form-model-item class="input-pass">
            <a-input-password v-model="form.password" placeholder="请输入密码" />
          </a-form-model-item>
          <a-form-model-item>
            <slide-code
                :successFun="handleSuccessFun"
                :errorFun="handleErrorFun"
            ></slide-code>
          </a-form-model-item>
          <a-checkbox  v-model="isRemember" class="recordPass">
            记住密码
          </a-checkbox>
          <a-button class="login-btn" type="primary" html-type="submit" @click="handleSubmit">
            登录
          </a-button>
          </a-form-model>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slideCode from "@/components/slideCode";
export default {
  components: {
    slideCode
  },
  data() {
    return {
      form: {

      },
      allowLogin:false,
      rules: {
        user:[
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入您的密码", trigger: "blur" },
        ],
      },
      isRemember:false
    }
  },
  mounted(){
    this.form=JSON.parse(localStorage.getItem('userAndPass'))||{};
  },
  methods:{
    // 滑块验证成功回调
    handleSuccessFun() {
      this.allowLogin = true;
    },
    // 滑块验证失败回调
    handleErrorFun() {

    },
    onChange(){

    },
    handleSubmit(){
      if(this.allowLogin) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if(this.form.user==="123456"&&this.form.password==="123456"){
              this.$message.success("登录成功！");
              if(this.isRemember){
                localStorage.setItem("userAndPass",JSON.stringify(this.form));
              }
              this.$router.push('/manage')
            }
            else {
              this.$message.error("密码错误！");
            }
          } else {
            return false;
          }
        });
      }
      else {
        this.$message.warning("请先进行滑块验证");
      }

    }
  }
}

</script>

<style scoped>
  .login-wrap{
   width: 100%;
    height: 100%;
    background-image: url("//file.dhunting.com/official_website/v1/login-bg.png");
    background-size: 100% 100%;
    position: relative;
  }
  .login{
    max-width: 1440px;
  }
  .login-logo{
    position: absolute;
    top: 25px;
    left: 29px;
    width: 159px;
    height: 30px;
  }
  .center{
    flex-direction: column;
  }
  .login-title{
    width: 192px;
    height: 45px;
    font-weight: 600;
    font-size: 32px;
    color: #000000;
    margin-bottom: 40px;
  }
  .login-table{
    padding: 60px 80px;
    width: 460px;
    height: 464px;
    background: #FFFFFF;
    box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
  }
  .login-table-title{
    width: 112px;
    height: 28px;
    font-weight: 500;
    font-size: 28px;
    color: #1B1C33;
    margin-bottom: 40px;
  }
  .input-pass
  {
    margin: 24px 0;
  }
  .recordPass{
    margin-top: 0;
    margin-bottom: 24px;
  }
  .login-btn{
    width: 300px;
    height: 40px;
    background: #264AFF;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
  }


</style>
