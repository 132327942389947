<template>
  <div>
    <div class="dhunting-wrap">
      <div class="dhunting">
        <div class="dhunting-title">Dhunting递航</div>
        <div class="dhunting-info">Dhunting递航是一家人力资源科技公司，我们为尊重人才、善用人才的企业提供智能化的一站式招聘管理系统。2019年5月，Dhunting递航正式成立。目前在上海、北京、香港、北美均设有专业的服务团队，平均从业经验超过10年，服务精英人才超过200000名。分别涵盖科技、金融、零售、医疗、地产等领域；2019年10月，开始搭建产品、研发、运营团队。核心成员分别来自于猎聘、阿里巴巴、领英等知名互联网公司；</div>
      </div>
    </div>
    <div class="phone-wrap">
      <div class="phone small-hand" v-if="!isPlay" @click="handlePlay">
        <img src="//file.dhunting.com/official_website/v1/aboutUsCover.png" alt="">
      </div>
      <a-modal
          v-model="isPlay"
          centered
          :footer="null"
          :closable="false"
          @cancel="handleStop"
          wrapClassName="pc-home-video-wrap"
          dialogClass="pc-home-video"
      >
        <div class="video-wrap">
          <!--//file.dhunting.com/official_website/v1/Dhunting.mp4              <a-icon type="close-circle" @click="handleStop"/>-->
          <video src="//file.dhunting.com/official_website/v1/aboutUs.mp4" id="aboutUsVideo" controls autoplay></video>
        </div>
        <div class="close-video small-hand" @click="handleStop"><a-icon type="close" /></div>
      </a-modal>
    </div>
    <div class="target-and-hope-wrap">
      <div class="target-and-hope">
        <div class="target-and-hope-item">
          <div class="targer-and-hope-title wow backInUp">使命</div>
          <div class="targer-and-hope-slogan wow backInUp">让金子早点发光</div>
          <div class="targer-and-hope-info wow bounceInLeft">
            让金子早点发光我们为尊重人才、善用人才的企业，提供智能化招聘管理系统及一站式招聘解决方案。致力于通过卓越的服务、一流的产品，为企业整合人才数据、打通招聘渠道，优化招聘流程，提高招聘效率。让金子早点发光！
          </div>
        </div>
        <div class="target-and-hope-item">
          <div class="targer-and-hope-title wow backInUp">愿景</div>
          <div class="targer-and-hope-slogan wow backInUp">多做一步，成为用户最信任的合作伙伴！</div>
          <div class="targer-and-hope-info wow bounceInRight">
            成为用户最信任的合作伙伴，就是通过递航人为客户多做一步，帮助用户成为更好的自己，获得更好的结果。多做一步是优先替用户想到业务解决方案，并在系统上实现。多做一步是高效为用户解决使用疑问，让用户学习系统时间成本最低，价值产出最大化。多做一步是陪伴用户快速成长，递航会持续邀请猎头大咖、知名企业HR分享，让猎企的每一个角色都能快速成长，学习先进经验。
          </div>
        </div>
      </div>
    </div>
    <div class="core-member-wrap">
      <div class="core-member">
        <div class="next-icon" @click="nextSwiper">
          <a-icon type="right" />
        </div>
        <div class="core-member-content">
          <div class="core-member-title">核心团队</div>
          <!--        <div class="core-member-people lpl-list" ref="lplListBox">-->
          <!--          <ul class="lpl-ul flex" ref="lplList">-->
          <!--            <li class="core-member-item-list lpll-item" v-for="(item, index) in coreStuffList" :key="index">-->
          <!--              <div class="core-member-item-img wow backInUp">-->
          <!--                <img :src="item.img" alt="">-->
          <!--              </div>-->
          <!--              <div class="core-member-item-name wow backInUp">{{item.name}}</div>-->
          <!--              <div class="core-member-item-info wow backInUp">{{item.info}}</div>-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--          <div class="custom-slick-arrow" style="right: 73px; top: 35%; zIndex: 99">-->
          <!--            <div style="width: 100%;height: 100%" @click.stop="next(coreStuffList)">-->
          <!--              <a-icon type="right"/>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
          <div class="new-core-member-people">
            <a-carousel arrows ref="corePeopleSwiper">
<!--              <div-->
<!--                  slot="prevArrow"-->
<!--                  class="custom-slick-arrow"-->
<!--                  style="left: 10px;zIndex: 1"-->
<!--              >-->
<!--                <a-icon type="left" />-->
<!--              </div>-->
<!--              <div slot="nextArrow" class="custom-slick-arrow">-->
<!--                <a-icon type="right" />-->
<!--              </div>-->
              <div class="people-item" v-for="(item ,index) in coreStuffList" :key="index">
                <div class="img">
                  <img :src="item.img" alt="">
                </div>
                <div class="name">{{item.name}}</div>
                <div class="info">{{item.info}}</div>
              </div>
            </a-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      count: 0,
      isPlay:false,
      slideIndex:-1,
      coreStuffList: [
        {
          name: 'Alma',
          img: "//file.dhunting.com/official_website/v1/alma.png",
          info: 'Dhunting CEO。12年在线招聘行业经验，经历智联和猎聘从初创到上市的全过程；全盘负责过业务、产品、运营等模块。从0到1地打造了从团队治理、人才培养到产品打造、运营管理等一系列行业经典案例。'
        },
        {
          name: 'Lily',
          img: "//file.dhunting.com/official_website/v1/lily.png",
          info: '战略发展部负责人。十年+在线招聘行业经验，曾任知名上市公司战略大客户经理，服务金融行业标杆企业，擅长为企业提供人力资源创新解决方案以及为招聘团队赋能，多次荣获全国冠军。'
        },
        {
          name: 'Lu Jie',
          img: "//file.dhunting.com/official_website/v1/lujie.png",
          info: 'Dhunting CTO。近10年技术研发及管理经验，曾担任多个知名互联网公司CTO，负责过多个企业服务SAAS项目的从0到1。精通微服务架构/容器/算法/集群/高并发。擅长微服务架构设计及治理，系统重构方案与实施，系统优化、互联网转型和非功能性架构设计等。'
        },
        {
          name: '无名',
          img: "//file.dhunting.com/official_website/v1/wuming.png",
          info: '技术专家。拥有15年互联网行业从业经历，曾作为技术专家就职于阿里巴巴，并担任北京知名研究院科学家顾问，主导项目获国家科技进步二等奖；擅长技术突破和技术攻坚；长期致力于前沿技术研究，在芯片设计、图形视觉、机器学习等多领域拥有多项发明专利。'
        },
      ],
      scrollX: 0,
      prizeTimer: null,
      moveWidth: 0,
    }
  },
  mounted() {
    this.$wow.init()
    this.$bus.$emit("changeTabsIndex", 3);
  },
  methods:{
    handlePlay(){
      this.isPlay=true
      const video=document.getElementById('aboutUsVideo')
      video.play()
    },
    handleStop(){
      this.isPlay=false
      const video=document.getElementById('aboutUsVideo')
      video.pause()
    },
    nextSwiper(){
      this.$refs.corePeopleSwiper.next()
    }
    // next(val){
    //   // 当滚动还在执行时，什么都不做；不滚动状态，即!this.moveWidth，进行以下操作
    //   if(!this.moveWidth) {
    //     // 将定时器清除
    //     clearInterval(this.prizeTimer);
    //     this.prizeTimer = null;
    //     this.$nextTick(()=>{
    //       const tag = this.$refs.lplList.style;
    //       const width = document.getElementsByClassName('lpll-item')[0].offsetWidth;
    //       if (val.length >= 4) {
    //         this.moveWidth = document.querySelector('.core-member-item-list').offsetWidth; // 记录需要移动的距离
    //         this.prizeTimer = setInterval(() => {
    //           // 如果还有距离要移动
    //           if(this.moveWidth > 0) {
    //             // X坐标+1
    //             this.scrollX++;
    //             // 需要移动的距离-1
    //             this.moveWidth--;
    //             for(let i = 0; i < val.length; i++) {
    //               if (this.scrollX === (i * width + width)) {
    //                 let first = val[i];
    //                 this.coreStuffList.push(first);
    //                 this.coreStuffList.shift();
    //                 this.scrollX = 0;
    //               }
    //             }
    //             tag.transform = `translateX(-${this.scrollX}px)`;
    //           }
    //           else {
    //             clearInterval(this.prizeTimer);
    //             this.prizeTimer = null;
    //             this.moveWidth = 0;
    //             console.log(this.prizeTimer)
    //           }
    //         }, 1)
    //       }
    //     })
    //   }
    // },
  }
}
</script>
<style lang="less" scoped>
.core-member{
  position: relative;
  .next-icon{
    position: absolute;
    right: 10px;
    cursor: pointer;
    transform: translateY(-50%);
    top: 314px;
    z-index: 10;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    font-size: 24px;
    background: #FFFFFF;
    color: grey;
    box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      z-index: 99;
      position: relative;
    }
  }
}
.new-core-member-people{
  width: 420px!important;
  box-sizing: border-box;
  .people-item{
    img{
      width: 360px;
      height: 360px;
      border-radius: 8px;
    }
    .name{
      font-weight: 500;
      font-size: 20px;
      color: #1B1C33;
      margin: 35px 0 15px 0;
    }
    .info{
      font-weight: 500;
      font-size: 14px;
      color: #5E5E66;
      line-height: 30px;
    }
  }
  /deep/ .slick-list{
    overflow: visible!important;
  }
  /deep/ .slick-track{
    display: flex;
    flex-wrap: nowrap;
  }
  /deep/ .slick-slide {
    width: 420px!important;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 30px;
  }
  ///deep/ .custom-slick-arrow:before {
  //  display: none;
  //}
  ///deep/ .custom-slick-arrow {
  //  right: -840px;
  //  width: 64px;
  //  height: 64px;
  //  display: flex!important;
  //  align-items: center;
  //  justify-content: center;
  //  border-radius: 50%;
  //  font-size: 35px;
  //  background: #FFFFFF;
  //  color: grey;
  //  box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.25);
  //  i{
  //    z-index: 99;
  //    position: relative;
  //  }
  //}
}
.small-hand{
  cursor: pointer;
}
  .dhunting-wrap,
  /*.phone-wrap,*/
  .target-and-hope-wrap,
  .core-member-wrap
  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dhunting-wrap{
    background-image: url("//file.dhunting.com/official_website/v1/dhunting-bg.png");
    background-size:100% 100%;
  }
  .dhunting,
  .target-and-hope,
  .core-member
  {
    width: 100%;
    max-width: 1440Px;
  }

  .dhunting{
    width: 100%;
    padding: 68px 300px 200px 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 700px;
  }
  .dhunting-title{
    font-weight: 600;
    font-size: 48px;
    color: #000000;
    margin-bottom: 32px;
    margin-top: 60px;
  }
  .dhunting-info{
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    color: #5E5E66;
    text-align: center;
  }
  .phone{
    width: 700px;
    height: 408px;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
  }
  .phone > img{
    width: 100%;
    height: 100%;
  }
  .target-and-hope{
    padding: 263px 115px 40px 115px;
    display: flex;
    justify-content: space-between;
  }
  .target-and-hope-item{
    width: 518px;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
  }
  .targer-and-hope-title{
    font-weight: 600;
    font-size: 48px;
    line-height: 70px;
    color: #000000;
  }
  .targer-and-hope-slogan{
    margin-top: 16px;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: #1B1C33;
  }
  .targer-and-hope-info{
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    color: #5E5E66;
    text-align: center;
  }
  .marginR180{
    margin-right: 180px;
  }
  .core-member{
    position: relative;
    padding: 40px 99px 76px 104px;
  }

  .core-member-content{
    overflow: hidden;
  }
  .core-member-title{
    font-weight: 500;
    font-size: 36px;
    color: #000000;
    margin-bottom: 40px;
  }
  //.core-member-people{
  //  width: 100%;
  //  overflow: hidden;
  //}
  //.core-member-item{
  //  width: 100%;
  //  display: flex !important;
  //  justify-content: space-between;
  //}
  //.core-member-item-list {
  //  padding: 0 60px;
  //}
  //.core-member-item-img{
  //  width: 300px;
  //  height: 300px;
  //}
  //.core-member-item-img > img{
  //  width: 100%;
  //  height: 100%;
  //}
  //.core-member-item-name{
  //  margin-top: 35px;
  //  margin-bottom: 15px;
  //  font-weight: 500;
  //  font-size: 20px;
  //  color: #1B1C33;
  //}
  //.core-member-item-info{
  //  font-weight: 500;
  //  font-size: 14px;
  //  line-height: 30px;
  //  width: 300px;
  //  color: #5E5E66;
  //}
  ///deep/ .custom-slick-arrow {
  //  position: absolute;
  //  top: 30%;
  //  width: 64px;
  //  height: 64px;
  //  border-radius: 50%;
  //  font-size: 20px;
  //  background: #FFFFFF;
  //  box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.25);
  //  color: #0055FF;
  //  line-height: 64px;
  //  text-align: center;
  //  cursor: pointer;
  //}
  ///deep/ .custom-slick-arrow:before {
  //  display: none;
  //}
  //.lucky-prize-list {
  //  border-radius: 6px;
  //  overflow: hidden;
  //  .lpl-title{
  //    padding: 0.26rem 0;
  //    font-size: 0.75rem;
  //    color:#fff;
  //    line-height: 1.19rem;
  //    text-align: center;
  //    text-shadow:0px 0px 0px rgba(210,63,7,0.53);
  //    background:linear-gradient(232deg,rgba(255,224,57,1) 0%,rgba(251,57,105,1) 100%);
  //  }
  //}
  //.lpl-list{
  //  box-sizing: border-box;
  //  width: 100%;
  //  padding: 40px 0;
  //  background: rgba(255, 255, 255, 0.35);
  //}
  //.lpl-ul{
  //  display: flex;
  //  box-sizing: border-box;
  //}
  //.lpll-item-child{
  //  flex-shrink: 0;
  //  margin-left: 0.34rem;
  //  width: 4rem;
  //  background: #fff;
  //  border-radius: 6px;
  //  overflow: hidden;
  //}

  .video-wrap video{
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
  .close-video{
    width: 32px;
    height: 32px;
    background: #0055FF;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
    position: absolute;
    right: -32px;
    top: 0;
    cursor: pointer;
  }
</style>
