import { render, staticRenderFns } from "./comHrSystem.vue?vue&type=template&id=d6c0b178&scoped=true"
import script from "./comHrSystem.vue?vue&type=script&lang=js"
export * from "./comHrSystem.vue?vue&type=script&lang=js"
import style0 from "./comHrSystem.vue?vue&type=style&index=0&id=d6c0b178&prod&lang=less&scoped=true"
import style1 from "./comHrSystem.vue?vue&type=style&index=1&id=d6c0b178&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6c0b178",
  null
  
)

export default component.exports