<template>
  <div>
    <div class="quickUseWrap-wrap">
      <div class="quickUseWrap">
        <div class="quickUse ">
          <div class="quickUse-title">让业绩翻倍的</div>
          <div class="quickUse-title">智能化招聘业务管理系统</div>
          <div class="quickUse-info">递航系统以协同和赋能为核心功能、提高交付效率为核心目的，帮助猎企倍增招聘生产力，提高人均单产。适用于猎头、RPO、人力资源外包企业。</div>
          <a-button type="primary" class="quickUse-btn" @click="toHunterSystem">立即体验</a-button>
        </div>
        <div class="quickUseImg">
          <img src="//file.dhunting.com/official_website/v1/quick-use.png" alt="">
        </div>
      </div>
    </div>
    <div class="why-choose-wrap-wrap">
      <div class="why-choose-wrap">
        <div class="why-choose">
          <div class="why-choose-tittle wow bounceInUp">为什么选择 <span class="blueFont">递航招聘业务管理系统</span></div>
          <div class="why-choose-content">
            <div class="why-choose-content-item wow bounceInUp">
              <div class="icon-title">
                <span class="why-choose-content-item-icon">
                  <iconpark-icon name="why-choose-1"></iconpark-icon>
                </span>
                <span class="choose-item-title">资产管理</span>
              </div>
              <div class="choose-item-info">系统会记录所有的录入信息和行为数据，包含客户、职位、人才（含沟通记录、评价反馈）、合同、发票信息等资产留存，保障了整个业务在运营时候的数据安全以及招聘效率最大化。</div>
            </div>
            <div class="why-choose-content-item wow bounceInUp">
              <div class="icon-title">
                <span class="why-choose-content-item-icon">
                  <iconpark-icon name="why-choose-2"></iconpark-icon>
                </span>
                <span class="choose-item-title">业务提效</span>
              </div>
              <div class="choose-item-info">通过协同与赋能进行业务提效，系统融入了先进的赋能型管理理念、成熟的业务协同框架、丰富的学习园区，真正做到每一个业务环节效率最大化。</div>
            </div>
            <div class="why-choose-content-item wow bounceInUp">
              <div class="icon-title">
                <span class="why-choose-content-item-icon"><iconpark-icon name="why-choose-3"></iconpark-icon></span>
                <span class="choose-item-title">业绩预测</span>
              </div>
              <div class="choose-item-info">独家的订单管理系统赋能业绩管理，通过业绩管理进行现金流预测，帮助企业及顾问精准预测业绩走向，为管理者提供科学的决策依据。</div>
            </div>
            <div class="why-choose-content-item wow bounceInUp">
              <div class="icon-title">
                <img src="//file.dhunting.com/official_website/v1/why-choose-4.png"/>
                <span class="choose-item-title">数据洞察</span>
              </div>
              <div class="choose-item-info">通过多维度的数据分析，全面洞察企业业务数据，赋予管理者看懂数据的能力，用更加科学的方式进行管理和决策。顾问可根据横向、纵向对比数据，实时了解自己的数据动向。</div>
            </div>
          </div>
        </div>
        <div class="why-choose-img">
          <img src="//file.dhunting.com/official_website/v1/hunter-why-choose.png" alt="">
        </div>
      </div>
    </div>
    <div class="message-wrap-wrap">
      <div class="message-wrap">
        <!--      <img src="//file.dhunting.com/official_website/v1/message.png" alt="">-->
        <div class="message-img wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/message-record.png" alt=""></div>
        <div class="message-member wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/member.png" alt=""></div>
        <div class="message-change wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/message-choose.png" alt=""></div>
        <div class="message-todo wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/message-todo.png" alt=""></div>
        <div class="message-noproblem wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/message-noproblem.png" alt=""></div>
        <div class="message">
          <div class="message-title wow bounceInUp">独家IM即时通讯</div>
          <div class="message-tip wow bounceInUp">@成员，消息一触即达！</div>
          <div class="message-info wow bounceInUp">业内独家的IM即时通讯技术，让顾问、PM、Leader高效完成在线推荐、审核、面试、入职安排等工作流。并可随时在沟通记录中@团队成员，信息即时弹出，沟通0时差。
          </div>
        </div>
      </div>
    </div>
    <div class="resume-wrap-wrap">
      <div class="resume-wrap">
        <!--      <img src="//file.dhunting.com/official_website/v1/resume.png" alt="">-->
        <div class="resume-left">
          <div class="resume-dialog wow fadeInLeft"><img src="//file.dhunting.com/official_website/v1/resume-noUse.png" alt=""></div>
          <div class="resume-title wow fadeInRightBig">激活私域简历库</div>
          <div class="resume-tip wow fadeInRightBig">简历智能解析，一键导入自有人才库</div>
          <div class="resume-info wow fadeInRightBig">各类简历均可一键上传，智能解析后标准入库。一键导入自有人才库，既能提前对比避免重复下载，浪费成本，又能免费更新人才库。</div>
        </div>
        <div class="resume-right">
          <img src="//file.dhunting.com/official_website/v1/resume-img.png" alt="">
        </div>
      </div>
    </div>
    <div class="changeSpeak-wrap-wrap">
      <div class="changeSpeak-wrap">
        <!--      <img src="//file.dhunting.com/official_website/v1/changeSpeak.png" alt="">-->
        <div class="changeSpeak-left wow fadeInLeftBig">
          <img src="//file.dhunting.com/official_website/v1/phone-img.png" alt="">
        </div>
        <div class="changeSpeak-right">
          <div class="changeSpeak-dialog wow fadeInLeftBig">
            <img src="//file.dhunting.com/official_website/v1/phone-dislog.png" alt="">
          </div>
          <div class="changeSpeak-title wow fadeInRightBig">在线矫正话术</div>
          <div class="changeSpeak-tip wow fadeInRightBig">帮助新人猎头成长</div>
          <div class="changeSpeak-info wow fadeInRightBig">一键呼叫、自动录音、话术打分，递航让没有管理经验pm或小白顾问也可以直接上手，提高单产。</div>
        </div>
      </div>
    </div>
    <div class="work-wrap-wrap">
      <div class="work-wrap">
        <!--      <img src="//file.dhunting.com/official_website/v1/work.png" alt="">-->
        <div class="work-left">
          <div class="work-dialog wow fadeInLeft"><img src="//file.dhunting.com/official_website/v1/work-dialog.png" alt=""></div>
          <div class="work-title wow fadeInRightBig">全链路订单系统</div>
          <div class="work-tip wow fadeInRightBig">业内首创的全链路业务管理功能</div>
          <div class="work-info wow fadeInRightBig">不仅作为招聘管理系统，Dhunting独家特有的业务管理功能。一键提交，即可在线生成合同与发票；自定义审批流配置权限，适应不同的业务场景；合同-发票-回款全链路追踪技术，让你轻松实现业务的管理闭环。</div>
        </div>
        <div class="work-right">
          <img src="//file.dhunting.com/official_website/v1/work-img.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      count: 0
    }
  },
  mounted() {
    this.$wow.init()
    this.$bus.$emit("changeTabsIndex", 1);
  },
  methods: {
    // 跳转猎企标准版
    toHunterSystem() {
      let routerUrl = this.$router.resolve({path: '/productContact', query: { systemType: 1 }});
      window.open(routerUrl.href, '_blank');
    },
  }
}
</script>
<style lang="less" scoped>
  .quickUseWrap-wrap,
  .why-choose-wrap-wrap,
  .message-wrap-wrap,
  .resume-wrap-wrap,
  .changeSpeak-wrap-wrap,
  .work-wrap-wrap
  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message-wrap-wrap,
  .changeSpeak-wrap-wrap
  {
    background-image: url("//file.dhunting.com/official_website/v1/message-bg.png");
    background-size: 100% 100%;
  }
  .resume-wrap-wrap,
  .work-wrap-wrap
  {
    background-image: url("//file.dhunting.com/official_website/v1/resume-bg.png");
    background-size: 100% 100%;
  }
  .quickUseWrap,
  .why-choose-wrap,
  .message-wrap,
  .resume-wrap,
  .changeSpeak-wrap,
  .work-wrap
  {
    width: 100%;
    max-width: 1440Px;
  }

  .blueFont{
    color: rgba(0, 85, 255, 1);
  }
  .quickUseWrap{
    display: flex;
  }
  .quickUse{
    flex: 1;
    padding: 120px 0px 80px 80px;
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    flex-direction: column;
    /*box-sizing: border-box;*/
  }
  .quickUse-title{
    font-weight: 600;
    font-size: 48px;
    line-height: 80px;
    color: #1B1C33;
  }
  .blue{
    color: #0055FF;
  }
  .quickUse-info{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #5E5E66;
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .quickUse-btn{
    width: 300px;
    height: 56px !important;
    background: #0055FF;
    border-radius: 8px!important;
    font-weight: 400;
    font-size: 20px !important;
    line-height: 56px;
    color: #FFFFFF;
  }
  .quickUseImg{
    flex: 1.5;
  }
  .quickUseImg > img{
    width: 100%;
    height: 100%;
  }
  .message-wrap{
    position: relative;
    width: 100%;
    height: 710px;
  }
  .message-img{
    position: absolute;
    width: 450px;
    //height: 700px;
    right: 0;
    top: 100px;
  }
  .message-member{
    width: 400px;
    position: absolute;
    left: 3%;
    bottom: 8%;
  }
  .message-change{
    width: 400px;
    position: absolute;
    left: 2%;
    bottom: 30%;
  }
  .message-todo{
    width: 400px;
    position: absolute;
    left: 3%;
    top: 20%;
  }
  .message-noproblem{
    width: 600px;
    position: absolute;
    left: 30%;
    top: 10%;
  }
  .message{
    position: absolute;
    top: 55%;
    left: 48%;
    transform: translate(-50%,-50%);
    width: 400px;
  }
  .message-title{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #0055FF;
  }
  .message-tip{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #1B1C33;
    margin-top: 24px;
    margin-bottom: 32px;
  }
  .message-info{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #5E5E66;
  }
  .resume-wrap{
    display: flex;
    height: 653px;
  }
  .resume-left{
    flex: 1;
    padding: 127px 30px 0px 100px;
  }
  .resume-dialog{
    width: 400px;
    //height: 300px;
    margin-bottom: 48px;
  }
  .resume-title{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #0055FF;
  }
  .resume-tip{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #1B1C33;
    margin-top: 40px;
    margin-bottom: 25px;
  }
  .resume-info{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #5E5E66;
    margin-top: 32px;
  }
  .resume-right{
    flex: 1;
    padding: 40px 0 0 0;
    box-sizing: border-box;
    img{
      height: auto;
    }
  }
  .changeSpeak-wrap{
    display: flex;
    width: 100%;
    //height: 612px;
  }
  .changeSpeak-left{
    flex: 1.5;
    padding: 77px 0 0 80px;
    box-sizing: border-box;
    img{
      height: auto;
    }
  }
  .changeSpeak-right{
    flex: 1;
    padding: 228px 80px 200px 0;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .changeSpeak-dialog{
    position: absolute;
    top: 80px;
    width: 600px;
    right: 200px;
  }
  .changeSpeak-title{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #0055FF;
  }
  .changeSpeak-tip{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #1B1C33;
    margin-top: 24px;
    margin-bottom: 32px;
  }
  .changeSpeak-info{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #5E5E66;
  }
  .work-wrap{
    display: flex;
    width: 100%;
    //height: 800px;
  }
  .work-left{
    flex: 1;
  }
  .work-left{
    padding: 160px 60px 100px 120px;
  }
  .work-title{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #0055FF;
    margin-top: 48px;
    margin-bottom: 24px;
  }
  .work-tip{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #1B1C33;
  }
  .work-info{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #5E5E66;
    margin-top: 32px;
  }

  .work-right{
    flex: 1;
    padding: 40px 120px 40px 130px
  }
  .work-right img{
    height: auto;
  }

  /*.message-wrap,*/
  /*.resume-wrap,*/
  /*.changeSpeak-wrap,*/
  /*.work-wrap*/
  /*{*/
  /*  width: 100%;*/
  /*  height: 700px;*/
  /*}*/
  /*.message-wrap > img,*/
  /*.resume-wrap > img,*/
  /*.changeSpeak-wrap > img,*/
  /*.work-wrap > img*/
  /*{*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*}*/
  .why-choose-wrap{
    padding: 60px 0px 0px 120px;
    display: flex;
    align-items: center;
  }
  .why-choose{
    width: 782px!important;
  }
  .why-choose-tittle{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #1B1C33;
    margin-bottom: 60px!important;
  }
  .why-choose-content{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .why-choose-content-item{
    padding-right: 80px;
    padding-bottom: 48px;
    width: 50%;
    box-sizing: border-box;
    flex-wrap: wrap;
    font-size: 24px;
    .icon-title{
      display: flex;
      align-items: center;
      .why-choose-content-item-icon{
        font-size: 32px!important;
        display: flex;
        align-items:  center;
      }
    }
  }
  .why-choose-content-item  img{
    width: 32px;
    height: 32px;
  }
  .choose-item-title{
    font-weight: 600;
    font-size: 24px;
    color: #1B1C33;
    margin-left: 16px;
    /*margin-bottom: 26px;*/
  }
  .why-choose-img{
    width: 538px!important;
  }
  .why-choose-img > img{
    width: 100%;
    height: 100%;
  }
  .why-choose-tittle{
    margin-bottom: 100px;
  }
  .choose-item-info{
    margin-top: 26px;
    font-size: 14px;
    line-height: 30px;
    color: #5E5E66;
  }
</style>
