<!--选择城市的下拉框  -->
<template>
  <a-cascader
      :placeholder="placeholder"
      :value="defaultListData"
      :field-names="type === 'city' ?
        { label: 'areaName', value: 'areaId', children: 'children' } :
        { label: 'industryName', value: 'industryId', children: 'children' }
      "
      :options="cascaderList"
      change-on-select
      @change="onChange"
      :show-search="{ filter }"
      expand-trigger="hover"
      :getPopupContainer="getPopupContainer"
      :popupClassName="getPopupClassName"
  />
</template>
<script>
// :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
import {getCity, sysIndustryTree} from "@/api/request";
export default {
  data() {
    //这里存放数据
    return {
      cascaderList: [], //数据列表
      defaultListData:this.defaultList
    };
  },
  props:{
    defaultList:{
      type:Array
    },
    type:{
      type:String
    },
    placeholder:{
      type:String,
      default:'请选择'
    }
  },
  // props:['defaultList', 'type'], // type: city: 选择城市；industry: 选择行业
  mounted() {
    console.log('mounted')
    if(this.type === 'city') {
      this.getSysAreaChildrenFun(); // 获取地区
    }
    else if(this.type === 'industry') {
      this.getIndustryFun(); // 获取行业
    }
    this.defaultListData = this.defaultList;
  },
  watch:{
    defaultList: {
      deep: true,
      handler(val) {
        this.defaultListData = val;
      },
      immediate:true
    },
    type(val) {
      if(val === 'city') {
        this.getSysAreaChildrenFun(); // 获取地区
      }
      else if(val === 'industry') {
        this.getIndustryFun(); // 获取行业
      }
    }
  },
  computed:{
    getPopupContainer() {
      if (this.$route.matched[0].meta.hasBtn) {
        return () => document.body
      } else {
        return (triggerNode) => triggerNode.parentNode
      }
    },
    getPopupClassName(){
      if (this.$route.matched[0].meta.hasBtn) {
        return 'table-choose-company-base'
      } else {
        return ''
      }
    }
  },
  //方法集合
  methods: {
    // 获取地区
    getSysAreaChildrenFun() {
      getCity().then(res => {
        if (res.data.code === 200) {
          this.cascaderList = res.data.data;
          this.handleData(this.cascaderList)
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 获取行业
    getIndustryFun() {
      sysIndustryTree().then(res => {
        if(res.data.code === 200) {
          this.cascaderList = res.data.data;
          this.handleData(this.cascaderList)
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    handleData(data) {
      data.forEach((element) => {
        if (element.children && element.children.length > 0) {
          this.handleData(element.children);
        } else {
          delete element.children;
        }
      });
    },
    filter(inputValue, path) {
      if(this.type === 'city') {
        return path.some((option) =>option.areaName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
      }
      else if(this.type === 'industry') {
        return path.some((option) =>option.industryName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
      }
    },
    onChange(value,select) {
      this.defaultListData = value;
      this.$emit("cascaderChange", value,select);
    },
  },
};
</script>

<style scoped lang="less">
/deep/.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #0055FF;
}
</style>
<style lang="less">
// 滚动条样式
::-webkit-scrollbar {
  width: 8px!important;
  /* 纵向滚动条*/
  height: 8px;
  /* 横向滚动条 */
  background-color: #F1F4FA !important;
}
/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  //box-shadow      : inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #F1F4FA;
  border-radius   : 10px;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  //box-shadow      : inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(67, 94, 190, .4);
  border-radius   : 10px;
  border-width: 2px!important;
  border-color: transparent;
  border-style: solid;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  border-color: rgba(67, 94, 190, .4);
}
</style>

<style>
.table-choose-company-base{
  z-index: 999999!important;
}
</style>
