<template>
  <div class="manage-index-wrap center">
    <div class="manage-index">
      <a-layout id="components-layout-demo-custom-trigger">
        <a-layout-sider  :trigger="null" :collapsed="true" collapsible>
          <a-menu theme="dark" mode="inline" :default-selected-keys="['1']" v-model="selectedKeys">
            <a-menu-item disabled>
<!--              <img src="../assets/head-logo.png" class="article-logo" alt="">-->
              <a-icon type="slack" />
              <span>Dhutning递航</span>
            </a-menu-item>
            <a-menu-item key="1" @click="goToManageArticle">
              <a-icon type="container" />
              <span>官网文章管理</span>
            </a-menu-item>
<!--            <a-menu-item key="2" @click="goToAddArticle">-->
<!--              <a-icon type="file-add" />-->
<!--              <span>新增文章</span>-->
<!--            </a-menu-item>-->
<!--            <a-menu-item key="3" @click="goToEditArticle">-->
<!--              <a-icon type="form" />-->
<!--              <span>编辑文章</span>-->
<!--            </a-menu-item>-->
          </a-menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header style="background: #fff; padding: 0">
<!--            <a-icon-->
<!--                class="trigger"-->
<!--                :type="collapsed ? 'menu-unfold' : 'menu-fold'"-->
<!--                @click="() => (collapsed = !collapsed)"-->
<!--            />-->
            <span class="bigTitle" v-if="selectedKeys=='1'">官网文章管理</span>
            <span class="bigTitle hand" @click="goToManageArticle" v-if="selectedKeys=='2'">&lt; 新增文章</span>
            <span class="bigTitle hand" @click="goToManageArticle" v-if="selectedKeys=='3'">&lt; 编辑文章</span>
          </a-layout-header>
          <a-layout-content
              :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }"
          >
<!--            <manage-article @addArticle="handleAddArticle" v-if="selectedKeys==='1'"></manage-article>-->
<!--            <add-article v-if="selectedKeys==='2'"></add-article>-->
<!--            <add-article v-if="selectedKeys==='3'"></add-article>-->
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
  </div>
</template>

<script>
// import addArticle from "@/views/addArticle";
// import manageArticle from "@/views/manageArticle";
import Bus from "@/utils/bus";
export default {
  components: {
    // addArticle,
    // manageArticle
  },
  data(){
    return{
      collapsed: false,
      selectedKeys:["1"]
    }
  },
  mounted() {
    //用这种方式还是不太行，能解决点击后显示激活的侧边栏
    //但是当返回上一层路由时，仍然没有切换
    //看来得监听一下路由
    Bus.$on("updateKey",res=>{
      let temp=[]
      temp.push(res)
      this.selectedKeys=temp
    })
  },
  methods:{
    goToManageArticle(){
      this.$router.push('/manage/manageArticle')
    },
    goToAddArticle(){
      this.$router.push('/manage/addArticle')
    },
    goToEditArticle(){
      this.$router.push('/manage/editArticle')
    }
  },
  watch:{
    //最终解决方案
    $route:{
      handler(val){
        // console.log(val,'xin');//新路由信息
        // console.log(oldval,'old');//老路由信息
        if(val.path.indexOf('manageArticle')!==-1){
          this.selectedKeys=['1']
        }
        if(val.path.indexOf('addArticle')!==-1){
          this.selectedKeys=['2']
        }
        if(val.path.indexOf('editArticle')!==-1){
          this.selectedKeys=['3']
        }
      },
      // 深度观察监听
      deep: true,
      //必须上来就监听一次
      immediate:true
    }
  }

}
</script>

<style>
.bigTitle{
  margin-left: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #1B1C33;
}
.hand{
  cursor:pointer
}
.manage-index-wrap{
  width: 100%;
}
.manage-index{
  max-width: 1440px;
}
#components-layout-demo-custom-trigger{
  width: 100%;
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.article-logo{
  width: 14px;
  height: 14px;
  display: inline-block;
  background-color: #FFFFFF;
  margin-right: 10px;
  border-radius: 4px;
}
</style>
