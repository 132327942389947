<!--soho版版本对比-->
<template>
  <div>
    <div class="version-wrap">
      <div class="version-content">
        <div class="version-title">Free版</div>
        <div class="version-price">￥0</div>
        <div class="version-date">账号/天</div>
        <div class="version-intro">
          <div>可储存2000份简历</div>
          <div>无推荐  面试  offer等过程性数据分析</div>
        </div>
        <div class="version-img-wrap">
          <img src="//file.dhunting.com/official_website/freeVersion.png" alt="" class="img">
          <img src="//file.dhunting.com/official_website/freeVersionHover.png" alt="" class="img-hover">
          <div class="version-btn" @click="sohoTypeChangeFun(1)">立即体验</div>
        </div>
      </div>
      <div class="version-content">
        <div class="label-item">百万顾问推荐</div>
        <div class="version-title">Pro版</div>
        <div class="version-price">￥1<span>.01</span></div>
        <div class="version-date">账号/天</div>
        <div class="version-intro">
          <div>可储存1万份简历</div>
          <div>拥有SOHO的全部功能</div>
        </div>
        <div class="version-img-wrap">
          <img src="//file.dhunting.com/official_website/criterionVersion.png" alt="" class="img">
          <img src="//file.dhunting.com/official_website/criterionVersionHover.png" alt="" class="img-hover">
          <div class="version-btn" @click="sohoTypeChangeFun(2)">立即购买</div>
        </div>
      </div>
      <div class="version-content">
        <div class="version-title">Max版</div>
        <div class="version-price">￥1<span>.56</span></div>
        <div class="version-date">账号/天</div>
        <div class="version-intro">
          <div>可储存10万份简历</div>
          <div>拥有SOHO的全部功能</div>
        </div>
        <div class="version-img-wrap">
          <img src="//file.dhunting.com/official_website/sohoMaxVersion.png" alt="" class="img">
          <img src="//file.dhunting.com/official_website/sohoMaxVersionHover.png" alt="" class="img-hover">
          <div class="version-btn" @click="sohoTypeChangeFun(3)">立即购买</div>
        </div>
      </div>
    </div>
    <div class="compare-wrap">
      <img src="//file.dhunting.com/official_website/v1/pc-soho-compare.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    // 点击立即体验进入购买详情
    sohoTypeChangeFun(type) {
      this.$emit('sohoTypeChange', type);
    }
  }
}
</script>

<style lang="less" scoped>
.version-wrap {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0 100px;
}
.version-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1B1C33;
  width: 286px;
  //height: 466px;
  border-radius: 24px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  transition: all 0.3s ease-out; /*ease-out 减速、慢慢结束*/
  -webkit-transition: all 0.3s ease-out;
  margin-bottom: 60px;
}
.version-title {
  font-size: 24px;
  font-weight: 600;
  margin: 24px 0 32px;
}
.version-price {
  font-size: 68px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 1;
}
.version-price > span {
  font-size: 24px;
}
.version-date {
  color: #8D8E99;
  font-size: 12px;
  margin-bottom: 42px;
}
.version-intro {
  height: 40px;
  color: #8D8E99;
  font-size: 14px;
  text-align: center;
  padding: 0 24px;
  margin-bottom: 4px;
  line-height: 25px;
}
.version-img-wrap {
  position: relative;
  height: 163px;
}
.version-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 170px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 20px;
  background: linear-gradient(91.61deg, #075AFF 4.65%, #679CF8 96.64%);
}

.version-img-wrap img {
  height: 100%;
}
.version-content .img-hover {
  display: none;
  transition: all 0.3s ease-out; /*ease-out 减速、慢慢结束*/
  -webkit-transition: all 0.3s ease-out;
}
.version-content:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  border-radius: 16px;
  background: linear-gradient(112.45deg, #075AFF 14.21%, #6396EF 89.13%), #FFFFFF;
  box-shadow: 0 9px 28px 8px rgba(0, 85, 255, 0.05), 0 6px 16px rgba(0, 85, 255, 0.08), 0 3px 6px -4px rgba(0, 85, 255, 0.12);
}
.version-content:hover .img {
  display: none;
}
.version-content:hover .img-hover {
  display: inline-block;
}
.version-content:hover,
.version-content:hover .version-date,
.version-content:hover .version-intro {
  color: #FFFFFF;
}
.version-content:hover .version-btn {
  color: #0055FF;
  background: #FFFFFF;
}
.compare-wrap {
  width: 100%;
  padding: 0 150px;
  margin-bottom: 80px;
}
.compare-wrap > img {
  width: 1000px;
}
.version-content{
  position: relative;
  .label-item{
    left: 0;
    top: 22px;
    position: absolute;
    color: #FFFFFF;
    font-size: 12px;
    line-height: 16px;
    background: #FE6C30;
    padding: 4px 12px 4px 8px;
    border-radius: 0 20px 20px 0;
    //height: 24px;
  }
}
</style>
