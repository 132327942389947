import {axiosGet,axiosPost} from "./axios.js";

//获取头部文章列表
export function getTopArticle(data){
    return axiosGet('/ow/article/list_header',data);
}

// 获取文章
export function getArticle(data){
    return axiosGet('/ow/article/list', data);
}

//设置为头部
export function setHead(data){
    return axiosPost('/ow/article/setHeader', data);
}

//取消设置为头部
export function cancleHead(data){
    return axiosPost('/ow/article/cancelHeader', data);
}

//设置为置顶
export function setTop(data){
    return axiosPost('/ow/article/setTop', data);
}

//取消置顶
export function cancleTop(data){
    return axiosPost('/ow/article/cancelTop', data);
}

//删除文章
export function deleteArticle(data){
    return axiosPost('/ow/article/delete', data);
}

//获取文章详情
export function getArticleDetail(data){
    return axiosGet('/ow/article/one', data);
}

//新增或者修改文章
export function addAndEditArticle(data){
    return axiosPost('/ow/article/add_reedit', data);
}

//上传图片
export function uploadImg(data){
    return axiosPost('/file/low_ow_upload', data);
}

//获取文章类型
export function getArticleType(data){
    return axiosGet('/ow/article/type/list', data);
}

// 获取验证码
export function code(data){
    return axiosPost('/valid_code/get', data);
}

// 获取人员规模
export function getEmployees(data){
    return axiosGet('/core/sys_dictionary/get_employees', data);
}

//获取商品信息
export function getDiscount(data){
    return axiosGet('/order/item_discount',data);
}

// 提交订单
export function submitOrder(data){
    return axiosPost('/order/save_info',data)
}

// 获取二维码
export function payCode(data){
    return axiosGet('/pay/unifiedorder',data)
}
// 轮询订单状态
export function check(data){
    return axiosGet('/pay/status',data)
}
// 检验手机号码
export function checkPhone(data){
    return axiosPost('user/uplicate_account',data)
}
// 检验验证码是否正确
export function checkValid(data){
    return axiosPost('valid_code/check',data)
}
// 获取城市
export function getCity(){
    return axiosGet('/core/sys_area/get_tree')
}
// 获取行业
export function sysIndustryTree(data){
    return axiosGet('/core/sys_industry/get_tree',data);
}
// 保存咨询信息
export function consultationSave(data) {
    return axiosPost('/consultation/save', data);
}
// 获取咨询版本
export function consultationVersions(data) {
    return axiosGet('/consultation/versions', data);
}

// 获取渠道列表
export function listChannel() {
    return axiosGet("/consultation/channels")
}
// 验证优惠码
export function checkCode(data){
    return axiosGet('/coupon/check',data);
}
// 绑定验证发送
export function bindUserInfo(data){
    return axiosGet('/wx/qrcode/bindUserInfo',data)
}
