<!--系统内绑定微信成功页面-->
<template>
  <div class="container">
    <div class="picture">
      <img class="success_picture" src="//file.dhunting.com/official_website/v1/get_success.png" alt="success"/>
    </div>
    <span class="title">授权成功</span>
    <p class="content">
      我们将为您发送推荐报告审核提醒及笔试与面试提醒，<br/>
      您可在系统中设置笔试与面试提醒的提醒时间，<br/>
      祝您使用系统愉快。
    </p>
    <div class="footer"></div>
  </div>
</template>

<script>
import qs from 'querystring'
import {bindUserInfo} from '@/api/request.js'
export default {
  name: 'bindWeixinSuccess',
  mounted() {
    this.getUrl();
  },
  methods:{
    getUrl(){
      let obj = qs.parse(window.location.search.slice(1,window.location.search.length))
      bindUserInfo({code:obj.code,state:obj.state}).then(()=>{
        console.log('绑定微信')
      })
    }
  }
}
</script>

<style scoped>
.container{
  width: 375px;
  height: 734px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
.container .picture{
  position: relative;
  margin-left:143.8px;
  margin-top:120.1px;
  width: 87.5px;
  height: 87.5px;
  font-size: 14px;
  line-height: 19.5px;
  color: #1B1C33;
}
.container .picture .success_picture{
  width: 87.5px;
  height: 87.5px;
}
.container .title{
  position: relative;
  display: block;
  width: 56px;
  margin-left:159.5px;
  text-align: center;
  white-space: nowrap;
  margin-top:30.25px;
  font-size: 14px;
  line-height: 19.5px;
  color: #1B1C33;
}
.container .content{
  position: relative;
  width: 336px;
  margin-top:24px;
  margin-left:19.5px;
  height: auto;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: #1B1C33;
}
.container .footer{
  position: absolute;
  bottom:8px;
  width: 134px;
  height: 5px;
  margin-left: 120.5px;
  background: #1B1C33;
  border-radius: 100px;
}
</style>
