<template>
  <div>
    <div class="mo-hunter-system-banner">
      <div class="title1 banner-title">
        让业绩翻倍的
      </div>
      <div class="title2 banner-title">
        智能化招聘业务管理系统
      </div>
      <div class="info banner-info">
        递航系统以协同和赋能为核心功能、
      </div>
      <div class="info banner-info">
        提高交付效率为核心目的，帮助猎企倍增招聘生产力，提高人均单产。适用于猎头、RPO、人力资源外包企业。
      </div>
      <div class="img">
        <img src="//file.dhunting.com/official_website/v1/quick-use.png" alt="">
      </div>
      <div class="btn flex-enter">
        <a-button  @click="toHunterSystem" class="banner-btn" type="primary">立即体验</a-button>
      </div>
    </div>
    <div class="mo-hunter-system-whychoose">
      <div class="title1 item-title">为什么选择</div>
      <div class="title2 item-title item-title-blue">递航招聘业务管理系统</div>
      <div class="whychoose-item flex-col-enter">
        <div class="title-wrap">
          <div class="icon flex-enter">
            <iconpark-icon name="why-choose-1"></iconpark-icon>
          </div>
          <div class="title flex-enter">资产管理</div>
        </div>
        <div class="info">
          系统会记录所有的录入信息和行为数据，包含客户、职位、人才（含沟通记录、评价反馈）、合同、发票信息等资产留存，保障了整个业务在运营时候的数据安全以及招聘效率最大化.
        </div>
        <div class="line"></div>
      </div>
      <div class="whychoose-item flex-col-enter">
        <div class="title-wrap">
          <div class="icon flex-enter">
            <iconpark-icon name="why-choose-2"></iconpark-icon>
          </div>
          <div class="title flex-enter">业务提效</div>
        </div>
        <div class="info">
          通过协同与赋能进行业务提效，系统融入了先进的赋能型管理理念、成熟的业务协同框架、丰富的学习园区，真正做到每一个业务环节效率最大化。
        </div>
        <div class="line"></div>
      </div>
      <div class="whychoose-item flex-col-enter">
        <div class="title-wrap">
          <div class="icon flex-enter">
            <iconpark-icon name="why-choose-3"></iconpark-icon>
          </div>
          <div class="title flex-enter">业绩预测</div>
        </div>
        <div class="info">
          独家的订单管理系统赋能业绩管理，通过业绩管理进行现金流预测，帮助企业及顾问精准预测业绩走向，为管理者提供科学的决策依据。
        </div>
        <div class="line"></div>
      </div>
      <div class="whychoose-item flex-col-enter">
        <div class="title-wrap">
          <div class="icon-img">
            <img src="//file.dhunting.com/official_website/v1/why-choose-4.png"/>
          </div>
          <div class="title flex-enter">数据洞察</div>
        </div>
        <div class="info">
          通过多维度的数据分析，全面洞察企业业务数据，赋予管理者看懂数据的能力，用更加科学的方式进行管理和决策。顾问可根据横向、纵向对比数据，实时了解自己的数据动向。
        </div>
        <div class="line"></div>
      </div>
    </div>
    <div class="mo-hunter-system-message flex-col-enter">
      <div class="message-title item-title item-title-blue">独家IM即时通讯</div>
      <div class="message-tip item-title">@成员，消息一触即达！
      </div>
      <div class="message-info">
        业内独家的IM即时通讯技术，让顾问、PM、Leader高效完成在线推荐、审核、面试、入职安排等工作流。并可随时在沟通记录中@团队成员，信息即时弹出，沟通0时差。
      </div>
      <div class="img-wrap">
<!--        <div class="message wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/message-.png" alt=""></div>-->
<!--        <div class="message-noproblem wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/message-noproblem.png" alt=""></div>-->
<!--        <div class="message-img wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/message-record.png" alt=""></div>-->
<!--        <div class="message-member wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/member.png" alt=""></div>-->
<!--        <div class="message-change wow bounceInRight"><img src="//file.dhunting.com/official_website/v1/message-choose.png" alt=""></div>-->
        <img src="//file.dhunting.com/official_website/v1/only-message.png" alt="">
      </div>
    </div>
    <div class="mo-hunter-system-resume flex-col-enter">
      <div class="title item-title item-title-blue">激活私域简历库</div>
      <div class="tip item-title">简历智能解析，一键导入自有人才库</div>
      <div class="info">各类简历均可一键上传，智能解析后标准入库。一键导入自有人才库，既能提前对比避免重复下载，浪费成本，又能免费更新人才库。</div>
      <div class="img flex-col-enter">
        <div class="resume-top wow fadeInLeft"><img src="//file.dhunting.com/official_website/v1/resume-noUse.png" alt=""></div>
        <div class="resume-bottom">
          <img src="//file.dhunting.com/official_website/v1/resume-img.png" alt="">
        </div>
      </div>
    </div>
    <div class="mo-hunter-system-change-speak flex-col-enter">
      <div class="changeSpeak-title wow fadeInRightBig item-title item-title-blue">在线矫正话术</div>
      <div class="changeSpeak-tip wow fadeInRightBig item-title">帮助新人猎头成长</div>
      <div class="changeSpeak-info wow fadeInRightBig">一键呼叫、自动录音、话术打分，递航让没有管理经验pm或小白顾问也可以直接上手，提高单产。</div>
      <div class="img flex-col-enter">
        <div class="changeSpeak-dialog wow fadeInLeftBig">
          <img src="//file.dhunting.com/official_website/v1/phone-dislog.png" alt="">
        </div>
        <div class="changeSpeak-left wow fadeInLeftBig">
          <img src="//file.dhunting.com/official_website/v1/phone-img.png" alt="">
        </div>
      </div>
    </div>
    <div class="mo-hunter-system-order-system flex-col-enter">
      <div class="title  item-title-blue item-title">全链路订单系统</div>
      <div class="tip item-title">业内首创的全链路业务管理功能</div>
      <div class="info">不仅作为招聘管理系统，Dhunting独家特有的业务管理功能。一键提交，即可在线生成合同与发票；自定义审批流配置权限，适应不同的业务场景；合同-发票-回款全链路追踪技术，让你轻松实现业务的管理闭环。</div>
      <div class="img">
<!--        <div class="work-dialog wow fadeInLeft"><img src="//file.dhunting.com/official_website/v1/work-dialog.png" alt=""></div>-->
<!--        <div class="order-img-top">-->
<!--          <img src="../../../assets/order-img-top.png" alt="">-->
<!--        </div>-->
<!--        <div class="order-img-content">-->
<!--          <img src="../../../assets/order-img-bottom.png" alt="">-->
<!--        </div>-->
        <img src="//file.dhunting.com/official_website/v1/mo-order-system.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mohunterSystem',
  components: {

  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods:{
    toHunterSystem() {
      this.$router.push('/productContact')
    }
  }
}
</script>
<style lang="less" scoped>
.mo-hunter-system-banner{
  padding: 32px;
  .title2{
    margin-bottom: 12px;
  }
  .info{
    font-size: 14px;
  }
  .img{
    margin-top: 14px;
    width: 100%;
    margin-bottom: 32px;
  }
  .btn{
    width: 100%;
  }
}
.mo-hunter-system-whychoose{
  padding: 32px;
  .title1{
    //margin-top: 32px;
  }
  .title2{
    margin-bottom: 34px;
  }
  .whychoose-item{
    .title-wrap{
      display: flex;
      .icon{
        width: 24px;
        font-size: 24px;
      }
      .icon-img{
        width: 24px!important;
        height: 24px!important;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title{
        margin-left: 9px;
        font-weight: 500;
        font-size: 14px;
        color: #1B1C33;
        text-align: center;
      }
    }
    .info{
      margin-top: 18px;
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 12px;
      color: #5E5E66;
      text-align: center;
    }
    .line{
      //margin-top: 18px;
      width: 100px;
      height: 2px;
      background: #0055FF;
      border-radius: 2px;
      border: none;
      margin-bottom: 32px!important;
    }
  }
}
.mo-hunter-system-message{
  background-color:  #F9FAFF;
  padding: 32px;
  .message-tip{
    //margin-top: 8px;
  }
  .message-info{
    font-weight: 400;
    font-size: 14px;
    color: #5E5E66;
    margin: 24px 0;
  }
  .img-wrap{
    position: relative;
    width: 100%;
    height: 222px;
    //.message-img{
    //  position: absolute;
    //  left: 150px;
    //  top: 50px;
    //  width: 114px;
    //  height: 162px;
    //}
    //.message-member{
    //  position: absolute;
    //  left: 20px;
    //  top: 220px;
    //  width: 93px;
    //  height: 26px;
    //}
    //.message-change{
    //  position: absolute;
    //  top: 140px;
    //  left: -20px;
    //  width: 109px;
    //  height: 26px;
    //}
    //.message-todo{
    //  position: absolute;
    //  top: 70px;
    //  left: 20px;
    //  width: 109px;
    //  height: 32px;
    //}
    //.message-noproblem{
    //  position: absolute;
    //  left: 100px;
    //  top: 0px;
    //  width: 155px;
    //  height: 30px;
    //}
    //img{
    //  width: 150% !important;
    //  height: 150% !important;
    //}
  }
}
.mo-hunter-system-resume{
  padding: 32px;
  .tip{
    margin: 0 0 24px 0;
  }
  .info{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #5E5E66;
  }
  .img{
    margin-top: 24px;
    width: 100%;
    height: 290px;
    .resume-top{
      width: 175px;
      height: 40px;
    }
    .resume-bottom{
      width: 280px;
      height: 261px;
    }
  }
}
.mo-hunter-system-change-speak{
  background: #F9FAFF;
  padding: 32px;
  .changeSpeak-tip{
    //margin-top: 8px;
  }
  .changeSpeak-info{
    font-weight: 400;
    font-size: 14px;
    color: #5E5E66;
    margin: 24px 0;
  }
  .img{
    .changeSpeak-dialog{
      width: 220px;
      height: 40px;
    }
    .changeSpeak-left{
      width: 280px;
      height: 208px;
    }
  }
}
.mo-hunter-system-order-system{
  padding: 32px;
  .tip{
    //margin-top: 8px;
  }
  .info{
    font-weight: 400;
    font-size: 14px;
    color: #5E5E66;
    margin: 24px 0;
  }
  .img{
    .work-dialog{
      width: 225px;
      height: 40px;
    }
  }
}
</style>