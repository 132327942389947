import { render, staticRenderFns } from "./newMoEnterpriseCriterion.vue?vue&type=template&id=063f7bdf&scoped=true"
import script from "./newMoEnterpriseCriterion.vue?vue&type=script&lang=js"
export * from "./newMoEnterpriseCriterion.vue?vue&type=script&lang=js"
import style0 from "./newMoEnterpriseCriterion.vue?vue&type=style&index=0&id=063f7bdf&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063f7bdf",
  null
  
)

export default component.exports