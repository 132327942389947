import { render, staticRenderFns } from "./addArticle.vue?vue&type=template&id=457b2588&scoped=true"
import script from "./addArticle.vue?vue&type=script&lang=js"
export * from "./addArticle.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./addArticle.vue?vue&type=style&index=1&id=457b2588&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "457b2588",
  null
  
)

export default component.exports