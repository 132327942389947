<template>
  <div class="article-all-wrap">
    <div class="article-all">
      <div class="article-title">{{all_article.title}}</div>
      <div class="article-time">{{all_article.updateTime}}</div>
      <div class="article-btn">
        <div class="btn-left">
          <a-button v-if="all_article.topFlag" class="cancle-btn" type="primary" @click="handleCancleTop">
            取消置顶
          </a-button>
          <a-button v-if="!all_article.topFlag" class="top-btn" @click="handleSetTop">
            设为置顶
          </a-button>
          <a-button v-if="all_article.headerFlag" class="cancle-btn" type="primary" @click="handleCancleHead">
            取消头部
          </a-button>
          <a-button v-if="!all_article.headerFlag" class="top-btn" @click="handleSetHead">
            设为头部
          </a-button>
        </div>
        <div class="btn-right">
          <a-popconfirm
              title="确定删除?删除后不可恢复"
              ok-text="确定"
              cancel-text="取消"
              @confirm="confirm"
              @cancel="cancel"
          >
            <a-button class="delete-btn">删除</a-button>
          </a-popconfirm>
          <a-button class="edit-btn" @click="handleEditArticle">编辑</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setHead,cancleHead,setTop,cancleTop,deleteArticle} from '@/api/request'
import Bus from "@/utils/bus";
export default {
  components: {

  },
  props: {
    all_article: {
      type: Object
    }
  },
  methods:{
    handleSetHead(){
      setHead({
        owArticleId:this.all_article.owArticleId
      }).then(res=>{
        if(res.data.code===200){
          this.$message.success('设置成功！');
        }
        else {
          this.$message.error(res.data.message);
        }
        //更新文章
        this.$emit('update')
      })
    },
    handleCancleHead(){
      cancleHead({
        owArticleId:this.all_article.owArticleId
      }).then(res=>{
        if(res.data.code===200){
          this.$message.success('取消成功！');
        }
        else {
          this.$message.error(res.data.message);
        }
        //更新文章
        this.$emit('update')
      })
    },
    handleCancleTop(){
      cancleTop({
        owArticleId:this.all_article.owArticleId
      }).then(res=>{
        if(res.data.code===200){
          this.$message.success('取消成功！');
        }
        else {
          this.$message.error(res.data.message);
        }
        //更新文章
        this.$emit('update')
      })
    },
    handleSetTop(){
      setTop({
        owArticleId:this.all_article.owArticleId
      }).then(res=>{
        // console.log('ddd',res)
        if(res.data.code===200){
          this.$message.success('设置成功！');
        }
        else {
          this.$message.error(res.data.message);
        }
        //更新文章
        this.$emit('update')

      })
    },
    handleDeleteArticle(){
      deleteArticle(
          {
            owArticleId:this.all_article.owArticleId
          }
      ).then(res=>{
        if(res.data.code===200){
          this.$message.success('删除成功！');
        }
        else {
          this.$message.error(res.data.message);
        }
        //更新文章
        this.$emit('update')
      })
    },
    handleEditArticle(){
      Bus.$emit('updateKey','3')
      const owArticleId=this.all_article.owArticleId
      this.$router.push({path:'/manage/editArticle',query: {owArticleId}})
    },
    confirm() {
      this.handleDeleteArticle()
    },
    cancel() {
      this.$message.success('取消成功！');
    },
  }
}
</script>

<style scoped>
  .article-all-wrap{
    padding: 18px 16px 18px 24px;
    width: 400px;
    height: 156px;
    background: #FFFFFF;
    box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .article-all{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .article-title{
    font-weight: 600;
    font-size: 20px;
    color: #1B1C33;
    height: 60px;
    line-height: 25px;
    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .article-time{
    font-weight: 500;
    font-size: 12px;
    color: #5E5E66;
    margin-top: 10px;
    margin-bottom: 22px;
  }
  .article-btn{
    display: flex;
    justify-content: space-between;
  }
  .cancle-btn{
    width: 88px;
    height: 32px;
    background: #0055FF;
    border-radius: 2px 0px 0px 2px;
  }
  .top-btn{
    width: 88px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 0px 2px 2px 0px;
  }
  .btn-right{
    display: flex;
    justify-content: right;
  }
  .delete-btn,
  .edit-btn
  {
    width: 60px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
  }
  .edit-btn{
    margin-left: 8px;
  }
</style>
