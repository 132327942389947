import {axiosGet, axiosPost} from "@/api/axios";

// soho pro 价格菜单
export function getSohoProPriceMenu() {
    return axiosGet("/order/after/pro/account_sku")
}

// soho max 价格菜单
export function getSohoMaxPriceMenu() {
    return axiosGet("/order/after/max/account_sku")
}

// 提交订单-官网渠道
export function submitOrder(data) {
    return axiosPost('/order/ow/soho/save_info', data)
}

// 获取二维码
export function payCode(data) {
    return axiosGet('/pay/unifiedorder', data)
}

// 轮询订单状态
export function check(data) {
    return axiosGet('/pay/soho_pay', data)
}

// 个人版-free 注册账号
export function createPersonalAccount(data){
    return axiosPost('/order/create_personal_account',data);
}
// 猎企免费版注册
export function registerEnterpriseFree(data) {
    return axiosPost("/order/ow/enterprise/free_create", data)
}