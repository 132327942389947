<template>
<!-- soho-free版立即体验弹窗 -->
  <div>
    <a-modal
        :visible="outerVisible"
        :footer="null"
        dialogClass="new-pc-soho-pro"
        @cancel="handleCancel"
    >
      <div class="top">
        <div class="title-wrap">
          <div class="icon"><iconpark-icon name="new-modal-icon"></iconpark-icon></div>
          <div class="title">已选择产品类型：SOHO-Free版</div>
        </div>
        <div class="modal-line"></div>
        <div class="info">填写信息</div>
        <div class="form-and-register">
          <a-form-model :model="form" :rules="rules" ref="form" layout="horizontal" :hideRequiredMark="true">
            <a-form-model-item label="姓名" prop="name">
              <a-input placeholder="请输入您的姓名" v-model="form.name"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电话" prop="phone">
              <a-input placeholder="请输入您的手机号码" v-model="form.phone"></a-input>
            </a-form-model-item>
            <a-form-model-item label="验证码" prop="validCode" class="get-code-wrap">
              <a-input placeholder="请输入验证码" v-model="form.validCode"></a-input>
              <div class="hit-code-wrap">
                <a v-show="!showTimer" class="items-code-2" @click="getCode">获取验证码</a>
                <span v-show="showTimer" class="items-code-time-2">{{time}}s重新发送</span>
                <span class="code-hit-2">{{hitCode}}</span>
              </div>
            </a-form-model-item>
          </a-form-model>
          <div class="register-wrap">
            <div class="register-customer-service">
              <img src="//file.dhunting.com/official_website/weixin.svg" alt="" class="register-customer-service-img">
              <span class="register-customer-service-text">扫描添加客服，回复关键词“注册码”获取注册码</span>
            </div>
            <div class="register-code-wrap">
              <img src="//file.dhunting.com/official_website/customerServiceQrCode.svg" alt="" class="register-qr-code">
              <a-form-model :model="form" :rules="rules" ref="formRegister" :hideRequiredMark="true">
                <a-form-model-item  prop="registerCode" class="register-item">
                  <a-input v-model="form.registerCode" placeholder="请输入注册码" class="register-qr-input"></a-input>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="btn-wrap">
          <div class="submit-check">
            <a-checkbox v-model="isAgree"></a-checkbox>
            <span class="agree">同意<span @click="showDhuntingWindow" class="small-hand agreement">《递航服务协议》</span>
                <span class="small-hand agreement" @click="showPrivacyWindow">《递航隐私政策》</span>
              </span>
          </div>
          <a-button type="danger" class="enterprise-criterion-btn" @click="onSubmit" :loading="isLoading">提交结算</a-button>
        </div>
      </div>
      </a-modal>
    <!--    递航服务协议弹窗-->
    <serviceAgreementModal :showDhunting="showDhunting" @visibleCancel="showDhunting = false"></serviceAgreementModal>
    <!--    递航隐私政策弹窗-->
    <privacyPolicyModal :showPrivacy="showPrivacy" @visibleCancel="showPrivacy = false"></privacyPolicyModal>
    <!--    成功提交弹窗-->
    <successModal :isVisible="showSuccessWindow" type="register" :phone="form.phone" @cancel="cancel" @toHome="toHome"></successModal>
  </div>
</template>
<script>
import successModal from "@/views/PCPage/productContact/component/successModal";
import serviceAgreementModal from "@/components/serviceAgreementModal";
import privacyPolicyModal from "@/components/privacyPolicyModal";
import {code} from "@/api/request";
import {createPersonalAccount} from "@/api/Interface/order";

export default {
  name: "newSohoFree",
  components: {successModal,serviceAgreementModal,privacyPolicyModal},
  props:{
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading:false,
      hitCode: '',
      time: 0,//倒计时
      outerVisible:this.visible,
      showTimer: false,//显示倒计时
      showSuccessWindow: false, // 是否展示预约成功弹窗
      showDhunting: false,//显示用户协议
      showPrivacy: false,//显示隐私政策
      isAgree: false, // 是否同意服务协议
      form: {
        name: undefined, // 姓名
        phone: undefined, // 电话
        validCode: undefined, // 验证码
        registerCode: undefined, // 注册码
      },
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: ['blur','change'] }],
        phone: [
          {
            required: true,
            len: 11,
            pattern: RegExp(/(^(\+?0?86-?)?1[3456789]\d{9}$)|(^(\+?852-?)?[569]\d{3}-?\d{4}$)|(^(\+?886-?|0)?9\d{8}$)|(^(0065)\d{8}$)/),
            message: "请输入正确的手机号",
            trigger: ['blur','change'],
          },
        ],
        // 大陆手机号：/^(\+?0?86-?)?1[345789]\d{9}$/
        // 香港手机号：/^(\+?852-?)?[569]\d{3}-?\d{4}$/
        // 台湾手机号：/^(\+?886-?|0)?9\d{8}$/
        // 新加坡手机号：/^(0065)\d{8}$/
        validCode: [{ required: true, message: '请输入验证码', trigger: ['blur','change'] }],
        registerCode: [{ required: true, message: '请输入注册码', trigger: ['blur','change'] }],
      },
      inviteUserId: undefined, // 邀请人用户id
    }
  },
  mounted(){
    if(this.$route.query && this.$route.query.inviteUserId){
      this.inviteUserId = this.$route.query.inviteUserId
    }
  },
  methods:{
    toHome(){
      this.$router.push('/home')
    },
    handleCancel(){
      this.outerVisible = false
      this.$emit('sohoTypeReset');
    },
    // 是否显示递航服务协议
    showDhuntingWindow() {
      this.showDhunting = true;
    },
    // 是否显示递航隐私政策
    showPrivacyWindow() {
      this.showPrivacy = true;
    },
    // 获取验证码
    getCode(){
      // setTimeout(()=>{
      // 先进行手机号验证，成功后发获取验证码请求
      this.$refs.form.validateField(['phone'], valid => {
        if (!valid) {
          code({ username: this.form.phone}).then((res)=>{
            if(res.data.code === 200){
              //转变成倒计时
              this.time = 60;
              this.codeTimer = setInterval(()=>{
                if(this.time === 0){
                  clearInterval(this.codeTimer);
                  this.codeTimer = null;
                  this.showTimer = false;
                }
                this.time--;
              },1000)
              this.showTimer = true;
            }
            else{
              this.$message.error(res.data.message);
            }
          })
        }
        else {
          return false
        }
      })
      // },1000)
    },
    // 立即体验
    onSubmit() {
      // 表单验证姓名、电话、验证码
      this.$refs.form.validate(valid => {
        if(valid) {
          // 表单验证注册码
          //相当于这个校验交给后端
          this.$refs.formRegister.validate(valid1 => {
            if(valid1) {
              // 是否同意递航服务协议和递航隐私政策
              if(this.isAgree) {
                this.purchaseSohoFree()
              }
              else {
                this.$message.warning('请先阅读并同意递航服务协议和递航隐私政策');
              }
            }
            else {
              return false;
            }
          })
        }
        else {
          return false;
        }
      });
    },
    // 注册成功弹窗关闭
    cancel() {
      this.showSuccessWindow = false;
      this.outerVisible=false
      this.$emit('sohoTypeReset');
      this.form = {
        name: undefined, // 姓名
        phone: undefined, // 电话
        validCode: undefined, // 验证码
        registerCode: undefined, // 注册码
        inviteUserId: undefined, // 邀请人用户id
      };
      this.showTimer=false
    },
    // 购买soho-free
    async purchaseSohoFree() {
      this.isLoading = true;
      let obj = {
        name: this.form.name,
        mobile: this.form.phone,
        registerCode: this.form.registerCode,
        validCode: this.form.validCode,
        inviteUserId: this.inviteUserId
      }
      let { data } = await createPersonalAccount(obj);
      this.isLoading = false;
      if (data.code === 200) {
        this.showSuccessWindow = true;
      }else {
        this.$message.error(data.message)
      }
    }
  }
}
</script>
<style lang="less">
.new-pc-soho-pro{
  width: 848px!important;
}
.new-pc-soho-pro .ant-modal-content{
  width: 848px!important;
  padding: 32px!important;
  background: #F7F8FA;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}
.new-pc-soho-pro .ant-modal-body{
  padding: 0;
}
.new-pc-soho-pro .top,
.new-pc-soho-pro .bottom
{
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;
}
.new-pc-soho-pro .top {
  .title-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #1B1C33;

    .icon {
      font-size: 24px;
    }

    .title {
      margin-left: 23px;
    }
  }

  .modal-line {
    height: 1px;
    background: #EDEDF0;
    margin: 16px 0;
  }

  .info {
    font-size: 14px;
    font-weight: 400;
    color: #1B1C33;
    margin-bottom: 16px;
  }
  .form-and-register{
    display: flex;
    .ant-form{
      flex: 1;
      margin-right: 30px;
      flex-shrink: 0;
      .ant-form-item{
        display: flex;
        flex-shrink: 0;
        margin-bottom: 20px;
        .ant-form-item-label{
          width: 80px!important;
          text-align: right;
          flex-shrink: 0;
        }
        .ant-form-item-control-wrapper{
          flex-shrink: 0;
          //todo
          width: 220px;
        }
      }
      .get-code-wrap{
        position: relative;
        .hit-code-wrap{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          .items-code-2{
            color: #0055FF;
          }
          .items-code-time-2{
            color: #8D8E99;
          }
        }
      }
    }
    .register-wrap{
      flex: 1;
      margin-left: 30px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      .register-customer-service{
        display: flex;
        align-items: center;
        margin-bottom: 16px!important;
        img{
          width: 24px;
          height: 24px;
        }
        span{
          margin-left: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1B1C33;
        }
      }
      .register-code-wrap{
        display: flex;
        align-items: center;
        justify-content: end;
        img{
          width: 80px;
          height: 80px;
          margin-right: 20px;
        }
        .ant-form{
          margin-right: 0;
        }
      }
    }
  }
}
.new-pc-soho-pro .bottom{
  margin-top: 8px;
  display: flex;
  justify-content: end;

  .btn-wrap{
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    .submit-check{
      .agree{
        margin-left: 8px;
        font-size: 14px;
        line-height: 20px;
        color: #8D8E99;
        .agreement{
          color: #0055FF;
        }
      }
    }
    .enterprise-criterion-btn{
      margin-top: 8px;
      background: #FE6C30;
      border-radius: 8px;
      width: 276px;
      height: 40px;
    }
  }
}
</style>
