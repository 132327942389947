<template>
  <div class="outer">
    <img :src="articleDetail.banner" alt="">
    <div class="article-wrap-wrap">
      <div class="article-wrap">
        <div class="article-title">{{articleDetail.title}}</div>
        <div class="article-time"><iconpark-icon name="time" style="margin-right: 10px"></iconpark-icon>{{articleDetail.updateTime}}</div>
        <div class="content-contact">
          <div class="article-content">
            <div class="article-banner">
              <div class="article-banner-wrap">
                <img :src="articleDetail.banner" alt="">
              </div>
            </div>
<!--            //file.dhunting.com/official_website/v1/article-banner.png-->
            <div class="article-line"></div>
            <div class="pc-article-detail-content" style="font-size: 14px;" v-html="articleDetail.content"></div>
          </div>
          <!--<div class="contact-table">
            <contactTable></contactTable>
            <weChat></weChat>
          </div>-->
        </div>
      </div>
    </div>
    <div class="artitle_wrap" v-if="false">
      <div class="artitle">
        <div class="article-title">文章资讯</div>
        <articleCard @articleIsNone="handleArticleIsNone" class=" wow backInUp">
          <template v-slot:advar>
            <span></span>
          </template>
          <template v-slot:author>
            <span></span>
          </template>
        </articleCard>
        <div class="more_article_wrap center">
          <a-button class="more-article-btn" type="primary">更多文章</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import articleCard from "@/components/articleCard/articleCard";
// import weChat from "@/components/weChat/weChat";
// import contactTable from "@/components/contactTable/contactTable";
import {getArticleDetail} from "@/api/request";
export default {
  components:{
    articleCard,
    // weChat,
    // contactTable
  },
  data() {
    return {
      isShowArticle:true,
      articleDetail:{},
      owArticleId:''
    }
  },
  methods:{
    handleArticleIsNone(){
      this.isShowArticle=false
    },
    getArticleDetail(){
      getArticleDetail(
          {
            owArticleId:this.owArticleId
          }
      ).then(res=>{
        if(res.data.code===200){
         this.articleDetail=res.data.data
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    getArticleInfo(){
      //预览
      if(this.$route.query.isPreview){
        const obj =JSON.parse(localStorage.getItem('article'))
        this.articleDetail=obj
      }
      //从官网前台点击进去得文章详情
      else {
        this.owArticleId=this.$route.query.owArticleId
        this.getArticleDetail()
      }
    }
  },
  mounted() {
    this.getArticleInfo()
  },
  watch:{
    $route:{
      handler(){
        this.getArticleInfo()
      },
      // 深度观察监听
      deep: true
    }
  }
}
</script>
<style lang="less" scoped>
  .article-wrap-wrap,
  .artitle_wrap
  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .article-wrap,
  .artitle
  {
    max-width: 1440Px;
    width: 100%;
  }
  .outer{
    background: rgba(255,255,255,0.95);
    position: relative;
  }
  .outer > img{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 932px;
    object-fit: contain!important;
  }
.article-wrap{
  width: 100%;
  box-sizing: border-box;
  padding:80px 120px;
}
.article-time{
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  color: #8D8E99;
}
.article-banner{
  height: 320px;
  margin-bottom: 40px;
  .article-banner-wrap{
    height: 100%;
    text-align: center;
    img{
      object-fit: contain!important;
      width: auto;
      border-radius: 16px!important;
    }
  }
}
.content-contact{
  display: flex;
}
.article-line{
  height: 1px;
  background-color:  #C8C8CC;
  margin-bottom: 40px;
}
.article-content{
  flex: 3;
  //padding-right: 80px;
  box-sizing: border-box;
}
.contact-table{
  flex: 1;
  /*height: 950px;*/
  padding: 24px;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  position: relative;
  right: -110px;
  background-color: rgba(255,255,255,1);
}

.artitle{
  height: 800px;
  background: #FFFFFF;
  padding: 60px 120px 116px 120px;
}
.article-title{
  height: 80px;
  font-weight: 600;
  font-size: 36px;
  line-height: 80px;
  color: #1B1C33;
  //margin-bottom: 30px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.contact-table /deep/ .ant-btn-primary{
  width: 272px;
  height: 40px;
  background: #0055FF;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  margin-bottom: 40px;
}
.more-article-btn{
  margin-top: 80px;
  width: 320px;
  height: 60px;
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;

}
</style>
<style>
.pc-article-detail-content img{
  border-radius: 16px;
}
</style>
