<!--登录时的滑块  -->
<template>
  <div class="slider-check-box">
    <div class="slider-check" :class="rangeStatus ? 'success' : ''">
      <i @mousedown="rangeMove" @touchstart="touchMove" :class="rangeStatus ? successIcon : startIcon">
        {{rangeStatus?"":">>"}}
      </i>
      {{ rangeStatus ? successText : startText }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 成功之后的函数
    successFun: {
      type: Function
    },
    //成功图标
    successIcon: {
      type: String,
      default: 'check'
    },
    //成功文字
    successText: {
      type: String,
      default: '验证成功'
    },
    //开始的图标
    startIcon: {
      type: String,
      default: 'double-right'
    },
    //开始的文字
    startText: {
      type: String,
      default: '拖住滑块，拖动到最右边'
    },
    //失败之后的函数
    errorFun: {
      type: Function
    },
    //或者用值来进行监听
    status: {
      type: String
    }
  },
  data() {
    return {
      disX: 0,
      rangeStatus: false
    }
  },
  methods: {
    // 触摸滑块移动
    touchMove(e) {
      let ele = e.target
      let startX = e.touches[0].clientX
      let eleWidth = ele.offsetWidth
      let parentWidth = ele.parentElement.offsetWidth
      let MaxX = parentWidth - eleWidth
      let disX = this.disX;
      if (this.rangeStatus) {
        //不运行
        return false
      }
      document.addEventListener('touchmove', (e) => {
        let endX = e.touches[0].clientX
        disX = endX - startX
        if (disX <= 0) {
          disX = 0
        }
        if (disX >= MaxX - eleWidth) {
          //减去滑块的宽度,体验效果更好
          disX = MaxX
        }
        ele.style.transition = '.1s all'
        ele.style.transform = 'translateX(' + disX + 'px)'
        e.preventDefault()
      }, {passive: false});
      document.addEventListener('touchend', () => {
        if (disX !== MaxX) {
          ele.style.transition = '.5s all'
          ele.style.transform = 'translateX(0)'
          //执行成功的函数
          this.errorFun && this.errorFun()
        } else {
          this.rangeStatus = true
          if (this.status) {
            this.$parent[this.status] = true
          }
          //执行成功的函数
          this.successFun && this.successFun()
        }
        document.addEventListener('touchmove', null);
        document.addEventListener('touchend', null);
      }, {passive: false})
    },
    //滑块移动
    rangeMove(e) {
      let ele = e.target
      let startX = e.clientX
      let eleWidth = ele.offsetWidth
      let parentWidth = ele.parentElement.offsetWidth
      let MaxX = parentWidth - eleWidth
      if (this.rangeStatus) {
        //不运行
        return false
      }
      document.onmousemove = e => {
        let endX = e.clientX
        this.disX = endX - startX
        if (this.disX <= 0) {
          this.disX = 0
        }
        if (this.disX >= MaxX - eleWidth) {
          //减去滑块的宽度,体验效果更好
          this.disX = MaxX
        }
        ele.style.transition = '.1s all'
        ele.style.transform = 'translateX(' + this.disX + 'px)'
        e.preventDefault()
      }
      document.onmouseup = () => {
        if (this.disX !== MaxX) {
          ele.style.transition = '.5s all'
          ele.style.transform = 'translateX(0)'
          //执行成功的函数
          this.errorFun && this.errorFun()
        } else {
          this.rangeStatus = true
          if (this.status) {
            this.$parent[this.status] = true
          }
          //执行成功的函数
          this.successFun && this.successFun()
        }
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>
<style lang="less" scoped>
.slider-check-box {
  .slider-check {
    background-color: #F2F2F2;
    border-radius: 8px;
    position: relative;
    transition: 1s all;
    user-select: none;
    color: #1B1C33;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    &.success {
      background-color: #21D95E;
      color: #fff;
      i {
        color: #21D95E;
        &::after{
          display: inline-block;
          position: absolute;
          content: "";
          width: 5px;
          height: 10px;
          top: 50%;
          left: 50%;
          border: 2px solid #21D95E;
          border-top: none;
          border-left: none;
          transform: translate(-50%,-50%) rotate(45deg)!important;
        }
      }
    }
    i {
      position: absolute;
      left: 0;
      width: 40px;
      height: 100%;
      color: #BBB;
      font-size: 18px;
      background-color: #fff;
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
