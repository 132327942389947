<template>
  <div class="mo-home-wrap">
    <div class="mo-home-banner">
      <div class="mo-home-banner-title">
        <div class="mo-home-banner-title1 banner-title">递航智能化招聘工具</div>
      </div>
      <div class="mo-home-banner-info">
        <div class="mo-home-banner-info1 banner-info">一个既能对内提效，</div>
        <div class="mo-home-banner-info2 banner-info">又能对外开源的智能化招聘管理工具</div>
      </div>
      <div class="mo-home-banner-img">
        <img src="//file.dhunting.com/official_website/v1/mo-home-banner.png" v-if="!isPlay" @click="handlePlay" alt="">
        <a-modal
            v-model="isPlay"
            centered
            :footer="null"
            :closable="false"
            width="300px"
            height="200px"
            dialogClass="modal-home-video"
        >
          <div class="video-wrap">
            <!--              <a-icon type="close-circle" @click="handleStop"/>-->
            <video src="//file.dhunting.com/official_website/v1/Dhunting.mp4" id="dhuntingVideo" controls autoplay></video>
          </div>
          <div class="close-video" @click="handleStop"><a-icon type="close" /></div>
        </a-modal>
      </div>
      <div class="banner-btn-wrap flex-enter">
        <a-button @click="toFreeUse" class="banner-btn" type="primary">免费试用</a-button>
      </div>
    </div>
    <div class="mo-home-swiper-wrap">
      <div class="mo-home-swiper">
        <a-carousel>
          <div class="swiper-item">
            <div class="swiper-title">数据资产的留存包含了所有的沟通记录、评价反馈</div>
            <div class="swiper-img">
              <img src="//file.dhunting.com/official_website/v1/swiper1.png" alt="">
            </div>
            <div class="dialog-wrap">
              <div class="dialog-left flex-col-enter">
                <div class="dialog-avatar flex-col-enter">
                  <img src="//file.dhunting.com/official_website/v1/swiper-advar.png" alt="">
                </div>
                <div class="dialog-name">某公司HRD</div>
              </div>
              <div class="dialog-right">
                <div class="dialog-content">
                  “和团队之间的信息对齐效率大大提高！不会出现任何沟通信息不准确的问题。”
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-item">
            <div class="swiper-title">打通猎企私域合作渠道，发挥自身优势，让交付效率更高，实现双赢！</div>
            <div class="swiper-img">
              <img src="//file.dhunting.com/official_website/v1/swiper2.png" alt="">
            </div>
            <div class="dialog-wrap">
              <div class="dialog-left flex-col-enter">
                <div class="dialog-avatar">
                  <img src="//file.dhunting.com/official_website/v1/swiper-advar.png" alt="">
                </div>
                <div class="dialog-name">某猎企CEO</div>
              </div>
              <div class="dialog-right">
                <div class="dialog-content">
                  通过使用猎头联盟，季度增收了30万+业绩额！
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-item">
            <div class="swiper-title">免费使用SOHO猎头系统，限量注册</div>
            <div class="swiper-img">
              <img src="//file.dhunting.com/official_website/v1/swiper3.png" alt="">
            </div>
            <div class="dialog-wrap">
              <div class="dialog-left flex-col-enter">
                <div class="dialog-avatar">
                  <img src="//file.dhunting.com/official_website/v1/swiper-advar.png" alt="">
                </div>
                <div class="dialog-name">某SOHO猎头</div>
              </div>
              <div class="dialog-right">
                <div class="dialog-content">
                  功能完全不输付费的系统，可以建立自己的私域人才库，积累自己的候选人和客户。
                </div>
              </div>
            </div>
          </div>
        </a-carousel>
      </div>
    </div>
    <div class="mo-home-feature flex-col-enter">
      <div class="feature-title">高效协同、深度赋能、精准开源！</div>
      <div class="feature-info">递航人才推荐系统核心价值主张</div>
      <div class="feature-item-wrap">
        <div class="feature-item marginBottom32 flex-col-enter">
          <div class="feature-item-img">
            <img src="//file.dhunting.com/official_website/v1/user.png" alt="">
          </div>
          <div class="feature-item-title">猎头/RPO/外包版</div>
          <div class="feature-item-content">
            <div class="content-item marginBottom16">
              <div class="item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
              <div class="item-content"> <span class="fontW500">资产管理：</span>记录并留存业务流程中所有录入及行为数据</div>
            </div>
            <div class="content-item marginBottom16">
              <div class="item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
              <div class="item-content"><span class="fontW500">业务提效：</span>通过内外协同，精准赋能助力业务提效</div>
            </div>
            <div class="content-item marginBottom16">
              <div class="item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
              <div class="item-content"><span class="fontW500">业绩管理：</span>独家的订单系统，精准预测业绩走向</div>
            </div>
            <div class="content-item marginBottom24">
              <div class="item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
              <div class="item-content"><span class="fontW500">数据洞察：</span>多维度数据洞察，帮助企业做科学管理</div>
            </div>
          </div>
          <div class="feature-item-btn">
            <a-button type="primary" class="btn marginR32" @click="toBuyHunterSystem">立即体验</a-button>
<!--            <a-button type="primary" class="know-more btn" ghost @click="toHunterSystem">了解更多</a-button>-->
            <div @click="toHunterSystem" class="know-more-btn">了解更多</div>
          </div>
        </div>
        <div class="feature-item flex-col-enter">
          <div class="feature-item-img">
            <img src="//file.dhunting.com/official_website/v1/user.png" alt="">
          </div>
          <div class="feature-item-title">HR版</div>
          <div class="feature-item-content">
            <div class="content-item marginBottom16">
              <div class="item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
              <div class="item-content"><span class="fontW500">资产管理：</span>自建企业人才库，沉淀人才资产及招聘数据</div>
            </div>
            <div class="content-item marginBottom16">
              <div class="item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
              <div class="item-content"><span class="fontW500">Al小助理：</span>24小时智能寻访、人才评估、面试管理等降本增效功能</div>
            </div>
            <div class="content-item marginBottom16">
              <div class="item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
              <div class="item-content"><span class="fontW500">对外开源：</span>递航速聘、猎头联盟助力企业获取更多简历</div>
            </div>
            <div class="content-item marginBottom24">
              <div class="item-icon"><img src="//file.dhunting.com/official_website/v1/solute-icon.png" alt=""></div>
              <div class="item-content"><span class="fontW500">数据洞察：</span>招聘全流程数据分析，赋能企业做出决策</div>
            </div>
          </div>
          <div class="feature-item-btn">
            <a-button type="primary" class="btn marginR32" @click="toBuyHrSystem">立即体验</a-button>
<!--            <a-button type="primary" class="know-more btn" ghost @click="toHrSystem">了解更多</a-button>-->
            <div @click="toHrSystem" class="know-more-btn">了解更多</div>
          </div>
        </div>
      </div>
    </div>
    <div class="effient-collaboration-wrap flex-col-enter">
      <div class="title item-title item-title-blue">高效协同</div>
      <div class="info item-title">0时差的内外协同</div>
      <div class="info item-title">全面提高沟通及反馈效率</div>
      <div class="img">
        <img src="//file.dhunting.com/official_website/v1/efficent-collabor-banner.png" alt="">
      </div>
      <div class="sub-wrap">
        <div class="sub-title">猎头/RPO/外包版</div>
        <div class="sub-info">内协同：数据信息留存对齐、及通、接派单、任务看板、日程待办等。
          外协同：通过猎头联盟，与信任的猎企建立联盟，发挥各自的优势，一起高效交付</div>
        <a-divider></a-divider>
        <div class="sub-title">HR版</div>
        <div class="sub-info">三方协同：沉浸式流程管理，与用人经理/面试官、供应商0时差协同。
          外部协同：通过递航速聘社交裂变、猎头联盟助力交付，帮助企业获取更多优质简历。</div>
      </div>
    </div>
    <div class="deep-power-wrap flex-col-enter">
      <div class="title item-title item-title-blue">深度赋能</div>
      <div class="info item-title">让没有经验的小白也能瞬间</div>
      <div class="info item-title">变身招聘专家</div>
      <div class="img">
        <img src="//file.dhunting.com/official_website/v1/deep-power-banner.png" alt="">
      </div>
      <div class="content">
        <div class="content-item">
          管理赋能：先进的赋能型管理理念让没有任何经验的用户也能做好招聘绩效管理。
        </div>
        <div class="content-item">
          数据赋能：实时查看招聘过程中的每一项关键数据，及时察觉工作能力的优劣势。
        </div>
        <div class="content-item">
          AI赋能：企业简历自动化解析入库、智能画像分析、自动设置待办、发送面试提醒，将用户从反复的事务性工作中解脱出来。
        </div>
      </div>
    </div>
    <div class="accurate-open-source-wrap flex-col-enter">
      <div class="title item-title item-title-blue">精准开源</div>
      <div class="info item-title">递航速聘、猎头联盟助力企业</div>
      <div class="info item-title">获取更多优质简历</div>
      <div class="img">
        <img src="//file.dhunting.com/official_website/v1/deep-power-banner.png" alt="">
      </div>
      <div class="content">
        <div class="content-item">
          递航速聘：可快速扩散企业招聘信息，高效整合员工的二度、三度人脉，扩大简历来源。
        </div>
        <div class="content-item">
          猎头联盟：可获取更多专业猎头的优质简历推荐，在现有招聘渠道外，有效补充简历来源。
        </div>
        <div class="content-item">
          智能推荐：智能化推荐算法及人才获取路径追踪技术，降低职位筛选及扩散成本。
        </div>
      </div>
    </div>
    <div class="do-more-one flex-col-enter">
      <div class="title item-title">多做一步</div>
      <div class="title item-title">成为用户最信任的合作伙伴</div>
      <div class="data" ref="scroll">
        <div class="data1">
          <div class="data-item marginR60">
            <div class="data-item-top">
              <span class="data-item-top-data1 number">{{num1}}</span>
              <span class="percent">%</span>
              <iconpark-icon name="up" class="percent-icon"></iconpark-icon>
            </div>
            <div class="data-item-bottom">
              招聘成本 降低
            </div>
          </div>
          <div class="data-item">
            <div class="data-item-top">
              <span class="data-item-top-data2 number">{{num2}}</span>
              <span class="percent">%</span>
              <iconpark-icon name="down" class="percent-icon"></iconpark-icon>
            </div>
            <div class="data-item-bottom">
              推荐效率 提升
            </div>
          </div>
        </div>
        <div class="data2">
          <div class="data-item marginR60">
          <div class="data-item-top">
            <span class="data-item-top-data3 number">{{num3}}</span>
            <span class="percent">%</span>
            <iconpark-icon name="down" class="percent-icon"></iconpark-icon>
          </div>
          <div class="data-item-bottom">
            反馈效率 提升
          </div>
        </div>
          <div class="data-item">
            <div class="data-item-top">
              <span class="data-item-top-data4 number">{{num4}}</span>
              <span class="percent">%</span>
              <iconpark-icon name="down" class="percent-icon"></iconpark-icon>
            </div>
            <div class="data-item-bottom">
              候选人满意度 提升
            </div>
          </div>
        </div>
      </div>
      <div class="friend-intro">
        <mo-swiper></mo-swiper>
      </div>
    </div>
    <div v-if="headArticle.length>0" class="article-message flex-col-enter">
      <div class="title">文章资讯</div>
      <mo-article-card v-for="(item,index) in headArticle" :key="index"  :articleItem="item"></mo-article-card>
      <a-button class="more-article-btn" @click="toArticleTab" type="primary">更多文章</a-button>
    </div>
  </div>
</template>

<script>
import "@/assets/css/common.css"
import moSwiper from "@/views/MoPage/components/moSwiper";
import moArticleCard from "@/views/MoPage/components/moArticleCard";
export default {
  name: 'moHome',
  components: {
    moSwiper,
    moArticleCard
  },
  data() {
    return {
      //头部文章
      headArticle:[],
      num1:0,
      num2:0,
      num3:0,
      num4:0,
      timer1: null,
      timer2: null,
      timer3: null,
      timer4: null,
      elementScroll:undefined,
      isPlay:false
    };
  },
  mounted() {
    this.elementScroll=this.$refs.scroll
    window.addEventListener('scroll', this.handleScroll)
    this.headArticle=JSON.parse(localStorage.getItem('headArticle')) || []
  },
  methods:{
    toFreeUse(){
      this.$router.push('/freeUse')
    },
    toHrSystem(){
      this.$router.push('/hrSystem')
    },
    toBuyHrSystem(){
      this.$router.push('/buyHrSystem')
    },
    toHunterSystem(){
      this.$router.push('/hunterSystem')
    },
    toBuyHunterSystem(){
      this.$router.push('/productContact')
    },
    handlePlay(){
      this.isPlay=true
      const video=document.getElementById('dhuntingVideo')
      video.play()
    },
    handleStop(){
      const video=document.getElementById('dhuntingVideo')
      video.pause()
      this.isPlay=false
    },
    // 跳转文章资讯tab
    toArticleTab() {
      // let routerUrl = this.$router.resolve({path: '/indexArticle/defaultArticle'});
      // window.open(routerUrl.href, '_blank');
      this.$router.push('/defaultArticle')
    },
    handleScroll() {
      if(this.elementScroll){
        if(this.elementScroll.getBoundingClientRect().top < 800) {
          this.timer1 = setInterval(() => {
            if(this.num1 === 50) {
              this.elementScroll.querySelector('.data-item-top-data1').classList.add('bounceIn');
              return;
            }
            this.num1 += 1;
          }, 20);
          this.timer2 = setInterval(() => {
            if(this.num2 === 65) {
              this.elementScroll.querySelector('.data-item-top-data2').classList.add('bounceIn');
              return;
            }
            this.num2 += 1;
          }, 10);
          this.timer3 = setInterval(() => {
            if(this.num3 === 66) {
              this.elementScroll.querySelector('.data-item-top-data3').classList.add('bounceIn');
              return;
            }
            this.num3 += 1;
          }, 10);
          this.timer4 = setInterval(() => {
            if(this.num4 === 80) {
              this.elementScroll.querySelector('.data-item-top-data4').classList.add('bounceIn');
              return;
            }
            this.num4 += 1;
          }, 10);
          // this.$refs.scroll.querySelector('.percent-icon').classList.add('animate__slideInRight');
          // setTimeout(() => {
          //   this.elementScroll.querySelector('.percent-icon').classList.add('heartBeat');
          //   // this.$refs.scroll.querySelector('.rightBox-2').style.opacity = 1;
          // }, 2000)
        }
      }
    },
  },
  beforeDestory() {
    clearInterval(this.timer1);
    clearInterval(this.timer2);
    clearInterval(this.timer3);
    clearInterval(this.timer4);
  }
}
</script>
<style lang="less" scoped>
.know-more-btn{
  width: 104px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #0055FF;
  border: 1px solid #0055FF;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 400;
}
.fontW500{
  font-weight: 500;
}
.mo-home-banner{
  padding: 24px 32px;
  background-image: url("//file.dhunting.com/official_website/v1/mo-home-banner-bg.png");
  width: 100%;
  height: 100%;
}
.mo-home-banner-info{
  margin-top: 12px;
  margin-bottom: 32px;
}
.banner-btn{
  margin-top: 32px;
}
.mo-home-swiper-wrap{
  height: 390px;
  margin-top: 32px;
  .swiper-item{
    position: relative;
  }
  .swiper-title{
    padding: 0 10px;
    width: 311px;
    height: 50px;
    overflow: hidden;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #1B1C33;
    margin-bottom: 9px;
  }
  .swiper-img{
    width: 300px;
    height: 200px;
  }
  .dialog-wrap{
    display: flex;
    overflow: visible;
    position: absolute;
    top: 278px;
    left: 0;
    z-index: 99;
    width: 100%;
    .dialog-left{
      margin-right: 16px;
      width: 70px!important;
      .dialog-avatar{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-bottom: 5px;
      }
      .dialog-name{
        font-weight: 500;
        font-size: 20px;
        zoom: 0.5;
        color: #1B1C33;
      }
    }
    .dialog-right{
      padding: 8px 16px;
      width: 243px;
      background: #FFFFFF;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      border-radius: 0px 16px 16px 16px;
      font-size: 12px;
    }
  }
}
.mo-home-swiper /deep/ .ant-carousel .slick-list{
  overflow: visible;
}
.mo-home-swiper /deep/ .ant-carousel .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //overflow: hidden;
}
.mo-home-swiper /deep/ .slick-dots button{
  width: 24px !important;
  height: 2px !important;
  background: #EDEDF0!important;
  position: relative;
  top: 15px;
}
.mo-home-swiper /deep/  .slick-active button{
  background: #0055FF !important;
}
.marginBottom16{
  margin-bottom: 16px;
}
.marginBottom24{
  margin-bottom: 24px;
}
.marginBottom32{
  margin-bottom: 32px;
}
.mo-home-feature{
  padding: 32px;
  .feature-title{
    font-weight: 600;
    font-size: 18px;
    color: #1B1C33;
  }
  .feature-info{
    font-weight: 400;
    font-size: 14px;
    color: #5E5E66;
    margin: 8px 0 32px 0;
  }
  .feature-item-wrap{
    .feature-item{
      .feature-item-img{
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .feature-item-title{
        font-weight: 500;
        font-size: 14px;
        color: #1B1C33;
        margin: 10px 0 16px 0;
      }
      .feature-item-content{
        .content-item{
          display: flex;
          .item-icon{
            display: inline-block;
            margin-right: 8px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            height: 16px!important;
            line-height: 16px!important;
            img{
              font-size: initial;
              width: 12px!important;
              height: 12px!important;
            }
          }
          .item-content{
            font-weight: 400;
            font-size: 12px;
            color: #5E5E66;
            height: 16px!important;
            line-height: 16px!important;
          }
        }
      }
      .feature-item-btn{
        display: flex;
        //justify-content: space-between;
        justify-content: center;
        .marginR32{
          margin-right: 32px;
        }
        .btn{
          width: 104px;
          height: 32px;
          border-radius: 20px;
        }
        .know-more{
          color: #0055FF !important;
          //margin-left: 48px;
        }
        .know-more:hover, .know-more:focus{
          background-color: transparent !important;
        }
      }
    }
  }


}
.effient-collaboration-wrap{
  padding: 32px;
  .img{
    width: 244px;
    height: 200px;
    margin: 16px 0;
  }
  /deep/ .ant-divider{
    margin: 0;
  }
  .sub-wrap{
    background-color: #F9FAFF;
    border-radius: 8px;
    padding:0 16px;
  }
  .sub-title{
    font-weight: 500;
    font-size: 14px;
    color: #1B1C33;
    margin-top: 16px;
    display: flex;
    width: 100%;
    justify-content: left;
  }
  .sub-info{
    font-weight: 400;
    font-size: 12px;
    color: #5E5E66;
    margin: 16px 0;
  }
}
.deep-power-wrap{
  padding: 32px;
  img{
    width: 244px;
    height: 200px;
    margin: 16px 0 32px 0;
  }
  .content{
    padding: 16px;
    padding-bottom: 0;
    border-radius: 8px;
    background-color:  #F9FAFF;;
    .content-item{
      font-weight: 400;
      font-size: 12px;
      color: #5E5E66;
      margin-bottom: 20px;
    }
  }
}
.accurate-open-source-wrap{
  padding: 32px;
  img{
    width: 244px;
    height: 200px;
    margin: 16px 0 32px 0;
  }
  .content{
    padding: 16px;
    padding-bottom: 0;
    border-radius: 8px;
    background-color:  #F9FAFF;
    .content-item{
      font-weight: 400;
      font-size: 12px;
      color: #5E5E66;
      margin-bottom: 20px;
    }
  }
}
.marginR60{
  margin-right: 60px;
}
.do-more-one{
  background: #F9FAFF;
  padding-top: 32px;
  .data{
    margin-bottom: 24px;
    .data1,.data2{
      margin-top: 16px;
      display: flex;
      .data-item{
        .data-item-top{
          .number{
            font-weight: 500;
            font-size: 32px;
            color: #1B1C33;
          }
          .percent{
            font-weight: 500;
            font-size: 20px;
            color: #1B1C33;
          }
        }
        .data-item-bottom{
          margin-top: 8px;
          font-weight: 400;
          font-size: 12px;
          color: #464E69;
        }
      }

    }
  }
}
.article-message{
  padding: 32px;
  .title{
    margin: 32px 0;
    font-weight: 600;
    font-size: 18px;
    margin-top: 0;
  }
  .more-article-btn{
    width: 104px;
    height: 32px;
    border-radius: 20px;
  }
}
/deep/ .modal-home-video{
  .ant-modal-content{
    width: 300px;
    height: 200px;
    .ant-modal-body{
      width: 300px;
      height: 200px;
      padding: 0;
      position: relative;
      .video-wrap{
        width: 100%;
        height: 100%;
        video{
          width: 100%;
          height: 100%;
        }
      }
      .close-video{
        width: 15px;
        height: 15px;
        text-align: center;
        line-height: 15px;
        background: #0055FF;
        color: #FFFFFF;
        font-size: 10px;
        position: absolute;
        right: -15px;
        top: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
