<template>
  <div class="add-article-wrap">
    <div class="add-article-content-wrap">
      <div class="add-article-content">
        <a-form-model :model="form" layout="vertical">
          <a-form-model-item label="是否为头部文章">
            <a-radio-group v-model="form.isTopArticle">
              <a-radio :value="true">
                是
              </a-radio>
              <a-radio :value="false">
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="是否置顶文章">
            <a-radio-group v-model="form.isOnArticle">
              <a-radio :value="true">
                是
              </a-radio>
              <a-radio :value="false">
                否
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="文章类型">
            <a-radio-group v-model="form.articleType">
              <a-radio :value="item.owArticleTypeId" v-for="(item,index) in articleTypeList" :key="index">
                {{item.typeName}}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="标题内容">
            <a-input v-model="form.title" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="banner">
            <div class="clearfix">
              <a-upload
                  name="avatar"
                  list-type="picture-card"
                  class="avatar-uploader"
                  :show-upload-list="false"
                  :customRequest="doFileUpload"
                  :before-upload="beforeUpload"
              >
                <img v-if="imgUrl" :src="imgUrl" alt="avatar" />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">
                    Upload
                  </div>
                </div>
              </a-upload>
            </div>
          </a-form-model-item>
          <a-form-model-item label="正文内容">
            <template>
              <div style="border: 1px solid #ccc;">
                <Toolbar
                    style="border-bottom: 1px solid #ccc"
                    :editor="editor"
                    :defaultConfig="toolbarConfig"
                    :mode="mode"
                />
                <Editor
                    style="height: 230px;"
                    v-model="html"
                    :defaultConfig="editorConfig"
                    :mode="mode"
                    @onCreated="onCreated"
                />
              </div>
            </template>
          </a-form-model-item>
        </a-form-model>
        <div class="preview-artitle" @click="handlePreviewArticle">预览文章</div>
        <div class="bottom-btn center">
          <a-button class="cancle-btn" @click="handleCancelConfirm">
            取消
          </a-button>
          <a-button class="confirm-btn" @click="handleConfirm" type="primary">确定</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {getArticleDetail,addAndEditArticle,uploadImg,getArticleType} from '@/api/request'
export default {
  components:{
    Editor, Toolbar
  },
  data() {
    return {
      loading: false,
      //图片链接，无前缀
      imgUrl: '',
      form:{},
      editor: null,
      html: '',
      toolbarConfig: {
        excludeKeys: [
          // 排除菜单组，写菜单组 key 的值即可
          "group-video" //去掉视频
        ]
      },
      editorConfig: {MENU_CONF: {}, placeholder: '请输入正文内容' },
      mode: 'simple', // or 'simple'
      owArticleId:'',
      //上传文件路径
      uploadBaseUrl: process.env.VUE_APP_BASE_IMG_URL,
      articleTypeList:''
    };
  },
  methods: {
    handelGetArticleType(){
      getArticleType().then(res=>{
        if(res.data.code===200){
          this.articleTypeList=res.data.data
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    //检验是否有空的字段
    isEmpty(){
      if(this.imgUrl&&(''+this.form.isTopArticle)&&(''+this.form.isOnArticle)&&this.form.articleType&&this.form.title&&this.html){
        return true
      }else{
        this.$message.error('不能有为空的选项！');
        return false
      }

    },
    //获取当前时间
    getCurrentTime(){
      let today = new Date();
      let DD = String(today.getDate()).padStart(2, '0'); // 获取日
      let MM = String(today.getMonth() + 1).padStart(2, '0'); //获取月份，1 月为 0
      let yyyy = today.getFullYear(); // 获取年

      let hh =  String(today.getHours()).padStart(2, '0');       //获取当前小时数(0-23)
      let mm = String(today.getMinutes()).padStart(2, '0');     //获取当前分钟数(0-59)
      // let ss = String(today.getSeconds()).padStart(2, '0');     //获取当前秒数(0-59)
      today = yyyy + '-' + MM + '-' + DD + ' ' + hh + ':' + mm ;
      return today
    },

    handleCancelConfirm(){
      this.html=''
      this.imgUrl= '', this.form={},
          this.$message.warning('取消编辑！')
      this.$router.push('/manage/manageArticle')
    },
    handleConfirm(){
      if(!this.isEmpty()){
        return
      }
      const time=this.getCurrentTime()
      addAndEditArticle(
          {
            content:this.html,
            headerFlag:this.form.isTopArticle,
            topFlag:this.form.isOnArticle,
            owArticleTypeId:this.form.articleType,
            title:this.form.title,
            banner:this.imgUrl,
            owArticleId:this.owArticleId ? this.owArticleId : undefined,
            updateTime:time
          }
      ).then(res=>{
        // console.log('上传文章',res);
        if(res.data.code===200){
          this.$message.success('发布成功！');
          setTimeout(
              ()=>{
                this.$router.push('/manage/manageArticle')
              },
              2000
          )
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    async getArticleDetail(){
      await getArticleDetail(
          {
            owArticleId:this.owArticleId
          }
      ).then(res=>{
        if(res.data.code===200){
          // console.log(res.data,'获取文章详情')
          this.html=res.data.data.content
          this.$set(this.form,'isTopArticle',res.data.data.headerFlag)
          this.$set(this.form,'isOnArticle',res.data.data.topFlag)
          this.$set(this.form,'articleType',res.data.data.owArticleTypeId)
          this.$set(this.form,'title',res.data.data.title)
          this.imgUrl=res.data.data.banner
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    handlePreviewArticle(){
      if(!this.isEmpty()){
        return
      }
      const time=this.getCurrentTime()
      let obj={
        content:this.html,
        headerFlag:this.form.isTopArticle,
        topFlag:this.form.isOnArticle,
        owArticleTypeId:this.form.articleType,
        title:this.form.title,
        banner:this.imgUrl,
        owArticleId:this.owArticleId,
        updateTime:time
      }
      localStorage.setItem("article",JSON.stringify(obj));
      localStorage.setItem('isGetFromSession',true)
      // this.$router.push({path:'/indexArticle/articleDetail',query:{isPreview:true}})
      //编程式导航打开新页面
      let routeData = this.$router.resolve({
        query: {isPreview:true},
        path:'/indexArticle/articleDetail'
      });
      window.open(routeData.href, '_blank');
    },
    doFileUpload(file){
      const formData = new FormData();
      formData.append("file", file.file);
      uploadImg(formData).then(res=>{
        if(res.data.code===200){
          //路径不能写死，外面再建一个配置文件
          this.imgUrl=this.uploadBaseUrl+res.data.data.path
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    beforeUpload(file) {
      let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' ||file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("只能上传png、jpg、jpeg格式的图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 10MB!');
      }
      return isJpgOrPng && isLt2M;
    },
  },
  async mounted() {
    this.owArticleId=this.$route.query.owArticleId || ''
    //如果isGetFromSession为true，从缓存中获取
    //只有传参才是修改文章
    if(this.owArticleId){
      await this.getArticleDetail()
    }
    this.$nextTick( ()=>{
      if(localStorage.getItem('isGetFromSession')=='true'){
        const obj =JSON.parse(localStorage.getItem('article'))
        this.html=obj.content
        this.form.isTopArticle=obj.headerFlag
        this.form.isOnArticle=obj.topFlag
        this.form.articleType=obj.owArticleTypeId
        this.form.title=obj.title
        this.imgUrl=obj.banner
        this.owArticleId=obj.owArticleId
        // this.updateTime=obj.updateTime
        localStorage.setItem('isGetFromSession',false)
      }
    })

    this.handelGetArticleType()
    // setTimeout(()=>{
    // },3000)
    // // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    // }, 1500)
  },
  created() {
    const  that=this
    this.editorConfig.MENU_CONF["uploadImage"] = {
      timeout: 5 * 1000, // 5s
      fieldName: "file",
      maxFileSize: 10 * 1024 * 1024, // 10M
      onBeforeUpload(files) {
        return files; // 返回哪些文件可以上传
        // return false 会阻止上传
      },
      onProgress(progress) {
        console.log("onProgress", progress);
      },
      onSuccess(file, res) {
        console.log("onSuccess", file, res);
      },
      onFailed(file, res) {
        alert(res.message);
        console.log("onFailed", file, res);
      },
      onError(file, err, res) {
        alert(err.message);
        console.error("onError", file, err, res);
      },
      // 用户自定义上传图片
      customUpload(file, insertFn) {
        const formData = new FormData();
        formData.append("file", file);
        uploadImg(formData).then(res=>{
          if(res.data.code===200){
            //路径不能写死，外面再建一个配置文件
            let url = that.uploadBaseUrl + res.data.data.path; //拼接成可浏览的图片地址
            insertFn(url,'图片',url); //插入图片
          }
          else {
            this.$message.error(res.data.message);
          }
        })

      }

    };
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.add-article-wrap{
  padding: 24px 198px 59px 198px;
  max-width: 1184px;
}

/deep/ .ant-form-item-label label{
  font-weight: 500;
  font-size: 14px;
  color: #1B1C33;
  /*background-color: red;*/
}
/deep/ .ant-radio-wrapper span{
  font-size: 12px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.preview-artitle{
  color: #0055FF;
  font-weight: 500;
  font-size: 14px;
  margin: 24px 0;
  cursor:pointer;
}
.confirm-btn{
  margin-left: 16px;
}
.cancle-btn,
.confirm-btn{
  width: 120px;
  height: 48px;
  border: 1px solid #D7D7DB;
  border-radius: 8px;
}
/deep/ .w-e-toolbar{
  white-space:normal;
  flex-direction: row;
  width: 100%;
  overflow-y: hidden;
}
/deep/ .w-e-bar-item{
  /*display: flex;*/
  /*width: 50px;*/
  /*height: 30px;*/
}

/deep/ .w-e-toolbar{
  overflow-y: visible;
}
</style>