<template>
  <div id="app">
    <component :is="pages"></component>
  </div>
</template>

<script>
import CIndex from '@/views/PCPage/index';
import MIndex from '@/views/MoPage/index';
import bindWeixinSuccess from "@/views/MoPage/views/bindWeixinSuccess";

export default {
  name: 'App',
  components: {
    CIndex,
    MIndex,
    bindWeixinSuccess
  },
  data() {
    return {
      pages:'',
    };
  },
  mounted() {
    this.pages = this._isMobile();
    // 系统内微信授权成功页面
    if(this.pages == 'MIndex'){
      if(window.location.pathname == '/Success' || window.location.pathname == '/Success/'){
        this.pages = 'bindWeixinSuccess';
      }
    }
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      if (flag) {
        return 'MIndex'
      } else {
        return 'CIndex'
      }
    },
    homeScroll(){
      if(this.element2){
        if (window.scrollY!=0) {
          this.element2.classList.add('scroll')
        } else {
          this.element2.classList.remove('scroll')
        }
      }
    }
  }
}
</script>

<style  lang="less">
.scroll{
  box-shadow: 12px 0px 48px 16px rgba(0, 0, 0, 0.03), 6px 0px 16px -8px rgba(0, 0, 0, 0.08);
}
//根据图片宽高自适应，会有留白
#app{
  //min-width: 1240px;
}
img{
  width: 100%;
  height: 100%;
  object-fit: contain!important;
}
 *,a,ul,li,p,span{
   margin:0;
   padding:0;
   list-style: none;
 }
#app {
  font-family: PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  color: #2c3e50;
  width: 100%;
  height:auto;
  overflow: hidden;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.small-hand{
  cursor: pointer;
}

</style>
