<!--产品选择-->
<template>
  <div class="product-wrap">
    <div class="product">
      <!--      猎头系统版本对比页面-->
      <comHuntingSystem @systemTypeReset="systemTypeReset"></comHuntingSystem>
      <!--      hr系统预约演示页面-->
      <hrSystem @systemTypeReset="systemTypeReset" @toHome="toHome" v-if="systemType === 2"></hrSystem>
    </div>
    <!--<div class="product">
      <div class="choose-title">请选择您倾向的产品</div>
      <div class="product-content">
        <div class="content-left-wrap" @click="systemType = 1">
          <div class="img-wrap">
            <img src="//file.dhunting.com/official_website/headhunterImage.png" alt="" class="headhunting-img">
            <img src="//file.dhunting.com/official_website/headhuntingHover.png" alt="" class="headhunting-img-hover">
          </div>
          <div class="text">猎头系统</div>
        </div>
        <div class="content-right-wrap" @click="systemType = 2">
          <div class="img-wrap">
            <img src="//file.dhunting.com/official_website/hrImage.png" alt="" class="hr-img">
            <img src="//file.dhunting.com/official_website/hrHover.png" alt="" class="hr-img-hover">
          </div>
          <div class="text">HR系统</div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import comHuntingSystem from "@/views/PCPage/productContact/component/comHunterSystem/comHunterSystem";
import hrSystem from "@/views/PCPage/productContact/component/comHrSystem/comHrSystem";
export default {
  components: {comHuntingSystem, hrSystem},
  data() {
    return {
      systemType: 0, // 1为猎头系统，2为HR系统,
      inviteUserId: undefined
    }
  },
  mounted() {
    // 默认企业版/SOHO版
    if(this.$route.query && this.$route.query.systemType) {
      this.systemType = Number(this.$route.query.systemType);
    }
    // 存在inviteUserId，则为邀请链接
    if( this.$route.query && this.$route.query.inviteUserId){
      this.inviteUserId = this.$route.query.inviteUserId;
    }
  },
  methods: {
    // 将systemType重置为0
    systemTypeReset() {
      this.systemType = 0;
    },
    // 返回首页
    toHome() {
      this.systemType = 0;
      this.$emit('toHome')
    }
  },
}
</script>

<style lang="less" scoped>
.product-wrap {
  padding-top: 142px;
}
.product {
  min-height: calc(100vh - 80px - 383px - 55px);
}
.choose-title {
  color: #1B1C33;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  padding: 74px 0 80px;
}
.product-content {
  display: flex;
  justify-content: space-around;
  padding: 0 66px;
  margin-bottom: 96px;
}
.content-left-wrap,
.content-right-wrap {
  cursor: pointer;
}
.content-left-wrap > .img-wrap,
.content-right-wrap > .img-wrap {
  width: 472px;
  height: 278px;
  margin: 40px 0 64px;
  border-radius: 6px;
  box-shadow: 0 9px 28px 8px rgba(0, 85, 255, 0.05), 0 6px 16px rgba(0, 85, 255, 0.08), 0 3px 6px -4px rgba(0, 85, 255, 0.12);
  transition: all 0.3s ease-out; /*ease-out 减速、慢慢结束*/
  -webkit-transition: all 0.3s ease-out;
}
.content-left-wrap img,
.content-right-wrap img {
  width: 100%;
  height: 100%;
}
.content-left-wrap .headhunting-img-hover,
.content-right-wrap .hr-img-hover {
  display: none;
  transition: all 0.3s ease-out; /*ease-out 减速、慢慢结束*/
  -webkit-transition: all 0.3s ease-out;
}
.content-left-wrap > .text,
.content-right-wrap > .text {
  color: #1B1C33;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
}
.content-left-wrap:hover .img-wrap,
.content-right-wrap:hover .img-wrap {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  border-radius: 16px;
  background: linear-gradient(112.45deg, #075AFF 14.21%, #6396EF 89.13%), #FFFFFF;
  box-shadow: 0 9px 28px 8px rgba(0, 85, 255, 0.05), 0 6px 16px rgba(0, 85, 255, 0.08), 0 3px 6px -4px rgba(0, 85, 255, 0.12);
}
.content-left-wrap:hover .headhunting-img,
.content-right-wrap:hover .hr-img {
  display: none;
}
.content-left-wrap:hover .headhunting-img-hover,
.content-right-wrap:hover .hr-img-hover {
  display: inline-block;
}
.content-left-wrap:hover > .text,
.content-right-wrap:hover > .text {
  color: #0055FF;
}
</style>
