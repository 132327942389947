<template>
  <!-- 轮播图 -->
  <div class="swiper">
    <div class="swiper-wrapper-soho" id="swiper-wrapper-soho">
      <div  class="swiper-slide">
        <div class="mo-product-card flex-col-enter">
          <div class="title">Free版</div>
          <div class="price">￥0</div>
          <div class="per-day">账号/天</div>
          <div class="info">
            <div>
              可储存2000份简历
            </div>
            <div>
              无推荐  面试  offer等过程性数据分析
            </div>
          </div>
<!--          <div class="btn" @click="sohoTypeChangeFun(1)">立即体验</div>-->
          <div class="btn-wrap">
            <div class="btn" @click="toSoHoFree">立即体验</div>
          </div>
          <div class="img">
            <img src="//file.dhunting.com/official_website/criterionVersion.png" alt="">
          </div>
        </div>
      </div>
      <div  class="swiper-slide">
        <div class="mo-product-card flex-col-enter">
          <div class="title">Pro版</div>
          <div class="price">￥1<span>.01</span></div>
          <div class="per-day">账号/天</div>
          <div class="info">
            <div>可储存1万份简历</div>
            <div>拥有SOHO的全部功能</div>
          </div>
<!--          <div class="btn" @click="sohoTypeChangeFun(2)">立即购买</div>-->
          <div class="btn-wrap">
            <div class="swiper-slide-update">注册Free版后升级</div>
          </div>
          <div class="img">
            <img src="//file.dhunting.com/official_website/criterionVersion.png" alt="">
          </div>
        </div>
      </div>
      <div  class="swiper-slide">
        <div class="mo-product-card flex-col-enter">
          <div class="title">Max版</div>
          <div class="price">￥1<span>.56</span></div>
          <div class="per-day">账号/天</div>
          <div class="info">
            <div>可储存10万份简历</div>
            <div>拥有SOHO的全部功能</div>
          </div>
<!--          <div class="btn" @click="sohoTypeChangeFun(3)">立即购买</div>-->
          <div class="btn-wrap">
            <div class="swiper-slide-update">注册Free版后升级</div>
          </div>
          <div class="img">
            <img src="//file.dhunting.com/official_website/criterionVersion.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  data() {
    return {
      // domParent:null,
      // lastChild:null,
      // firstChild:null,
      // moveWidth:0,
      // prizeTimer: null,
      // startX:0,
      // startY:0,
      // moveEndX:0,
      // moveEndY:0,
    };
  },
  mounted() {
    //获取要操作的dom元素
    // this.domParent = document.getElementById('swiper-wrapper-soho');
    // this.lastChild = this.domParent.lastElementChild;
    // this.firstChild = this.domParent.firstElementChild;
  },
  methods:{
    toSoHoFree(){
      this.$router.push('/productContact/soHoFree')
    },
    // 点击立即体验进入购买详情
    sohoTypeChangeFun(type) {
      this.$emit('sohoTypeChange', type);
    },
    // //开始移动
    // moveStart(e){
    //   e.preventDefault();
    //   this.startX = e.changedTouches[0].pageX
    //   this.startY = e.changedTouches[0].pageY;
    // },
    // //移动结束
    // moveSlide(e){
    //   e.preventDefault();
    //   this.moveEndX = e.changedTouches[0].pageX
    //   this.moveEndY = e.changedTouches[0].pageY
    //   let X = this.moveEndX - this.startX
    //   let Y = this.moveEndY - this.startY
    //   //有可能出现不是水平滑动，需要计算Y
    //   if ( Math.abs(X) > Math.abs(Y) && X > 0 ) {
    //     this.next()
    //   }
    //   else if ( Math.abs(X) > Math.abs(Y) && X < 0 ) {
    //     this.pre()
    //   }
    //
    // },
    // //右滑
    // pre(){
    //   // 将最后一个子元素插入到第一个子元素之前
    //   if(!this.moveWidth){
    //     clearInterval(this.prizeTimer);
    //     this.prizeTimer = null;
    //     this.moveWidth=document.getElementsByClassName('swiper-slide')[0].offsetWidth
    //     const width=this.moveWidth
    //     let scrollX=0
    //     this.prizeTimer = setInterval(() => {
    //       if(this.moveWidth>0){
    //         scrollX++;
    //         // 需要移动的距离-1
    //         this.moveWidth--;
    //         if (scrollX === width) {
    //           this.domParent.insertBefore(this.lastChild, this.firstChild);
    //         }
    //         this.domParent.style.transform = `translateX(${this.scrollX}px)`;
    //       }
    //       else{
    //         clearInterval(this.prizeTimer);
    //         this.prizeTimer = null;
    //         this.moveWidth = 0;
    //       }
    //     },1)
    //   }
    // },
    // //左滑
    // next(){
    //   // 将最后一个子元素插入到第一个子元素之前
    //   if(!this.moveWidth){
    //     clearInterval(this.prizeTimer);
    //     this.prizeTimer = null;
    //     this.moveWidth=document.getElementsByClassName('swiper-slide')[0].offsetWidth
    //     const width=this.moveWidth
    //     let scrollX=0
    //     this.prizeTimer = setInterval(() => {
    //       if(this.moveWidth>0){
    //         scrollX++;
    //         // 需要移动的距离-1
    //         this.moveWidth--;
    //         if (scrollX === width) {
    //           this.domParent.removeChild(this.firstChild);
    //           this.domParent.appendChild(this.firstChild);
    //         }
    //         this.domParent.style.transform = `translateX(-${this.scrollX}px)`;
    //       }
    //       else{
    //         clearInterval(this.prizeTimer);
    //         this.prizeTimer = null;
    //         this.moveWidth = 0;
    //       }
    //     },1)
    //   }
    // }
  }
};
</script>

<style lang="less" scoped>

.swiper{
  //width: 100vw!important;
  overflow-x: auto;
  //box-sizing: content-box;
}
.swiper-wrapper-soho{
  overflow-x: auto;
  display: flex;
  padding:8px 87.5px;
  padding-bottom: 40px;
  //transform: translateX(-375px);
}
//去除overflow产生的滚动条
.swiper-wrapper-soho::-webkit-scrollbar {
  display: none;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin-right: 32px;
}
.mo-product-card{
  position: relative;
  padding: 24px 18px 32px;
  width: 200px;
  height: 304px;
  background: #FFFFFF;
  box-shadow: 0px 6.82867px 21.2448px 6.06993px rgba(0, 0, 0, 0.05), 0px 4.55245px 12.1399px rgba(0, 0, 0, 0.08), 0px 2.27622px 4.55245px -3.03497px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  .title{
    font-weight: 500;
    font-size: 14px;
    color: #1B1C33;
  }
  .price{
    margin-top: 16px;
    font-weight: 600;
    font-size: 32px;
    color: #1B1C33;
  }
  .per-day{
    font-weight: 400;
    font-size: 10px;
    color: #8D8E99;
  }
  .info{
    font-weight: 400;
    font-size: 12px;
    color: #8D8E99;
    margin: 24px 0 0 0;
    text-align: center;
  }
  .btn-wrap{
    display: flex;
    height: 100%;
    width: 100%;
    align-items:flex-end;
    justify-content: center;
  }
  .btn{
    position: relative;
    z-index: 999;
    border-radius: 51.0404px;
    //width: 96px;
    display: flex;
    align-items: center;
    height: 33px;
    padding: 8px 24px;
    color: #FFFFFF;
    font-size: 12px;
    background: linear-gradient(91.61deg, #075AFF 4.65%, #679CF8 96.64%)
  }
  .img{
    position: absolute;
    width: 200px;
    height: 180px;
    left: 0;
    top: 124px;
  }
}
.swiper-slide-update{
  font-weight: 600;
  font-size: 12px;
  color: #0055FF;

}
</style>