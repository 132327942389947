import { render, staticRenderFns } from "./swiperIndex.vue?vue&type=template&id=b58f9e02&scoped=true"
import script from "./swiperIndex.vue?vue&type=script&lang=js"
export * from "./swiperIndex.vue?vue&type=script&lang=js"
import style0 from "./swiperIndex.vue?vue&type=style&index=0&id=b58f9e02&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b58f9e02",
  null
  
)

export default component.exports