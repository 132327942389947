<template>
  <div class="enterprise-wrap">
    <div class="mo-product-card flex-col-enter">
      <div class="title">标准版</div>
      <div class="price">￥4.35</div>
      <div class="per-day">账号/天</div>
      <div class="info">
        <div>可储存100万份简历</div>
        <div>拥有全部功能</div>
      </div>
<!--      <div class="btn" @click="enterpriseTypeChangeFun(2)">立即咨询</div>-->
      <div class="btn-wrap">
        <div class="btn" @click="toEnterpriseCriterion">立即咨询</div>
      </div>
      <div class="img">
        <img src="//file.dhunting.com/official_website/criterionVersion.png" alt="">
      </div>
    </div>
    <div class="mo-product-card flex-col-enter">
      <div class="title">免费版</div>
      <div class="price">￥0</div>
      <div class="per-day">账号/天</div>
      <div class="info">
        <div>可储存3000份简历</div>
        <div>无合同、发票、业绩预测及管理功能</div>
      </div>
<!--      <div class="btn" @click="enterpriseTypeChangeFun(1)">立即体验</div>-->
      <div class="btn-wrap">
        <div class="btn" @click="toEnterpriseFree">立即体验</div>
      </div>
      <div class="img">
        <img src="//file.dhunting.com/official_website/criterionVersion.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/common.css'
export default {
  name: 'moEnterPrice',
  components: {

  },
  data() {
    return {

    };
  },
  methods:{
    toEnterpriseCriterion(){
      this.$router.push('/productContact/enterpriseCriterion')
    },
    toEnterpriseFree(){
      this.$router.push('/productContact/enterpriseFree')
    },
    // 点击立即体验进入购买详情
    enterpriseTypeChangeFun(type) {
      this.$emit('enterpriseTypeChange', type);
    }
  }
}
</script>

<style lang="less" scoped>
.enterprise-wrap{
  overflow-x: auto;
  display: flex;
  padding:8px 87.5px;
  padding-bottom: 40px;
  //transform: translateX(-375px);
}
//去除overflow产生的滚动条
.enterprise-wrap::-webkit-scrollbar {
  display: none;
}

.mo-product-card{
  position: relative;
  flex-shrink: 0;
  padding: 24px 18px 32px;
  width: 200px!important;
  height: 304px;
  margin-right: 32px;
  background: #FFFFFF;
  box-shadow: 0px 6.82867px 21.2448px 6.06993px rgba(0, 0, 0, 0.05), 0px 4.55245px 12.1399px rgba(0, 0, 0, 0.08), 0px 2.27622px 4.55245px -3.03497px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  .title{
    font-weight: 500;
    font-size: 14px;
    color: #1B1C33;
  }
  .price{
    margin-top: 16px;
    font-weight: 600;
    font-size: 32px;
    color: #1B1C33;
  }
  .per-day{
    font-weight: 400;
    font-size: 10px;
    color: #8D8E99;
  }
  .info{
    font-weight: 400;
    font-size: 12px;
    color: #8D8E99;
    margin: 24px 0 0 0;
    text-align: center;
  }
  .btn-wrap{
    display: flex;
    height: 100%;
    width: 100%;
    align-items:flex-end;
    justify-content: center;
  }
  .btn{
    border-radius: 51.0404px;
    //width: 96px;
    height: 33px;
    display: flex;
    align-items: center;
    padding: 8px 24px;
    color: #FFFFFF;
    font-size: 12px;
    background: linear-gradient(91.61deg, #075AFF 4.65%, #679CF8 96.64%);
    position: relative;
    z-index: 999;
  }
  .img{
    position: absolute;
    width: 200px;
    height: 180px;
    left: 0;
    top: 124px;
  }
}
</style>

