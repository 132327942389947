<template>
  <div class="new-core-member-wrap" >
    <van-swipe :show-indicators="false" :width="304" class="core-member-item-wrap">
      <van-swipe-item class="core-member-item" v-for="(item, index) in coreStuffList" :key="index">
        <div class="core-member-item-img">
          <img :src="item.img" alt="">
        </div>
        <div class="core-member-item-name-info"><span>{{item.name}}{{item.info}}</span></div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: 'newCoreMember',
  components: {
  },
  data() {
    return {
      coreStuffList: [
        {
          name: '付亚楠',
          img: "//file.dhunting.com/official_website/v1/alma.png",
          info: 'Dhunting CEO。12年在线招聘行业经验，经历智联和猎聘从初创到上市的全过程；全盘负责过业务、产品、运营等模块。从0到1地打造了从团队治理、人才培养到产品打造、运营管理等一系列行业经典案例。'
        },
        {
          name: 'Lily',
          img: "//file.dhunting.com/official_website/v1/lily.png",
          info: '战略发展部负责人。十年+在线招聘行业经验，曾任知名上市公司战略大客户经理，服务金融行业标杆企业，擅长为企业提供人力资源创新解决方案以及为招聘团队赋能，多次荣获全国冠军。'
        },
        {
          name: 'Lu Jie',
          img: "//file.dhunting.com/official_website/v1/lujie.png",
          info: 'Dhunting CTO。近10年技术研发及管理经验，曾担任多个知名互联网公司CTO，负责过多个企业服务SAAS项目的从0到1。精通微服务架构/容器/算法/集群/高并发。擅长微服务架构设计及治理，系统重构方案与实施，系统优化、互联网转型和非功能性架构设计等。'
        },
        {
          name: '无名',
          img: "//file.dhunting.com/official_website/v1/wuming.png",
          info: '技术专家。拥有15年互联网行业从业经历，曾作为技术专家就职于阿里巴巴，并担任北京知名研究院科学家顾问，主导项目获国家科技进步二等奖；擅长技术突破和技术攻坚；长期致力于前沿技术研究，在芯片设计、图形视觉、机器学习等多领域拥有多项发明专利。'
        },
      ],
    };
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
.new-core-member-wrap{
  overflow: hidden;
}
//这应该算是vant轮播图的一个bug，如果给core-member-item-wrap加宽度，
//不能自适应，只能以width的属性的方式添加，而且计算也貌似有点问题，多一个12
.core-member-item{
  position: relative;
  padding: 0 12px;
  box-sizing: border-box;
  .core-member-item-img{
    width: 280px;
    height: 280px;
  }
  .core-member-item-name-info{
    position: absolute;
    width: 280px;
    height: 100px;
    bottom: 0px;
    left: 12px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 0px 0px 8px 8px;
    padding: 8px;
    font-weight: 400;
    font-size: 10px;
    //zoom: 0.5;
    color: #FFFFFF;
    z-index: 9999;
    span{
      zoom: 0.5;
      font-size: 20px;
    }
  }
}
</style>

