<!--HR系统预约演示-->
<template>
  <div class="hr-wrap">
    <div class="hr-top">
      <div class="return-wrap" @click="returnFun">
        <a-icon type="left" />
        <span>返回</span>
      </div>
      <div class="top-text">HR系统预约演示</div>
    </div>
    <div class="hr-content">
      <div class="hr-form">
        <a-form-model :model="form" :rules="rules" ref="form" :hideRequiredMark="true">
          <a-form-model-item label="公司名称" prop="companyName">
            <a-input placeholder="请输入公司名称" v-model="form.companyName"></a-input>
          </a-form-model-item>
          <a-form-model-item label="姓名" prop="name">
            <a-input placeholder="请输入您的姓名" v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="电话" prop="phone">
            <a-input placeholder="请输入您的手机号码" v-model="form.phone"></a-input>
          </a-form-model-item>
          <a-form-model-item label="公司地址" prop="baseShow">
            <areaCascader
                @cascaderChange="onCityChange"
                :defaultList="form.baseShow"
                type="city"
            />
          </a-form-model-item>
          <a-form-model-item label="公司规模" prop="companySize">
            <a-select placeholder="请选择公司规模" v-model="form.companySize">
              <a-select-option
                  v-for="(item, index) in employeesList"
                  :key="index"
                  :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
<!--          <a-form-model-item label="公司行业" :colon="false" prop="industryShow">-->
<!--             <areaCascader-->
<!--               @cascaderChange="onIndustryChange"-->
<!--               :defaultList="form.industryShow"-->
<!--               type="industry"-->
<!--             />-->
<!--          </a-form-model-item>-->
          <a-form-model-item label="从哪个渠道了解到递航：" prop="consultationChannelId">
            <a-select
                dropdownClassName="select-wrap"
                v-model="form.consultationChannelId"
                placeholder="请选择"
                :getPopupContainer="(triggerNode)=>triggerNode.parentNode"
            >
              <a-select-option v-for="(item, index) in consultationChannelList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-form-model-item v-if="form.consultationChannelId && form.consultationChannelId === '5'" label="" :colon="false" prop="consultationChannelName">
              <a-input placeholder="请输入" v-model="form.consultationChannelName"></a-input>
            </a-form-model-item>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div>
        <img src="//file.dhunting.com/official_website/hrSystemWrite.svg" alt="">
      </div>
    </div>
    <a-button type="primary" class="consultation-btn" @click="onSubmit">立即咨询</a-button>
    <successModal :is-visible="visible" type="order" @cancel="cancel"></successModal>
  </div>
</template>

<script>
import {consultationSave, getEmployees, listChannel} from '@/api/request'
import areaCascader from "@/components/areaCascader";
import successModal from "@/views/PCPage/productContact/component/successModal";

export default {
  components: { successModal,areaCascader },
  data() {
    return {
      visible: false, // 是否展示预约成功弹窗
      employeesList: [], // 公司人员规模列表,
      base: '',
      baseShow:[],
      consultationChannelId: undefined,  //渠道
      consultationChannelName: "",  //其他渠道
      consultationChannelList: [],
      form: {
        companyName: undefined, // 公司名称
        name: undefined, // 姓名
        phone: undefined, // 电话
        companySize: undefined, // 公司规模
        consultationChannelId: undefined,  //渠道
        consultationChannelName: "",  //其他渠道
        base: '',
        baseShow:[],
        industry: undefined,
        industryShow: [],
        intentProductType: undefined
      },
      rules: {
        companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        industryShow: [
          { required: true, message: '请选择行业', trigger: ['blur', 'change'] },
        ],
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        phone: [
          {
            required: true,
            len: 11,
            pattern: RegExp(/(^(\+?0?86-?)?1[3456789]\d{9}$)|(^(\+?852-?)?[569]\d{3}-?\d{4}$)|(^(\+?886-?|0)?9\d{8}$)|(^(0065)\d{8}$)/),
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        // 大陆手机号：/^(\+?0?86-?)?1[345789]\d{9}$/
        // 香港手机号：/^(\+?852-?)?[569]\d{3}-?\d{4}$/
        // 台湾手机号：/^(\+?886-?|0)?9\d{8}$/
        // 新加坡手机号：/^(0065)\d{8}$/
        companySize: [{ required: true, message: '请选择公司规模', trigger: ['change', 'blur'] }],
        baseShow: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] },
        ],
        consultationChannelId: [{ required: true, message: '请选择渠道', trigger: ['blur', 'change'] },],
        consultationChannelName: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.consultationChannelId && this.form.consultationChannelId === '5' && (!this.form.consultationChannelName || this.form.consultationChannelName === "") ) {
                callback(new Error("请输入渠道"))
              }else {
                callback()
              }
            }
          }
        ]
      },
    }
  },
  async mounted() {
    //获取公司规模
    await this.getEmployeesByCodeFun();
    // 获取渠道
    await this.getChannelFun()
  },
  methods: {
    // 行业选择
    onIndustryChange(value) {
      if (value.length > 0) {
        // this.form.industry = value[value.length - 1];
        this.$set(this.form,'industry',value[value.length - 1])
        // this.form.industryShow = value;
        this.$set(this.form,'industryShow',value)
      }
      else {
        // this.form.industry = '';
        this.$set(this.form,'industry','')
        this.$set(this.form,'industryShow',[])
        // this.form.industryShow = [];
      }
    },
    // 城市选择
    onCityChange(value) {
      if (value.length > 0) {
        this.$set(this.form,'base',value[value.length - 1])
        // this.form.base = value[value.length - 1];
        // this.form.baseShow = value;
        this.$set(this.form,'baseShow',value)
      }
      else {
        // this.form.base = '';
        this.$set(this.form,'base','')
        // this.form.baseShow = [];
        this.$set(this.form,'baseShow',[])
      }
    },
    // 获取人员规模
    async getEmployeesByCodeFun() {
      let res = await getEmployees();
      if (res.data.code === 200) {
        this.employeesList = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    //从哪里了解到递航
    async getChannelFun() {
      let { data } = await listChannel()
      if (data.code === 200) {
        this.consultationChannelList = data.data
      }else {
        this.$message.error(data.message)
      }
    },
    // 返回上一个页面
    returnFun() {
      // 调用父组件的重置systemType方法
      this.$emit('systemTypeReset');
    },
    // 立即体验
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const tempObj={
            base:this.form.base,
            companyName:this.form.companyName,
            companySize:this.form.companySize,
            consultationChannelId:this.form.consultationChannelId,
            consultationChannelName:this.form.consultationChannelName,
            phone:this.form.phone,
            userName:this.form.name,
            industry: this.form.industry,
            intentProductType: this.form.intentProductType
          }
          consultationSave(tempObj).then(res=>{
            if(res.data.code === 200) {
              // this.$message.success('提交成功');
              this.visible = true;
              let form = {
                companyName: "",  //公司名称
                companySize: undefined, // 公司规模
                consultationChannelId: undefined,  //渠道
                consultationChannelName: "",  //其他渠道
                name: '',
                phone:'',
                base: '',
                baseShow:[],
                industry: undefined,
                industryShow: [],
                intentProductType: undefined
              };
              this.form = JSON.parse(JSON.stringify(form));
              //通知areaCasader组件清空数据
            }
            else {
              this.$message.error(res.data.message);
            }
          })
        } else {
          return false;
        }
      });
    },
    // 预约成功弹窗关闭
    cancel() {
      this.visible = false;
    },
  }
}
</script>

<style lang="less" scoped>
.hr-wrap {
  text-align: center;
  min-height: calc(100vh - 470px);
  width: 100%;
  height: 100%;
  background: url("//file.dhunting.com/official_website/hrSystemBg.png") no-repeat;
  background-size: 100% 100%;
}
.hr-top {
  position: relative;
  padding: 64px 0 90px;
}
.top-text {
  color: #1B1C33;
  font-size: 36px;
  font-weight: 500;
  text-align: center;
}
.hr-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 220px;
  margin-bottom: 80px;
}
.hr-form {
  margin-right: 200px;
}
.hr-form /deep/ .ant-form{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/deep/.ant-form-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}
/deep/.ant-form-item-label {
  //width: 100px;
}
/deep/.ant-form-item-label label {
  color: #1B1C33;
  font-size: 16px;
  font-weight: 500;
  margin-right: 24px;
}
/deep/.ant-form-item-control input,
/deep/.ant-form-item-control .ant-select > div {
  width: 378px;
}
.consultation-btn {
  width: 300px;
  height: 56px;
  font-size: 24px;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 48px;
}
</style>
<style lang="less">
a, a:hover {
  color: #0055FF!important;
}
.ant-select-selection:hover {
  border-color: #0055FF!important;
}
.ant-btn {
  color: #0055FF!important;
  border-color: #0055FF!important;
}
.ant-btn-primary {
  color: #FFFFFF!important;
  border-color: #0055FF!important;
  background-color: #0055FF!important;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: #0049D9!important;
  background-color: #0049D9!important;
}
.ant-btn-danger {
  color: #FFFFFF!important;
  border-color: #FF812D!important;
  background-color: #FF812D!important;
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  border-color: #FE6C30!important;
  background-color: #FE6C30!important;
}
.ant-form-item-label {
  height: 42px!important;
  line-height: 42px!important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-form-item-control .ant-input:focus,
.ant-form-item-control .ant-input:hover {
  border-color: #0055FF!important;
}
.ant-form .ant-form-item:nth-last-child(1) {
  margin-bottom: 0!important;
}
.ant-select-selection__rendered {
  height: 40px!important;
}
.ant-form-item-control {
  position: relative;
  line-height: normal;
}
.ant-form-explain {
  position: absolute;
  left: 0;
  bottom: calc(-100% + 20px);
  text-align: left;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #FF4D4F!important;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  border-color: #FF4D4F!important;
}
input,
.ant-select > div,
.ant-select-selection-selected-value {
  height: 42px!important;
  line-height: 42px!important;
  border-radius: 6px!important;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #E6F2FF!important;
}
 .ant-cascader-picker-label{
  text-align: left!important;
}
 .ant-select-dropdown-menu-item,
 .ant-cascader-menu-item
 {
   text-align: left!important;
 }
</style>
