<template>
  <!-- 轮播图 -->
  <div class="swiper" id="swiper1">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in friendList" :key="index">
        <div class="friend-intro-title">{{item.title}}</div>
        <div class="friend-intro-line"></div>
        <div class="friend-intro-info">
          {{item.info}}
        </div>
      </div>
    </div>
  </div>

<!--  <div class="swiper-container" id="swiper1">-->
<!--    <div class="swiper-wrapper">-->
<!--      <div class="swiper-slide">Slide1</div>-->
<!--      <div class="swiper-slide">Slide2</div>-->
<!--      <div class="swiper-slide">Slide3</div>-->
<!--      <div class="swiper-slide">Slide4</div>-->
<!--      <div class="swiper-slide">Slide5</div>-->
<!--    </div>-->
<!--  </div>-->
</template>
<script>
import Swiper from 'swiper';

export default {
  data() {
    return {
      friendList:[
        {
          title:'某猎头公司创始人',
          info:'猎头行业从流程时代进入协同时代的趋势已很明显，我一直为公司物色一款适合协同时代的系统，这时Dhunting系统的出现让我眼前一亮，给我解决了一个难题。'
        },
        {
          title:'某猎头公司创始人',
          info:'入行十多年，纵观国内外上百家猎头公司的人才系统，也亲自实践使用过数个猎头系统，一直觉得略差火候，很庆幸近期能碰到Dhunting系统，不管是从视觉还是用户体验来看，都很流畅，非常符合现在猎头人的操作手法和使用技巧，高效，协同，响应速度也极快，没想到这么年轻的创始团队能有这么不错的招聘产品问世，很值得入手，简约而不简单  。'
        },
        {
          title:'某猎头公司CEO',
          info:'朋友推荐接触并使用了Dhunting，他们在猎头业务流管理、项目运作管理做的非常仔细和到位，另外这个系统的优势就是有猎头公司内部之间可以相互派单的功能，还是很不错的，让内部资源尽可能的兑现。'
        },
        {
          title: '某人力资源公司创始人',
          info: 'Dhunting的高效协同SaaS管理系统，强力赋能Automne Professional Group全球HR专业服务，帮助APG亚太区C-level高端猎寻快速发展定制了业务模式及管理体系的系统，快速提升Consultant的快速沟通协作效率和团队合作，给APG带来有价值的系统服务。'
        },
        {
          title: '某猎头公司创始人',
          info: '长期以来，猎头行业都有一些痛点没有被解决，Dhunting递航系统是一款基于猎头行业尤其是管理者和团队长痛点入手的系统。解决三个核心问题:管人、管项目、管钱。真是精准又高效，流程清晰，操作极简。据说他们公司无论产品、技术和销售都做过猎头或者加入公司后都要做3-12个月猎头业务，深入了解行业和用户，才能做出好产品！'
        },
        {
          title: '某猎头公司总经理',
          info: '从事猎头行业近20年，从最初的信息化管理需求，到后面的项目运作管理，再到公司运营管理。尝试过3个不同品牌的猎头业务管理系统，接触并使用了Dhunting,我发现他们的系统设计真的很懂“猎头”，无论是猎头顾问操作、业务流管理、项目运作管理、还是公司运营管理方面都很符合我们的具体场景需求，很棒！'
        },
        {
          title: '某外包公司总经理',
          info: '我们选择递航招聘工具，是因为它不仅提供了一个用户友好的界面，还具备强大的功能，使我们的招聘流程更加高效和精准。递航招聘工具以其直观的设计和全面的招聘管理功能脱颖而出。数据分析功能帮助我们洞察招聘超势，从而做出更明智的招聘决策。此外，团队成员之间的协作也变得更加顺畅，共享信息和反馈变得更加容易。'
        }
      ]

    };
  },
  mounted() {
    new Swiper("#swiper1", {
      loop: true,
      // autoplay: {
      //  delay: 1500,
      //  stopOnLastSlide: false,
      //  disableOnInteraction: true,
      //  },
      //不要对slide指定宽度，如果想给它设置宽度，用想指定的宽度/最外层swiper的宽度
      slidesPerView: 1.31,
      centeredSlides: true,
      spaceBetween:24,
      // effect: "coverflow",
      // coverflowEffect: {
      //   rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
      //   stretch: 10, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
      //   depth: 20, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
      //   modifier: 5,
      //   slideShadows: false, //开启slide阴影。默认 true。
      // },
    });
  },
};
</script>
<style scoped>

.swiper{
  width: 100vw!important;
  overflow: visible;
  /*height: 240px!important;*/
  margin-bottom: 32px;
}
.swiper-wrapper{
  /*height: 240px;*/
  width: 100%;
  display: flex!important;
  align-items: stretch!important;
}
.swiper-slide{
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: auto;
}
.friend-intro-title{
  font-size: 14px;
  color: #1B1C33;
}
.friend-intro-line{
  height: 1px;
  background: #EDEDF0;
  margin: 12px 0;
}
.friend-intro-info{
  font-weight: 400;
  font-size: 12px;
  color: #5E5E66;
  line-height: 20px!important;
}
</style>