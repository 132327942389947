<template>
  <div class="manage-article-wrap">
    <div class="manage-article">
      <div class="manage-mid-bottom">
        <div class="manage-article-mid center">
          <div class="manage-article-mid-left">
            <a-button type="primary" @click="goToOfficeWebsite" class="look">
              查看官网
            </a-button>
          </div>
          <div class="manage-article-mid-right">
            <a-input class="input-title" v-model="title" @keyup.enter="handleGetArticle" placeholder="请输入文章标题">
            </a-input>
            <a-button class="search-btn" @click="handleGetArticle" type="primary">
              搜索
            </a-button>
            <a-button class="add-article" @click="handleAdd">新增文章</a-button>
          </div>
        </div>
        <div class="manage-article-bottom">
          <div class="top-title-wrap">
            <div class="top-title">
              <span class="title-line"></span>
              头部文章
            </div>
            <div class="top-title-content">
              <article-card-top @update="handleUpdateAllAndTopArticle" v-for="(item,index) in topArticle" :key="index" :top="item"></article-card-top>
            </div>
          </div>
          <div class="all-title-wrap">
            <div class="top-title">
              <span class="title-line"></span>
              全部文章
            </div>
            <div class="all-title-content">
              <article-card-all @update="handleUpdateAllAndTopArticle" v-for="(item,index) in allArticle" :key="index" :all_article="item"></article-card-all>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <span class="total-num">共有{{total}}条</span>
        <a-pagination
            v-model="current"
            :page-size-options="pageSizeOptions"
            :total="total"
            show-size-changer
            :page-size="pageSize"
            :show-quick-jumper="true"
            @change="onChange"
            @showSizeChange="onShowSizeChange"
        >
          <template slot="buildOptionText" slot-scope="props">
            <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
            <span v-if="props.value === '50'">全部</span>
          </template>
        </a-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import {getTopArticle,getArticle} from '@/api/request'

import articleCardTop from "@/components/articleCardTop";
import articleCardAll from "@/components/articleCardAll";
import Bus from "@/utils/bus";
export default {
  components: {
    articleCardTop,
    articleCardAll
  },
  data(){
    return{
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      current: 1,
      pageSize: 10,
      total: 0,
      title:'',
      topArticle:[],
      allArticle:[]
    }
  },
  methods: {
    goToOfficeWebsite(){
      window.open("https://www.dhunting.com/")
    },
    handleUpdateAllAndTopArticle(){
      this.handleGetTopArticle()
      this.handleGetArticle()
      //同时也得更新缓存中得头部文章信息
      localStorage.setItem('headArticle', JSON.stringify(this.topArticle));
    },
    handleAdd(){
      Bus.$emit('updateKey','2')
      this.$router.push('/manage/addArticle')
    },
    handleGetTopArticle(){
      getTopArticle().then(res=>{
        if(res.data.code===200){
          this.topArticle=res.data.data
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    handleGetArticle(){
      getArticle({
        current:this.current,
        size:this.pageSize,
        title:this.title
      }).then(res=>{
        if(res.data.code===200){
          this.total=res.data.data.total
          this.allArticle=res.data.data.records
          // console.log(res.data.data.total)
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    getDefaultArticle(){
      getArticle({
        current:this.current,
        size:this.pageSize,
        title:undefined
      }).then(res=>{
        if(res.data.code===200){
          this.total=res.data.data.total
          this.allArticle=res.data.data.records
          // console.log(res.data.data.total)
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
    //每页显示多少条
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.handleGetArticle()
    },
    //快速跳转某页
    onChange(pageNumber){
      this.current=pageNumber
      this.handleGetArticle()
    }
  },
  mounted() {
    this.handleGetTopArticle()
    this.getDefaultArticle()
  }
}
</script>

<style scoped>
  .manage-article{
    max-width: 1184px;
  }
  .manage-mid-bottom{
  }
  .manage-article-mid{
    width: 1184px;
    height: 88px;
    padding: 24px;
    /*background: #FFFFFF;*/
    border-radius: 16px;
  }
  .center{
    justify-content: space-between;
  }
  .look{
    background: #0055FF;
    border-radius: 8px;
    width: 104px;
    height: 40px;
  }
  .input-title{
    display: inline-block;
    width: 320px;
    height: 40px;
    border: 1px solid #C8C8CC;
    border-radius: 8px;
  }
  .search-btn{
    width: 104px;
    height: 40px;
    background: #0055FF;
    border-radius: 8px;
    margin: 0 12px;
  }
  .add-article{
    border: 1px solid #EDEDF0;
    border-radius: 8px;
    width: 104px;
    height: 40px;
  }
  .manage-article-bottom{
    padding: 24px;
  }

  .top-title{
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 16px;
  }
  .title-line{
    margin-right: 4px;
    width: 4px;
    height: 16px;
    background: #0055FF;
    border-radius: 2px;
    display: inline-block;
  }
  .top-title-content{
    padding: 16px 106px 24px 106px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .all-title-content{
    padding: 16px 152px 24px 152px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .pagination{
    height: 30px;
    line-height: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .total-num{
    margin-right: 16px;
  }
  /deep/ .ant-pagination{
    display: flex;
  }
  /deep/ .ant-pagination-options{
    display: flex;
  }
</style>