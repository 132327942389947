<template>
  <div>
    <div class="hr-system-wrap">
      <div class="hr-system">
        <div class="hr-system-info">
          <div class="hr-system-title">让<span class="fontBlue">HR</span>获取更多优质简历的</div>
          <div class="hr-system-title">Al招聘助手+智能化招聘管理工具</div>
          <div class="hr-system-tip">不止是招聘管理系统，更是打开了一个新的招聘渠道</div>
          <a-button type="primary" class="hr-sys-btn" @click="toHrSystem">立即体验</a-button>
        </div>
        <img src="//file.dhunting.com/official_website/v1/hr-bg.png" alt="">
      </div>
    </div>
    <div class="why-choose-wrap-wrap">
      <div class="why-choose-wrap">
        <div class="why-choose">
          <div class="why-choose-tittle wow fadeInLeftBig">为什么选择 <span class="fontBlue">递航智能化招聘管理工具</span></div>
          <div class="why-choose-content">
            <div class="why-choose-content-item  wow fadeInUp">
              <div class="icon-title">
                <span class="why-choose-content-item-icon"><iconpark-icon name="why-choose-1"></iconpark-icon></span>
                <span class="choose-item-title">资产管理</span>
              </div>
              <div class="choose-item-info">企业自建人才库，多渠道简历快速导入自有人才库，既能提前对比避免重复下载，浪费成本，又能免费更新人才库。帮助企业快速沉淀人才资产及招聘过程数据，让招聘数据成为企业重要数字资产。</div>
            </div>
            <div class="why-choose-content-item  wow fadeInUp">
              <div class="icon-title">
                <span class="why-choose-content-item-icon"><iconpark-icon name="why-choose-2"></iconpark-icon></span>
                <span class="choose-item-title">协同提效</span>
              </div>
              <div class="choose-item-info">沉浸式流程管理，与用人经理/面试官、供应商0时差协同，打通PC和移动间的各端口，真正实现招聘者、人选、面试官多触点的高效协同。通过递航速聘社交裂变、猎头联盟助力交付，帮助企业获取更多优质简历。</div>
            </div>
            <div class="why-choose-content-item  wow fadeInUp" >
              <div class="icon-title">
                <span class="why-choose-content-item-icon"><iconpark-icon name="why-choose-5"></iconpark-icon></span>
                <span class="choose-item-title">对外开源</span>
              </div>
              <div class="choose-item-info">通过 <span class="fontBlue">递航速聘</span> 快速扩散企业招聘信息，高效整合员工的二度、三度人脉，内推外扩，扩大简历来源，降低简历成本。通过 <span class="fontBlue">猎头联盟</span> 获取更多专业猎头的优质简历推荐，在现有招聘渠道外，有效补充简历来源。</div>
            </div>
            <div class="why-choose-content-item  wow fadeInUp">
              <div class="icon-title">
                <span class="why-choose-icon"><img src="//file.dhunting.com/official_website/v1/why-choose-4.png" alt=""></span>
                <span class="choose-item-title">数据洞察</span>
              </div>
              <div class="choose-item-info">丰富的招聘数据分析维度：招聘失败分析、招聘效率分析、反馈效率分析、渠道分析等，实时查看招聘过程中的每一项关键数据，及时察觉工作能力的优劣势。</div>
            </div>
          </div>
        </div>
        <div class="why-choose-img">
          <img src="//file.dhunting.com/official_website/v1/hr-why-choose.png" alt="">
        </div>
      </div>
    </div>
    <div class="talent-wrap">
      <div class="talent">
        <div class="talent-left wow fadeInLeftBig">
          <div class="talent-title">人才库建设</div>
          <div class="talent-tip">建立企业私有人才库，<div>大幅度节约简历获取成本</div></div>
          <div class="talent-info">标准化、智能化简历库建立，多维度条件设置，高效搜索符合条件的简历，简洁的可视化搜索界面，易于HR上手，符合用户操作习惯。</div>
        </div>
        <div class="talent-right wow fadeInRightBig">
          <img src="//file.dhunting.com/official_website/v1/talent.png" alt="">
        </div>
      </div>
    </div>
    <div class="open-wrap">
      <div class="open">
        <div class="open-left wow fadeInLeftBig">
          <img src="//file.dhunting.com/official_website/v1/talent-left-chart.png" alt="">
        </div>
        <div class="open-middle wow fadeInUp">
          <div class="open-title wow fadeInUp">
            开源
          </div>
          <div class="open-tip wow fadeInUp">对外开源的人才推荐系统</div>
          <div class="open-info wow fadeInUp">独家的社交招聘小程序-递航速聘，可快速扩散企业招聘信息高效整合员工的二度、三度人脉，扩大简历来源。
            <div class="open-line"></div>
            独家的猎头联盟链，可获取更多专业猎头的优质简历推荐，在现有招聘渠道外，有效补充简历来源。
            <div class="open-line"></div>
            智能化推荐算法及人才获取路径追踪技术，降低职位筛选及扩散成本。</div>
        </div>
        <div class="open-right wow fadeInRightBig">
          <img src="//file.dhunting.com/official_website/v1/talent-right-phone.png" alt="">
        </div>
      </div>
    </div>
    <div class="up-wrap">
      <div class="up">
        <div class="up-left wow fadeInRightBig">
          <img src="//file.dhunting.com/official_website/v1/up-left.png" alt="">
        </div>
        <div class="up-right wow fadeInRightBig">
          <div class="up-title wow fadeInUpBig">提效</div>
          <div class="up-tip wow fadeInUpBig">对内提效的人才管理系统</div>
          <div class="up-info wow fadeInUpBig">
            高效协同：超出所有其他招聘系统的流程细分及用户体验，打通PC和移动间的各端口，真正实现招聘者、人选、面试官多触点的高效协同。
            <div class="open-line"></div>
            深度赋能: 独创的CC话术、职位访谈模板、任务目标及待办管理体系，融入了先进的赋能型管理理念让没有任何经验的用户也能做好招聘绩效管理。
            <div class="open-line"></div>
            AI自动化: 企业简历自动化解析入库、智能画像分析、自动设置待办、发送面试提醒，将用户从反复的事务性工作中解脱出来大大提升了招聘效率。
          </div>
        </div>
      </div>
    </div>
    <div class="data-look-wrap">
      <div class="data-look">
        <div class="data-look-left wow fadeInLeftBig">
          <div class="data-look-title wow fadeInUp">数据洞察</div>
          <div class="data-look-tip wow fadeInUp">为提高招聘效率提供科学的决策依据</div>
          <div class="data-look-info wow fadeInUp">
            实时查看招聘过程中的每一项关键数据，及时察觉工作能力的优劣势，更好地扬长避短。
            <div class="open-line"></div>
            推荐量、面试量、推荐/面试反馈效率、沟通记录、电话录音等招聘数据全部留痕，便于针对每个HR、用人经理实现全时段复盘，具体问题具体分析。
            <div class="open-line"></div>
            针对职位、人选、渠道等全方位的数据洞察，便于分析出最优的招聘及用人策略，比如职位要求、薪酬待遇合理性、各渠道ROI等。
          </div>
        </div>
        <div class="data-look-right wow fadeInRightBig">
          <img src="//file.dhunting.com/official_website/v1/data-look.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
    this.$wow.init()
    this.$bus.$emit("changeTabsIndex", 2);
  },
  methods: {
    // 跳转HR预约演示
    toHrSystem() {
      // let routerUrl = this.$router.resolve({path: '/productContact', query: { systemType: 2 }});
      // window.open(routerUrl.href, '_blank');
      this.$bus.$emit('openHrTable')
    },
  }
}
</script>
<style lang="less" scoped>
  .hr-system-wrap,
  .why-choose-wrap-wrap,
  .talent-wrap,
  .open-wrap,
  .up-wrap,
  .data-look-wrap
  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .talent-wrap{
    background-image: url("//file.dhunting.com/official_website/v1/resume-bg.png");
    background-size: 100% 100%;
  }
  .open-wrap{
    background-image: url("//file.dhunting.com/official_website/v1/message-bg.png");
    background-size: 100% 100%;
  }
  .up-wrap{
    background-image: url("//file.dhunting.com/official_website/v1/message-bg.png");
    background-size: 100% 100%;
  }
  .data-look-wrap{
    background-image: url("//file.dhunting.com/official_website/v1/resume-bg.png");
    background-size: 100% 100%;
  }

  .hr-system,
  .why-choose-wrap,
  .talent,
  .open,
  .up,
  .data-look
  {
    width: 100%;
    max-width: 1440Px;
  }
  .hr-system{
    height: 928px;
    position: relative;
  }
  .hr-system  img{
    margin-top: 250px;
    height: auto;
  }
  .hr-sys-btn{
    width: 300px!important;
    height: 56px!important;
    font-weight: 400!important;
    font-size: 20px!important;
    line-height: 56px;
    color: #FFFFFF!important;
    border-radius: 8px!important;
  }
  .hr-system-info{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction:column ;
    align-items: center;
    position: absolute;
    top: 100px;
    //left: 446px;
  }
  .hr-system-title{
    font-weight: 600;
    font-size: 48px;
    line-height: 80px;
    color: #1B1C33;
  }
  .hr-system-tip{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #5E5E66;
    margin-top: 54px;
    margin-bottom: 60px;
  }
.fontBlue{
  color: rgba(0, 85, 255, 1);
}
  .talent{
    display: flex;
    width: 100%;
    height: 570px;
  }
  .talent-left{
    width: 50%;
    padding: 138px 40px 0 120px;
    box-sizing: border-box;
  }
  .talent-title,.open-title,.up-title,.data-look-title{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #0055FF;
  }
  .talent-tip,.open-tip,.up-tip,.data-look-tip{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #1B1C33;
    margin-top: 24px;
    margin-bottom: 32px;
  }
  .talent-info,.open-info,.up-info,.data-look-info{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #5E5E66;
  }
  .talent-right{
    width: 50%;
    padding: 60px 60px 60px 0;
    box-sizing: border-box;
  }
  .open{
    display: flex;
    padding: 40px 0;
    width: 100%;
    height: 684px;
  }
  .open-left{
    flex: 1;
    padding: 85px 0;
    position: relative;
    /*transform: translateX(-20%);*/
    left: -100px;
  }
  .open-middle{
    flex: 1;
    padding: 40px ;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .open-right{
    flex: 0.5;
    position: relative;
    left: 130px;
    /*transform: translateX(50%);*/
  }
  .open-line{
    height: 20px;
  }
  .up{
    display: flex;
    padding: 60px;
    width: 100%;
    height: 700px;
  }
  .up-left{
    flex: 2;
    img{
      height: auto;
    }
  }
  .up-right{
    flex: 1;
    padding-left: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .data-look{
    display: flex;
    padding: 40px;
    width: 100%;
    height: 760px;
  }
  .data-look-left{
    flex: 1;
    padding: 50px;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .data-look-right{
    flex: 1;
  }
  .why-choose-wrap{
    padding: 60px 0px 0 120px;
    display: flex;
    align-items: center;
  }
  .why-choose{
    /*flex: 1.2;*/
    width: 782px;
  }
  .why-choose-tittle{
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    color: #1B1C33;
    margin-bottom: 60px;
  }
  .why-choose-content{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .why-choose-content-item{
    padding-right: 80px;
    padding-bottom: 18px;
    width: 50%;
    box-sizing: border-box;
    flex-wrap: wrap;
    font-size: 24px;
    .icon-title {
      display: flex;
      align-items: center;
      .why-choose-content-item-icon{
        font-size: 32px!important;
        display: flex;
        align-items:  center;
      }
      .why-choose-icon{
        font-size: 20px!important;
        display: inline-block;
      }
    }
  }
  .why-choose-icon{
    width: 32px;
    height: 32px;
    display: inline-block;
  }
  .choose-item-title{
    font-weight: 600;
    font-size: 24px;
    color: #1B1C33;
    margin-left: 16px;
  }
  .container-icon{

  }
  .choose-item-info{
    margin-top: 26px;
    font-size: 14px;
    line-height: 30px;
    color: #5E5E66;
  }
  .why-choose-img{
    width: 538px!important;
  }
  .why-choose-img > img{
    width: 100%;
    height: 100%;
  }
</style>
