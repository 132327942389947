<template>
  <div>
    <indexHeader v-if="!$route.meta.isArticle"></indexHeader>
    <rightBtn v-if="$route.meta.hasBtn"></rightBtn>
    <router-view></router-view>
    <indexFooter v-if="!$route.meta.isArticle"></indexFooter>
  </div>
</template>

<script>
import indexHeader from "@/components/indexHeader/indexHeader";
import indexFooter from '../../components/indexFooter/indexFooter.vue';
import rightBtn from '@/components/rightBtn/rightBtn'
import {getTopArticle} from "@/api/request";
export default {
  name: 'App',
  components: {
    indexHeader,
    indexFooter,
    rightBtn
  },
  data() {
    return {
      element2:undefined,
    };
  },
  mounted() {
    //头部文章在很多页面都会用到，存到缓存中
    this.handleHeadArticle()
    this.element2=document.querySelector('.headerwrap');
    window.addEventListener('scroll', this.homeScroll)
  },
  methods:{
    handleHeadArticle(){
      getTopArticle().then(res=>{
        if(res.data.code===200){
          // this.headArticle=res.data.data
          localStorage.setItem('headArticle', JSON.stringify(res.data.data));
        }
        else {
          this.$message.error(res.data.message);
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>

</style>
