import axios from "axios";
import qs from 'qs'


const { VUE_APP_BASE_API_URL } = process.env; //获取环境和地址
const baseURL = VUE_APP_BASE_API_URL ;
const request = axios.create({
    baseURL: baseURL,
    paramsSerializer(params){
        return qs.stringify(params, {arrayFormat: 'repeat'});
    }
});

export function axiosPost(url, data) {
    return new Promise((resolve, reject) => {
        request
            .post(url, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function axiosGet(url, params = {}) {
    return new Promise((resolve, reject) => {
        request
            .get(url, {
                params: params,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export default request;